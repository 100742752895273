import React, { FunctionComponent } from 'react';

interface Props {
  percentage: number;
}

const CircleProgressBar: FunctionComponent<Props> = ({ percentage }) => {
  const radius = 50; // Set the radius of the circle
  const circumference = 2 * Math.PI * radius; // Calculate the circumference of the circle
  const strokeDashoffset = circumference - (percentage / 100) * circumference; // Calculate the offset of the stroke based on the percentage

  return (
    <svg
      className="mx-auto"
      width={radius * 2}
      height={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      fill="none"
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius - 5} // Set the radius of the inner circle
        stroke="#E5E7EB"
        strokeWidth="10"
        fill="transparent" // Add this to make the background transparent
      />
      <circle
        cx={radius}
        cy={radius}
        r={radius - 5} // Set the radius of the inner circle
        stroke="#22C55E" // Set the color of the progress bar
        strokeWidth="10"
        strokeDasharray={`${circumference} ${circumference}`} // Set the size of gap and dash
        strokeDashoffset={strokeDashoffset}
        fill="transparent" // Add this to make the fill transparent
      />
      <text
        x={radius}
        y={radius}
        dominantBaseline="middle" // Center the text vertically
        textAnchor="middle" // Center the text horizontally
        className="text-4xl font-bold text-gray-800 text-center"
      >
        {`${percentage}%`}
      </text>
    </svg>
  );
};

export default CircleProgressBar;
