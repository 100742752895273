import React from 'react'
import MyRouter from 'routers/index'
// import AuthProvider from './components/AuthProvider/AuthProvider'
import bg from 'images/basko-assets/images/gradient.png'
import bg1 from 'images/basko-assets/images/headers/bg-garadient.jpg'
import avatarImg from 'images/basko-assets/images/sign-up/avatar3.png'
import logoRightlyGuided from 'images/rightly-guided-logo.svg'

import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import NcImage from 'shared/NcImage/NcImage'
import { JoinCommunityPopup } from '../src/containers/PageJoinCommunity/JoinCommunityPopup'

function App() {
  return (
    <div className='flex flex-col text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200'>
      {/* <AuthProvider> */}
      <MyRouter />
      {/* </AuthProvider> */}
      {/* <section className=' flex-grow'>
        <NcImage className='w-20 mx-auto mt-10' src={logoRightlyGuided} />
        <div className='flex container'>
          <div className='text-xs text-center mt-4 px-8 bg-slate-800 text-white mx-auto max-w-xs py-2'>
            Nurturing Faith, Empowering Success
          </div>
        </div>

        <div className='pt-16 pb-28 bg-gradient-to-t flex flex-col h-full from-gray-100 to-white overflow-hidden dark:bg-slate-700 z-20'>
          <div className='container px-4 mx-auto flex flex-col justify-center'>
            <div className='max-w-2xl mx-auto text-center'>
              <p className='text-lg mb-2 font-semibold'>Launching 1st Day of the Islamic New Year</p>
              <h1 className='font-heading mb-4 text-4xl md:text-7xl tracking-tighter font-bold'>1445 Hijri</h1>
              <p className='text-sm mb-8 font-semibold'>Tuesday "8th July 2023</p>
              <p className='text-lg font-semibold'>Coming Soon:</p>
              <p className='mb-10 max-w-md mx-auto text-sm'>
                Empowering Muslim Community Success with AI, Social Groups, and Expert Collaboration. Built by Scholars, Business Owners,
                Investors, and Industry High Achievers.
              </p>

              <JoinCommunityPopup />

              <p className='mb-20 text-gray-600 text-sm tracking-tight'>Get involved today by joining our waitlist</p>
            </div>
            <div className='max-w-6xl mx-auto'>
              <img className='sm:max-w-lg max-w-full mx-auto' src='https://storage.googleapis.com/videos-rg/card.gif' alt='card' />
            </div>
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default App
