import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import emailjs, { init } from 'emailjs-com'
import photoArticleLarge from 'images/aboutPageImg.png'
import iconImg from 'images/flaro-assets/images/features/chat.svg'
import blueLightImg from 'images/saturn-assets/images/content/blue-light-right.png'
import startLeft from 'images/saturn-assets/images/content/stars-left-top.svg'
import avatarImg from 'images/saturn-assets/images/cta/avatar3.png'
import React, { FC, FormEvent, useState } from 'react'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'
import AreasSection from './AreasSection'
import ProductsBlockView from './ProductsBlock'
import ShowAppSamplesBusiness from './ShowAppSamplesBusiness'
import ShowAppSamples from './ShowAppSamplesEdu'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const AboutTextBlock = () => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[0].value
    const message = 'Early Access Waitlist'

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <section className='relative py-20 lg:py-24 overflow-hidden dark:bg-slate-800'>
      <div className='bg-gradient-to-b from-white to-slate-100 dark:bg-slate-800 dark:from-slate-800 dark:to-slate-800'>
        <img className='absolute top-0 left-0 mt-44' src={startLeft} alt='' />
        <img className='absolute top-0 right-0 mt-52' src={blueLightImg} alt='' />
        <div className='relative container px-4 mx-auto '>
          <div className='mx-auto'>
            <div className='flex flex-wrap mb-16 max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto'>
              <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                <div className='max-w-lg'>
                  <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full'>
                    Business
                  </span>
                  <h2 className='font-heading text-3xl xs:text-4xl font-base dark:text-white dark:text-opacity-60 font-semibold text-gray-800'>
                    Business decisions made simple
                  </h2>
                  <p className='text-lg font-base dark:text-white dark:text-opacity-60 mb-5'>
                    'The trustworthy, honest Muslim merchant will be with the martyrs on the Day of Resurrection." [Sunan Ibn Majah 2139
                    Book 12, Hadith 3]
                  </p>
                  {/* <form className='flex flex-wrap mb-7 -m-1.5 mt-6' action='#' method='post' onSubmit={handleSubmit}>
                    <div className='w-full lg:w-64 p-1.5'>
                      <input
                        className='text-gray-500 px-5 py-4 w-full placeholder-gray-500 outline-none focus:ring-4 focus:ring-indigo-400 rounded'
                        type='email'
                        placeholder='Enter email to get started'
                      />
                    </div>
                    <div className='w-full lg:w-auto p-1.5'>
                      <button
                        className='group relative font-heading px-6 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold tracking-px bg-gray-900 hover:bg-gray-800 dark:bg-slate-700 overflow-hidden rounded-md'
                        type='submit'
                        disabled={formStatus === 'sending'}
                      >
                        <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gradient-indigo'></div>
                        <p className='relative z-10'>Join</p>
                      </button>
                    </div>
                    {formStatus === 'success' && (
                      <p className=' mt-1 text-center'>
                        <span className=' text-green-500 rounded-xl text-xs12 py-2 px-2 w-full'>Email sent successfully!</span>
                      </p>
                    )}
                    {formStatus === 'error' && (
                      <p className=' mt-1 text-center'>
                        <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                          There was an error sending the email. Please try again.
                        </span>
                      </p>
                    )}
                  </form> */}
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div className='max-w-lg'>
                  <p className='text-lg font-semibold font-base dark:text-white dark:text-opacity-60 mb-5'>
                    For Muslim entrepreneurs, navigating the complexities of modern decision-making can come with many challenges.
                  </p>

                  <p className='text-lg font-base dark:text-white dark:text-opacity-60'>
                    Our network of global scholars and experts is dedicated to assisting you in achieving business growth, conducting
                    transactions in accordance with Sharia principles, and providing guidance on expanding your business within the bounds
                    of Islamic law.
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-center justify-center '>
              {/* <img className='block w-full rounded-t-xl' src={photoArticleLarge} alt='' /> */}
              <div className='py-10 bg-white overflow-hidden rounded-xl dark:bg-slate-900'>
                <div className='px-8 sm:px-16 mx-auto'>
                  <div className='flex flex-wrap items-center -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Join our waitlist to gain early access</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Meet like-minded people</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Courses and Events led by Scholars & Experts</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ShowAppSamplesBusiness />
      <div className=' relative bg-gradient-to-t from-white to-slate-100 dark:from-slate-800 dark:to-slate-800'>
        <BgGlassmorphism />
        <div className='relative'>
          <AreasSection />
        </div>
      </div>
      <AboutHowItWorks />
      {/* <AboutIntro /> */}
      {/* <CommunityBlock /> */}
      <ProductsBlockView />
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
        <GetInvolvedBlock />
      </div>
      <div className='relative'>
        <DonateTypes />
      </div>
    </section>
  )
}

export default AboutTextBlock
