import { useEffect, useRef, useState } from 'react'

// ChatGpt Icons
import chatGptLogo from 'images/ChatGPT_logo.svg'
import abstractIcon1 from 'images/abstractIcon1.svg'
import abstractIcon2 from 'images/abstractIcon2.svg'
import abstractIcon3 from 'images/abstractIcon3.svg'
import abstractIcon4 from 'images/abstractIcon4.svg'
import bingLogo from 'images/bingLogo.png'
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  VKIcon,
  ViberIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from 'react-share'

// Tootle
type ToggleProps = {
  selected: boolean
  onToggle: () => void
  title: string
  isLast?: boolean
}

const Toggle: React.FC<ToggleProps> = ({ selected, onToggle, title, isLast }) => {
  const [randomNumber, setRandomNumber] = useState<string | null>(null)

  const handleButtonClick = () => {
    const numbers = ['0.10p', '0.15p', '0.20p', '0.25p', '0.29p', '0.30p', '0.35p', '0.39p', '0.45p']
    const randomIndex = Math.floor(Math.random() * numbers.length)
    const selectedNumber = numbers[randomIndex]
    setRandomNumber(selectedNumber)
  }

  return (
    <div className='flex items-center'>
      <button
        className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${
          selected ? 'bg-green-400' : 'bg-gray-200'
        }`}
        onClick={onToggle}
      >
        <span
          className={`inline-block w-4 h-4 transition-transform rounded-full transform ${
            selected ? 'translate-x-6 bg-white' : 'translate-x-1 bg-gray-500'
          }`}
        />
      </button>
      <span className='text-sm ml-2 font-medium' onClick={onToggle}>
        {title}
      </span>
      {isLast && (
        <button className='text-xs text-slate-500 px-1 ml-1 flex items-center' onClick={handleButtonClick}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-8 h-8 z-20'>
            <path d='M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z' />
            <path
              fillRule='evenodd'
              d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z'
              clipRule='evenodd'
            />
          </svg>

          {randomNumber && (
            <span className='bg-slate-500 text-white text-xs10 -ml-2 px-2 py-0.5 pl-1 z-10 rounded-r-lg'>{randomNumber}</span>
          )}
        </button>
      )}
    </div>
  )
}

// Option grid
type OptionProps = {
  id: string
  title: string
  text: string
  imgSrc: string
}

const Option: React.FC<OptionProps> = ({ id, title, text, imgSrc }) => {
  return (
    <li>
      <input type='checkbox' id={id} value='' className='hidden peer' required />
      <label
        htmlFor={id}
        className='inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700'
      >
        <div className='block'>
          <img className='w-6 mb-2' src={imgSrc} alt='' />

          <div className='w-full text-md font-semibold'>{title}</div>
          <div className='w-full text-sm'>{text}</div>
        </div>
      </label>
    </li>
  )
}

const countries: string[] = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. "Swaziland")',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

const languages: string[] = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'Hindi',
  'Arabic',
  'Portuguese',
  'Bengali',
  'Russian',
  'Japanese',
  'German',
  'French',
  'Italian',
  'Korean',
  'Turkish',
  'Dutch',
  'Polish',
  'Swedish',
  'Vietnamese',
  'Persian (Farsi)',
  'Ukrainian',
]

function LanguageSettings() {
  const [search, setSearch] = useState('')
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const searchRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const filteredLanguages = languages.filter(
    language => language.toLowerCase().includes(search.toLowerCase()) && !selectedLanguages.includes(language)
  )

  const addLanguage = (language: string) => {
    setSelectedLanguages([...selectedLanguages, language])
    setSearch('')
    setIsOpen(false)
  }

  const removeLanguage = (language: string) => {
    setSelectedLanguages(selectedLanguages.filter(c => c !== language))
    setSearch('')
    setIsOpen(true)
  }

  const handleClickOutside = (event: any) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Languages:</h3>
      <div className='flex flex-col items-start py-2 px-2'>
        <div className='relative w-full'>
          <input
            type='text'
            className='border rounded py-4 px-4 w-full'
            placeholder='Search countries...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            onFocus={() => setIsOpen(true)}
            ref={searchRef}
          />
          {isOpen && (
            <div ref={dropdownRef} className='absolute left-0 mt-2 w-full border bg-white z-10 max-h-48 overflow-auto'>
              {filteredLanguages.map(language => (
                <div key={language} className='px-2 py-2 hover:bg-gray-100 cursor-pointer' onClick={() => addLanguage(language)}>
                  {language}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='mt-4 px-2 w-full flex relative gap-4 flex-wrap'>
          {selectedLanguages.map(language => (
            <div key={language} className='border rounded p-1 flex justify-between items-center'>
              <span className='text-xs'>{language}</span>
              <button
                className='ml-2 text-slate-800 w-4 h-4 rounded-full text-xs flex items-center justify-center'
                onClick={() => removeLanguage(language)}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-6 h-6'>
                  <path
                    fillRule='evenodd'
                    d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function CountrySettings() {
  const [search, setSearch] = useState('')
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const searchRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const filteredCountries = countries.filter(
    country => country.toLowerCase().includes(search.toLowerCase()) && !selectedCountries.includes(country)
  )

  const addCountry = (country: string) => {
    setSelectedCountries([...selectedCountries, country])
    setSearch('')
    setIsOpen(false)
  }

  const removeCountry = (country: string) => {
    setSelectedCountries(selectedCountries.filter(c => c !== country))
    setSearch('')
    setIsOpen(true)
  }

  const handleClickOutside = (event: any) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Countries</h3>
      <div className='flex flex-col items-start py-2 px-2'>
        <div className='relative w-full'>
          <input
            type='text'
            className='border rounded py-4 px-4 w-full'
            placeholder='Search countries...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            onFocus={() => setIsOpen(true)}
            ref={searchRef}
          />
          {isOpen && (
            <div ref={dropdownRef} className='absolute left-0 mt-2 w-full border bg-white z-10 max-h-48 overflow-auto'>
              {filteredCountries.map(country => (
                <div key={country} className='p-1 hover:bg-gray-200 cursor-pointer' onClick={() => addCountry(country)}>
                  {country}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='mt-4 px-2 w-full flex relative gap-4 flex-wrap'>
          {selectedCountries.map(country => (
            <div key={country} className='border rounded p-1 flex justify-between items-center'>
              <span className='text-xs'>{country}</span>
              <button
                className='ml-2 text-slate-800 w-4 h-4 rounded-full text-xs flex items-center justify-center'
                onClick={() => removeCountry(country)}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-6 h-6'>
                  <path
                    fillRule='evenodd'
                    d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function TranslateSettings() {
  const [selectedTranslateOptions, setSelectedTranslateOptions] = useState(0)

  const translateSettingsOptions = [{ title: 'Setting one' }, { title: 'Professional' }]

  const handleModelToggle = (index: number) => {
    setSelectedTranslateOptions(index)
  }

  return (
    <div>
      <LanguageSettings />
      <CountrySettings />
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Tools:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {translateSettingsOptions.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedTranslateOptions === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === translateSettingsOptions.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function SocialMediaSettings() {
  const [selectedMediaSettings, setSelectedMediaSettings] = useState(0)

  const socialMediaSettingsOptions = [{ title: 'IOpn' }, { title: 'Professional' }]

  const handleModelToggle = (index: number) => {
    setSelectedMediaSettings(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Social Media:</h3>
      <div className='flex space-x-2 px-4 py-2'>
        <FacebookIcon size={32} round />
        <TwitterIcon size={32} round />
        <LinkedinIcon size={32} round />
        <WhatsappIcon size={32} round />
        <EmailIcon size={32} round />

        <FacebookMessengerIcon size={32} round />
        <TelegramIcon size={32} round />
        <HatenaIcon size={32} round />
        <InstapaperIcon size={32} round />
        <LineIcon size={32} round />
        <EmailIcon size={32} round />
        <LivejournalIcon size={32} round />
        <OKIcon size={32} round />
        <PocketIcon size={32} round />
        <TwitterIcon size={32} round />

        <TwitterIcon size={32} round />

        <ViberIcon size={32} round />

        <VKIcon size={32} round />

        <WeiboIcon size={32} round />

        <WhatsappIcon size={32} round />

        <WorkplaceIcon size={32} round />
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Tools:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {socialMediaSettingsOptions.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedMediaSettings === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === socialMediaSettingsOptions.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function ChatGptSettings() {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedDraftOption, setSelectedDraftOption] = useState(0)

  const chatGptModels = [{ title: 'ChatGPT 3.5' }, { title: 'ChatGPT 4' }]
  const chatGptOptions = [{ title: 'Create Versions' }, { title: 'Translate' }]

  const chatGptPlugins = [
    { id: 'browse-bing', title: 'Browse with Bing', text: 'Use Microsoft Bing features.', imgSrc: bingLogo },
    { id: 'speak-voice', title: 'Speak', text: 'Use text to voice features.', imgSrc: bingLogo },
    { id: 'schoolar', title: 'Scholar AI', text: 'Unleash scientific research tools.', imgSrc: bingLogo },
    // add more options as needed
  ]

  const handleModelToggle = (index: number) => {
    setSelectedModel(index)
  }

  const handleDraftOptionToggle = (index: number) => {
    setSelectedDraftOption(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {chatGptModels.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedModel === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === chatGptModels.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Plugins:</h3>
      <ul className='grid w-full gap-6 md:grid-cols-3 px-4 py-2 pb-4'>
        {chatGptPlugins.map(option => (
          <Option key={option.id} {...option} />
        ))}
      </ul>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start py-6 px-4 space-x-6'>
          {chatGptOptions.map((toggle, index) => (
            <Toggle
              key={index}
              selected={selectedDraftOption === index}
              onToggle={() => handleDraftOptionToggle(index)}
              title={toggle.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function ImageSettings() {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedDraftOption, setSelectedDraftOption] = useState(0)

  const imageSettingsModels = [{ title: 'Standard' }, { title: 'Professional' }]
  const imageSettingsOptions = [{ title: 'Medium Resolution' }, { title: 'High Resolution' }, { title: 'Best Resolution' }]

  const imageSettingsPlugins = [
    { id: '1', title: 'AI Generator', text: 'Use Ai to generate', imgSrc: abstractIcon3 },
    { id: '2', title: 'Real Images', text: 'Use real images', imgSrc: abstractIcon3 },
    // add more options as needed
  ]

  const handleModelToggle = (index: number) => {
    setSelectedModel(index)
  }

  const handleDraftOptionToggle = (index: number) => {
    setSelectedDraftOption(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {imageSettingsModels.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedModel === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === imageSettingsModels.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Plugins:</h3>
      <ul className='grid w-full gap-6 md:grid-cols-3 px-4 py-2 pb-4'>
        {imageSettingsPlugins.map(option => (
          <Option key={option.id} {...option} />
        ))}
      </ul>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start py-6 px-4 space-x-6'>
          {imageSettingsOptions.map((toggle, index) => (
            <Toggle
              key={index}
              selected={selectedDraftOption === index}
              onToggle={() => handleDraftOptionToggle(index)}
              title={toggle.title}
              isLast={index === imageSettingsOptions.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function GoogleBardSettings() {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedDraftOption, setSelectedDraftOption] = useState(0)

  const googleBardModels = [{ title: 'Free Version' }, { title: 'Premium Version' }]
  const googleBardDraftOptions = [{ title: 'Resume versions' }, { title: 'Extended versions' }, { title: 'All versions' }]

  const googleBardOptions = [
    { id: 'react-option', title: 'Google Docs', text: 'Use all google docs apps.', imgSrc: bingLogo },
    { id: 'flowbite-option', title: 'Gmail', text: 'Use gmail email account.', imgSrc: bingLogo },
    { id: 'angular-option', title: 'Save History', text: 'Save activity on google.', imgSrc: bingLogo },
    // add more options as needed
  ]

  const handleModelToggle = (index: number) => {
    setSelectedModel(index)
  }

  const handleDraftOptionToggle = (index: number) => {
    setSelectedDraftOption(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {googleBardModels.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedModel === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === googleBardModels.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Plugins:</h3>
      <ul className='grid w-full gap-6 md:grid-cols-3 px-4 py-2 pb-4'>
        {googleBardOptions.map(option => (
          <Option key={option.id} {...option} />
        ))}
      </ul>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start py-6 px-4 space-x-6'>
          {googleBardDraftOptions.map((toggle, index) => (
            <Toggle
              key={index}
              selected={selectedDraftOption === index}
              onToggle={() => handleDraftOptionToggle(index)}
              title={toggle.title}
              isLast={index === googleBardDraftOptions.length - 1}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function TextGeneratorSettings() {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedDraftOption, setSelectedDraftOption] = useState(0)

  const textGeneratorModels = [{ title: 'ChatGPT 3.5' }, { title: 'Chat GPT 4' }]
  const textGeneratorOptions = [{ title: 'Resume' }, { title: 'Extended' }, { title: 'Complete' }]

  const textGeneratorPlugins = [
    { id: '1', title: 'Text Plugin', text: 'Your to text plugin', imgSrc: abstractIcon2 },
    { id: '2', title: 'Text Plugin', text: 'Your to text plugin', imgSrc: abstractIcon2 },
    { id: '3', title: 'Text Plugin', text: 'Your to text plugin', imgSrc: abstractIcon2 },
    // add more options as needed
  ]

  const handleModelToggle = (index: number) => {
    setSelectedModel(index)
  }

  const handleDraftOptionToggle = (index: number) => {
    setSelectedDraftOption(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {textGeneratorModels.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedModel === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === textGeneratorModels.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Plugins:</h3>
      <ul className='grid w-full gap-6 md:grid-cols-3 px-4 py-2 pb-4'>
        {textGeneratorPlugins.map(option => (
          <Option key={option.id} {...option} />
        ))}
      </ul>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start py-6 px-4 space-x-6'>
          {textGeneratorOptions.map((toggle, index) => (
            <Toggle
              key={index}
              selected={selectedDraftOption === index}
              onToggle={() => handleDraftOptionToggle(index)}
              title={toggle.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

function VideoGeneratorSettings() {
  const [selectedModel, setSelectedModel] = useState(0)
  const [selectedDraftOption, setSelectedDraftOption] = useState(0)

  const videoGeneratorModels = [{ title: '720p' }, { title: '1080p' }, { title: '4K' }]
  const videoGeneratorOptions = [{ title: 'Get Copyrights' }, { title: 'Get meta information' }, { title: 'Multiple versions' }]

  const videoGeneratorPlugins = [
    { id: '1', title: 'Realistic', text: 'Get a realistic image generated.', imgSrc: abstractIcon1 },
    { id: '2', title: 'Abstract', text: 'Get a abstract image generated', imgSrc: abstractIcon1 },
    { id: '3', title: '3D', text: 'Get 3D images generated', imgSrc: abstractIcon1 },
    // add more options as needed
  ]

  const handleModelToggle = (index: number) => {
    setSelectedModel(index)
  }

  const handleDraftOptionToggle = (index: number) => {
    setSelectedDraftOption(index)
  }

  return (
    <div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Models:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start'>
          <div className='flex items-center justify-start py-6 px-4 space-x-6'>
            {videoGeneratorModels.map((toggle, index) => (
              <Toggle
                key={index}
                selected={selectedModel === index}
                onToggle={() => handleModelToggle(index)}
                title={toggle.title}
                isLast={index === videoGeneratorModels.length - 1}
              />
            ))}
          </div>
        </div>
      </div>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Plugins:</h3>
      <ul className='grid w-full gap-6 md:grid-cols-3 px-4 py-2 pb-4'>
        {videoGeneratorPlugins.map(option => (
          <Option key={option.id} {...option} />
        ))}
      </ul>
      <h3 className='mr-4 block py-4 px-4 text-lg font-semibold'>Available Tools:</h3>
      <div className='flex items-center justify-start'>
        <div className='flex items-center justify-start py-6 px-4 space-x-6'>
          {videoGeneratorOptions.map((toggle, index) => (
            <Toggle
              key={index}
              selected={selectedDraftOption === index}
              onToggle={() => handleDraftOptionToggle(index)}
              title={toggle.title}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export {
  ChatGptSettings,
  GoogleBardSettings,
  ImageSettings,
  SocialMediaSettings,
  TextGeneratorSettings,
  TranslateSettings,
  VideoGeneratorSettings,
}
