import HeroSearchForm2MobileFactory from 'components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory'
import React, { FC } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Logo from 'shared/Logo/Logo'
import MenuBar from 'shared/MenuBar/MenuBar'
import Navigation from 'shared/Navigation/Navigation'
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode'
import DropdownWhatToDo from './DropdownWhatToDo'

// TODO: Main Nav Default

export interface MainNav1Props {
  className?: string
}

const MainNav: FC<MainNav1Props> = ({ className = '' }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className='hidden md:flex px-4 lg:container py-4 lg:py-5 relative justify-between items-center'>
        <div className='hidden md:flex justify-between flex-1 items-center space-x-4 sm:space-x-10'>
          <div className='flex'>
            {' '}
            <Logo />
            <div className='ml-8'>
              <DropdownWhatToDo />
            </div>
          </div>

          <Navigation />
        </div>

        <div className='hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100'>
          <div className='hidden xl:flex items-center space-x-0.5'>
            <SwitchDarkMode />

            <div className='px-1' />
            {/* <ButtonPrimary href="/login">Sign up</ButtonPrimary> */}
            <MenuBar />
          </div>
          <div className='flex xl:hidden items-center'>
            {/* <ButtonPrimary href='/login'>Sign up</ButtonPrimary> */}
            <SwitchDarkMode />
            <div className='px-0.5' />
            <MenuBar />
          </div>
        </div>
      </div>
      <div className=' md:hidden px-4 container py-4 relative flex justify-between items-center'>
        <div className='flex justify-start flex-6 items-center space-x-4 sm:space-x-10'>
          <Logo />
          <DropdownWhatToDo />
        </div>
        <div className='flex justify-end flex-6 items-center space-x-4 sm:space-x-10'>
          {/* <ButtonPrimary href='/login'>Sign up</ButtonPrimary> */}
          <MenuBar />
        </div>
      </div>
    </div>
  )
}

export default MainNav
