import Label from 'components/Label/Label'
import React, { FC, useState, useEffect } from 'react'
import Avatar from 'shared/Avatar/Avatar'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import Select from 'shared/Select/Select'
import Textarea from 'shared/Textarea/Textarea'
import CommonLayout from './CommonLayout'
import { Helmet } from 'react-helmet'
// import { useAuthContext } from 'context/AuthContext'
import { API } from '../../constant'
import { getToken } from '../../helpers'
import { useAuthContext } from '../../context/AuthContext'

// TODO: Account page Done

export interface AccountPageProps {
  className?: string
}

let id = null
let name = ''
let username = ''
let email = ''
let about = ''
let role = ''

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
  const [loading, setLoading] = useState(false)
  const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)
    if (!isLoggedIn) {
      window.location.href = '/login'
    }
  }, [])

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    // save the user and jwt to the state userData if any of them is not null/undefined
    if (user && jwt) {
      setUser(user)
      console.log('USER DATA TO CHECK - ' + user)
      console.log('-----------------------')
      setUserData(JSON.parse(user))

      // distructuring the user data
      const {
        id: userAuthId,
        name: userAuthName,
        username: userAuthUsername,
        email: userAuthEmail,
        about: userAuthAbout,
        role: userAuthRole,
      } = JSON.parse(user)

      // Assigning the destructured properties to the variables declared outside the function
      id = userAuthId
      name = userAuthName
      username = userAuthUsername
      email = userAuthEmail
      about = userAuthAbout
      role = userAuthRole

      setIsLogged(true)
      console.log('-----------------------')

      console.log('userData ' + typeof userData)
    }

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  // updating profile
  const handleProfileUpdate = async (event: any) => {
    event.preventDefault() // prevent default form submission behavior

    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData.entries())

    console.log(data)

    setLoading(true)
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
      const responseData = await response.json()

      setUser(responseData)
      setUserData(responseData)
      setLoading(false)
      alert('Data saved successfully!')
      // TODO: Remove logs in production
      console.log('log here ', responseData)

      // checking if data is different that local storage and update it
      if (JSON.stringify(responseData) !== JSON.stringify(userData)) {
        localStorage.setItem('userData', JSON.stringify(responseData))
      }
    } catch (error) {
      console.error(error) // corrected error object
      console.log('Error While Updating the Profile!')
    } finally {
      setLoading(false)
    }
  }

  console.log('user ', user)
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id='AccountPage'>
      <Helmet>
        <title>Account | Rightly Guided </title>
      </Helmet>
      <CommonLayout>
        <div className='space-y-6 sm:space-y-8'>
          {/* HEADING */}
          <h2 className='text-3xl font-semibold'>Account infomation</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          <form onSubmit={handleProfileUpdate}>
            <div className='flex flex-col md:flex-row'>
              {/* <div className='flex-shrink-0 flex items-start'>
                <div className='relative rounded-full overflow-hidden flex'>
                  <Avatar sizeClass='w-32 h-32' />
                  <div className='absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer'>
                    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125'
                        stroke='currentColor'
                        strokeWidth={1.5}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>

                    <span className='mt-1 text-xs'>Change Image</span>
                  </div>
                  <input type='file' className='absolute inset-0 opacity-0 cursor-pointer' />
                </div>
              </div> */}
              <div className='flex-grow mt-10 md:mt-0 max-w-3xl space-y-6'>
                <div>
                  <Label>Name</Label>
                  <Input name='name' className='mt-1.5' defaultValue={name} />
                </div>
                {/* ---- */}
                <div>
                  <Label>Gender</Label>
                  <Select className='mt-1.5'>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                    <option value='Other'>Other</option>
                  </Select>
                </div>
                {/* ---- */}
                <div>
                  <Label>Username</Label>
                  <Input name='username' className='mt-1.5' defaultValue={username} />
                </div>
                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <Input name='email' className='mt-1.5' defaultValue={email} />
                </div>
                {/* ---- */}
                <div className='max-w-lg'>
                  <Label>Date of birth</Label>
                  <Input className='mt-1.5' type='date' defaultValue='1990-07-22' />
                </div>
                {/* ---- */}
                <div>
                  <Label>City & Country</Label>
                  <Input className='mt-1.5' defaultValue='Manchester, United Kingdom' />
                </div>
                {/* ---- */}
                <div>
                  <Label>Phone number</Label>
                  <Input className='mt-1.5' defaultValue='07461 123 1211' />
                </div>
                {/* ---- */}
                <div>
                  <Label>About you</Label>
                  <Textarea className='mt-1.5' defaultValue={about} />
                </div>
                <div className='pt-2'>
                  <ButtonPrimary type='submit'>Update info</ButtonPrimary>
                </div>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  )
}

export default AccountPage
