import Header from 'components/Header/Header'
import React from 'react'
import { Helmet } from 'react-helmet'
// Auth

export type SiteHeaders = 'Header Default' | 'Header User'

// let OPTIONS = {
//   root: null,
//   rootMargin: '0px',
//   threshold: 1.0,
// }
const SiteHeader = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null)

  // Login
  // const [isLogged, setIsLogged] = React.useState(true)
  // const { user, isLoading, setUser } = useAuthContext()
  // const [userData, setUserData] = React.useState({})

  const [headerSelected, setHeaderSelected] = React.useState<SiteHeaders>('Header Default')

  // const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5)
  // const location = useLocation()

  // useEffect(() => {
  //   const isLoggedIn = checkUserLoggedIn()
  //   console.log('logged in:', isLoggedIn)

  //   // Get the correct header
  //   if (isLoggedIn) {
  //     setHeaderSelected('Header User')
  //   } else {
  //     setHeaderSelected('Header Default')
  //   }
  // }, [location.pathname])

  // Check if user is already logged in when the app starts
  // function checkUserLoggedIn(): boolean {
  //   const user = window.sessionStorage.getItem('user')
  //   const jwt = window.sessionStorage.getItem('jwt')

  //   // save the user and jwt to the state userData if any of them is not null/undefined
  //   if (user && jwt) {
  //     setUser(user)
  //     console.log('USER DATA TO CHECK - ' + user)
  //     console.log('-----------------------')
  //     setUserData(JSON.parse(user))
  //     setIsLogged(true)
  //   }

  //   return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  // }

  const renderHeader = () => {
    let headerClassName = 'shadow-sm dark:border-b dark:border-neutral-700'

    switch (headerSelected) {
      case 'Header Default':
        return <Header className={headerClassName} navType='MainNav' />
      case 'Header User':
        return <Header className={headerClassName} navType='MainNavUser' />

      default:
        return <Header className={headerClassName} />
    }
  }

  return (
    <>
      <Helmet>
        <title>Rightly Guided | Authenticated Islamic AI </title>
      </Helmet>
      {renderHeader()}
      <div ref={anchorRef} className='h-1 absolute invisible'></div>
    </>
  )
}

export default SiteHeader
