import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import HomeHeader from 'components/Home/HomeHeader'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import barChartPageViews from 'images/chartViews.svg'
import collectinAiImg from 'images/collecting-AI.svg'
import ourAiImg from 'images/our-AI.svg'
import stepOne from 'images/step-1.png'
import stepTwo from 'images/step-2.png'
import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import AboutFeatures from '../PageAbout/AboutFeatures'

import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import NotifyBlock from 'containers/PageHome/NotifyBlock'

import MarketplaceList from '../AppPreview/Tools/MarketplaceList'
import SectionStatistic from '../PageAbout/SectionStatistic'
import IntegrationHeader from './IntegrationHeader'
import IntegrationSecition from './IntegrationsSection'
export interface PageAboutProps {
  className?: string
}

const PageMarketplace: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageMarketplace overflow-hidden relative ${className}`} data-nc-id='PageMarketplace'>
      <Helmet>
        <title>Marketplace | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* Integrations */}
      <div className='relative container space-y-8'>
        <IntegrationHeader />
        <MarketplaceList />
        <IntegrationSecition />
      </div>

      {/* <AboutHowItWorks /> */}
      <div className='relative -mt-32'>
        <NotifyBlock />
      </div>
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800 dark:bg-slate-900 dark:from-slate-900 dark:via-slate-900'>
        <GetInvolvedBlock />
      </div>
      {/* <div className='relative mb-24 lg:mb-32'>
          <Workflow />
        </div> */}
      <div className='relative'>
        <DonateTypes />
      </div>
    </div>
  )
}

export default PageMarketplace
