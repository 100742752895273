import React, { FC, useState, useEffect } from 'react'
import TabFilters from './TabFilters'
import Heading2 from 'components/Heading/Heading2'
import FamilyQuizList, { ListingsFamilyQuizzesCardProps } from 'containers/FamilyQuizzesPage/ListingsFamilyQuizzesCard'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import NcModal from 'shared/NcModal/NcModal'
import FormItem from './FormItem'
import Input from 'shared/Input/Input'
import Select from 'shared/Select/Select'
import Checkbox from 'shared/Checkbox/Checkbox'
import NcInputNumber from 'components/NcInputNumber/NcInputNumber'
import AddQuizzPage from './AddQuizz/AddQuizzPage'
import { API } from '../../constant'
import { getToken } from '../../helpers'
import { useAuthContext } from '../../context/AuthContext'
import Quiz from 'containers/ListingQuizzesPage/GetRandomQuestions'

export interface FamilyQuizzesListFilterCardProps {
  className?: string
}

// Quizzes interface
export interface FamilyQuizAnswer {
  id: number
  familyQuizQuestionAnswerText: string
  familyQuizQuestionAnswerIsCorrect: boolean
}

export interface FamilyQuizQuestion {
  id: number
  familyQuizQuestionTitle: string
  Answer: FamilyQuizAnswer[]
}

export interface FamilyQuiz {
  id: number
  attributes: {
    familyQuizTitle: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    familyQuizDescription: string
    familyQuizUserEmail: string
    familyQuizUserName: string
    Questions: FamilyQuizQuestion[]
  }
}

// Function to filter family quizzes by email
const filterFamilyQuizzesByEmail = (quizzes: FamilyQuiz[], email: string): FamilyQuiz[] => {
  return quizzes.filter(quiz => quiz.attributes.familyQuizUserEmail === email)
}

const addNewFamilyQuiz = () => {
  return (
    <div className='w-full flex flex-col space-y-6 sm:space-y-8 px-0 sm:p-4 xl:p-8'>
      {/* FORM */}
      <div className='space-y-8'>
        <AddQuizzPage />
      </div>
    </div>
  )
}

const FamilyQuizzesListFilterCard: FC<FamilyQuizzesListFilterCardProps> = ({ className = '' }) => {
  const [familyQuizzes, setFamilyQuizzes] = useState<FamilyQuiz[]>([])

  const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})
  const [isLogged, setIsLogged] = useState(false)
  const [email, setEmail] = useState('')

  // Check if user is already logged in when the app starts
  // function checkUserLoggedIn(): boolean {
  //   const user = window.sessionStorage.getItem('user')
  //   const jwt = window.sessionStorage.getItem('jwt')

  //   // save the user and jwt to the state userData if any of them is not null/undefined
  //   if (user && jwt) {
  //     setUser(user)
  //     console.log('USER DATA TO CHECK - ' + user)
  //     console.log('-----------------------')
  //     setUserData(JSON.parse(user))
  //     setEmail(JSON.parse(user).email)
  //     setIsLogged(true)
  //   } else {
  //     setIsLogged(false)
  //     // Redirect to login page
  //     window.location.href = '/login'
  //   }

  //   return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  // }

  useEffect(() => {
    // const isLoggedIn = checkUserLoggedIn()
    // console.log('logged in:', isLoggedIn)

    async function fetchFamilyQuizzes() {
      const response = await fetch('http://localhost:1337/api/family-quizzes/?populate=deep')
      const json = await response.json()
      setFamilyQuizzes(json.data)
    }

    fetchFamilyQuizzes()
  }, [])

  // getting the user email
  // const { name, email } = JSON.parse(user)
  const filteredFamilyQuizzes = filterFamilyQuizzesByEmail(familyQuizzes, email)

  // handlling the update states when edition is done
  const updateQuizData = (quizId: number, updatedData: FamilyQuiz['attributes']) => {
    // Update the quiz data in the parent component's state
    setFamilyQuizzes(quizzes => quizzes.map(quiz => (quiz.id === quizId ? { ...quiz, attributes: updatedData } : quiz)))
  }
  const handleUpdateQuestions = (quizId: number, updatedQuestions: FamilyQuizQuestion[]) => {
    // Update the questions data in the parent component's state
    setFamilyQuizzes(prevFamilyQuizzes =>
      prevFamilyQuizzes.map(quiz =>
        quiz.id === quizId ? { ...quiz, attributes: { ...quiz.attributes, Questions: updatedQuestions } } : quiz
      )
    )
  }

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id='SectionGridFilterCard'>
      <div className='flex justify-between items-center mb-8 lg:mb-11  flex-col md:flex-row '>
        <div className='justify-end w-auto'>
          <Heading2
            heading='Your Family Quizzes'
            subHeading={
              <span className='block text-neutral-500 dark:text-neutral-400 mt-3 mb-1 lg:mb-1'>
                Where you can create, edit and delete your family quizzes
              </span>
            }
          />
        </div>

        <div className='flex justify-end w-auto'>
          <NcModal
            renderTrigger={openModal => (
              <button
                onClick={() => openModal()}
                className='mr-4 text-opacity-90
            group px-4 bg-orange-400 text-white py-2 mx-2 border border-orange-400 hover:border-orange-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer'
              >
                Create New
              </button>
            )}
            renderContent={addNewFamilyQuiz}
            modalTitle='Add New Family Quiz'
          />
          {/* <TabFilters /> */}
        </div>
      </div>

      {/* <div className="mb-8 lg:mb-11 ">
        <TabFilters />
      </div> */}
      <div className='lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl'>
        {filteredFamilyQuizzes.map(quiz => (
          <FamilyQuizList
            key={quiz.id}
            data={quiz?.attributes}
            quizId={quiz.id}
            onUpdate={updateQuizData}
            onUpdateQuestions={(quizId, updatedQuestions) => handleUpdateQuestions(quizId, updatedQuestions)}
          />
        ))}

        <div className='flex mt-6 justify-center items-center'>
          {filteredFamilyQuizzes.length === 0 && (
            <div className='flex flex-col items-center justify-center'>
              <h4 className='mb-8 text-2xl'>No quiz yet created!</h4>
              <NcModal
                renderTrigger={openModal => (
                  <button
                    onClick={() => openModal()}
                    className='mr-4 text-opacity-90
            group px-4 bg-orange-400 text-white py-2 mx-2 border border-orange-400 hover:border-orange-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer'
                  >
                    Create New
                  </button>
                )}
                renderContent={addNewFamilyQuiz}
                modalTitle='Add New Family Quiz'
              />
            </div>
          )}
          {/* <ButtonPrimary loading>Show more</ButtonPrimary> */}
        </div>
      </div>
    </div>
  )
}

export default FamilyQuizzesListFilterCard
