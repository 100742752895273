import React from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

const shareIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-5 h-5'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z'
    />
  </svg>
)

interface ShareToolProps {
  url: string
  quote: string
  hashtag: string
}

const ShareTool: React.FC<ShareToolProps> = ({ url, quote, hashtag }) => {
  const [showIcons, setShowIcons] = React.useState(false)

  const handleCopyAndShare = () => {
    navigator.clipboard.writeText(url)
    setShowIcons(prevState => !prevState)
  }

  return (
    <div className='flex'>
      {showIcons && (
        <div className='flex space-x-2'>
          <FacebookShareButton url={url} quote={quote} hashtag={hashtag}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={url} title={quote} hashtags={[hashtag]}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton url={url} summary={quote}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <WhatsappShareButton url={url} title={quote}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <EmailShareButton url={url} subject={quote} body={url}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      )}
      <button
        className='flex items-center justify-center w-10 h-10 p-1 ml-2 text-white bg-slate-500 rounded-full hover:bg-slate-600'
        onClick={handleCopyAndShare}
      >
        {shareIcon}
      </button>
    </div>
  )
}

export default ShareTool
