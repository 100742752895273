import React, { FC } from 'react'

export interface SocialsShareProps {
  className?: string
  itemClass?: string
}

export interface SocialType {
  name: string
  icon: string
  href: string
}

const tiktokIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-tiktok' viewBox='0 0 16 16'>
    {' '}
    <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />{' '}
  </svg>
)

const socials: SocialType[] = [
  { name: 'tiktok', icon: 'lab la-tiktok', href: '#' },
  { name: 'Facebook', icon: 'lab la-facebook-f', href: '#' },
  { name: 'Twitter', icon: 'lab la-twitter', href: '#' },
  { name: 'Youtube', icon: 'lab la-youtube', href: '#' },
  { name: 'Instagram', icon: 'lab la-instagram', href: '#' },
]

const SocialsShare: FC<SocialsShareProps> = ({ className = 'grid gap-[6px]', itemClass = 'w-7 h-7 text-base hover:bg-neutral-100' }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        key={index}
        href={item.href}
        className={`rounded-full leading-none flex items-center justify-center bg-white text-neutral-6000 ${itemClass}`}
        title={`Share on ${item.name}`}
      >
        {item.name === 'tiktok' ? tiktokIcon : <i className={item.icon}></i>}
      </a>
    )
  }

  return (
    <div className={`nc-SocialsShare ${className}`} data-nc-id='SocialsShare'>
      {socials.map(renderItem)}
    </div>
  )
}

export default SocialsShare
