import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import SectionSliderNewCategories from 'components/Home/SectionHomeAppeals'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import { TaxonomyType } from 'data/types'
import imgSampleRamadan from 'images/more-ramadam.png'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import SectionGridFilterCard from './ListingAppealsFilterGrid'
import ListingHeader from './ListingHeader'

export interface ListingAppealsPageProps {
  className?: string
}
// TODO: Interface review
const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '#',
    name: 'Appeal Sample Name ',
    taxonomy: 'category',
    count: 17288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '2',
    href: '#',
    name: 'Appeal Sample Name ',
    taxonomy: 'category',
    count: 2118,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '3',
    href: '#',
    name: 'Appeal Sample Name ',
    taxonomy: 'category',
    count: 36612,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '5',
    href: '#',
    name: 'Appeal Sample Name ',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '4',
    href: '#',
    name: 'Appeal Sample Name ',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
]

const ListingAppealsPage: FC<ListingAppealsPageProps> = ({ className = '' }) => {
  return (
    <div className={`nc-ListingCarPage relative overflow-hidden ${className}`} data-nc-id='ListingCarPage'>
      <Helmet>
        <title>Rightly Guided | Authenticated Islamic AI </title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative'>
        {/* SECTION HERO */}
        <ListingHeader className='' />

        {/* SECTION */}
        <SectionGridFilterCard className='pt-10 pb-24 lg:pb-28' />

        {/* SECTION 1 */}
        <div className='relative py-16'>
          <BackgroundSection />
          <SectionSliderNewCategories
            heading='Explore More'
            subHeading='Explore more'
            categoryCardType='card4'
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle='style2'
            uniqueClassName='ListingCarPage'
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className='py-24 lg:py-28' />
      </div>
    </div>
  )
}

export default ListingAppealsPage
