import React, { FC } from 'react'
import Heading from 'components/Heading/Heading'

export interface Statistic {
  id: string
  heading: string
  subHeading: string
}

const FeaturesData: Statistic[] = [
  {
    id: '1',
    heading: '500+ Quiz',
    subHeading: 'We have more than 500 quiz questions in our database and growing.',
  },
  {
    id: '2',
    heading: '100+ Downloads',
    subHeading: 'We have more than 100 downloads in our database and growing.',
  },
  {
    id: '3',
    heading: '220+ Guides',
    subHeading: 'We have more than 220 guides available in our database and growing.',
  },
]

export interface SectionStatisticProps {
  className?: string
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = '' }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading desc='We have available features that will help learning and playing, our ai is coming soon so stay tune!'>
        🚀 Some Features
      </Heading>
      <div className='grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8'>
        {FeaturesData.map(({ id, heading, subHeading }) => (
          <div key={id} className='p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
            <h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>{heading}</h3>
            <span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>{subHeading}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionStatistic
