import React, { FC, useState, Fragment, useEffect } from 'react'
import facebookSvg from 'images/Facebook.svg'
import appleSvg from 'images/Apple.svg'
import googleSvg from 'images/Google.svg'
import { Helmet } from 'react-helmet'
import Input from 'shared/Input/Input'
import { Link, useNavigate } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
// Strapi Auth
import { useAuthContext } from '../../context/AuthContext'
import { API } from '../../constant'
import { setToken } from '../../helpers'

//TODO: Login page Done

export interface PageLoginProps {
  className?: string
}

// Login stuff
export interface User {
  username: string
  email: string
  // add the rest of the properties if applicable
}

const loginSocials = [
  {
    name: 'Continue with Facebook',
    href: '#',
    icon: facebookSvg,
  },
  {
    name: 'Continue with Apple',
    href: '#',
    icon: appleSvg,
  },
  {
    name: 'Continue with Google',
    href: '#',
    icon: googleSvg,
  },
]

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  // Login stuff
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])

  const { user, isLoading, setUser } = useAuthContext()

  useEffect(() => {
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)
    if (isLoggedIn) {
      window.location.href = '/account'
    }
  }, [])

  // const onFinish = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   setLoading(true)
  //   try {
  //     const value = {
  //       identifier: (e.target as HTMLFormElement).email.value,
  //       password: (e.target as HTMLFormElement).password.value,
  //     }
  //     const response = await fetch(`${API}/auth/local`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(value),
  //     })

  //     const data = await response.json()
  //     if (data?.error) {
  //       throw data?.error
  //     } else {
  //       // set the token
  //       setToken(data.jwt)
  //       // console.log(data.jwt)

  //       // set the user
  //       setUserData(data.user)
  //       // proper user data
  //       setUser(data.user)
  //       // console.log(data)

  //       localStorage.setItem('userData', JSON.stringify(userData))
  //       console.log('userData Saved: ' + userData)
  //       setUser(data.user)
  //       console.log(user)
  //       alert(`Welcome back ${data.user.username}!`)

  //       // navigate('/account', { replace: true })
  //     }
  //   } catch (error) {
  //     console.error(error)
  //     alert('Something went wrong!')
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  async function loginUser(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()

    const data = {
      identifier: (e.target as HTMLFormElement).email.value,
      password: (e.target as HTMLFormElement).password.value,
    }

    try {
      const response = await fetch('http://localhost:1337/api/auth/local', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok) {
        const { user, jwt } = await response.json()
        // TODO: Remove logs in production
        console.log('User profile', user)
        console.log('User token', jwt)

        // Save user login information in sessionStorage
        window.sessionStorage.setItem('user', JSON.stringify(user))
        window.sessionStorage.setItem('jwt', jwt)
        setLoading(false)
        window.location.href = '/account'
      } else {
        console.log('An error occurred:', response.status, response.statusText)
        setLoading(true)
      }
    } catch (error) {
      console.log('An error occurred:', error)
    }
  }

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id='PageLogin'>
      <Helmet>
        <title>Login | Rightly Guided </title>
      </Helmet>
      <div className='container mb-24 lg:mb-32'>
        <h2 className='my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Login
        </h2>
        <div className='max-w-md mx-auto space-y-6'>
          <Fragment>
            {/* FORM */}
            <form className='grid grid-cols-1 gap-6' onSubmit={loginUser}>
              <label className='block'>
                <span className='text-neutral-800 dark:text-neutral-200'>Email address</span>
                <Input type='email' name='email' required placeholder='example@example.com' className='mt-1' />
              </label>
              <label className='block'>
                <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>
                  Password
                  <Link to='/forgot-pass' className='text-sm'>
                    Forgot password?
                  </Link>
                </span>
                <Input type='password' name='password' required className='mt-1' />
              </label>
              <ButtonPrimary type='submit'>
                Continue {isLoading && <span className='spinner-border spinner-border-sm ms-2'></span>}{' '}
              </ButtonPrimary>
            </form>
            {/* OR */}
          </Fragment>

          <div className='relative text-center'>
            <span className='relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900'>
              OR
            </span>
            <div className='absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800'></div>
          </div>
          <div className='grid gap-3'>
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className='nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]'
              >
                <img className='flex-shrink-0' src={item.icon} alt={item.name} />
                <h3 className='flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm'>{item.name}</h3>
              </a>
            ))}
          </div>
          {/* ==== */}
          <span className='block text-center text-neutral-700 dark:text-neutral-300'>
            New user? {` `}
            <Link to='/signup'>Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageLogin
