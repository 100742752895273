import emailjs, { init } from 'emailjs-com'
import subscribeBottomImg from 'images/subscribe-bottom.svg'
import mobileSample from 'images/testtest.png'
import React, { FC, FormEvent, useState } from 'react'
import Badge from 'shared/Badge/Badge'
import ButtonCircle from 'shared/Button/ButtonCircle'
import Input from 'shared/Input/Input'

export interface SectionSubscribe2Props {
  className?: string
}

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = '' }) => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[0].value
    const message = 'Early Access Waitlist'

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <div
      className={`nc-SectionSubscribe2 container relative flex flex-col lg:flex-col items-center mx-auto ${className}`}
      data-nc-id='SectionSubscribe2'
    >
      <div className='flex-shrink-0 mb-10 lg:mb-0'>
        <h2 className='font-semibold text-2xl text-center'>🎉 Join early access waitlist </h2>
        <span className='block mt-5 text-neutral-500 dark:text-neutral-400 text-center'>Be one of the firsts to join our community</span>
        {/* <ul className='space-y-4 mt-10'>
          <li className='flex items-center space-x-4'>
            <Badge name='Join' />
            <span className='font-medium text-neutral-700 dark:text-neutral-300'>Secure exclusive features</span>
          </li>
          <li className='flex items-center space-x-4'>
            <Badge color='red' name='Join' />
            <span className='font-medium text-neutral-700 dark:text-neutral-300'>Priority access granted</span>
          </li>
        </ul> */}
        <form className='mt-10 relative max-w-sm' action='#' method='post' onSubmit={handleSubmit}>
          <Input required aria-required placeholder='Enter your email' type='email' rounded='rounded-full' />
          <ButtonCircle
            type='submit'
            disabled={formStatus === 'sending'}
            className='absolute transform top-1/2 -translate-y-1/2 right-[5px]'
          >
            <i className='las la-arrow-right text-xl'></i>
          </ButtonCircle>
          {formStatus === 'success' && (
            <p className='absolute mt-1 text-center'>
              <span className=' text-green-500 rounded-xl text-xs12 py-2 px-2 w-full'>Email sent successfully!</span>
            </p>
          )}
          {formStatus === 'error' && (
            <p className='absolute mt-1 text-center'>
              <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                There was an error sending the email. Please try again.
              </span>
            </p>
          )}
        </form>
      </div>
      <div className='flex-grow -mb-10 mt-8 max-w-lg'>
        <img src={subscribeBottomImg} alt='...' />
      </div>
    </div>
  )
}

export default SectionSubscribe2
