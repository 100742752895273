import { JoinCommunityPopup } from 'containers/PageJoinCommunity/JoinCommunityPopup'
import bg from 'images/basko-assets/images/gradient.png'
import bg1 from 'images/basko-assets/images/headers/bg-garadient.jpg'
import avatarImg from 'images/basko-assets/images/sign-up/avatar3.png'
import piImg from 'images/bio/1611590720804.jpeg'
import iqLogo from 'images/bio/IQ-logo-gold-120px.png'
import irtisImage from 'images/bio/IRTIS.png'
import muftiAmjadSb from 'images/bio/Mufti-Amjad-sb.png'
import OliveFoundation from 'images/bio/Olive-Foundation-logo.png'
import booksImage from 'images/bio/books.jpeg'
import moonLogo from 'images/bio/moonLogo.png'
import zakatFoundation from 'images/bio/thumbnail_NZF-Icon-200px.png'
import ExternalAdvisors from 'images/newImages/External-Advisors.png'
import React from 'react'

export default function PageBio() {
  return (
    <div>
      {/* <section className='overflow-hidden'>
        <div className='pt-16 pb-28 bg-white overflow-hidden dark:bg-slate-700'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-2xl mx-auto text-center'>
              <h1 className='font-heading mb-4 text-6xl md:text-7xl tracking-tighter'>Bridging the Gap</h1>
              <p className='mb-10 max-w-md mx-auto'>How Mufti Amjad's Passion Drives Rightly Guided to Empower Muslims</p>
              <JoinCommunityPopup />
              <p className='mb-20 text-gray-600 text-sm tracking-tight'>Get involve today with our to-do list</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className='container mb-20'>
        <div className='flex flex-wrap'>
          <div className='w-full md:w-2/3 px-6'>
            <div className=' font-bold mb-4  pt-20'>
              <h2 className='text-2xl'>How Mufti Amjad's Passion Drives Rightly Guided to Empower Muslims</h2>
              <img src={muftiAmjadSb} className='block md:hidden' alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Mufti Qāḍī Sayyid Amjad M. Mohammed</h2>
              <p className='mb-4'>
                MPhil (PhD, Master of Philosophy, postgraduate research Masters) BSc (Hons), BMAIS, PGCE, PGDipRes, PGCHEP, and is
                recognized as a Fellow of the Higher Education Academy (FHEA).
              </p>
              <img className='my-8' src={ExternalAdvisors} alt='' />
              <p>
                <h2 className='text-2xl font-bold mb-2 mt-8'>Mufti SB explains Rightly Guided</h2>
                <iframe
                  width='100%'
                  height='415'
                  src='https://www.youtube.com/embed/u6Nq7ehyyf8?modestbranding=1'
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                ></iframe>
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>What is Rightly Guided?</h2>
              <p className='mb-4'>
                Rightly Guided is not just an ordinary platform; it is a passion project fueled by the vision and dedication of Mufti Amjad
                M. Mohammed, one of its esteemed co-founders. With a deep-rooted commitment to Islam and a desire to empower individuals on
                their spiritual and entrepreneurial journeys, Mufti Amjad's leadership ensures that Rightly Guided upholds the principles of
                Islam, promotes holistic growth, and bridges the gap between traditional teachings and contemporary challenges.
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Creating Oportunities</h2>
              <p className='mb-4'>
                Mufti Amjad's profound understanding of traditional Islamic teachings and his recognition of the need for relevance in the
                modern world has been instrumental in shaping Rightly Guided. The platform serves as a bridge, connecting the timeless
                wisdom of Islam with the practicalities of today's society. With Mufti Amjad at the helm, Rightly Guided strives to empower
                individuals to navigate the complexities of their faith and their entrepreneurial endeavours, fostering a harmonious balance
                between spirituality and worldly success.
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Promoting Holistic Growth</h2>
              <p className='mb-4'>
                Rightly Guided goes beyond traditional religious teachings and offers a holistic approach to personal development. Mufti
                Amjad's vision recognizes that true growth encompasses not only faith but also various aspects of life, such as education,
                business, and personal well-being. Through a wide range of courses, discussions, and support networks, Rightly Guided
                empowers individuals to develop spiritually, intellectually, and professionally, thereby nurturing their holistic growth.
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Spiritual Guidance and Mentorship</h2>
              <p className='mb-4'>
                Mufti Amjad's dedication to serving as a trusted figure for guidance and mentorship is evident within the Rightly Guided
                community. His deep knowledge of Islamic jurisprudence, ethics, and philosophy, combined with his experience as an educator
                and researcher, makes him an invaluable resource for individuals seeking spiritual enlightenment and clarity. Through the
                platform, users have access to his wisdom, insights, and personalised guidance, helping them navigate the intricacies of
                their faith and find practical solutions to contemporary challenges.
              </p>
              <img className='max-w-[100px]' src={moonLogo} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Entrepreneurial Support</h2>
              <p className='mb-4'>
                Recognizing the importance of entrepreneurship and economic empowerment, Mufti Amjad has embedded this vision within Rightly
                Guided. The platform offers a range of resources, including courses, discussions, and networking opportunities, to support
                individuals in their entrepreneurial journeys. Mufti Amjad's expertise and the collective knowledge of the Rightly Guided
                team provide invaluable guidance on aligning business practices with Islamic principles, navigating ethical challenges, and
                seizing opportunities for success.
              </p>
            </div>
            {/* <h2 className='text-2xl font-bold mb-4'>Mufti SB inspiring works</h2> */}
            {/* HERE IS THE TEXT */}
            {/* <div className='flex flex-col'>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-lg font-bold'>Scholar and founder of Olive Foundation</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src={booksImage} alt='The Renowned Scholar and Founder' />
                </div>
              </div>

              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Comprehensive Islamic Education</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Dean and Head Researcher at IRTIS</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src={irtisImage} alt='IRTIS' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Addressing Contemporary Challenges</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Active Involvement in Religious and Community Organizations</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Chief Sharia Officer for the National Zakat Foundation</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Consulted Authority and Media Presence</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Academic Accomplishments and Fellowships</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Prolific Author and Expertise</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Business consultancy - Pi2 Consultancy</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Chief Mufti on IqraTV channel</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
              <div className='flex flex-row mb-4 items-center'>
                <div className='w-2/3'>
                  <h2 className='text-xl font-bold'>Leadership in Rightly Guided</h2>
                </div>
                <div className='w-1/3'>
                  <img className='w-full h-32 object-cover rounded' src='https://via.placeholder.com/150' alt='Image placeholder' />
                </div>
              </div>
            </div> */}

            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>The Renowned Scholar and Founder</h2>
              <p className='mb-4'>
                Mufti Qāḍī Sayyid Amjad M. Mohammed, MPhil, is a renowned scholar and the founder of Dār al-ʿUlūm al-Zaytuniyya, a
                distinguished Islamic seminary based in Bradford, UK. With a deep commitment to Islamic education and research, Mufti Amjad
                M. Mohammed has played a pivotal role in providing comprehensive Islamic teachings and services to students from all walks
                of life.
              </p>
              <img className='max-w-[100px]' src={OliveFoundation} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Comprehensive Islamic Education</h2>
              <p className='mb-4'>
                As the principal of Dār al-ʿUlūm al-Zaytuniyya, Mufti Amjad oversees the seminary's secondary schools, elementary academies
                for foundational Islamic teachings, Qur'ān memorization programs, and other primary student services. He has also developed
                higher academies that offer the full traditional seminary ʿĀlimiyya course, which has produced numerous Imams and scholars.
              </p>
              <img className='max-w-[100px]' src={OliveFoundation} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Dean and Head Researcher at IRTIS</h2>
              <p className='mb-4'>
                Beyond his work in education, Mufti Amjad serves as the dean and head researcher at the Institute for the Revival of
                Traditional Islamic Sciences (IRTIS). Through IRTIS, he conducts critical research on the validity and coherence of
                traditional Islam within contemporary global society. His research focuses on areas such as Islamic jurisprudence and
                philosophy of law (uṣūl al-fiqh), Islamic ethics, Qur'anic and hadith studies, Islam and political thought, and the
                challenges faced by Muslim minorities.
              </p>
              <img src={irtisImage} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>
                Chief Sharia Officer for the National Zakat Foundation & Addressing Contemporary Challenges
              </h2>
              <p className='mb-4'>
                In addition to his academic and research endeavours, Mufti Amjad is actively involved in various religious and community
                organisations. He is the principal jurisconsult of Markaz al-Iftāʾ wa’l-Qaḍāʾ, a leading centre for religious consultancy,
                spiritual care, and advisory judgments. He also serves as the chair at Wifaqul Ulama, a scholarly organisation dedicated to
                addressing Muslim minority issues. Furthermore, Mufti Amjad acts as the chief sharia officer for the National Zakat
                Foundation, demonstrating his commitment to upholding Islamic principles in matters of charity and social welfare.
              </p>
              <img src={zakatFoundation} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Business consultancy - Pi2 Consultancy</h2>
              <p className='mb-4'>
                Pi2 Consultancy, founded by Mufti Amjad M. Mohammed, is a trusted business consultancy dedicated to helping businesses and
                entrepreneurs navigate the realm of Shariah compliance. With a deep understanding of Islamic principles and extensive
                expertise in business and entrepreneurship Mufti Amjad and his team provide valuable guidance and solutions for businesses
                seeking to align their operations with Shariah principles. Such services can now be offered via the Rightly Guided platform.
              </p>
              <img src={piImg} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Active Involvement in Religious and Community Organisations</h2>
              <p className='mb-4'>
                Mufti Amjad M. Mohammed's contributions extend beyond academia, as he is frequently consulted on matters of Islamic
                jurisprudence and Muslim minority affairs. His expertise and insights have been shared through various media presentations,
                conferences, and programs. He holds multiple degrees, including BSc (Hons), BMAIS, PGCE, PGDipRes, PGCHEP, and is recognized
                as a Fellow of the Higher Education Academy (FHEA).
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Prolific Author and Expertise</h2>
              <p className='mb-4'>
                With a prolific publishing record, Mufti Amjad has authored works on Islamic jurisprudence, Muslim minority jurisprudence,
                Islamic theology, tafsīr studies, ḥadīth studies, Islamic bioethics, and Islamic finance. His deep knowledge and experience
                make him an invaluable asset to the Rightly Guided community, enriching the platform with his wisdom and guidance.
              </p>
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Chief Mufti on IqraTV channel</h2>
              <p className='mb-4'>
                Mufti Amjad M. Mohammed, with his extensive knowledge and expertise, served as the Chief Mufti on IqraTV channel for several
                years. Hosting a Q&A show, he diligently answered questions from the public, providing insightful guidance and clarity on
                various matters related to Islam. His dedication to serving the community through media platforms has made him a trusted and
                respected figure among viewers seeking reliable religious advice.
              </p>
              <img className='max-w-[100px]' src={iqLogo} alt='' />
            </div>
            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Leadership in Rightly Guided</h2>
              <p className='mb-4'>
                As a co-founder of Rightly Guided, Mufti Amjad M. Mohammed's vision and leadership ensure that the platform upholds the
                principles of Islam, promotes holistic growth, and supports individuals in their spiritual and entrepreneurial journeys. His
                dedication to bridging the gap between traditional Islamic teachings and contemporary challenges makes him a trusted figure
                for guidance and mentorship within the Rightly Guided community.
              </p>
            </div>

            <div>
              <h2 className='text-2xl font-bold mb-2 mt-8'>Conclusion</h2>
              <p className='mb-4'>
                Mufti Amjad M. Mohammed's passion and unwavering commitment to empowering Muslims have shaped Rightly Guided into a platform
                that bridges the gap between traditional Islamic teachings and contemporary challenges. With a focus on holistic growth,
                spiritual guidance, entrepreneurial support, and community engagement, Rightly Guided embodies Mufti Amjad's vision of
                creating a space where individuals can thrive in their faith and in their pursuit of success. Under his leadership, Rightly
                Guided continues to inspire and empower Muslims around the world, providing them with the tools and resources to lead
                purposeful and fulfilling lives according to the principles of Islam.
              </p>
            </div>
          </div>
          <div className='w-full md:w-1/3 px-6'>
            <img src={muftiAmjadSb} className='-mb-20' alt='' />
            <div className='flex flex-col bg-slate-50 p-6 pt-32'>
              <h2 className='text-2xl font-bold mb-4'>Amjad M. Mohammed</h2>
              <h3 className='text-xl font-semibold mb-2'>Title</h3>
              <p className='mb-4'>Mufti, Qadi, Imam and Islamic scholar</p>
              <h3 className='text-xl font-semibold mb-2'>Personal</h3>
              <ul className='list-disc pl-5 mb-4'>
                <li>Born: England</li>
                <li>Religion: Islam</li>
                <li>Nationality: British</li>
                <li>Era: 21st-century philosophy</li>
                <li>Denomination: Sunni Islam</li>
                <li>Jurisprudence: Hanafi</li>
                <li>Movement: Deobandi</li>
              </ul>
              <h3 className='text-xl font-semibold mb-2'>Main interest(s)</h3>
              <p className='mb-4'>
                Quran, Shari'a, Hadith, Fiqh, Tafsir, Muslim minorities in the West, Fiqh al-Aqalliyat, Islamic finance, Usul al-Fiqh,
                Fatwa, Islamic ethics
              </p>
              <h3 className='text-xl font-semibold mb-2'>Notable idea(s)</h3>
              <p className='mb-4'>
                Western Muslim minority jurisprudence, Sharīʿa hermeneutics, Western Fiqh al-Nawazil, Fiqh of Moonsighting
              </p>
              <h3 className='text-xl font-semibold mb-2'>Alma mater</h3>
              <ul className='list-disc pl-5 mb-4'>
                <li>Darul Uloom Jamia Khatam al-Nabiyyin</li>
                <li>Bradford University</li>
              </ul>
              <h3 className='text-xl font-semibold mb-2'>Muslim leader</h3>
              <h3 className='text-xl font-semibold mb-2'>Website</h3>
              <a className='text-blue-500 underline' href='https://www.irtis.org.uk'>
                www.irtis.org.uk
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
