import { Tab } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import JoinCommunityForm from 'components/Community/JoinEventForm'
import DonateTypes from 'components/Donate/DonateTypes'
import { DateRage } from 'components/HeroSearchForm/StaySearchForm'
import { GuestsObject } from 'components/HeroSearchForm2Mobile/GuestsInput'
import Label from 'components/Label/Label'
import ModalSelectDate from 'components/ModalSelectDate'
import ModalSelectGuests from 'components/ModalSelectGuests'
import StartRating from 'components/StartRating/StartRating'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import avatarImg1 from 'images/flaro-assets/images/cta/avatar-circle.png'
import avatarImg2 from 'images/flaro-assets/images/cta/avatar-circle2.png'
import avatarImg3 from 'images/flaro-assets/images/cta/avatar-circle3.png'
import mastercardPng from 'images/mastercard.svg'
import eventImage from 'images/rg-event.jpg'
import logoRightlyGuided from 'images/rightly-guided-logo.svg'
import visaPng from 'images/vis.png'
import moment from 'moment'
import React, { FC, Fragment, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import NcImage from 'shared/NcImage/NcImage'
import NcModal from 'shared/NcModal/NcModal'
import Textarea from 'shared/Textarea/Textarea'
import converSelectedDateToString from 'utils/converSelectedDateToString'

const joinEvent = () => {
  return <JoinCommunityForm />
}

export interface CheckOutPageProps {
  className?: string
}

const EventPage: FC<CheckOutPageProps> = ({ className = '' }) => {
  const [rangeDates, setRangeDates] = useState<DateRage>({
    startDate: moment().add(1, 'day'),
    endDate: moment().add(5, 'days'),
  })
  const [guests, setGuests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  })

  const renderSidebar = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8'>
        <div className='flex flex-col sm:flex-row sm:items-center'>
          <div className='flex-shrink-0 w-full sm:w-12'>
            <div className='overflow-hidden'>
              <NcImage className='w-20' src={logoRightlyGuided} />
            </div>
          </div>
          <div className='py-5 sm:px-5 space-y-3'>
            <div>
              <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'>Donate or Invest</span>
              <span className='text-base font-medium mt-1 block'>Help us building the future!</span>
            </div>

            <div className='w-10 border-b border-neutral-200  dark:border-neutral-700'></div>
            <StartRating />
          </div>
        </div>
        <div className='flex flex-col space-y-4'>
          <h3 className='text-2xl font-semibold'>Total</h3>
          <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
            <span>Donations</span>
            <span>£750</span>
          </div>
          <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
            <span>Investment</span>
            <span>£1250</span>
          </div>

          <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='flex justify-between font-semibold'>
            <span>Total</span>
            <span>£2000</span>
          </div>
        </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <div>
          <div>
            <h3 className='text-2xl font-semibold mb-6'>Build yours</h3>
            <NcModal
              renderTrigger={openModal => (
                <span onClick={() => openModal()} className='block lg:hidden underline  mt-1 cursor-pointer'>
                  View Details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle='Details'
              showCloseButton={true}
              closeConfirmationOption={true}
            />
          </div>
          <div className='mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700'>
            <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button type='button' onClick={openModal} className='text-left flex-1 p-5 flex justify-between space-x-5'>
                  <div className='flex flex-col'>
                    <span className='text-sm text-neutral-400'>Options</span>
                    <span className='mt-1.5 text-lg font-semibold'>
                      <span className='line-clamp-1'>
                        {`${(guests.guestAdults || 0) + (guests.guestChildren || 0)} Donations, ${guests.guestInfants || 0} Investment`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className='w-6 h-6 text-neutral-6000 dark:text-neutral-400' />
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className='text-2xl font-semibold'>Pay with</h3>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 my-5'></div>

          <div className='mt-6'>
            <Tab.Group>
              <Tab.List className='flex my-5'>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? 'bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900'
                          : 'text-neutral-6000 dark:text-neutral-400'
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? 'bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900'
                          : ' text-neutral-6000 dark:text-neutral-400'
                      }`}
                    >
                      <span className='mr-2.5'>Credit card</span>
                      <img className='w-8' src={visaPng} alt='' />
                      <img className='w-8' src={mastercardPng} alt='' />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className='space-y-5'>
                  <div className='space-y-1'>
                    <Label>Card number </Label>
                    <Input defaultValue='111 112 222 999' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Card holder </Label>
                    <Input defaultValue='JOHN DOE' />
                  </div>
                  <div className='flex space-x-5  '>
                    <div className='flex-1 space-y-1'>
                      <Label>Expiration date </Label>
                      <Input type='date' defaultValue='MM/YY' />
                    </div>
                    <div className='flex-1 space-y-1'>
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className='space-y-1'>
                    <Label>Notes </Label>
                    <Textarea placeholder='...' />
                    <span className='text-sm text-neutral-500 block'>If you need anything please let us know.</span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className='space-y-5'>
                  <div className='space-y-1'>
                    <Label>Email </Label>
                    <Input type='email' defaultValue='example@gmail.com' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Password </Label>
                    <Input type='password' defaultValue='***' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Notes </Label>
                    <Textarea placeholder='...' />
                    <span className='text-sm text-neutral-500 block'>If you need anything please let us know.</span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className='pt-8'>
              <ButtonPrimary href={'/pay-done'}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id='CheckOutPage'>
      <div className='relative flex items-start justify-end container'>
        <NcModal
          renderTrigger={openModal => (
            <button
              onClick={() => openModal()}
              className='absolute m-6 group font-heading px-6 py-4 lg:w-auto uppercase text-white text-xs font-semibold bg-orange-500 overflow-hidden rounded-md tracking-px'
            >
              <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-orange-600'></div>
              <p className='relative z-10 flex items-center justify-center'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='w-6 h-6'>
                  <path d='M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z' />
                  <path
                    fillRule='evenodd'
                    d='M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z'
                    clipRule='evenodd'
                  />
                </svg>
                <span className='ml-2'> Join the Event</span>
              </p>
            </button>
          )}
          renderContent={joinEvent}
          modalTitle='Join the Event'
          showCloseButton={true}
          closeConfirmationOption={true}
        />
        <img className='text-center mx-auto w-full' src={eventImage} alt='Register for the event' />
      </div>
      <div className='pt-24 pb-28 bg-white overflow-hidden dark:bg-slate-800'>
        <div className='container px-4 mx-auto'>
          <div className='text-center max-w-xl mx-auto'>
            <h2 className='mb-5 text-3xl md:text-5xl font-bold font-heading text-center tracking-px-n leading-tight'>
              Register for the Event
            </h2>
            <p className='mb-7 text-lg  font-medium'>Join everyone today</p>
            <div className='mb-11 md:inline-block'>
              <NcModal
                renderTrigger={openModal => (
                  <button
                    onClick={() => openModal()}
                    className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
                  >
                    <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gray-800'></div>
                    <p className='relative z-10'>Join the Event</p>
                  </button>
                )}
                renderContent={joinEvent}
                modalTitle='Join the Event'
                showCloseButton={true}
                closeConfirmationOption={true}
              />
            </div>
            <div className='flex flex-wrap items-center justify-center -m-1'>
              <div className='w-auto p-1'>
                <div className='flex flex-wrap'>
                  <div className='w-auto'>
                    <img src={avatarImg1} alt='' />
                  </div>
                  <div className='w-auto -ml-3'>
                    <img src={avatarImg2} alt='' />
                  </div>
                  <div className='w-auto -ml-3'>
                    <img src={avatarImg3} alt='' />
                  </div>
                </div>
              </div>
              <div className='w-auto p-1'>
                <p className=' font-medium'>
                  <span>Join</span>
                  <span className='font-bold px-1'>150+</span>
                  <span>other People</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DonateTypes />
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800 dark:bg-slate-900 dark:from-slate-900 dark:via-slate-900'>
        <GetInvolvedBlock />
      </div>
      {/* <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'> */}
      {/* <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10 '>{renderMain()}</div> */}
      {/* <div className='hidden lg:block flex-grow'>{renderSidebar()}</div> */}
      {/* </main> */}
    </div>
  )
}

export default EventPage
