import { ShareIcon } from '@heroicons/react/24/outline'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import Logo from 'shared/Logo/Logo'
import MenuBar from 'shared/MenuBar/MenuBar'
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode'
import AvatarDropdown from './AvatarDropdown'
import CreateMenuDropdown from './CreateMenuDropdown'
import CurrencyDropdown from './CurrencyDropdown'
import DropdownWhatToDo from './DropdownWhatToDo'
import NotifyDropdown from './NotifyDropdown'

export interface MainNav2Props {
  className?: string
}

const MainNavUser: FC<MainNav2Props> = ({ className = '' }) => {
  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className='px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center'>
        <div className='hidden md:hidden justify-start flex-6 items-center space-x-3 sm:space-x-8 lg:space-x-10'>
          <Logo />
          <div className='hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500'></div>
          {/* <div className="hidden lg:block"> */}
          {/* Desktop */}
          {/* <DropdownWhatToDo /> */}
          {/* </div> */}
        </div>

        <div className=' px-4 container py-4 relative flex justify-between items-center'>
          <div className='flex justify-start flex-6 items-center space-x-4 sm:space-x-10'>
            <Logo />
            <div className='block h-10 border-l border-neutral-300 dark:border-neutral-500'></div>
            <DropdownWhatToDo />
          </div>
        </div>

        <div className=' md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100'>
          <div className='hidden items-center lg:flex space-x-1'>
            <div className='flex'>
              <Link
                to='#'
                className='
                text-opacity-90
                group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              >
                <ShareIcon className='w-[18px] h-[18px] opacity-80 mr-2' /> Share
              </Link>
            </div>
            {/* <Create menu for logged in users /> */}
            <CreateMenuDropdown />
            <div></div>
            <SwitchDarkMode />
            <div className='pr-1.5'>
              <NotifyDropdown className='-ml-2 xl:-ml-1' />
            </div>
            <AvatarDropdown />
          </div>
          <div className='flex items-center space-x-2 lg:hidden'>
            <NotifyDropdown />
            <AvatarDropdown />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainNavUser
