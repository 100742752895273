import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import HomeHeader from 'components/Home/HomeHeader'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import AreasSection from 'containers/PageAreas/AreasSection'
import barChartPageViews from 'images/chartViews.svg'
import collectinAiImg from 'images/collecting-AI.svg'
import ourAiImg from 'images/our-AI.svg'
import stepOne from 'images/step-1.png'
import stepTwo from 'images/step-2.png'
import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import AboutFeatures from '../PageAbout/AboutFeatures'

import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'
import AboutIntro from '../PageAbout/AboutIntro'
import AboutTextBlock from '../PageAbout/AboutTextBlock'
import CommunityBlock from '../PageAbout/CommunityBlock'
import DonateBlock from '../PageAbout/DonateBlock'
import ProductsBlockView from '../PageAbout/ProductsBlock'
import SectionFounder from '../PageAbout/SectionFounder'
import SectionStatistic from '../PageAbout/SectionStatistic'
import InvestBlock from './InvestBlock'
export interface PageInvestProps {
  className?: string
}

// interface Block {
//   id: number
//   image: string
//   paragraphs: string[]
// }

// interface BlockComponentProps {
//   blocks: Block[]
// }

// const blocks = [
//   // Populate with your own data
//   {
//     id: 1,
//     image: logoRightlyGuided,
//     paragraphs: [
//       'Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1',
//       'Text 2',
//       'Text 3',
//     ],
//   },
//   { id: 2, image: logoRightlyGuided, paragraphs: ['Text 4', 'Text 5', 'Text 6'] },
// ]

const PageInvest: FC<PageInvestProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id='PageAbout'>
      <Helmet>
        <title>About | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <InvestBlock />
      {/* <AboutHowItWorks /> */}
      {/* <AboutIntro /> */}
      {/* <CommunityBlock /> */}
      {/* <ProductsBlockView /> */}
      {/* <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
        <GetInvolvedBlock />
      </div> */}
      <div className='relative'>
        <DonateTypes />
      </div>
      {/* <DonateBlock /> */}
      {/* <div className='lg:py-28 space-y-16 lg:space-y-28'> */}
      {/* TODO: Home Header */}
      {/* <HomeHeader className='py-20 md:py-15 lg:py-10 ' /> */}
      {/* <section className='section-bg-base section-bg-navy-ellipse'>
          <div className='main-container'>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Collect everything from all availables AI in our Marketplace</h2>
                  <p className='lg:text-lg'>
                    Our marketplace is a hub of AI technology, where we gather all the information from every available source. This ensures
                    that you have access to the latest and most comprehensive AI solutions to meet all the require needs for the subject.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:mx-0 mx-auto -mb-10'>
                    <div>
                      <img className='rounded-xl my-4' src={stepTwo} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-2 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Constructing with AI: Harnessing Our Custom-Built Models and Solutions</h2>
                  <p className='lg:text-lg'>
                    Our AI efficiently manages diverse tasks, such as analyzing text, images, videos, and social media, while handling
                    translations, personalization, anomaly detection, content moderation, ad customization, recommendations, and
                    fact-checking. It ensures the delivery of the most accurate and relevant data possible.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:-mb-32 md:mx-0 -mb-36 mx-auto'>
                    <div>
                      <img className='rounded-xl my-4' src={stepOne} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Collect everything from all availables AI in our Marketplace</h2>
                  <p className='lg:text-lg'>
                    Our marketplace is a hub of AI technology, where we gather all the information from every available source. This ensures
                    that you have access to the latest and most comprehensive AI solutions to meet all the require needs for the subject.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:mx-0 mx-auto -mb-10'>
                    <div>
                      <img className='rounded-xl my-4' src={collectinAiImg} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>AI and Human Collaboration: Integrating Community, Partners, and Experts</h2>
                  <p className='lg:text-lg'>
                    Our AI collaborates with humans, including community members, partners, and Muftis worldwide, to create a seamless
                    integration of expertise. The AI oversees the process, harnessing valuable insights from human input to enhance overall
                    performance and produce superior results.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='-mb-14 md:min-w-[450px] mx-auto md:mx-0'>
                    <div>
                      <img className='rounded-xl my-4' src={stepTwo} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* <div className='w-full mx-auto'>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            From interactive quizzes to an app with all the answers to your questions from does this break my fast to can I vape; we want
            you to be able to openly ask questions and gain the right answers. We are working with trusted Ulemah and Scholars to provide
            you with knowledge with ease.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We have partnered with scholars across the UK to work towards a system built according to the Quran, sunnah and sharia.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We aim to ensure we are utilising the developments in technology whilst preserving Islam and making your life easier whether
            you’re trying to find an authentic hadith or you have a fiqh-related question and want to ask an actual Mufti or search through
            previous responses. This includes us training an AI which you can learn more about here.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            Together we can ensure that the timeless wisdom of Islam continues to inspire and rightly guide us for generations to come.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We’d love to have your input as we develop our application for you
          </p>
        </div> */}
      {/* <SectionFounder /> */}
      {/* <div className='relative py-16'>
          <BackgroundSection />
          <SectionClientSay uniqueClassName='PageAbout_' />
        </div> */}
      {/* <SectionStatistic />
        <SectionSubscribe2 /> */}
      {/* </div> */}
    </div>
  )
}

export default PageInvest
