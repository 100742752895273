import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import Heading from 'components/Heading/Heading'
import SectionHomeAppShowAdvisory from 'components/Home/SectionHomeAppShowAdvisory'
import SectionHomeAppShowEdu from 'components/Home/SectionHomeAppShowEdu'
import SectionHomeAppShowFly from 'components/Home/SectionHomeAppShowFly'
import SectionHomeAppShowFood from 'components/Home/SectionHomeAppShowFood'
import React, { useEffect, useState } from 'react'

function TabsAppShow() {
  const [activeTab, setActiveTab] = useState(1)
  const [showTabs, setShowTabs] = useState(true)

  const handleTabChange = (tabNumber: number) => {
    setActiveTab(tabNumber)
  }

  return (
    <div id='products' className=' w-full container text-xl text-center'>
      <Heading desc='Whatever your needs; we provide a solution with ease' isCenter={true} className='mb-4'>
        AI in action
      </Heading>
      <div className='relative w-full text-xl text-center'>
        {showTabs && (
          <div id='tabsButtons' className='mb-2 z-40 dark:border-neutral-700 py-2'>
            <div className='flex flex-wrap justify-center p-2 gap-8'>
              <button
                className={` w-auto md:text-base text-sm text-center py-2 border-b-2 border-white ${
                  activeTab === 1 ? 'border-b-2 border-blue-600' : ''
                } hover:border-b-2 hover:border-blue-600 transition-all`}
                onClick={() => handleTabChange(1)}
              >
                Food
              </button>
              <button
                className={` w-auto md:text-base text-sm text-center py-2 border-b-2 border-white ${
                  activeTab === 2 ? 'border-b-2 border-blue-600' : ''
                } hover:border-b-2 hover:border-blue-600 transition-all`}
                onClick={() => handleTabChange(2)}
              >
                Education
              </button>
              <button
                className={` w-auto md:text-base text-sm text-center py-2 border-b-2 border-white ${
                  activeTab === 3 ? 'border-b-2 border-blue-600' : ''
                } hover:border-b-2 hover:border-blue-600 transition-all`}
                onClick={() => handleTabChange(3)}
              >
                Travelling
              </button>
              <button
                className={` w-auto md:text-base text-sm text-center py-2 border-b-2 border-white ${
                  activeTab === 4 ? 'border-b-2 border-blue-600' : ''
                } hover:border-b-2 hover:border-blue-600 transition-all`}
                onClick={() => handleTabChange(4)}
              >
                Business
              </button>
            </div>
          </div>
        )}
        <div className='relative pt-8 max-w-xl mx-auto'>
          {activeTab === 1 && (
            <SectionHomeAppShowFood
              key={1} // This will force the component to re-render
              heading='App Show 1'
              subHeading='What we have for you in our app'
              categoryCardType='card5'
              itemPerRow={1}
              uniqueClassName='PageHome-AppShow-Food'
              showTitle={false}
            />
          )}
          {activeTab === 2 && (
            <SectionHomeAppShowEdu
              key={2} // This will force the component to re-render
              heading='App Show 2'
              subHeading='What we have for you in our app'
              categoryCardType='card5'
              itemPerRow={1}
              uniqueClassName='PageHome-AppShow-Edu'
              showTitle={false}
            />
          )}
          {activeTab === 3 && (
            <SectionHomeAppShowFly
              key={3} // This will force the component to re-render
              heading='App Show 3'
              subHeading='What we have for you in our app'
              categoryCardType='card5'
              itemPerRow={1}
              uniqueClassName='PageHome-AppShow-Fly'
              showTitle={false}
            />
          )}
          {activeTab === 4 && (
            <SectionHomeAppShowAdvisory
              key={4} // This will force the component to re-render
              heading='App Show 3'
              subHeading='What we have for you in our app'
              categoryCardType='card5'
              itemPerRow={1}
              uniqueClassName='PageHome-AppShow-Fly'
              showTitle={false}
            />
          )}
        </div>
      </div>
      {/* <BgGlassmorphism /> */}
      <div className='mt-8'>
        <div className='mb-7 md:inline-block'>
          <a
            className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
            type='button'
            href='#joinWaitlist'
          >
            Coming Soon
          </a>
        </div>
        <div className='mb-16 block'>
          <a className='inline-block text-black hover:text-gray-800' href='##'>
            <div className='flex flex-wrap items-center -m-1.5'>
              <div className='w-auto p-1.5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59'
                  />
                </svg>
              </div>
              <div className='w-auto p-1.5'>
                <p className='font-medium dark:text-white'>More Apps Coming Soon</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default TabsAppShow
