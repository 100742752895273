import React, { FC } from 'react'
import MainNav from './MainNav'
import MainNavUser from './MainNavUser'

export interface HeaderProps {
  navType?: 'MainNav' | 'MainNavUser'
  className?: string
}

const Header: FC<HeaderProps> = ({ navType = 'MainNav', className = '' }) => {
  const renderNav = () => {
    switch (navType) {
      case 'MainNav':
        return <MainNav />
      case 'MainNavUser':
        return <MainNavUser />
      default:
        return <MainNav />
    }
  }

  return <div className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}>{renderNav()}</div>
}

export default Header
