import { PlayCircleIcon } from '@heroicons/react/24/solid'
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon'
import Heading2 from 'components/Heading/Heading2'
import SaleOffBadge from 'components/SaleOffBadge/SaleOffBadge'
import StartRating from 'components/StartRating/StartRating'
import { StayDataType } from 'data/types'
import imageSample from 'images/more-ramadam.png'
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Badge from 'shared/Badge/Badge'
import NcImage from 'shared/NcImage/NcImage'
import NcModal from 'shared/NcModal/NcModal'
import Pagination from 'shared/Pagination/Pagination'
import GetRandomQuestions from './GetRandomQuestions'
import TabFilters from './TabFilters'

export interface SectionGridFilterCardProps {
  className?: string
  data?: StayDataType[]
}

const renderGetRandomQuestions = (numQuestions: number, levelFilter: string) => {
  return (
    <div className='w-full flex flex-col space-y-6 sm:space-y-8 px-0 sm:p-4 xl:p-8'>
      {/* FORM */}
      <div className='space-y-8'>
        <GetRandomQuestions start={true} numQuestions={numQuestions} levelFilter={levelFilter} enableTimer={true} />
      </div>
    </div>
  )
}

const catsData = [
  {
    id: 1,
    level: 'beginner',
    numQuestions: 10,
    title: 'Questions About Ramadan',
    description: 'Questions about Ramadan for beginners',
    image:
      'https://images.unsplash.com/photo-1616777370079-8b5f2f1b5f1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
    type: 'quiz',
  },
  {
    id: 2,
    level: 'beginner',
    numQuestions: 10,
    title: 'Another Quiz',
    description: 'Some description',
    image: 'https://example.com/image.jpg',
    type: 'quiz',
  },
]

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({ className = '', data = catsData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [quizKey, setQuizKey] = useState(Date.now())

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const renderTienIch = (text: string) => {
    return (
      <div className='inline-grid grid-cols-1 gap-2'>
        <div className='flex items-center space-x-2'>
          <span className='hidden sm:inline-block'>
            <i className='las la-user text-lg'></i>
          </span>
          <span className='text-xs text-neutral-500 dark:text-neutral-400'>{text}</span>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return catsData.map(cat => (
      <div
        key={cat.id}
        id='quizItem'
        className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow`}
        data-nc-id='PropertyCardH'
      >
        <div className='h-full w-full flex flex-col sm:flex-row sm:items-center'>
          {/* <div className='flex-shrink-0 p-3 w-full sm:w-64 '>
            <NcImage
              id='imageQuizOpen'
              containerClassName='flex items-center justify-center w-full h-full rounded-2xl overflow-hidden will-change-transform pointer-events-none'
              className='w-full'
              src={imageSample}
            />
            {cat.level && <SaleOffBadge className='absolute left-5 top-5 !bg-orange-500' />}
          </div> */}
          <NcModal
            renderTrigger={openModal => (
              <div>
                <div onClick={() => openModal()} className='flex-grow p-3 sm:pr-6 flex flex-col items-start cursor-pointer'>
                  <div className='space-y-4 w-full'>
                    <div className='inline-flex space-x-3 pt-2'>
                      <Badge
                        name={
                          <div className='flex items-center'>
                            <i className='text-sm las la-share-alt'></i>
                            <span className='ml-1'>Beginner</span>
                          </div>
                        }
                      />
                      <Badge
                        name={
                          <div className='flex items-center'>
                            <i className='text-sm las la-user-friends'></i>
                            <span className='ml-1'>Family</span>
                          </div>
                        }
                        color='yellow'
                      />
                    </div>
                    <div className='flex items-center space-x-2  border-t pt-4 border-gray-100'>
                      {catsData && <Badge name='20' color='green' />}
                      <h2 className='text-lg font-medium capitalize'>
                        <span className='line-clamp-2'>{cat.title}</span>
                      </h2>
                    </div>
                    {/* Render stuff */}
                    {renderTienIch(cat.description)}
                    <div className='w-14 border-b border-neutral-100 dark:border-neutral-800 '></div>
                    <div className='flex w-full justify-between items-center'>
                      <StartRating reviewCount={2} point={3} />
                      <span className='flex items-center justify-center px-1 py-0 leading-none text-base font-medium text-secondary-500'>
                        {/* {`${price}`} */}
                        {`${Number(((Math.random() * 45) | 0) + 1)}`}

                        <button
                          onClick={handleOpenModal}
                          className='
                text-opacity-90
                group px-4 bg-orange-400 text-white py-2 mx-2 border border-orange-400 hover:border-orange-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                        >
                          Play Quiz
                          <PlayCircleIcon className='w-[24px] h-[24px] opacity-100 ml-2' />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            renderContent={() => renderGetRandomQuestions(cat.numQuestions, cat.level)}
            modalTitle='Play'
            showCloseButton={false}
          />
        </div>
        <BtnLikeIcon
          colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
          isLiked={true}
          className='absolute right-3 top-3 sm:right-3 sm:top-3 '
        />
      </div>
    ))
  }

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id='SectionGridFilterCard'>
      <div className='mb-8 lg:mb-11'>
        {/* <TabFilters /> */}
        <div className='flex flex-col md:flex-row md:justify-between md:space-x-4'>
          <Heading2
            heading='Play Quiz'
            subHeading={
              <span className='block text-neutral-500 dark:text-neutral-400 mt-3 mb-1 lg:mb-1 md:w-full'>
                Play and Lear with our quizzes
              </span>
            }
          />

          <div className='flex space-x-4 '>
            <div className='flex items-center justify-center gap-2'>
              <button className='nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  w-full sm:w-auto bg-green-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'>
                Play Quiz
              </button>
              <div
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all'
        }`}
              >
                Quizzes: <span className='ml-1'>{catsData.length}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 '>{renderContent()}</div>
      <div className='flex mt-16 justify-center items-center'>{/* <Pagination /> */}</div>
    </div>
  )
}

export default SectionGridFilterCard
