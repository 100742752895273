import React, { ChangeEvent, MouseEvent, useState } from 'react'

interface Username {
  username: string
  isAvailable: boolean
  price: number
}

interface CheckUsernameProps {
  onUsernameListChange: (usernameList: Username[]) => void
  goToNextStep: () => void
}

const CheckUsername: React.FC<CheckUsernameProps> = ({ onUsernameListChange, goToNextStep }) => {
  const [username, setUsername] = useState<string>('')
  const [usernameList, setUsernameList] = useState<Username[]>([])

  const checkAvailability = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setUsernameList(prevUsernameList => [...prevUsernameList, { username, isAvailable: true, price: 10.2 }])
    setUsername('')
  }

  const handleNext = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    onUsernameListChange(usernameList)
    goToNextStep() // call next step function here
  }

  const handleClear = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    setUsername('')
  }

  const handleRemoveUsername = (usernameToRemove: string) => {
    setUsernameList(prevUsernameList => prevUsernameList.filter(username => username.username !== usernameToRemove))
  }

  return (
    <div className='flex flex-wrap items-end max-w-2xl pb-2 -m-1'>
      <div className='w-full sm:flex-1 p-1'>
        <label htmlFor='usernameInput' className='block text-xs font-bold mb-1'>
          Search your username
        </label>
        <input
          className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
          id='usernameInput'
          type='text'
          placeholder='@Ali'
          value={username}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
        />
      </div>
      <div className='w-full sm:w-auto p-1'>
        <a
          className='inline-flex flex-wrap items-center justify-center px-5 py-3.5 w-full text-sm text-neutral-50 hover:text-neutral-100 font-medium text-center bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
          href='##'
          onClick={checkAvailability}
        >
          Check Availability
        </a>
      </div>

      {usernameList.length > 0 ? (
        <div className='w-full max-w-md'>
          {usernameList.map((item, index) => (
            <div key={index} className='flex justify-between items-center bg-slate-50 rounded-lg p-2 my-1'>
              <div>
                <span className='font-bold'>
                  {item.username} - {item.price.toFixed(2)}
                </span>
                <span className='text-green-500 ml-2'>{item.isAvailable ? 'Available' : 'Not Available'}</span>
              </div>
              <button onClick={() => handleRemoveUsername(item.username)} className='text-red-500'>
                Remove
              </button>
            </div>
          ))}
        </div>
      ) : null}
      <div className='w-full flex items-center justify-start sm:justify-start p-2 py-4 space-x-4'>
        <a onClick={handleClear} className='' href='##'>
          Clear
        </a>
        <a
          onClick={handleNext}
          className='inline-block w-full lg:w-auto py-4 px-8 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
          href='##'
        >
          Save
        </a>
      </div>
    </div>
  )
}

export { CheckUsername }
