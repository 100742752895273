import React, { useState } from 'react'

//initialize verification interface
interface Verification {
  isVerified: boolean
  price: number
}

interface AddVerificationProps {
  onVerificationChange: (verification: Verification) => void
  goToNextStep: () => void
}

const AddVerification: React.FC<AddVerificationProps> = ({ onVerificationChange, goToNextStep }) => {
  const [verification, setVerification] = useState<Verification>({ isVerified: false, price: 0 })

  const handleAddVerification = () => {
    if (verification.isVerified) {
      setVerification({ isVerified: false, price: 0 })
      onVerificationChange({ isVerified: false, price: 0 })
    } else {
      setVerification({ isVerified: true, price: 10 })
      onVerificationChange({ isVerified: true, price: 10 })
    }
    goToNextStep()
  }

  return (
    <div className='flex flex-wrap max-w-2xl pb-11 -m-1'>
      <div className='w-full sm:w-1/2 p-1 flex items-center'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='w-6 h-6 mr-2'
        >
          <path d='M20 6L9 17l-5-5' />
        </svg>

        <p>{verification.isVerified ? 'Remove verification' : 'Add verification for 10£'}</p>
      </div>
      <div className='w-full flex items-center justify-start sm:justify-start p-2 py-4 space-x-4'>
        <button
          className={`inline-block w-full lg:w-auto py-4 px-8 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center ${
            verification.isVerified ? 'bg-red-500 hover:bg-red-600 focus:ring-red-500' : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-500'
          } focus:ring-opacity-50 rounded-xl`}
          onClick={handleAddVerification}
        >
          {verification.isVerified ? 'Remove Verification' : 'Add Verification'}
        </button>
      </div>
    </div>
  )
}

export default AddVerification
