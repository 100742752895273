import Glide from '@glidejs/glide'
import Heading from 'components/Heading/Heading'
import useNcId from 'hooks/useNcId'
import sampleImage from 'images/our-features.png'
import React, { FC, useEffect, useRef, useState } from 'react'
// import images
import fa from 'images/services/service-fa.svg'
import hp from 'images/services/service-hp.svg'
import hr from 'images/services/service-hr.svg'
import ma from 'images/services/service-ma.svg'
import pt from 'images/services/service-pt.svg'
import qr from 'images/services/service-qr.svg'

export interface SectionFeaturesProps {
  className?: string
  uniqueClassName: string
  data?: FeaturesData[]
  hasTitle?: boolean
}

interface FeaturesData {
  id: number
  title: string
  feature: string[]
  image: string
  content: string
}

const dataFeatures = [
  {
    id: 1,
    title: 'Education',
    feature: ['Online Teacher', 'Training Generator', 'School Border', 'Online Teacher'],
    image: fa,
    content:
      'Ai to help teachers and students to get the best content for any subjects helping then getting the best thing they can get yo yo.',
  },
  {
    id: 2,
    title: 'Virtual imam chatbot',
    feature: ['AI Generated', 'sadasd', 'ddsfds'],
    image: ma,
    content:
      'a virtual assistant that answers Islamic questions, provides advice on religious matters, and delivers sermons in response to user requests.',
  },
  {
    id: 3,
    title: 'Islamic finance app',
    feature: ['AI Generated', 'sadasd', 'ddsfds'],
    image: qr,
    content:
      'an AI-powered platform that offers Halal investment advice, financial planning services, and investment management tools that comply with Islamic principles.',
  },
  {
    id: 4,
    title: 'Halal product scanner',
    feature: ['AI Generated', 'sadasd', 'ddsfds'],
    image: pt,
    content:
      'HalalAI can scan the barcodes of food products and determine whether they are halal or not, making it easy to shop for halal groceries.',
  },
  {
    id: 5,
    title: 'Quranic recitation assistant',
    feature: ['AI Generated', 'sadasd', 'ddsfds'],
    image: hp,
    content: 'HalalAI can assist with Quranic recitation, providing guidance and feedback on pronunciation and intonation.',
  },
]

declare module '@glidejs/glide' {
  export default class Glide {
    constructor(selector: string, options?: object)

    public index: number
    public on(event: string, callback: () => void): void
    public mount(): void
    public destroy(): void
    public move(direction: string | number): void
  }
}

const SectionFeatures: FC<SectionFeaturesProps> = ({ className = '', uniqueClassName = '', data = dataFeatures, hasTitle = true }) => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId()
  const glideRef = useRef<Glide | null>(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      const glide = new Glide(`.${UNIQUE_CLASS}`, {
        perView: 1,
      })

      glide.on('run.after', () => {
        setCurrentSlideIndex(glide.index)
        setCurrentFeatureIndex(0) // Reset the current feature index when the slide changes
      })

      glide.mount()

      glideRef.current = glide

      return () => glide.destroy()
    }
  }, [])

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentFeatureIndex(prevIndex => (prevIndex + 1) % data.length)
    }, 5000)

    setIntervalId(id)

    return () => {
      clearInterval(id)
    }
  }, [data.length])

  return (
    <div className={`nc-SectionClientSay relative ${className} `} data-nc-id='SectionClientSay'>
      {hasTitle && (
        <Heading desc='Our main features' isCenter>
          Features
        </Heading>
      )}
      <div className='relative md:mb-16 max-w-2xl mx-auto'>
        {/* {renderBg()} */}

        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <div className='glide__track ' data-glide-el='track'>
            <ul className='glide__slides '>
              {data.map(item => (
                <li key={item.id} className='glide__slide flex flex-col items-center text-center'>
                  <div className='w-full'>
                    <img className='mx-auto w-36 mb-2' src={item.image} alt='' />
                    <span className='block mt-8 text-3xl font-semibold mb-8'>{item.title}</span>
                    <span className='block text-2xl'>{item.content}</span>
                  </div>

                  <div className='flex-grow flex items-center justify-center space-x-8 mt-6'>
                    <div className='flex-1 flex items-center justify-center'>
                      <div className='relative'>
                        {item.feature.map((feature, index) => (
                          <span
                            key={index}
                            className={`absolute w-64 py-0 px-2 border rounded-md text-xs ${
                              index === currentFeatureIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                            style={{
                              left: '50%',
                              transform: 'translateX(-50%)',
                            }}
                          >
                            {feature}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div>
              <button className='text-3xl' onClick={() => (glideRef.current as any)?.go('<')}>
                &larr;
              </button>
              <button className='text-3xl' onClick={() => (glideRef.current as any)?.go('>')}>
                &rarr;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionFeatures
