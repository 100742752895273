import squareBg from 'images/trizzle-assets/images/banner-circle-square-medium.png'
import React from 'react'

export default function IntegrationsSection() {
  return (
    <section className='py-4'>
      <div className='container px-4 mx-auto'>
        <div className='relative pt-16 pb-10 px-8 bg-blue-500 rounded-2xl overflow-hidden'>
          <img className='absolute top-0 left-0 h-full w-full object-cover' src={squareBg} alt='' />
          <div className='relative'>
            <h3 className='text-3xl md:text-4xl font-bold text-white mb-3'>Looking for something new?</h3>
            <p className='font-medium text-blue-200 mb-36'>Join our Preview Tools Online Marketplace to be the firs one to play with!</p>
            <a
              className='inline-block w-full px-6 py-3 leading-6 text-center font-semibold text-blue-50 bg-gray-500 hover:bg-gray-600 transition duration-200 rounded-lg'
              href='##'
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
