import notionImg from 'images/dashy-assets/images/notion.svg'
import React, { useState } from 'react'
import {
  AllFilterIcon,
  DisableIcon,
  EnableIcon,
  FreeIcon,
  ImageAiIcon,
  InfoToolTipIcon,
  PaidIcon,
  SelectedIcon,
  SettingsIcon,
  TextAiIcon,
  VideoAiIcon,
} from './BuilderIcons'
import {
  ChatGptSettings,
  ImageSettings,
  SocialMediaSettings,
  TextGeneratorSettings,
  TranslateSettings,
  VideoGeneratorSettings,
} from './SettingsOptions'

interface AiModels {
  id: number
  title: string
  description: string
  icon: string
  isSelected: boolean
  type: string
  isFree: boolean
  toolTip: string
  settings: JSX.Element
}

export default function SelectModels() {
  const [aiToolsData, setAiToolsData] = useState<AiModels[]>([
    {
      id: 1,
      title: 'Text Generator',
      description: 'Text based model to generate any type of content you need.',
      icon: notionImg,
      isSelected: true,
      type: 'text',
      isFree: true,
      toolTip: 'Text based model to generate any type of content you need.',
      settings: <TextGeneratorSettings />,
    },
    {
      id: 2,
      title: 'Image Generator *',
      description: 'Image based model that allows to generate high quality images in seconds',
      icon: notionImg,
      isSelected: false,
      type: 'image',
      isFree: true,
      toolTip: 'Image based model that allows to generate high quality images in seconds',
      settings: <ImageSettings />,
    },
    {
      id: 3,
      title: 'Video Generator',
      description: 'Create your videos using this model making it all automatically.',
      icon: notionImg,
      isSelected: false,
      type: 'video',
      isFree: true,
      toolTip: 'Create your videos using this model making it all automatically.',
      settings: <VideoGeneratorSettings />,
    },
    {
      id: 4,
      title: 'Social Media',
      description: 'Get all relevant information about something on social media platforms.',
      icon: notionImg,
      isSelected: false,
      type: 'video',
      isFree: false,
      toolTip: 'Get all relevant information about something on social media platforms.',
      settings: <SocialMediaSettings />,
    },
    {
      id: 5,
      title: 'Content Moderation',
      description: 'Moderate your content using our moderation personas.',
      icon: notionImg,
      isSelected: false,
      type: 'text',
      isFree: true,
      toolTip: 'Moderate your content using our moderation personas.',

      settings: <ChatGptSettings />,
    },
    {
      id: 6,
      title: 'Translate Tools *',
      description: 'Translate everything during the process of creation.',
      icon: notionImg,
      isSelected: false,
      type: 'text',
      isFree: true,
      toolTip: 'Translate everything during the process of creation.',

      settings: <TranslateSettings />,
    },
    {
      id: 7,
      title: 'Spam Tools',
      description: 'Analise all content for spam and fraud content.',
      icon: notionImg,
      isSelected: false,
      type: 'text',
      isFree: true,
      toolTip: 'Analise all content for spam and fraud content.',

      settings: <ChatGptSettings />,
    },
  ])

  const handleClick = (id: number) => {
    setAiToolsData(aiToolsData.map(tool => (tool.id === id ? { ...tool, isSelected: !tool.isSelected } : tool)))
  }

  const [filter, setFilter] = useState('all')
  const [visibleSettingsId, setVisibleSettingsId] = useState<null | number>(null)
  const [visibleInfoTip, setVisibleInfoTip] = useState<null | number>(null)

  const handleSettingsClick = (event: React.MouseEvent, id: number) => {
    event.stopPropagation()
    if (visibleSettingsId === id) {
      setVisibleSettingsId(null)
      setVisibleInfoTip(null)
    } else {
      setVisibleSettingsId(id)
      setVisibleInfoTip(null)
    }
  }

  const handleInfoTip = (event: React.MouseEvent, id: number) => {
    event.stopPropagation()
    if (visibleInfoTip === id) {
      setVisibleInfoTip(null)
      setVisibleSettingsId(null)
    } else {
      setVisibleInfoTip(id)
      setVisibleSettingsId(null)
    }
  }

  return (
    <section className='py-4 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='p-6 border rounded-xl'>
          <div className='flex flex-wrap justify-between mb-6 -m-2 border-b pb-4'>
            <div className='w-auto p-2'>
              <h3 className='font-heading text-lg font-semibold'>Select Models you want to use</h3>
            </div>
            <div className='w-auto p-2'>
              <ul className='inline-flex flex-wrap items-center -m-4'>
                <li className='text-xs font-semibold'>Filters:</li>
                <li className='p-4'>
                  <a className='group flex items-center text-sm text-neutral-600 font-medium' href='##' onClick={() => setFilter('all')}>
                    <AllFilterIcon />

                    <span>All</span>
                  </a>
                </li>
                <li className='p-4'>
                  <a className='group flex items-center text-sm text-neutral-600 font-medium' href='##' onClick={() => setFilter('free')}>
                    <FreeIcon />

                    <span>Free</span>
                  </a>
                </li>
                <li className='p-4'>
                  <a className='group flex items-center text-sm text-neutral-600 font-medium' href='##' onClick={() => setFilter('paid')}>
                    <PaidIcon />

                    <span>Paid</span>
                  </a>
                </li>
                <li className='p-4'>
                  <a
                    className='group flex items-center text-sm text-neutral-400 hover:text-neutral-600 font-medium'
                    href='##'
                    onClick={() => setFilter('text')}
                  >
                    <TextAiIcon />

                    <span>Text AI</span>
                  </a>
                </li>
                <li className='p-4'>
                  <a
                    className='group flex items-center text-sm text-neutral-400 hover:text-neutral-600 font-medium'
                    href='##'
                    onClick={() => setFilter('image')}
                  >
                    <ImageAiIcon />

                    <span>Image AI</span>
                  </a>
                </li>
                <li className='p-4'>
                  <a
                    className='group flex items-center text-sm text-neutral-400 hover:text-neutral-600 font-medium'
                    href='##'
                    onClick={() => setFilter('video')}
                  >
                    <VideoAiIcon />

                    <span>Video AI</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='flex flex-wrap -m-2.5'>
            {aiToolsData
              .filter(tool => {
                if (filter === 'all') return true
                if (filter === 'free') return tool.isFree
                if (filter === 'paid') return !tool.isFree
                if (filter === 'text') return tool.type === 'text'
                if (filter === 'image') return tool.type === 'image'
                if (filter === 'video') return tool.type === 'video'
              })
              .map(tool => (
                <div className='w-full sm:w-1/2 p-2.5' key={tool.id}>
                  <div
                    className={`relative p-6 bg-white hover:bg-light border rounded-lg cursor-pointer shadow-md ${
                      tool.isSelected ? 'bg-yellow-50' : ''
                    }`}
                    onClick={() => handleClick(tool.id)}
                  >
                    {tool.isSelected && (
                      <span className='absolute -top-3 -left-3 bg-green-500 text-white rounded-full text-xs py-1 px-1'>
                        <SelectedIcon />
                      </span>
                    )}
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full'>
                          <img src={tool.icon} alt='' />
                        </div>
                      </div>
                      <div className='flex-1 p-2 relative'>
                        <h3 className='font-heading mb-1 font-semibold'>{tool.title}</h3>
                        <p className='text-xs text-neutral-500'>{tool.description}</p>
                      </div>
                      <div className='flex items-center justify-center border-l pl-2'>
                        <button
                          onClick={event => handleInfoTip(event, tool.id)}
                          className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
                        >
                          <InfoToolTipIcon />
                        </button>
                        <button
                          onClick={event => handleSettingsClick(event, tool.id)}
                          className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
                        >
                          <SettingsIcon />
                        </button>
                        <button
                          onClick={() => handleClick(tool.id)}
                          className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
                        >
                          {tool.isSelected && <DisableIcon />}
                          {!tool.isSelected && <EnableIcon />}
                        </button>
                      </div>
                    </div>
                    <div className=''>
                      {visibleSettingsId === tool.id && <div className='mt-4 bg-slate-50 rounded-lg w-full'>{tool.settings}</div>}
                      {visibleInfoTip === tool.id && (
                        <div className='mt-4 bg-slate-50 rounded-lg w-full flex items-center'>
                          <div className='flex-shrink-0 w-full max-w-[100px]'>
                            <img src={tool.icon} className='w-full h-auto p-6' alt='' />
                          </div>
                          <div className='flex-grow px-4'>
                            <span>{tool.toolTip}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
