import { CustomLink } from 'data/types'

import bgCookies from 'images/uinel-assets/images/cookies/cookies-image.svg'
import React, { useEffect, useState } from 'react'
import Logo from 'shared/Logo/Logo'
import SocialsListFooter from 'shared/SocialsListFooter/SocialsListFooter'
export interface WidgetFooterMenu {
  id: string
  title: string
  menus: CustomLink[]
}

const CookiesComponent: React.FC<{ onAccept: () => void }> = ({ onAccept }) => {
  return (
    <div className='fixed bottom-0 right-0 z-50 p-6'>
      <div className='relative max-w-3xl px-8 md:px-14 pt-6 pb-9 bg-teal-50 rounded-2xl shadow-xl z-50 dark:bg-slate-700'>
        <div className='flex flex-wrap items-center -mx-4'>
          <div className='w-full md:w-1/2 px-4 mb-6 md:mb-0'>
            <div className='flex items-center mb-4'>
              <img className='mr-8' src={bgCookies} alt='' />
              <h3 className='font-heading font-medium text-xl sm:text-3xl leading-loose'>Cookies</h3>
            </div>
            <p className='text-sm sm:text-base leading-6'>
              By clicking &ldquo;Accept&rdquo;, you agree to the storing of cookies on your device ...
            </p>
          </div>
          <div className='w-full md:w-1/2 flex flex-row lg:flex-row justify-center lg:justify-end items-center px-4 gap-x-4'>
            <a
              className='inline-block py-1 mb-4 lg:mb-0 lg:mr-9 lg:mt-10 font-bold text-lg leading-7 border-b border-black'
              href='/privacy-policy'
            >
              <span className='text-sm sm:text-base'>Read</span>
            </a>
            <button
              className='lg:max-w-max py-3 px-10 lg:mt-10 text-xl sm:text-base leading-7 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
              onClick={onAccept} // Add the event handler to the Accept button
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: '5',
    title: 'Getting started',
    menus: [
      { href: '/', label: 'How it Works' },
      { href: '/about', label: 'About the Project' },
      { href: '/faq', label: 'Support' },
    ],
  },
  {
    id: '1',
    title: 'Areas',
    menus: [
      { href: '/education', label: 'Education' },
      { href: '/travel', label: 'Travel' },
      { href: '/health', label: 'Health' },
    ],
  },
  {
    id: '2',
    title: ' ',
    menus: [
      // { href: '/business', label: 'Business' },
      { href: '/finance', label: 'Finance' },
      { href: '/advisory', label: 'Advisory & Business' },
      { href: '/team', label: 'Team' },
    ],
  },
  {
    id: '4',
    title: 'Rightly Guided',
    menus: [
      { href: '/contact', label: 'Contact Us' },
      { href: '/donate', label: 'Donate' },
      { href: '/invest', label: 'Invest' },
    ],
  },
]

const Footer: React.FC = () => {
  const [acceptedCookies, setAcceptedCookies] = useState<boolean>(false) // State variable for tracking acceptance

  useEffect(() => {
    const isAccepted = sessionStorage.getItem('cookiesAccepted') === 'true' // Check session storage for acceptance status
    setAcceptedCookies(isAccepted) // Update the state variable
  }, [])

  const handleAcceptCookies = () => {
    sessionStorage.setItem('cookiesAccepted', 'true')
    setAcceptedCookies(true) // Update the state variable
  }
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className='text-sm'>
        <h2 className='font-semibold text-neutral-700 dark:text-neutral-200 h-4'>{menu.title}</h2>
        <ul className='mt-5 space-y-4'>
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a key={index} className='text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white' href={item.href}>
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        {/* {!acceptedCookies && <CookiesComponent onAccept={handleAcceptCookies} />}{' '} */}
        {/* Show CookiesComponent only if cookies are not accepted */}
      </div>
    )
  }

  return (
    <div className='nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700'>
      <div className='container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 '>
        <div className='grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col'>
          <div className='col-span-2 md:col-span-1'>
            <Logo />
          </div>
          <div className='col-span-2 flex items-center md:col-span-3'>
            <SocialsListFooter className='flex items-center justify-center space-x-3 lg:space-x-0 lg:space-y-2.5 lg:items-start' />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className='overflow-hidden'>
        <div className='container mx-auto px-4'>
          <div className='pt-20 pb-8'>
            <div className='border-b border-gray-200'></div>
          </div>
          <div className='container flex flex-wrap items-center justify-between pb-8 -m-6'>
            <div className='w-auto p-6'>
              <p className='text-sm text-gray-900'>&copy; Copyright 2023. All Rights Reserved. </p>
            </div>
            <div className='w-auto p-6'>
              <div className='flex flex-wrap items-center -m-1.5'>
                <div className='w-auto p-1.5'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
                    />
                  </svg>
                </div>
                <div className='w-auto p-1.5'>
                  <h3 className='font-heading font-medium text-base text-gray-900'>The UK Catalogue</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
