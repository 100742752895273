import notionImg from 'images/dashy-assets/images/notion.svg'
import React from 'react'

export default function NewTools() {
  return (
    <section className='py-4 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='p-6  border rounded-xl'>
          <div className='flex flex-wrap justify-between mb-6 -m-2'>
            <div className='w-auto p-2'>
              <h3 className='font-heading text-lg font-semibold'>New tools spotlight</h3>
            </div>
            <div className='w-auto p-2'>
              <a className='text-xs text-slate-900 text-transparent font-semibold uppercase bg-clip-text bg-gradient-purple-left' href='##'>
                View all
              </a>
            </div>
          </div>
          <div className='flex flex-wrap -m-2.5'>
            <div className='w-full sm:w-1/2 p-2.5'>
              <div className='p-6 bg-white hover:bg-light border rounded-lg cursor-pointer shadow-md'>
                <div className='flex flex-wrap -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full'>
                      <img src={notionImg} alt='' />
                    </div>
                  </div>
                  <div className='flex-1 p-2 relative'>
                    <h3 className='font-heading mb-1 font-semibold'>Notion</h3>
                    <p className='text-xs text-neutral-500'>An Ai built with Mufti approvement.</p>
                  </div>
                  <div className='flex items-center justify-center border-l pl-2'>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 p-2.5'>
              <div className='p-6 bg-white hover:bg-light border rounded-lg cursor-pointer shadow-md'>
                <div className='flex flex-wrap -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full'>
                      <img src={notionImg} alt='' />
                    </div>
                  </div>
                  <div className='flex-1 p-2 relative'>
                    <h3 className='font-heading mb-1 font-semibold'>Notion</h3>
                    <p className='text-xs text-neutral-500'>An Ai built with Mufti approvement.</p>
                  </div>
                  <div className='flex items-center justify-center border-l pl-2'>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 p-2.5'>
              <div className='p-6 bg-white hover:bg-light border rounded-lg cursor-pointer shadow-md'>
                <div className='flex flex-wrap -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full'>
                      <img src={notionImg} alt='' />
                    </div>
                  </div>
                  <div className='flex-1 p-2 relative'>
                    <h3 className='font-heading mb-1 font-semibold'>Notion</h3>
                    <p className='text-xs text-neutral-500'>An Ai built with Mufti approvement.</p>
                  </div>
                  <div className='flex items-center justify-center border-l pl-2'>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 p-2.5'>
              <div className='p-6 bg-white hover:bg-light border rounded-lg cursor-pointer shadow-md'>
                <div className='flex flex-wrap -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex items-center justify-center w-12 h-12 bg-white rounded-full'>
                      <img src={notionImg} alt='' />
                    </div>
                  </div>
                  <div className='flex-1 p-2 relative'>
                    <h3 className='font-heading mb-1 font-semibold'>Notion</h3>
                    <p className='text-xs text-neutral-500'>An Ai built with Mufti approvement.</p>
                  </div>
                  <div className='flex items-center justify-center border-l pl-2'>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
                        />
                      </svg>
                    </button>
                    <button className='py-1 px-2 w-full text-slate-800 font-semibold focus:ring  transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M21 7.5V18M15 7.5V18M3 16.811V8.69c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 010 1.954l-7.108 4.061A1.125 1.125 0 013 16.811z'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
