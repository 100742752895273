import paymentProcess from 'images/uinel-assets/images/ecommerce-cart/procents.svg'
import imgSample from 'images/uinel-assets/images/ecommerce-product-info/phone01.png'
import React from 'react'
import BuildPackage from './BuildPackage'
import BuildPackageV2 from './BuildPackageV2'
import EducationsAddons from './EducationsAddons'

export default function PageAddons() {
  return (
    <div>
      <section className='pt-12 pb-24 overflow-hidden bg-slate-100'>
        <div className='container px-4 mx-auto'>
          <ul className='flex flex-wrap items-center mb-10 xl:mb-0'>
            <li className='mr-6'>
              <a className='flex items-center text-sm font-medium text-gray-400 hover:text-gray-500' href='#'>
                <span>Home</span>
                <svg className='ml-6' width='4' height='7' viewBox='0 0 4 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M0.150291 0.898704C-0.0500975 0.692525 -0.0500975 0.359292 0.150291 0.154634C0.35068 -0.0507836 0.674443 -0.0523053 0.874831 0.154634L3.7386 3.12787C3.93899 3.33329 3.93899 3.66576 3.7386 3.8727L0.874832 6.84594C0.675191 7.05135 0.35068 7.05135 0.150292 6.84594C-0.0500972 6.63976 -0.0500972 6.30652 0.150292 6.10187L2.49888 3.49914L0.150291 0.898704Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </a>
            </li>
            <li className='mr-6'>
              <a className='flex items-center text-sm font-medium text-gray-400 hover:text-gray-500' href='#'>
                <span>Store</span>
                <svg className='ml-6' width='4' height='7' viewBox='0 0 4 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M0.150291 0.898704C-0.0500975 0.692525 -0.0500975 0.359292 0.150291 0.154634C0.35068 -0.0507836 0.674443 -0.0523053 0.874831 0.154634L3.7386 3.12787C3.93899 3.33329 3.93899 3.66576 3.7386 3.8727L0.874832 6.84594C0.675191 7.05135 0.35068 7.05135 0.150292 6.84594C-0.0500972 6.63976 -0.0500972 6.30652 0.150292 6.10187L2.49888 3.49914L0.150291 0.898704Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </a>
            </li>
            <li>
              <a className='text-sm font-medium text-indigo-500 hover:text-indigo-600' href='#'>
                Your cart
              </a>
            </li>
          </ul>
          <div className='mb-8 pb-8 border-b border-gray-200 border-opacity-40'>
            <h1 className='text-center text-9xl xl:text-10xl font-heading font-medium'>Your cart</h1>
          </div>
          <div className='flex flex-wrap -mx-4 mb-14 xl:mb-24'>
            <div className='w-full md:w-8/12 xl:w-9/12 px-4 mb-14 md:mb-0'>
              <div className='py-12 px-8 md:px-12 bg-white rounded-3xl'>
                <span className='inline-block mb-16 text-darkBlueGray-300 font-medium'>3 products</span>
                <div className='xl:px-10'>
                  <div className='relative flex flex-wrap items-center xl:justify-between -mx-4 mb-8 pb-8 border-b border-gray-200 border-opacity-40'>
                    <div className='relative w-full md:w-auto px-4 mb-6 xl:mb-0'>
                      <a className='block mx-auto max-w-max' href='#'>
                        <img className='h-28 object-cover' src={imgSample} alt='' />
                      </a>
                    </div>
                    <div className='w-full md:w-auto px-4 mb-6 xl:mb-0'>
                      <a className='block mb-5 text-xl font-heading font-medium hover:underline' href='#'>
                        Apple iPhone 12 Pro (128GB)
                      </a>
                      <div className='flex flex-wrap'>
                        <p className='mr-4 text-sm font-medium'>
                          <span className='font-heading'>Color:</span>
                          <span className='ml-2 text-gray-400'>Silver</span>
                        </p>
                        <p className='text-sm font-medium'>
                          <span>Memory storage:</span>
                          <span className='ml-2 text-gray-400'>128GB</span>
                        </p>
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4 mb-6 xl:mb-0 mt-6 xl:mt-0'>
                      <div className='flex items-center'>
                        <h4 className='mr-4 font-heading font-medium'>Qty:</h4>
                        <input
                          className='w-16 px-3 py-2 text-center placeholder-gray-400 text-gray-400 bg-blue-50 border-2 border-blue-400 outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                          type='text'
                          placeholder='1'
                        />
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4'>
                      <span className='text-xl font-heading font-medium text-blue-500'>
                        <span className='text-sm'>$</span>
                        <span>544.90</span>
                      </span>
                    </div>
                    <button className='absolute top-0 right-0 lg:mt-6 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                        <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                        <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                      </svg>
                    </button>
                  </div>
                  <div className='relative flex flex-wrap items-center xl:justify-between -mx-4 mb-8 pb-8 border-b border-gray-200 border-opacity-40'>
                    <div className='relative w-full md:w-auto px-4 mb-6 xl:mb-0'>
                      <a className='block mx-auto max-w-max' href='#'>
                        <img className='h-28 object-cover' src={imgSample} alt='' />
                      </a>
                    </div>
                    <div className='w-full md:w-auto px-4 mb-6 xl:mb-0'>
                      <a className='block mb-5 text-xl font-heading font-medium hover:underline' href='#'>
                        Headphones SONY 1l X-O
                      </a>
                      <div className='flex flex-wrap'>
                        <p className='mr-4 text-sm font-medium'>
                          <span className='font-heading'>Color:</span>
                          <span className='ml-2 text-gray-400'>Grey</span>
                        </p>
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4 mb-6 xl:mb-0 mt-6 xl:mt-0'>
                      <div className='flex items-center'>
                        <h4 className='mr-4 font-heading font-medium'>Qty:</h4>
                        <input
                          className='w-16 px-3 py-2 text-center placeholder-gray-400 text-gray-400 bg-blue-50 border-2 border-blue-400 outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                          type='text'
                          placeholder='1'
                        />
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4'>
                      <span className='text-xl font-heading font-medium text-blue-500'>
                        <span className='text-sm'>$</span>
                        <span>44.90</span>
                      </span>
                    </div>
                    <button className='absolute top-0 right-0 lg:mt-6 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                        <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                        <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                      </svg>
                    </button>
                  </div>
                  <div className='relative flex flex-wrap items-center xl:justify-between -mx-4'>
                    <div className='relative w-full md:w-auto px-4 mb-10 xl:mb-0'>
                      <a className='block mx-auto max-w-max' href='#'>
                        <img className='h-28 object-cover' src={imgSample} alt='' />
                      </a>
                    </div>
                    <div className='w-full md:w-auto px-4 mb-6 xl:mb-0'>
                      <a className='block mb-5 text-xl font-heading font-medium hover:underline' href='#'>
                        Smartwatch Uistore Watch 19
                      </a>
                      <div className='flex flex-wrap'>
                        <p className='mr-4 text-sm font-medium'>
                          <span className='font-heading'>Color:</span>
                          <span className='ml-2 text-gray-400'>Green</span>
                        </p>
                        <p className='text-sm font-medium'>
                          <span>Size:</span>
                          <span className='ml-2 text-gray-400'>M</span>
                        </p>
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4 mb-6 xl:mb-0 mt-6 xl:mt-0'>
                      <div className='flex items-center'>
                        <h4 className='mr-4 font-heading font-medium'>Qty:</h4>
                        <input
                          className='w-16 px-3 py-2 text-center placeholder-gray-400 text-gray-400 bg-blue-50 border-2 border-blue-400 outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                          type='text'
                          placeholder='1'
                        />
                      </div>
                    </div>
                    <div className='w-full xl:w-auto px-4'>
                      <span className='text-xl font-heading font-medium text-blue-500'>
                        <span className='text-sm'>$</span>
                        <span>120.90</span>
                      </span>
                    </div>
                    <button className='absolute top-0 right-0 lg:mt-6 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                        <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                        <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full md:w-4/12 xl:w-3/12 px-4'>
              <div className='mb-14'>
                <h2 className='mb-7 md:mt-6 text-3xl font-heading font-medium'>Your Order</h2>
                <div className='flex relative items-center justify-between py-4 px-10 mb-3 leading-8 bg-white bg-opacity-50 font-heading font-medium rounded-3xl'>
                  <div>
                    Username Reserved<span className='block'>@tiago</span>
                  </div>
                  <span className='flex items-center text-xl'>
                    <span className='mr-2 text-base'>$</span>
                    <span>710,70</span>
                  </span>
                  <button className='absolute top-0 right-0 lg:mt-0 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                      <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                      <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                    </svg>
                  </button>
                </div>
                <div className='flex items-center justify-between py-4 px-10 mb-3 leading-8 bg-white bg-opacity-50 font-heading font-medium rounded-3xl'>
                  <span>Shipping</span>
                  <span className='flex items-center text-xl'>
                    <span className='mr-2 text-base'>$</span>
                    <span>10,00</span>
                  </span>
                </div>
                <div className='flex items-center justify-between py-4 px-10 mb-6 leading-8 bg-white font-heading font-medium rounded-3xl'>
                  <span>Total</span>
                  <span className='flex items-center text-xl text-blue-500'>
                    <span className='mr-2 text-base'>$</span>
                    <span>720,70</span>
                  </span>
                </div>
                <a
                  className='inline-block w-full lg:w-auto py-5 px-10 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                  href='#'
                >
                  Checkout
                </a>
              </div>
              <div className='text-center md:text-left'>
                <img className='block mb-9 mx-auto md:ml-0' src={paymentProcess} alt='' />
                <h4 className='mb-5 text-8xl font-heading font-medium'>Shipping</h4>
                <p className='lg:pr-10 text-lg text-darkBlueGray-300'>The nulla commodo, commodo eros.</p>
              </div>
            </div>
          </div>
          <div className='md:w-96'>
            <a
              className='block py-5 px-10 w-full text-xl leading-6 font-medium tracking-tighter font-heading text-center bg-white hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
              href='#'
            >
              Back to shop
            </a>
          </div>
        </div>
      </section>
      <EducationsAddons />
      <BuildPackage />
      <BuildPackageV2 />
    </div>
  )
}
