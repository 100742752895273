import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFetchCollectionFirstChapters } from './FetchFirstChapter'
import { useFetchHadiths } from './FetchHadiths'
import ShareTool from './ShareTool'

const ukFlag = (
  <svg className='h-2.5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 30'>
    <clipPath id='a'>
      <path d='M0 0v30h60V0z' />
    </clipPath>
    <clipPath id='b'>
      <path d='M30 15h30v15zv15H0zH0V0zV0h30z' />
    </clipPath>
    <g clip-path='url(#a)'>
      <path d='M0 0v30h60V0z' fill='#012169' />
      <path d='M0 0l60 30m0-30L0 30' stroke='#fff' stroke-width='6' />
      <path d='M0 0l60 30m0-30L0 30' clip-path='url(#b)' stroke='#C8102E' stroke-width='4' />
      <path d='M30 0v30M0 15h60' stroke='#fff' stroke-width='10' />
      <path d='M30 0v30M0 15h60' stroke='#C8102E' stroke-width='6' />
    </g>
  </svg>
)

const aeFlag = (
  <svg className='h-2.5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 3'>
    <path fill='#00843D' d='M0 0h6v3H0z' />
    <path fill='#fff' d='M0 1h6v2H0z' />
    <path d='M0 2h6v1H0z' />
    <path fill='#C8102E' d='M0 0h1.5v3H0z' />
  </svg>
)

const verifiedIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
    />
  </svg>
)

interface Hadith {
  lang: string
  body: string
}

interface HadithLang {
  lang: string
  chapterNumber: string
  chapterTitle: string
  urn: number
  body: string
  grades: {
    graded_by: string | null
    grade: string
  }[]
}

interface Hadiths {
  hadithNumber: string // change this to number
  hadith: HadithLang[]
}

export interface ChapterLang {
  lang: string
  chapterNumber: string
  chapterTitle: string
  intro: string | null
  ending: string | null
}

export interface Chapter {
  bookNumber: string
  chapterId: string
  chapter: ChapterLang[]
}

const ChapterPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const collectionName = searchParams.get('collection')
  const bookNumber = searchParams.get('book')

  const { fetchHadiths } = useFetchHadiths()
  const [hadiths, setHadiths] = useState<Hadiths[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [chapter, setChapter] = useState<Chapter[]>([])

  const { chapters, fetchFirstChapters } = useFetchCollectionFirstChapters()

  const [searchTerm, setSearchTerm] = useState('')

  const navigate = useNavigate()

  const [filteredHadiths, setFilteredHadiths] = useState<Hadiths[]>([])

  useEffect(() => {
    const fetchData = async () => {
      if (collectionName && bookNumber) {
        setIsLoading(true)
        try {
          const firstChapter = await fetchFirstChapters(collectionName, bookNumber)
          const result = await fetchHadiths(collectionName, bookNumber)
          console.log('First chapter: ', firstChapter) //added for debugging
          console.log('Result: ', result) //added for debugging
          setChapter(firstChapter)
          setHadiths(result)
        } catch (error) {
          console.error('Error:', error)
          navigate('/page404')
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchData()
  }, [collectionName, bookNumber, fetchHadiths, fetchFirstChapters])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const clearSearch = () => {
    setSearchTerm('')
    setFilteredHadiths(hadiths)
  }

  useEffect(() => {
    setFilteredHadiths(hadiths)
  }, [hadiths])

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      setFilteredHadiths(hadiths)
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase()
      const filtered = hadiths.filter(hadith =>
        hadith.hadith.some(hadithLang => hadithLang.body.toLowerCase().includes(lowercasedSearchTerm))
      )
      setFilteredHadiths(filtered)
    }
  }

  if (isLoading) {
    return (
      <div className='flex justify-center items-center'>
        <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500'></div>
      </div>
    )
  }

  return (
    <div className='container'>
      <div className='flex justify-between items-center my-12'>
        <button
          onClick={() => navigate(-1)}
          className='bg-slate-500 flex items-center justify-center hover:bg-slate-700 dark:bg-slate-800 text-white font-bold py-2 px-4 rounded'
        >
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3' />
            </svg>
          </span>
          <span>Go Back</span>
        </button>
        <form
          className='relative flex justify-center'
          onSubmit={e => {
            e.preventDefault()
            handleSearch()
          }}
        >
          <input
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearchChange}
            className='border-2 border-gray-300 bg-white h-10 px-5 pr-10 rounded-lg text-sm focus:outline-none w-64'
          />
          {searchTerm && (
            <button type='button' onClick={clearSearch} className='absolute right-12 top-0 mt-2 mr-4 text-gray-600 hover:text-black'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z'
                />
              </svg>
            </button>
          )}

          <button
            onClick={handleSearch}
            type='submit'
            className='bg-slate-500 ml-2 hover:bg-slate-700 dark:bg-slate-800 text-white font-bold py-2 px-4 rounded'
          >
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' className='w-4 h-4'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' />
            </svg>
          </button>
        </form>
      </div>

      <div className='py-6 px-4'>
        {/* render first Chapter */}

        {chapter[0] && (
          <div className='flex items-start justify-between flex-wrap'>
            {chapter[0].chapter.map((chapterLang, index) => (
              <div className={`${chapterLang.lang !== 'en' ? 'text-right w-full md:w-1/2' : 'w-full md:w-1/2'}`} key={index}>
                {/* <h4>Language: {chapterLang.lang}</h4> */}
                <h4 className='text-xl font-semibold'>{chapterLang.chapterTitle}</h4>
                <div dangerouslySetInnerHTML={{ __html: chapterLang.intro || '' }} />
              </div>
            ))}
          </div>
        )}
      </div>
      {filteredHadiths.map((hadith, i) => (
        <div
          className='py-4 px-4 pb-8 bg-slate-100 dark:bg-slate-800 dark:hover-bg-slate-700 dark:hover:bg-opacity-60 my-8 rounded-xl shadow-md'
          key={i}
        >
          <div className='relative flex items-start justify-between flex-wrap'>
            <span className='text-xs absolute flex items-center justify-center -top-4 -left-12 bg-slate-100 dark:bg-slate-800 w-6 h-6 rounded-full p-1'>
              {hadith.hadithNumber}
            </span>
            <span className='text-xs absolute flex items-center justify-center -top-4 -right-12 bg-slate-100 dark:bg-slate-800 w-6 h-6 rounded-full p-1'>
              {hadith.hadithNumber}
            </span>
            <div className='absolute right-0 bottom-0'>
              <ShareTool url='https://rightly-guided.co.uk' hashtag='#rg' quote='Rightly Guided Sunnah' />
            </div>
            {hadith.hadith.map((hadithLang, j) => (
              <div className={`${hadithLang.lang !== 'en' ? 'text-right w-full md:w-1/2' : 'w-full md:w-1/2'}`} key={j}>
                <div className={`${hadithLang.lang !== 'en' ? 'flex justify-end' : ''}`}>
                  <div className={`absolute top-4 ${hadithLang.lang !== 'en' ? '-right-12 pr-0.5' : '-left-12 pl-0.5'}`}>
                    {hadithLang.lang !== 'en' ? aeFlag : ukFlag}
                  </div>
                </div>

                {/* <h4>Language: {hadithLang.lang}</h4> */}
                <h4 className='text-xl font-semibold'>Chapter Title: {hadithLang.chapterTitle}</h4>
                <div
                  className={`${hadithLang.lang === 'en' ? '[&>*:first-child]:text-slate-600 [&>*:first-child]:font-bold' : ''}`}
                  dangerouslySetInnerHTML={{ __html: hadithLang.body }}
                />
                <p>urn {hadithLang.urn}</p>
                {/* <p>Grades:</p> */}
                {hadithLang.grades.map((grade, k) => (
                  <div key={k}>
                    {grade.graded_by && <div>{grade.graded_by} ,</div>}
                    {grade.grade}
                  </div>
                ))}
                <p>
                  Book: {bookNumber} | Hadiths: {hadith.hadithNumber}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ChapterPage
