import Glide from '@glidejs/glide'
import CardCategory3 from 'components/CardCategory3/CardCategory3'
import CardCategory4 from 'components/CardCategory4/CardCategory4'
import CardCategory5 from 'components/CardCategory5/CardCategory5'
import Heading from 'components/Heading/Heading'
import { TaxonomyType } from 'data/types'
import useNcId from 'hooks/useNcId'
import React, { FC, useEffect, useMemo, useState } from 'react'
import NextPrev from 'shared/NextPrev/NextPrev'

import appShowImg from 'images/appshow.png'
// App food
import appFood1 from 'images/appFood/1.png'
import appFood2 from 'images/appFood/2.png'
import appFood3 from 'images/appFood/3.png'
import appFood4 from 'images/appFood/4.png'
import appFood5 from 'images/appFood/5.png'
import appFood6 from 'images/appFood/6.png'
import appFood7 from 'images/appFood/7.png'

export interface SectionHomeAppShowInterface {
  className?: string
  itemClassName?: string
  heading?: string
  subHeading?: string
  categories?: TaxonomyType[]
  categoryCardType?: 'card3' | 'card4' | 'card5'
  itemPerRow?: 1 | 3 | 4 | 5
  sliderStyle?: 'style1' | 'style2'
  uniqueClassName: string
  showTitle?: boolean
}

const dataAppShow: TaxonomyType[] = [
  {
    id: '1',
    href: '#',
    name: 'Food App',
    taxonomy: 'category',
    count: 17288,
    thumbnail: appFood1,
  },
  {
    id: '2',
    href: '#',
    name: 'Food App',
    taxonomy: 'category',
    count: 2118,
    thumbnail: appFood2,
  },
  {
    id: '3',
    href: '#',
    name: 'Food App',
    taxonomy: 'category',
    count: 2118,
    thumbnail: appFood3,
  },
]
const SectionHomeAppShowFood: FC<SectionHomeAppShowInterface> = ({
  heading = 'Heading of sections',
  subHeading = 'Descriptions for sections',
  className = '',
  itemClassName = '',
  categories = dataAppShow,
  itemPerRow = 1,
  categoryCardType = 'card3',
  sliderStyle = 'style1',
  uniqueClassName,
  showTitle = true,
}) => {
  const UNIQUE_CLASS = 'SectionSliderNewCategories__' + uniqueClassName + useNcId()

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 2,
        },
        1024: {
          gap: 20,
          perView: 2,
        },
        768: {
          gap: 20,
          perView: 2,
        },
        680: {
          gap: 20,
          perView: 1,
        },
        460: {
          gap: 20,
          perView: 1,
        },
      },
    })
  }, [UNIQUE_CLASS])

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount()
    }, 100)
  }, [MY_GLIDEJS, UNIQUE_CLASS])

  const renderCard = (item: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case 'card3':
        return <CardCategory3 taxonomy={item} />
      case 'card4':
        return <CardCategory4 taxonomy={item} />
      case 'card5':
        return <CardCategory5 taxonomy={item} />
      default:
        return <CardCategory3 taxonomy={item} />
    }
  }

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        {showTitle && (
          <Heading desc={subHeading} hasNextPrev={sliderStyle === 'style2'} isCenter={sliderStyle === 'style2'}>
            {heading}
          </Heading>
        )}

        <div className='glide__track' data-glide-el='track'>
          <ul className='glide__slides'>
            {categories.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === 'style1' && <NextPrev className='justify-center mt-6' />}
      </div>
    </div>
  )
}

export default SectionHomeAppShowFood
