import notionImg from 'images/dashy-assets/images/notion.svg'
import React, { useEffect, useState } from 'react'
import SelectAi from '../Builder/SelectAi'
import SelectModels from '../Builder/SelectModels'
import SelectTools from '../Builder/SelectTools'
import SelectValidations from '../Builder/SelectValidations'
import Steps from '../Builder/Steps'

export default function SchoolSubjectAi() {
  const [step, setStep] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1)
    }
  }

  const handlePrevious = () => {
    if (step > 1) {
      // setStep(step - 1)
      setStep(1)
    }
  }

  const stepsComponents: Record<string, React.ComponentType> = {
    '1': SelectAi,
    '2': SelectModels,
    '3': SelectValidations,
    '4': SelectTools,
  }

  const StepComponent = stepsComponents[String(step)] || SelectAi

  return (
    <div>
      <Steps step={step} />
      <StepComponent />

      <section className='py-1'>
        <div className='container px-4 mx-auto'>
          <div className='px-5 py-6'>
            <div className='flex flex-wrap items-center justify-between -m-2'>
              <div className='w-full sm:w-auto p-2'>
                <div className='flex items-center'>
                  <button className='flex items-center justify-center h-6 w-11 mr-2 bg-gray-600 rounded-full'>
                    <div className='h-5 w-5 rounded-full bg-white'></div>
                    <div className='h-5 w-5 rounded-full bg-transparent'></div>
                  </button>
                  <span className='text-sm  font-medium'>Advanced</span>
                </div>
              </div>
              <div className='w-full sm:w-auto p-2'>
                <div className='flex flex-wrap items-center -m-1.5'>
                  <div className='w-full sm:w-auto'>
                    {step === 1 ? (
                      ''
                    ) : (
                      <button
                        className='inline-block w-full sm:w-auto py-2 px-4 mb-2 mr-3 text-xs text-center font-semibold leading-normal text-slate-800 rounded-lg transition duration-200'
                        onClick={handlePrevious}
                      >
                        Start again
                      </button>
                    )}
                  </div>
                  <div className='w-full sm:w-auto'>
                    <button
                      className='inline-block w-full sm:w-auto py-2 px-4 mb-2 text-xs text-center font-semibold leading-normal text-blue-50 bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200'
                      onClick={handleNext}
                      disabled={step === 4}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
