import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import chatGptLogo from 'images/ChatGPT_logo.svg'
import MidjourneyLogo from 'images/MidjourneyLogo.png'
import bingLogo from 'images/bingLogo.png'
import googleLogo from 'images/google-logo.svg'
import checkImg from 'images/gradia-assets/elements/features/check.svg'
import avatarCircleImg from 'images/gradia-assets/images/hero/avatar-circle.png'
import react from 'react'

const ProductsBlockView = () => {
  return (
    <section className='relative py-28 overflow-hidden bg-gradient-to-r from-rose-100 to-teal-100 dark:bg-slate-900 dark:from-slate-900 dark:to-slate-900'>
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap items-center -m-6 '>
          <div className='w-full lg:w-1/2 p-6'>
            <div className='lg:max-w-2xl'>
              <h2 className='mb-9 font-heading text-5xl sm:text-7xl font-semibold text-gray-800 dark:text-white dark:text-opacity-80'>
                Marketplace
              </h2>
              <ul className='mb-11'>
                <li className='mb-3 flex items-center font-heading font-semibold text-lg text-gray-900 dark:text-white dark:text-opacity-80'>
                  <img className='mr-3.5' src={checkImg} alt='' />
                  <span>Access multiple AI Models in one place</span>
                </li>
                <li className='mb-3 flex items-center font-heading font-semibold text-lg text-gray-900 dark:text-white dark:text-opacity-80'>
                  <img className='mr-3.5' src={checkImg} alt='' />
                  <span>Over 200+ Tools & Integrations</span>
                </li>
                <li className='mb-3 flex items-center font-heading font-semibold text-lg text-gray-900 dark:text-white dark:text-opacity-80'>
                  <img className='mr-3.5' src={checkImg} alt='' />
                  <span>Build tools to suit your needs</span>
                </li>
              </ul>
              <div className='max-w-xs'>
                <a
                  href='/marketplace'
                  className='font-heading flex justify-center items-center w-full lg:w-auto px-4 py-3 text-base font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md'
                >
                  <span className='mr-2.5'>Explore Our Marketplace</span>
                  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9'
                      stroke='#F9FAFB'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/2 p-6 lg:p-11'>
            <div className='lg:max-w-md ml-auto'>
              <div className='flex flex-wrap w-full lg:-m-3'>
                <div className='w-full p-3'>
                  <div className='p-4 bg-white rounded-2xl shadow-2xl'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-11 h-11 rounded-full'>
                          <img src={googleLogo} alt='' />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <h3 className='font-heading font-medium text-gray-900 text-base'>Google Bard</h3>
                        <p className='text-xs text-gray-500'>by Google</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full p-3'>
                  <div className='lg:relative lg:-left-6 transform hover:translate-x-6 p-4 bg-white opacity-70 rounded-2xl shadow-2xl transition ease-in-out duration-500'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-11 h-11 rounded-full'>
                          <img className='h-10' src={chatGptLogo} alt='' />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <h3 className='font-heading font-medium text-gray-900 text-base'>ChatGpt</h3>
                        <p className='text-xs text-gray-500'>by OpenAi</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full p-3'>
                  <div className='p-4 bg-white opacity-50 rounded-2xl shadow-xl'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-11 h-11 rounded-full'>
                          <img className='h-10' src={bingLogo} alt='' />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <h3 className='font-heading font-medium text-gray-900 text-base'>Bing</h3>
                        <p className='text-xs text-gray-500'>by Microsoft</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full p-3'>
                  <div className='lg:relative lg:-left-6 transform hover:translate-x-6 p-4 bg-white opacity-30 rounded-2xl shadow-2xl transition ease-in-out duration-500'>
                    <div className='flex flex-wrap items-center -m-2'>
                      <div className='w-auto p-2'>
                        <div className='flex items-center justify-center w-11 h-11 rounded-full'>
                          <img src={MidjourneyLogo} alt='' />
                        </div>
                      </div>
                      <div className='w-auto p-2'>
                        <h3 className='font-heading font-medium text-gray-900 text-base'>Midjourney</h3>
                        <p className='text-xs text-gray-500'>by Midjourney</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsBlockView
