import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthContext'
import { API } from '../../constant'
import { getToken } from '../../helpers'
import QuizData from './QuizData.json'

interface Props {
  numQuestions: number
  levelFilter: string
  enableTimer: boolean
  start: boolean
}

interface Answer {
  id: number
  answers: string
  isCorrect: boolean
}

interface QuizQuestion {
  id: number
  attributes: {
    question: string
    level: string
    totalPoints: number
    timeQuiz: string
    maxAttempts: number
    showAnswers: boolean
    createdAt: string
    updatedAt: string
    publishedAt: string
    answers: Answer[]
  }
}

interface QuizScoreData {
  data: {
    quizName: string
    quizLevel: string
    QuizNumberQuestions: number
    quizPoints: number
    userEmail: string
    userName: string
  }
}

const Quiz: React.FC<Props> = ({ numQuestions, levelFilter, enableTimer, start }) => {
  const [quizzes, setQuizzes] = useState<QuizQuestion[]>([])
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0)
  const [userAnswers, setUserAnswers] = useState<number[]>([])
  const [showResult, setShowResult] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState<number>()
  const [isScoreSaved, setIsScoreSaved] = useState(false)

  const [newQuiz, setNewQuiz] = useState(false)

  // Timer state
  const [time, setTime] = useState(0)
  const timerRef = useRef<NodeJS.Timeout>()
  const [timeReachedText, setTimeReachedText] = useState('')

  // User login propose
  // const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})
  const [isLogged, setIsLogged] = useState(false)

  // Confirmation close
  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleCloseQuiz = () => {
    if (!showConfirmation) {
      setShowConfirmation(true)
    } else {
      window.location.reload()
    }
  }

  const buttonText = showConfirmation ? 'Yes' : 'Close'
  const confirmationText = showConfirmation ? 'Close?' : ''

  function shuffleArray(array: QuizQuestion[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  useEffect(() => {
    let timerDuration: number
    if (numQuestions > 20) {
      timerDuration = 7 * 60
    } else if (numQuestions >= 10 && numQuestions <= 20) {
      timerDuration = 5 * 60
    } else if (numQuestions >= 3 && numQuestions <= 9) {
      timerDuration = 3 * 60
    } else {
      timerDuration = 1 * 60
    }
    setTime(timerDuration)
  }, [numQuestions])

  useEffect(() => {
    if (enableTimer && !showResult && time > 0) {
      timerRef.current = setInterval(() => {
        setTime(prevTime => prevTime - 1)
      }, 1000)
    } else if (enableTimer && time === 0) {
      clearInterval(timerRef.current)
      setShowResult(true)
      setTimeReachedText('Timeout!')
    } else {
      clearInterval(timerRef.current)
      setTimeReachedText('')
    }

    return () => {
      clearInterval(timerRef.current)
    }
  }, [enableTimer, showResult, time])

  useEffect(() => {
    async function fetchQuizzes() {
      // const response = await fetch(`http://localhost:1337/api/quiz-questions?populate=*&filters[level]=${levelFilter}`)
      const response = QuizData
      // const data = await response.json()
      const data = response

      // Shuffle the data array
      const shuffledData = shuffleArray(data.data)

      // Slice the shuffled array to get the first numQuestions elements
      const newQuizzes = shuffledData.slice(0, numQuestions)

      // Save the new array to quizzes
      setQuizzes(newQuizzes)
      // console.log(currentQuizIndex);

      // Check if user is already logged in when the app starts
      // const isLoggedIn = checkUserLoggedIn()
      // console.log('logged in:', isLoggedIn)
    }
    // if the prop comes true the new quiz is created
    setNewQuiz(start)

    if (!newQuiz) {
      setNewQuiz(true)
      handleRetakeQuiz()
    }

    fetchQuizzes()
  }, [numQuestions, levelFilter])

  // Check if user is already logged in when the app starts
  // function checkUserLoggedIn(): boolean {
  //   const user = window.sessionStorage.getItem('user')
  //   const jwt = window.sessionStorage.getItem('jwt')

  //   // save the user and jwt to the state userData if any of them is not null/undefined
  //   if (user && jwt) {
  //     setUser(user)
  //     console.log('USER DATA TO CHECK - ' + user)
  //     console.log('-----------------------')
  //     setUserData(JSON.parse(user))
  //     setIsLogged(true)
  //   }

  //   return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  // }

  function handleAnswerSelect(answerId: number) {
    const newAnswers = [...userAnswers]
    newAnswers[currentQuizIndex] = answerId
    setUserAnswers(newAnswers)
    setSelectedAnswer(answerId)
  }

  function handleNextQuiz() {
    if (userAnswers[currentQuizIndex] === undefined) {
      // Highlight the answer selector with a red border for 1000 milliseconds
      const els = document.querySelectorAll('#answerSelector') as NodeListOf<HTMLInputElement>
      els.forEach((el, index) => {
        setTimeout(() => {
          el.style.border = '2px solid red'
          setTimeout(() => {
            el.style.border = '' // Reset the border style after 400 milliseconds.
          }, 400)
        }, index * 500)
      })

      // Cannot go to the next question if the current question is not answered
      console.log('Please select an answer.')
      return
    } else if (currentQuizIndex < quizzes.length - 1 && userAnswers[currentQuizIndex] !== undefined) {
      setCurrentQuizIndex(currentQuizIndex + 1)
    } else if (currentQuizIndex === quizzes.length - 1 && userAnswers[currentQuizIndex] !== undefined) {
      setShowResult(true)
    }
    setSelectedAnswer(undefined)
  }

  function handlePreviousQuiz() {
    if (currentQuizIndex > 0 && !showResult) {
      setCurrentQuizIndex(currentQuizIndex - 1)
    } else if (currentQuizIndex === 0) {
      // Cannot go back from the first question
      console.log('This is the first question.')
      return
    }
  }

  function handleRetakeQuiz() {
    setCurrentQuizIndex(0)
    setUserAnswers([])
    setShowResult(false)
    // setIsScoreSaved(false)
    setSelectedAnswer(undefined)
    setTimeReachedText('')
  }

  if (quizzes.length === 0) {
    return <div>Loading...</div>
  }

  const currentQuiz = quizzes[currentQuizIndex].attributes
  const currentAnswers = currentQuiz.answers

  function calculateTotalScore(userAnswers: number[], quizzes: QuizQuestion[]): number {
    let totalScore = 30

    quizzes.forEach((quiz, index) => {
      const quizAnswers = quiz.attributes.answers as Answer[]
      const userAnswerId = userAnswers[index]
      const userAnswer = quizAnswers.find(answer => answer.id === userAnswerId)

      if (userAnswer) {
        totalScore += userAnswer.isCorrect ? 10 : -5
      }
    })
    if (time === 0) {
      return 0
    }
    return Math.max(totalScore, 0)
  }

  // function SaveScoreButton() {
  //   async function handleSaveScore(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
  //     e.preventDefault()

  //     const quizName = quizzes[0].attributes.level // Or replace this with the actual quiz name
  //     const quizLevel = levelFilter
  //     const QuizNumberQuestions = numQuestions
  //     const quizPoints = calculateTotalScore(userAnswers, quizzes)

  //     const { name, email } = JSON.parse(user)
  //     const userEmail = email // Assuming you have the user's email in the userData object
  //     const userName = name // Assuming you have the user's name in the userData object

  //     console.log(process.env.REACT_APP_API_TOKEN)

  //     const testData: QuizScoreData = {
  //       data: {
  //         quizName: quizName + Math.random(),
  //         quizLevel: quizLevel,
  //         QuizNumberQuestions: Number(QuizNumberQuestions),
  //         quizPoints: Number(quizPoints),
  //         userEmail: userEmail,
  //         userName: userName,
  //       },
  //     }

  //     const response = await fetch(`${API}/scores`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  //       },
  //       body: JSON.stringify(testData),
  //     })
  //     console.log(testData)
  //     const responseData = await response.json()
  //     setIsScoreSaved(true)
  //     console.log('Quiz score saved successfully', responseData)
  //   }

  //   return (
  //     <button
  //       onClick={handleSaveScore}
  //       className={`text-opacity-90 group px-4 bg-blue-400 text-white py-2 mx-2 border border-blue-400 hover:border-blue-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer ${
  //         isScoreSaved ? 'bg-gray-400 border-gray-400 pointer-events-none' : ''
  //       }`}
  //       disabled={isScoreSaved}
  //     >
  //       {isScoreSaved ? 'Saved' : 'Save your Score'}
  //     </button>
  //   )
  // }
  return (
    <div>
      <div className='absolute top-1 right-1 '>
        <div className='flex items-center justify-center'>
          {enableTimer && (
            <div className=' text-orange-600 flex items-center justify-center rounded-full py-2 px-3 text-2xl'>
              <span className='text-xs mr-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z' />
                </svg>
              </span>{' '}
              {Math.floor(time / 60)}:{time % 60 < 10 ? '0' + (time % 60) : time % 60}
              {timeReachedText && (
                <span className='text-xl text-opacity-90 group px-4 bg-red-400 text-white py-2 mx-2 border border-red-400'>
                  {' '}
                  {timeReachedText}
                </span>
              )}
            </div>
          )}
          {confirmationText}
          <div>
            <button
              className='text-opacity-90 group px-4 bg-green-400 text-white py-2 mx-2 border border-green-400 hover:border-green-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer'
              onClick={handleCloseQuiz}
            >
              {buttonText}
            </button>
            {showConfirmation && (
              <button
                className='text-opacity-90 group px-4 bg-red-400 text-white py-2 mx-2 border border-red-400 hover:border-red-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer'
                onClick={() => setShowConfirmation(false)}
              >
                No
              </button>
            )}
          </div>
        </div>
      </div>

      {!showResult && (
        <div className='flex flex-col'>
          <div className='w-full'>
            <h2 className='text-xl text-left'>{currentQuiz.question}</h2>
          </div>
          <div className='w-full my-6'>
            <ul className='flex flex-col gap-4'>
              {currentAnswers.map(({ id, answers }) => (
                <li
                  id='answerSelector'
                  key={id}
                  className={`w-full px-6 py-4 cursor-pointer border-2 border-white hover:border-green-600 ${
                    id === selectedAnswer ? 'bg-green-600 text-white' : 'bg-gray-50'
                  }`}
                  onClick={() => handleAnswerSelect(id)}
                >
                  <label className='w-full relative'>
                    <input
                      className='w-full h-full opacity-0 cursor-pointer absolute top-0 left-0'
                      type='radio'
                      name='answer'
                      value={id}
                      onChange={() => handleAnswerSelect(id)}
                      checked={userAnswers[currentQuizIndex] === id}
                    />
                    <span className='text-lg'>{answers}</span>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className='flex justify-between items-center gap-2'>
            <div className='flex-grow flex justify-start'>
              {currentQuizIndex === 0 ? (
                <div
                  style={{
                    width: 'calc(6rem + 10px)', // Match the total width of the 'Previous' button, including padding and borders
                    height: '2rem', // Match the height of the 'Previous' button
                  }}
                ></div>
              ) : (
                <button
                  className='text-opacity-90 group px-4 bg-sky-400 text-white py-2 mx-2 border border-sky-400 hover:border-sky-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer w-25'
                  onClick={handlePreviousQuiz}
                >
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5'>
                    <path
                      fillRule='evenodd'
                      d='M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z'
                      clipRule='evenodd'
                    />
                  </svg>
                </button>
              )}
            </div>

            <span className='text-xs py-2 px-1 mr-4 '>Question {currentQuizIndex + 1}</span>

            <div className='flex-grow flex justify-end'>
              <button
                className='text-opacity-90 group px-4 bg-sky-400 text-white py-2 mx-2 border border-sky-400 hover:border-sky-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer w-25'
                onClick={handleNextQuiz}
              >
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5'>
                  <path
                    fillRule='evenodd'
                    d='M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z'
                    clipRule='evenodd'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {showResult && (
        <div className='w-full'>
          <div className='flex text-center justify-between gap-4'>
            <div>
              <h2 className='text-3xl'>Results</h2>
            </div>
            <div className='flex items-center'>
              <h3 className='text-1xl'>
                Total Score:{' '}
                <span className='bg-orange-400 text-white rounded-full w-40 h-30 p-2'>{calculateTotalScore(userAnswers, quizzes)}</span>
              </h3>
              {/* {isLogged ? (
                <SaveScoreButton />
              ) : (
                <Link
                  to='/login'
                  className='text-opacity-90
group px-4 bg-blue-400 text-white py-2 mx-2 border border-blue-400 hover:border-blue-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer'
                >
                  Login or Create Account to Save Score
                </Link>
              )} */}
            </div>
          </div>
          {quizzes.map((quiz, index) => {
            const quizAnswers = quiz.attributes.answers as Answer[]
            const userAnswerId = userAnswers[index]
            const userAnswer = quizAnswers.find(answer => answer.id === userAnswerId)
            const isCorrect = userAnswer && userAnswer.isCorrect

            return (
              <div
                key={quiz.id}
                style={{
                  backgroundColor: isCorrect ? '#d3f7d3' : '#f7d9d9',
                  padding: '10px 15px 8px 15px',
                  margin: '10px 0',
                  display: 'block',
                }}
              >
                <h4 className='text-sm mb-2 font-bold '>{quiz.attributes.question}</h4>
                {isCorrect ? (
                  <p className='text-xs text-green-600 font-bold'>Your answer is correct: {userAnswer.answers}</p>
                ) : (
                  <>
                    <p className='text-xs'>Your answer: {userAnswer ? userAnswer.answers : 'No answer selected'}</p>
                    <p className='text-xs text-green-600 font-bold'>
                      Correct answer: {quizAnswers.find(answer => answer.isCorrect)?.answers ?? 'Not Found'}
                    </p>
                  </>
                )}
                {/* Use optional chaining and nullish coalescing operators */}
              </div>
            )
          })}

          <button className='bg-sky-400 text-white rounded-full w-40 h-30 p-2' onClick={handleRetakeQuiz}>
            Retake Quiz
          </button>
        </div>
      )}
    </div>
  )
}

export default Quiz
