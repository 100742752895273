import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import { CheckIcon } from "@heroicons/react/24/solid";

import ButtonSecondary from "shared/Button/ButtonSecondary";

// TODO: Account Billing Page

export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  {
    isPopular: false,
    name: "Starter",
    pricing: "£5",
    per: "/mo",
    features: ["Unlimited Quizes", "Unlimited Familt Quizes", "Other"],
    desc: ` All money will be used to build and provide better solutions for charities.`,
  },
  {
    isPopular: true,
    name: "Basic",
    pricing: "£15",
    per: "/mo",
    features: ["Unlimited Quizes", "Unlimited Familt Quizes", "Other"],
    desc: ` All money will be used to build and provide better solutions for charities.`,
  },
  {
    isPopular: false,
    name: "Plus",
    pricing: "£25",
    per: "/mo",
    features: ["Unlimited Quizes", "Unlimited Familt Quizes", "Other"],
    desc: ` All money will be used to build and provide better solutions for charities.`,
  },
];

const AccountBilling: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const renderPricingItem = (pricing: PricingItem, index: number) => {
    return (
      <div key={index} className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${pricing.isPopular ? "border-primary-500" : "border-neutral-100 dark:border-neutral-700"}`}>
        {pricing.isPopular && <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">POPULAR</span>}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">{pricing.name}</h3>
          <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
            <span>{pricing.pricing}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">{pricing.per}</span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">{item}</span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {pricing.isPopular ? (
            <ButtonPrimary>Donate Now</ButtonPrimary>
          ) : (
            <ButtonSecondary>
              <span className="font-medium">Donate Now</span>
            </ButtonSecondary>
          )}
          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">{pricing.desc}</p>
        </div>
      </div>
    );
  };
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <div className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`} data-nc-id="PageSubcription">
            <header className="text-center max-w-2xl mx-auto my-20">
              <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">Subscription</h2>
              <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">Help us growing our donations!</span>
            </header>
            <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
              <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">{pricings.map(renderPricingItem)}</div>
            </section>
          </div>
          <div className="container">
            {/* HEADING */}
            <h2 className="text-3xl font-semibold mb-10">Subscriptions & Payments</h2>
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="flex justify-between py-3 pl-2">
                  <div className="relative max-w-xs">
                    <label htmlFor="hs-table-search" className="sr-only">
                      Search
                    </label>
                    <input
                      type="text"
                      name="hs-table-search"
                      id="hs-table-search"
                      className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                      placeholder="Search..."
                    />
                    <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                      <svg className="h-3.5 w-3.5 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2">
                    <div className="relative">
                      <button className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1">
                        <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                            </svg>
                          </div>
                          <div className="hidden sm:block">Filter</div>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="p-1.5 w-full inline-block align-middle">
                  <div className="overflow-hidden border rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3 pl-4">
                            <div className="flex items-center h-5">
                              <input id="checkbox-all" type="checkbox" className="text-blue-600 border-gray-200 rounded focus:ring-blue-500" />
                              <label htmlFor="checkbox" className="sr-only">
                                Checkbox
                              </label>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                            ID
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                            Donation
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                            Description
                          </th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "></th>
                          <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        <tr>
                          <td className="py-3 pl-4">
                            <div className="flex items-center h-5">
                              <input type="checkbox" className="text-blue-600 border-gray-200 rounded focus:ring-blue-500" />
                              <label htmlFor="checkbox" className="sr-only">
                                Checkbox
                              </label>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">71723GB</td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">Donation £5</td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">All money will be used to build and provide better solutions for charities.</td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a className="text-green-500 hover:text-green-700" href="#">
                              Paid
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a className="text-blue-500 hover:text-blue-700" href="#">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                              </svg>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-3 pl-4">
                            <div className="flex items-center h-5">
                              <input type="checkbox" className="text-blue-600 border-gray-200 rounded focus:ring-blue-500" />
                              <label htmlFor="checkbox" className="sr-only">
                                Checkbox
                              </label>
                            </div>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">19876GB</td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">Donation £25</td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">All money will be used to build and provide better solutions for charities.</td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a className="text-green-500 hover:text-green-700" href="#">
                              Paid
                            </a>
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                            <a className="text-blue-500 hover:text-blue-700" href="#">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-10">
              <ButtonPrimary>Add payout mothod</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountBilling;
