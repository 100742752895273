import { useCallback, useEffect, useState } from 'react'

const API_URL = 'https://sunnah-server.herokuapp.com'
const API_KEY = 'SqD712P3E82xnwOAEOkGd5JZH8s9wRR24TqNFzjk'

export interface HadithLang {
  lang: string
  chapterNumber: string
  chapterTitle: string
  urn: number
  body: string
  grades: {
    graded_by: string | null
    grade: string
  }[]
}

interface Hadith {
  collection: string
  bookNumber: string
  chapterId: string
  hadithNumber: string
  hadith: HadithLang[]
}

export const useFetchHadiths = () => {
  const [hadiths, setHadiths] = useState<Hadith[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchHadiths = useCallback((collectionName: string, bookNumber: string): Promise<Hadith[]> => {
    setIsLoading(true)
    return fetch(`${API_URL}/collections/${collectionName}/books/${bookNumber}/hadiths`, {
      headers: {
        'X-API-Key': API_KEY,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('HTTP error ' + response.status)
        }
      })
      .then(result => {
        setIsLoading(false)
        return result.data
      })
      .catch(error => {
        setIsLoading(false)
        console.log('error', error)
        throw error
      })
  }, [])

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      setHadiths([])
    }
  }, [])

  return { hadiths, fetchHadiths, isLoading }
}
