import emailjs from 'emailjs-com'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_USER_ID } from '../../emailjs_config'

emailjs.init(EMAILJS_USER_ID)

interface FormData {
  fullName: string
  email: string
  phone: string
  gender: string
  interest: string
  type: string
}

export const JoinCommunityPopup = () => {
  const suggestions = ['Education', 'Fintech', 'Investments', 'Charities']
  const genders = ['Man', 'Woman', 'Prefer not to answer']
  const types = [
    {
      name: 'Scholars / Mufti / Sheikh',
      description: 'Opportunity to make meaningful impact & support individuals on their faith journey.',
    },
    { name: 'Teacher ', description: 'For teachers interested in community' },
    { name: 'Student of Deen', description: 'For entrepreneurs interested in community' },
    { name: 'Student', description: 'For business owners interested in community' },
    { name: 'Business/ Entrepreneur', description: 'Connect & collaborate with scholars, industry experts,fellow business owners' },
    {
      name: 'Learner ',
      description: 'Exclusive access to a vast range of courses, resources, and support systems designed to empower you',
    },
    {
      name: 'Other',
      description:
        'Dive into the vast landscape of technology: Explore and discover opportunities beyond traditional IT roles in diverse tech fields',
    },
  ]

  const [emailSent, setEmailSent] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [recaptchaVerified, setRecaptchaVerified] = useState<boolean>(false)
  const [recaptchaMessage, setRecaptchaMessage] = useState<string>('')
  const [showPopup, setShowPopup] = useState(false)
  const [selectedGender, setSelectedGender] = useState<string>('')
  const [selectedType, setSelectedType] = useState<string>('')
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    interest: '',
    type: '',
  })
  // Manage tags
  const [interests, setInterests] = useState<string[]>([])
  const [inputInterest, setInputInterest] = useState<string>('')
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false)
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState<string>('')

  const handleInterestInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.endsWith(',')) {
      setInterests(prevInterests => [...prevInterests, e.target.value.slice(0, -1)])
      setInputInterest('')
    } else {
      setInputInterest(e.target.value)
    }
  }

  const handleInterestDelete = (interest: string) => {
    setInterests(prevInterests => prevInterests.filter(i => i !== interest))
  }

  function sendEmail(formData: FormData) {
    const message = `${interests.join(', ')} (Gender: ${formData.gender}, Type: ${formData.type})`

    const templateParams = {
      from_name: formData.fullName,
      from_email: formData.email,
      from_phone: formData.phone,
      message_html: message,
      'g-recaptcha-response': recaptchaToken,
    }

    emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams).then(
      response => {
        console.log('SUCCESS!', response.status, response.text)
      },
      err => {
        console.log('FAILED...', err)
      }
    )
  }

  const handleRecaptcha = (value: string | null) => {
    setRecaptchaToken(value)
    if (value) {
      setRecaptchaVerified(true)
      setRecaptchaMessage('')
    } else {
      setRecaptchaVerified(false)
    }
  }

  const handleGenderSelection = (gender: string) => {
    setSelectedGender(gender)
    setFormData({
      ...formData,
      gender,
    })
  }

  const handleTypeSelection = (type: string) => {
    setSelectedType(type)
    setFormData({
      ...formData,
      type,
    })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    //check if terms accepted

    if (!checkboxChecked) {
      setCheckboxErrorMessage('Please agree to the terms and conditions')
      return
    } else {
      setCheckboxErrorMessage('') // clear error message if terms are accepted
    }

    // check recaptcha
    if (!recaptchaToken) {
      console.log('Please verify you are a human using the reCAPTCHA')
      setRecaptchaMessage('Please verify you are a human using the reCAPTCHA')
      return
    }
    sendEmail(formData)
    setEmailSent(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const resetForm = () => {
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      gender: '',
      interest: '',
      type: '',
    })
    setSelectedGender('')
    setSelectedType('')
    setEmailSent(false)
  }

  const renderPopup = () => {
    const closePopup = () => {
      setShowPopup(false)
    }

    return (
      <div className='fixed top-0 left-0 z-50 w-screen h-screen flex justify-center items-center bg-gray-800 bg-opacity-50 overflow-y-auto'>
        <div className='bg-slate-50 max-w-xl mx-auto rounded-lg shadow-lg p-8 relative'>
          <div className='mx-4 mb-6'>
            <h2 className=' text-xl sm:text-3xl font-semibold mb-4'>Be Part Of The Family </h2>
            <p className='text-lg sm:text-xl tracking-tight mb-4'>Be amongst people who want to see you win</p>
            {selectedGender && (
              <div className='hidden sm:flex'>
                <span> I'm a:</span> <span className=' px-2 ml-2 font-semibold'>{selectedGender}</span>
                {selectedType && (
                  <div className='flex items-center'>
                    <span>
                      {' '}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
                      </svg>
                    </span>
                    <span className=' px-2 ml-0 font-semibold'>{selectedType}</span>
                    <button
                      type='button'
                      className='w-6 inline-block text-slate-600 hover:text-slate-900 text-center font-semibold tracking-tight focus:ring-4 transition duration-200'
                      onClick={resetForm}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-5 h-5'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          {!selectedGender ? (
            <div className='grid grid-cols-1 gap-4'>
              <h1 className='text-2xl mt-4 font-semibold'> Gender</h1>
              {genders.map(gender => (
                <button
                  key={gender}
                  className='mx-auto bg-slate-500 text-white font-semibold rounded-lg p-4 w-full flex items-center justify-center'
                  onClick={() => handleGenderSelection(gender)}
                >
                  <div className='text-lg font-medium'>{gender}</div>
                </button>
              ))}
              <button
                type='button'
                className='inline-block w-full text-slate-600 hover:text-slate-900 text-center font-semibold tracking-tight focus:ring-4 transition duration-200'
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          ) : !selectedType ? (
            <div className='grid grid-cols-1 gap-4 overflow-auto'>
              {types.map(({ name, description }) => (
                <button
                  key={name}
                  className='bg-indigo-500 text-white font-semibold rounded-lg p-4 w-full'
                  onClick={() => handleTypeSelection(name)}
                >
                  <div className='text-sm font-medium'>{name}</div>
                  {/* <div className='text-sm text-gray-100'>{description}</div> */}
                </button>
              ))}
              <button
                type='button'
                className='inline-block w-full text-slate-600 hover:text-slate-900 text-center font-semibold tracking-tight focus:ring-4 transition duration-200'
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          ) : (
            <>
              <form
                className='mb-6 p-9 bg-white max-w-lg border border-gray-200 rounded-2xl'
                id='joinCommunity'
                onSubmit={handleFormSubmit}
              >
                {/* form fields */}
                <div className='w-full p-2'>
                  <label className='block'>
                    <span className='inline-block mb-2 font-medium tracking-tight'>Full Name</span>
                    <input
                      className='p-4 w-full text-gray-700 tracking-tight bg-white placeholder-gray-700 outline-none border border-gray-300 rounded-lg focus:border-gray-700 transition duration-200'
                      type='text'
                      placeholder='First Name & Last Name'
                      name='fullName'
                      value={formData.fullName}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className='w-full p-2'>
                  <label className='block'>
                    <span className='inline-block mb-2 font-medium tracking-tight'>Email Address</span>
                    <input
                      className='p-4 w-full text-gray-700 tracking-tight bg-white placeholder-gray-700 outline-none border border-gray-300 rounded-lg focus:border-gray-700 transition duration-200'
                      type='email'
                      placeholder='Your Email'
                      name='email'
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className='w-full p-2'>
                  <label className='block'>
                    <span className='inline-block mb-2 font-medium tracking-tight'>Phone Number</span>
                    <input
                      className='p-4 w-full text-gray-700 tracking-tight bg-white placeholder-gray-700 outline-none border border-gray-300 rounded-lg focus:border-gray-700 transition duration-200'
                      type='text'
                      placeholder='Phone Number'
                      name='phone'
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
                <div className='w-full p-2'>
                  <label className='block'>
                    <span className='inline-block mb-2 font-medium tracking-tight'>Interested in</span>
                    <input
                      className='p-4 w-full text-gray-500 tracking-tight bg-white placeholder-gray-500 outline-none border border-gray-300 focus:border-gray-700 rounded-lg transition duration-200'
                      type='text'
                      placeholder='Start writing some interests, separate by commas'
                      name='interest'
                      value={inputInterest}
                      onChange={handleInterestInputChange}
                    />
                  </label>
                  <div>
                    {interests.map((interest, index) => (
                      <span key={index} className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-2'>
                        {interest}
                        <button onClick={() => handleInterestDelete(interest)}>x</button>
                      </span>
                    ))}
                  </div>
                </div>
                <div className='my-2 mx-2'>
                  <ReCAPTCHA sitekey={'6LeUwh8nAAAAAKq5kk_KH6N0u_FMVfuitkdXNUTy'} onChange={handleRecaptcha} />
                </div>
                <div className='w-full p-3'>
                  <label className='items-center flex py-2 px-2'>
                    <input
                      type='checkbox'
                      className='form-checkbox text-indigo-500'
                      checked={checkboxChecked}
                      onChange={() => setCheckboxChecked(!checkboxChecked)}
                    />
                    <span className='ml-4 text-gray-700'>
                      I have read and agree to the
                      <a className='ml-1 text-xs text-indigo-500 hover:text-indigo-600 transition duration-200' href='##'>
                        Terms &amp; Conditions.
                      </a>
                    </span>
                  </label>

                  {checkboxErrorMessage && <p className='text-red-500'>{checkboxErrorMessage}</p>}
                </div>

                <div className='w-full p-2'>
                  <button
                    type='submit'
                    className='text-xs sm:text-lg inline-block mb-3 px-5 py-4 w-full text-white text-center font-semibold tracking-tight bg-indigo-500 hover:bg-indigo-600 rounded-lg focus:ring-4 focus:ring-indigo-300 transition duration-200'
                  >
                    Join Waitlist &amp; Secure your early access
                  </button>
                  <button
                    type='button'
                    className='inline-block w-full text-slate-600 hover:text-slate-900 text-center font-semibold tracking-tight focus:ring-4 transition duration-200'
                    onClick={resetForm}
                  >
                    Starting Again
                  </button>
                  {recaptchaMessage && <p className='text-red-500'>{recaptchaMessage}</p>}
                </div>
              </form>
              <div className='w-full text-center text-green-600'>{emailSent && <p>Email sent successfully!</p>}</div>
            </>
          )}
          <div className='flex justify-end'>
            <button
              type='button'
              className='absolute -top-4 -right-4 py-2 px-2 bg-black text-white font-semibold rounded-full hover:bg-gray-800 focus:outline-none'
              onClick={closePopup}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
              </svg>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const openPopup = () => {
    setShowPopup(true)
  }

  return (
    <div className='max-w-sm mx-auto my-4'>
      <button
        className='flex items-center justify-center py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
        onClick={openPopup}
      >
        <span className='mr-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5'
            />
          </svg>
        </span>
        Join our Community
      </button>
      {showPopup && renderPopup()}
    </div>
  )
}
