import { useEffect, useState } from 'react'

const API_URL = 'https://sunnah-server.herokuapp.com'
const API_KEY = 'SqD712P3E82xnwOAEOkGd5JZH8s9wRR24TqNFzjk'
// Define new interfaces
interface ChapterLang {
  lang: string
  chapterNumber: string
  chapterTitle: string
  intro: string | null
  ending: string | null
}

interface Chapter {
  bookNumber: string
  chapterId: string
  chapter: ChapterLang[]
}

// Modify fetch function and state
export const useFetchCollectionChapters = () => {
  const [chapters, setChapters] = useState<Chapter[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchChapters = (collectionName: string, number: string) => {
    setIsLoading(true)
    fetch(`${API_URL}/collections/${collectionName}/books/${number}/chapters`, {
      headers: {
        'X-API-Key': API_KEY,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('HTTP error ' + response.status)
        }
      })
      .then(result => {
        setChapters(result.data)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log('error', error)
      })
  }

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      setChapters([])
    }
  }, [])

  return { chapters, fetchChapters, isLoading }
}
