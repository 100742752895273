import React, { useState, useRef } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import Input from 'shared/Input/Input'

export interface Answer {
  id: number
  text: string
  isCorrect: boolean
}

export interface Question {
  id: number
  question: string
  answers: Answer[]
}

interface QuizFormChildProps {
  questions: Question[]
  addQuestion: () => void
  addAnswer: (questionIndex: number) => void
  handleQuestionChange: (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => void
  handleAnswerChange: (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number, answerIndex: number) => void
  handleCorrectAnswerChange: (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number, answerIndex: number) => void
  handleRemoveQuestion: (questionIndex: number) => void
  handleRemoveAnswer: (questionIndex: number, answerIndex: number) => void
  saveQuiz: () => void
  handleQuestionOrderChange: (questionIndex: number, direction: 'up' | 'down') => void
}

function QuizFormChild({
  questions,
  addQuestion,
  addAnswer,
  handleQuestionChange,
  handleAnswerChange,
  handleCorrectAnswerChange,
  handleRemoveQuestion,
  handleRemoveAnswer,
  saveQuiz,
  handleQuestionOrderChange,
}: QuizFormChildProps) {
  const [expandedQuestionIndex, setExpandedQuestionIndex] = useState<number | null>(null)

  const accordionRef = useRef<HTMLDivElement>(null)

  const handleQuestionClick = (questionIndex: number) => {
    setExpandedQuestionIndex(questionIndex === expandedQuestionIndex ? null : questionIndex)
    setTimeout(() => {
      if (accordionRef.current) {
        accordionRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  const handleAddQuestion = () => {
    setExpandedQuestionIndex(null)
    addQuestion()
    setExpandedQuestionIndex(questions.length)
  }

  const handlePreviousQuestionClick = () => {
    if (expandedQuestionIndex === null) {
      setExpandedQuestionIndex(questions.length - 1)
    } else if (expandedQuestionIndex > 0) {
      setExpandedQuestionIndex(expandedQuestionIndex - 1)
    }
  }

  function numberToNumber(i: number) {
    var j = i % 10,
      k = i % 100
    if (j === 1 && k != 11) {
      return i + 'st'
    }
    if (j === 2 && k != 12) {
      return i + 'nd'
    }
    if (j === 3 && k != 13) {
      return i + 'rd'
    }
    return i + 'th'
  }

  const handleNextQuestionClick = () => {
    if (expandedQuestionIndex === null) {
      setExpandedQuestionIndex(0)
    } else if (expandedQuestionIndex < questions.length - 1) {
      setExpandedQuestionIndex(expandedQuestionIndex + 1)
    } else {
      addQuestion()
      setExpandedQuestionIndex(questions.length)
    }
  }

  // Handle order up and down
  const handleOrderUpClick = (questionIndex: number) => {
    if (questionIndex > 0) {
      handleQuestionOrderChange(questionIndex, 'up')
    }
  }

  const handleOrderDownClick = (questionIndex: number) => {
    if (questionIndex < questions.length - 1) {
      handleQuestionOrderChange(questionIndex, 'down')
    }
  }

  const handleQuestionOrder = (result: DropResult) => {
    if (!result.destination) return
    const sourceIndex = result.source.index
    const destIndex = result.destination.index
    const direction = sourceIndex > destIndex ? 'up' : 'down'
    handleQuestionOrderChange(sourceIndex, direction)
  }

  return (
    <div className='w-full flex flex-col rounded-lg sm:border border-neutral-200 dark:border-neutral-700 space-y-4 px-0 sm:p-6 xl:p-8'>
      <div className='rounded-lg'>
        {/*item */}
        <DragDropContext onDragEnd={handleQuestionOrder}>
          <Droppable droppableId='questions'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions.map((question, questionIndex) => (
                  <Draggable key={question.id} draggableId={String(question.id)} index={questionIndex}>
                    {provided => (
                      <div
                        key={question.id}
                        className='rounded-lg my-4'
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div className='flex align-middle items-center bg-orange-100 rounded-xl gap-4 py-4 px-4'>
                          <button onClick={() => handleQuestionClick(questionIndex)} className='flex-none w-10 h-10 bg-white rounded-full'>
                            {expandedQuestionIndex === questionIndex ? (
                              <i className='las la-angle-up'></i>
                            ) : (
                              <i className='las la-angle-down'></i>
                            )}
                          </button>
                          <div className='flex grow cursor-pointer items-center' onClick={() => handleQuestionClick(questionIndex)}>
                            <div className='px-1 py-2 bg-amber-600 text-zinc-50 rounded-lg mr-4 w-11 text-center text-xs font-bold'>
                              {numberToNumber(questionIndex + 1)}
                            </div>
                            <div className='font-medium'>{question.question}</div>
                          </div>

                          <button
                            className='flex items-center flex-none w-30 bg-gray-100 rounded-full px-4 py-2 gap-1'
                            onClick={() => handleRemoveQuestion(questionIndex)}
                          >
                            <i className='las la-trash-alt text-lg'></i>
                            <span className='text-xs'>Remove Question</span>
                          </button>
                          <div className='flex '>
                            {questionIndex !== 0 && (
                              <button
                                id='orderUp'
                                className='flex-none w-7 h-7 bg-white rounded-full'
                                onClick={() => handleOrderUpClick(questionIndex)}
                              >
                                <i className='las la-angle-up'></i>
                              </button>
                            )}
                            {questionIndex !== questions.length - 1 && (
                              <button
                                id='orderDown'
                                className='flex-none w-7 h-7 bg-white rounded-full'
                                onClick={() => handleOrderDownClick(questionIndex)}
                              >
                                <i className='las la-angle-down'></i>
                              </button>
                            )}
                          </div>
                        </div>
                        {expandedQuestionIndex === questionIndex && (
                          <div className='card bg-sky-50 p-4 rounded-b-xl mx-6'>
                            <div className='flex flex-col gap-2 bg-white px-4 py-4 rounded-lg'>
                              <label htmlFor='question' className='block text-sm font-medium text-gray-700'>
                                Question
                              </label>
                              <input
                                type='text'
                                value={question.question}
                                id='question'
                                onChange={event => handleQuestionChange(event, questionIndex)}
                              />
                            </div>

                            {question.answers.map((answer, answerIndex) => (
                              <div key={answer.id} className='flex flex-col gap-2 rounded-lg bg-white my-4 mx-4'>
                                <div className='py-4 flex align-middle items-center gap-4'>
                                  <input
                                    className='mx-4 p-3'
                                    type='radio'
                                    checked={answer.isCorrect}
                                    onChange={event => handleCorrectAnswerChange(event, questionIndex, answerIndex)}
                                  />
                                  <input
                                    type='text'
                                    value={answer.text}
                                    onChange={event => handleAnswerChange(event, questionIndex, answerIndex)}
                                  />

                                  <button
                                    className='flex items-center px-4 py-2 gap-1'
                                    onClick={() => handleRemoveAnswer(questionIndex, answerIndex)}
                                  >
                                    <i className='las la-trash-alt text-3xl text-red-700'></i>
                                  </button>
                                  {answerIndex === question.answers.length - 1 && (
                                    <button
                                      className='mx-2 nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-xs  font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-lime-600 hover:bg-lime-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
                                      onClick={() => addAnswer(questionIndex)}
                                    >
                                      <i className='text-xl las la-plus'></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div className='flex justify-between'>
                              <button
                                className='mx-6 my-6 px-4 py-4 rounded-lg flex flex-col items-center min-w-[150px] bg-white'
                                onClick={handlePreviousQuestionClick}
                                id='previousQuestion'
                              >
                                <i className='text-3xl las la-angle-up'></i>
                                <span className=''>Previous</span>
                              </button>
                              <button
                                className='mx-6 my-6 px-4 py-4 rounded-lg flex flex-col items-center min-w-[150px] bg-white'
                                onClick={handleNextQuestionClick}
                                id='nextQuestion'
                              >
                                <span className=''>Next</span>
                                <i className='text-3xl las la-angle-down'></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/*item */}

        <div className='flex align-middle items-center outline-dashed outline-orange-300 gap-4 bg-orange-100 bo py-4 px-4'>
          <div className='px-1 py-2 bg-amber-600 text-zinc-50 rounded-lg mr-4 w-11 text-center text-xs font-bold'>
            {numberToNumber(Number(expandedQuestionIndex) + 2)}
          </div>

          <h3 className='flex-auto grow font-medium'>Add a new question to your Quiz</h3>

          <button
            className='mx-2nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
            onClick={handleAddQuestion}
          >
            <i className='text-xl las la-plus'></i>
            <span className='ml-3'>Add Question</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuizFormChild
