import React, { FC } from 'react'
import { SocialType } from 'shared/SocialsShare/SocialsShare'

export interface SocialsListProps {
  className?: string
  itemClass?: string
  socials?: SocialType[]
}

const tiktokIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-tiktok' viewBox='0 0 16 16'>
    {' '}
    <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />{' '}
  </svg>
)

const SocialIcons: SocialType[] = [
  { name: 'tiktok', icon: 'lab la-tiktok', href: 'https://www.tiktok.com/@rightlyguidedapp' },
  { name: 'Facebook', icon: 'lab la-facebook-square', href: '#' },
  { name: 'Twitter', icon: 'lab la-twitter', href: 'https://twitter.com/RightlyGuidedUk' },
  { name: 'Youtube', icon: 'lab la-youtube', href: 'https://www.youtube.com/channel/UCnrFiZzVhamuFrdXQ15n4Ng' },
  { name: 'Instagram', icon: 'lab la-instagram', href: 'https://www.instagram.com/rightlyguidedapp/' },
]

const SocialsList: FC<SocialsListProps> = ({ className = '', itemClass = 'block', socials = SocialIcons }) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id='SocialsList'
    >
      {socials.map((item, i) => (
        <a key={i} className={`${itemClass}`} href={item.href} target='_blank' rel='noopener noreferrer' title={item.name}>
          {item.name === 'tiktok' ? tiktokIcon : <i className={item.icon}></i>}
        </a>
      ))}
    </nav>
  )
}

export default SocialsList
