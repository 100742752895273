// VideoSection.tsx
import React, { useRef, useState } from 'react'
import BrowserFrame from 'react-browser-frame'

interface VideoPlayerProps {
  src: string
  thumbnail: string
  browserFrame?: boolean
}

const VideoSection: React.FC<VideoPlayerProps> = ({ src, thumbnail, browserFrame }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  const handleVideoClick = () => {
    if (isPlaying) {
      videoRef.current?.pause()
    } else {
      videoRef.current?.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div className='relative w-full mt-8'>
      {browserFrame ? (
        <BrowserFrame url='http://rightly-guided.co.uk'>
          <img
            src={thumbnail}
            alt='Video thumbnail'
            className={`cursor-pointer object-cover w-full h-full ${isPlaying ? 'hidden' : ''}`}
            onClick={handlePlay}
          />
          <video
            ref={videoRef}
            src={src}
            className={`w-full h-full object-cover ${isPlaying ? '' : 'hidden'}`}
            onClick={handleVideoClick}
            onEnded={() => setIsPlaying(false)}
            playsInline
            controls
          />
          <div
            className={`absolute top-0 left-0 w-full h-full flex items-center justify-center ${isPlaying ? 'hidden' : ''}`}
            onClick={handlePlay}
          >
            <button className='-mt-6 p-4 rounded-full bg-white flex items-center justify-center shadow-xl hover:bg-opacity-90'>
              <span className='font-xs font-semibold py-1 px-2 dark:text-slate-800'>Play Now</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='#333'
                className='w-6 h-6 my-1 mx-2'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z'
                />
              </svg>
            </button>
          </div>
        </BrowserFrame>
      ) : (
        <>
          <img
            src={thumbnail}
            alt='Video thumbnail'
            className={`cursor-pointer object-cover w-full h-full ${isPlaying ? 'hidden' : ''}`}
            onClick={handlePlay}
          />
          <video
            ref={videoRef}
            src={src}
            className={`w-full h-full object-cover ${isPlaying ? '' : 'hidden'}`}
            onClick={handleVideoClick}
            onEnded={() => setIsPlaying(false)}
            playsInline
            controls
          />
          <div
            className={`absolute top-0 left-0 w-full h-full flex items-center justify-center ${isPlaying ? 'hidden' : ''}`}
            onClick={handlePlay}
          >
            <button className='-mt-10 p-4 rounded-full bg-white flex items-center justify-center shadow-xl hover:bg-opacity-90'>
              <span className='font-xs font-semibold py-1 px-2 dark:text-slate-800'>Play Now</span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={2}
                stroke='#333'
                className='w-6 h-6 my-1 mx-2'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z'
                />
              </svg>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default VideoSection
