import emailjs, { init } from 'emailjs-com'
import photoArticleLarge from 'images/aboutPageImg.png'
import BusinessAndEntrepreneurship from 'images/newImages/Business-and-Entrepreneurship.jpg'
import CommunityAndCollaboration from 'images/newImages/Community-and-Collaboration.jpg'
import EventsAndOpportunities from 'images/newImages/Events-and-Opportunities.jpg'
import FaithAndKnowledge from 'images/newImages/Faith-and-Knowledge.jpg'
import MarketplaceAndServices from 'images/newImages/Marketplace-and-Services.jpg'
import blueLightImg from 'images/saturn-assets/images/content/blue-light-right.png'
import startLeft from 'images/saturn-assets/images/content/stars-left-top.svg'
import avatarImg from 'images/saturn-assets/images/cta/avatar3.png'
import React, { FC, FormEvent, useState } from 'react'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const AboutTextBlock = () => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[0].value
    const message = `Early Access Waitlist`

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <section className='relative py-20 lg:py-24 overflow-hidden'>
      <img className='absolute top-0 left-0 mt-44' src={startLeft} alt='' />
      <img className='absolute top-0 right-0 mt-52' src={blueLightImg} alt='' />
      <div className='relative container px-4 mx-auto'>
        <div className='mx-auto'>
          <div className='flex flex-wrap mb-16 max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto'>
            <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
              <div className='max-w-lg'>
                <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full'>About</span>
                <h2 className='font-heading text-3xl xs:text-4xl font-base dark:text-white dark:text-opacity-60 font-semibold text-gray-800'>
                  Practising Islam shouldn’t be overly complicated; Islam is simple.
                </h2>
                {/* <form className='flex flex-wrap mb-7 -m-1.5 mt-6' action='#' method='post' onSubmit={handleSubmit}>
                  <div className='w-full lg:w-64 p-1.5'>
                    <input
                      className='text-gray-500 px-5 py-4 w-full placeholder-gray-500 outline-none focus:ring-4 focus:ring-indigo-400 rounded'
                      type='email'
                      placeholder='Enter email to get started'
                    />
                  </div>
                  <div className='w-full lg:w-auto p-1.5'>
                    <button
                      className='group relative font-heading px-6 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold tracking-px bg-gray-900 hover:bg-gray-800 dark:bg-slate-700 overflow-hidden rounded-md'
                      type='submit'
                      disabled={formStatus === 'sending'}
                    >
                      <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gradient-indigo'></div>
                      <p className='relative z-10'>Join</p>
                    </button>
                  </div>
                  {formStatus === 'success' && (
                    <p className=' mt-1 text-center'>
                      <span className=' text-green-500 rounded-xl text-xs12 py-2 px-2 w-full'>Email sent successfully!</span>
                    </p>
                  )}
                  {formStatus === 'error' && (
                    <p className=' mt-1 text-center'>
                      <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                        There was an error sending the email. Please try again.
                      </span>
                    </p>
                  )}
                </form> */}
              </div>
            </div>
            <div className='w-full lg:w-1/2 px-4'>
              <div className='max-w-lg'>
                <p className='text-lg font-semibold font-base dark:text-white dark:text-opacity-60 mb-5'>
                  Welcome to Rightly Guided, a holistic platform that aims to empower and guide individuals on their spiritual and
                  entrepreneurial journeys.
                </p>
                <p className='text-lg font-base dark:text-white dark:text-opacity-60 mb-5'>
                  Our mission is to provide a comprehensive and dynamic environment where individuals can learn, grow, and connect with
                  like-minded individuals, all under the guidance of experienced scholars, business owners, educators, and thought leaders.
                </p>
              </div>
            </div>
          </div>

          {/* <div className='flex flex-col items-center justify-center'>
            <img className='block w-full' src={photoArticleLarge} alt='' />
            <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full -mt-8'>
              Building an Authenticated AI in line with the Quran and Sunnah
            </span>
          </div> */}
          <section className='py-10 lg:py-10 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='w-full'>
                    <img
                      className='h-96 w-full object-cover transform hover:-translate-y-2 transition duration-500'
                      src={FaithAndKnowledge}
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className=''>
                    <div className='flex flex-wrap -m-6'>
                      <div className='w-auto p-6'>
                        <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                          Faith and Knowledge
                        </h3>
                        <p className='tracking-tight'>
                          At Rightly Guided, we understand the significance of faith in our lives and strive to foster a deeper
                          understanding of Islamic teachings. Our team of dedicated scholars and Aalimas (women scholars) ensures the
                          availability of authentic and reliable resources for learning and spiritual development. From Quranic studies to
                          Hadith exploration, our platform offers a wealth of knowledge to nourish your faith and strengthen your connection
                          with Allah.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='py-10 lg:py-10 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='w-full'>
                    <img
                      className='h-96 w-full object-cover transform hover:-translate-y-2 transition duration-500'
                      src={BusinessAndEntrepreneurship}
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className=''>
                    <div className='flex flex-wrap -m-6'>
                      <div className='w-auto p-6'>
                        <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                          Business and Entrepreneurship
                        </h3>
                        <p className='tracking-tight'>
                          We believe that Islam encompasses all aspects of life, including business and entrepreneurship. With our focus on
                          business development, we offer a range of training courses and insights from industry experts. From understanding
                          the principles of Sharia-compliant business practices to exploring innovative strategies, our aim is to empower
                          you to succeed in your business endeavors while adhering to Islamic principles.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='py-10 lg:py-10 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='w-full'>
                    <img
                      className='h-96 w-full object-cover transform hover:-translate-y-2 transition duration-500'
                      src={CommunityAndCollaboration}
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className=''>
                    <div className='flex flex-wrap -m-6'>
                      <div className='w-auto p-6'>
                        <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                          Community and Collaboration
                        </h3>
                        <p className='tracking-tight'>
                          Rightly Guided is not just a platform; it's a thriving community where individuals come together to share their
                          experiences, seek guidance, and forge meaningful connections. Our diverse range of social groups and discussion
                          forums provide a space for fruitful conversations and collective growth. Engage with fellow members, share your
                          thoughts, and contribute to the enrichment of our community.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='py-10 lg:py-10 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='w-full'>
                    <img
                      className='h-96 w-full object-cover transform hover:-translate-y-2 transition duration-500'
                      src={MarketplaceAndServices}
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className=''>
                    <div className='flex flex-wrap -m-6'>
                      <div className='w-auto p-6'>
                        <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                          Marketplace and Services
                        </h3>
                        <p className='tracking-tight'>
                          To support your entrepreneurial journey, our platform features a marketplace where you can showcase and sell your
                          products and services. With separate sections for wholesale products, retail goods, and professional services, we
                          provide a platform to connect with potential customers and expand your reach.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='py-10 lg:py-10 overflow-hidden'>
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='w-full'>
                    <img
                      className='h-96 w-full object-cover transform hover:-translate-y-2 transition duration-500'
                      src={EventsAndOpportunities}
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className=''>
                    <div className='flex flex-wrap -m-6'>
                      <div className='w-auto p-6'>
                        <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                          Events and Opportunities
                        </h3>
                        <p className='tracking-tight'>
                          We understand the importance of personal interaction and networking. That's why we organize offline events,
                          workshops, and conferences to create an environment of learning, collaboration, and community cohesion. Take
                          advantage of these opportunities to meet industry experts, scholars, and like-minded individuals who share your
                          passion for growth and success.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default AboutTextBlock
