import React, { useState } from "react";
import { QuizQuestion } from "./AddQuizzPage";

interface QuizPreviewProps {
  questions: QuizQuestion[];
}

function QuizPreview({ questions }: QuizPreviewProps) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  function numberToNumber(i: number) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k != 11) {
      return i + "st";
    }
    if (j === 2 && k != 12) {
      return i + "nd";
    }
    if (j === 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }
  //   console.log("questions", questions);
  //   console.log("currentQuestionIndex", currentQuestionIndex);
  const currentQuestion = questions[currentQuestionIndex];

  const isFirstQuestion = currentQuestionIndex === 0;
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  const questionsLength = questions.length;
  if (!questions || questions.length === 0) {
    return <div className="w-full text-center">You don't have any question added at the moment!</div>;
  }
  return (
    <div className="w-full mb-4 p-4 card">
      <div className="flex items-center justify-center">
        <div className="flex-none w-14 h-14">
          {!isFirstQuestion && (
            <button className="font-bold py-2 px-4 rounded" onClick={handlePreviousQuestion}>
              <i className="las la-chevron-circle-left text-5xl"></i>
            </button>
          )}
        </div>
        <div className="grow h-14">
          <h2 className="text-5xl text-center">Preview</h2>
        </div>
        <div className="flex-none w-14 h-14">
          {!isLastQuestion && (
            <button className="font-bold py-2 px-4 rounded" onClick={handleNextQuestion}>
              <i className="las la-chevron-circle-right text-5xl"></i>
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-center py-4">
        <span className="px-1 py-2 bg-amber-600 text-zinc-50 rounded-lg mr-4 w-11 text-center text-xs font-bold">{numberToNumber(Number(currentQuestionIndex + 1))}</span>
        <h2 className="text-center text-2xl">{currentQuestion.question}</h2>
      </div>

      <div className="flex flex-col items-center justify-center my-4">
        {currentQuestion.answers.map((answer) => (
          <div key={answer.id} className={"px-4 py-2 my-2 w-full " + (answer.isCorrect ? "bg-green-400" : "bg-gray-300")}>
            <span className="px-1 py-2 bg-amber-600 text-zinc-50 rounded-lg mr-4 w-18 text-center text-xs font-bold">{numberToNumber(Number(answer.id))} answer: </span>
            <span className="text-lg">{answer.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuizPreview;
