import Heading from 'components/Heading/Heading'
import React from 'react'
import NcImage from 'shared/NcImage/NcImage'
import downloadThumb from 'images/download-thumb.jpg'

export interface People {
  id: string
  name: string
  description: string
  thumbnail: string
  download?: string
}

const TeamData: People[] = [
  {
    id: '1',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '2',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '3',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '4',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '5',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '6',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '7',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
  {
    id: '8',
    name: `Download Sample Name`,
    description: 'Download Sample description text if needed',
    thumbnail: downloadThumb,
    download: downloadThumb,
  },
]

const SectionFounder = () => {
  return (
    <div className='nc-SectionFounder relative'>
      <Heading
        desc='We’re impartial and independent, and every day we create distinctive,
          world-class programmes and content'
      >
        Team
      </Heading>
      <div className='grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8'>
        {TeamData.map(item => (
          <div key={item.id} className='max-w-sm bg-gray-50'>
            <NcImage
              containerClassName='relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden'
              className='absolute inset-0 object-cover'
              src={item.thumbnail}
            />
            <h3 className='text-lg text-center font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200 px-4'>{item.name}</h3>
            <span className='block text-center text-sm text-neutral-500 sm:text-base dark:text-neutral-400 px-4'>{item.description}</span>
            <div className='flex items-center justify-center mt-4'>
              <a
                href={item.download}
                className='w-full text-opacity-90
                bg-sky-500 hover:bg-sky-600 text-white py-2 px-4 text-center rounded-md'
                target='_new'
              >
                Download
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionFounder
