import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import emailjs, { init } from 'emailjs-com'
import photoArticleLarge from 'images/aboutPageImg.png'
import iconImg from 'images/flaro-assets/images/features/chat.svg'
import advisoryImg from 'images/newImages/advisory-img.jpg'
import rgAdvisoryLogo from 'images/rg-advisory-logo.png'
import blueLightImg from 'images/saturn-assets/images/content/blue-light-right.png'
import startLeft from 'images/saturn-assets/images/content/stars-left-top.svg'
import avatarImg from 'images/saturn-assets/images/cta/avatar3.png'
import React, { FC, FormEvent, useState } from 'react'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'
import AreasSection from './AreasSection'
import ProductsBlockView from './ProductsBlock'
import ShowAppSamplesAdvisory from './ShowAppSamplesAdvisory'
import ShowAppSamples from './ShowAppSamplesEdu'
// Import all exp logos
import logo1 from 'images/logos-experiences/logo1.png'
import logo10 from 'images/logos-experiences/logo10.png'
import logo11 from 'images/logos-experiences/logo11.png'
import logo12 from 'images/logos-experiences/logo12.png'
import logo13 from 'images/logos-experiences/logo13.png'
import logo14 from 'images/logos-experiences/logo14.png'
import logo15 from 'images/logos-experiences/logo15.png'
import logo16 from 'images/logos-experiences/logo16.png'
import logo17 from 'images/logos-experiences/logo17.png'
import logo18 from 'images/logos-experiences/logo18.png'
import logo19 from 'images/logos-experiences/logo19.png'
import logo2 from 'images/logos-experiences/logo2.png'
import logo20 from 'images/logos-experiences/logo20.png'
import logo21 from 'images/logos-experiences/logo21.png'
import logo22 from 'images/logos-experiences/logo22.png'
import logo23 from 'images/logos-experiences/logo23.jpg'
import logo24 from 'images/logos-experiences/logo24.jpg'
import logo3 from 'images/logos-experiences/logo3.png'
import logo4 from 'images/logos-experiences/logo4.png'
import logo5 from 'images/logos-experiences/logo5.png'
import logo6 from 'images/logos-experiences/logo6.jpg'
import logo7 from 'images/logos-experiences/logo7.png'
import logo8 from 'images/logos-experiences/logo8.png'
import logo9 from 'images/logos-experiences/logo9.png'

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
]
// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const AboutTextBlock = () => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[0].value
    const message = 'Early Access Waitlist'

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <div className='relative py-20 lg:py-24 overflow-hidden dark:bg-slate-800'>
      <section className='bg-gradient-to-b from-white to-slate-100 dark:bg-slate-800 dark:from-slate-800 dark:to-slate-800'>
        <img className='absolute top-0 left-0 mt-44' src={startLeft} alt='' />
        <img className='absolute top-0 right-0 mt-52' src={blueLightImg} alt='' />
        <div className='relative container px-4 mx-auto '>
          <div className='mx-auto'>
            <div className='flex flex-wrap mb-16 max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto'>
              <div className='w-full lg:w-1/2 px-4 mb-12 lg:mb-0'>
                <div className='max-w-lg'>
                  <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full'>
                    Advisory & Business
                  </span>
                  <h2 className='font-heading text-3xl xs:text-4xl font-base dark:text-white dark:text-opacity-60 font-semibold text-gray-800'>
                    Explore our Advisory Services
                  </h2>
                  {/* <form className='flex flex-wrap mb-7 -m-1.5 mt-6' action='#' method='post' onSubmit={handleSubmit}>
                    <div className='w-full lg:w-64 p-1.5'>
                      <input
                        className='text-gray-500 px-5 py-4 w-full placeholder-gray-500 outline-none focus:ring-4 focus:ring-indigo-400 rounded'
                        type='email'
                        placeholder='Enter email to get started'
                      />
                    </div>
                    <div className='w-full lg:w-auto p-1.5'>
                      <button
                        className='group relative font-heading px-6 py-5 w-full lg:w-auto uppercase text-white text-xs font-semibold tracking-px bg-gray-900 hover:bg-gray-800 dark:bg-slate-700 overflow-hidden rounded-md'
                        type='submit'
                        disabled={formStatus === 'sending'}
                      >
                        <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gradient-indigo'></div>
                        <p className='relative z-10'>Join</p>
                      </button>
                    </div>
                    {formStatus === 'success' && (
                      <p className=' mt-1 text-center'>
                        <span className=' text-green-500 rounded-xl text-xs12 py-2 px-2 w-full'>Email sent successfully!</span>
                      </p>
                    )}
                    {formStatus === 'error' && (
                      <p className=' mt-1 text-center'>
                        <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                          There was an error sending the email. Please try again.
                        </span>
                      </p>
                    )}
                  </form> */}
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div className='max-w-lg'>
                  <p className='text-lg font-semibold font-base dark:text-white dark:text-opacity-60 mb-5'>
                    Welcome to the Advisory section of Rightly Guided, where we provide comprehensive guidance and support for both
                    religious matters and business endeavors.
                  </p>
                  <p className='text-lg font-base dark:text-white dark:text-opacity-60 mb-5'>
                    Our platform is designed to empower individuals in their pursuit of spiritual growth and entrepreneurial success.
                    Through our various areas, we offer a range of resources, training courses, and advisory services to help you thrive in
                    both realms.
                  </p>
                </div>
              </div>
              <div className='max-w-sm mt-2'>
                <img className='' src={rgAdvisoryLogo} alt='' />
              </div>
            </div>

            <div className='flex flex-col items-center justify-center '>
              {/* <img className='block w-full rounded-t-xl' src={photoArticleLarge} alt='' /> */}
              <div className='py-10 bg-white overflow-hidden rounded-xl dark:bg-slate-900'>
                <div className='px-8 sm:px-16 mx-auto'>
                  <div className='flex flex-wrap items-center -m-8'>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Join our waitlist to gain early access</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Meet like-minded people</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-1/3 p-8'>
                      <div className='md:max-w-sm'>
                        <div className='flex flex-wrap items-center -m-3'>
                          <div className='w-auto p-3'>
                            <div className='flex items-center justify-center w-20 h-20 bg-indigo-100 rounded-full'>
                              <img src={iconImg} alt='' />
                            </div>
                          </div>
                          <div className='flex-1 p-3'>
                            <h3 className='text-base font-semibold'>Courses and Events led by Scholars & Experts</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-32 bg-slate-100 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap xl:items-start -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <img className='mx-auto rounded-2xl transform hover:-translate-y-1 transition duration-500' src={advisoryImg} alt='' />
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-xl'>
                <h2 className='font-heading mb-24 text-4xl md:text-6xl tracking-tighter'>Explore our Advisory Services</h2>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          1
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong>Feed and Groups:</strong> Join our vibrant community to discuss, share insights, seek guidance, and
                          connect with like-minded individuals for personal and professional growth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          2
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong>Courses:</strong> Access courses on setting up and running a successful halal business, covering
                          strategies, marketing, and financial management.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          3
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong> 1:1 and Small Group Sessions:</strong> Get personalized guidance on religious matters, business
                          development, and entrepreneurship from our expert advisors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          4
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong> Training:</strong> Join training programs on business and entrepreneurship aspects, providing practical
                          insights and strategies for success.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          5
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong>Rightly Guided Advisory Services:</strong> Visit our dedicated site for religious guidance, business
                          consulting, and entrepreneurship support tailored to your needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          6
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong>Sandbox:</strong> Collaborate in our sandbox for testing theories and developing fintech and education
                          apps to serve our community.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          7
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          <strong>Job and Voluntary Opportunities:</strong> Discover job and voluntary roles in advisory services, religious
                          matters, and business entrepreneurship worldwide.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-28 pb-32 bg-white overflow-hidden'>
        <h3 className='text-2xl w-full mb-20 text-center font-semibold'>Our Experiences</h3>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap items-center justify-center -m-10'>
            {logos.map((logo, i) => (
              <div key={i} className='w-auto p-2 max-w-[120px] max-h-[120px] flex items-center justify-center'>
                <img src={logo} alt='' className='p-2 object-contain max-h-full max-w-full' />
              </div>
            ))}
            h
          </div>
        </div>
      </section>
      <ShowAppSamplesAdvisory />
      {/* <div className=' relative bg-gradient-to-t from-white to-slate-100 dark:from-slate-800 dark:to-slate-800'>
        <BgGlassmorphism />
        <div className='relative'>
          <AreasSection />
        </div>
      </div> */}
      {/* <AboutHowItWorks /> */}
      {/* <AboutIntro /> */}
      {/* <CommunityBlock /> */}
      {/* <ProductsBlockView /> */}
      {/* <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
        <GetInvolvedBlock />
      </div> */}
      <div className='relative'>
        <DonateTypes />
      </div>
      <section className='relative py-20 md:py-32 overflow-hidden bg-slate-50 -mb-24'>
        <img className='absolute top-0 left-0 md:mt-24' src='images/saturn-assets/images/contact/dots-side-1.svg' alt='' />
        <img className='absolute bottom-0 right-0 mb-8 lg:mb-24' src='images/saturn-assets/images/contact/dots-side-2.svg' alt='' />
        <div className='relative container px-4 mx-auto'>
          <div className='max-w-2xl mx-auto mb-18 text-center'>
            <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full'>
              NEED SUPPORT
            </span>
            <h1 className='font-heading text-5xl xs:text-6xl font-bold text-gray-900 mb-4'>
              <span>Do you need any Help</span>
            </h1>
            <p className='text-xl text-gray-500 font-semibold'>Relax, we are ready to support you</p>
          </div>
          <div className='flex flex-wrap justify-center -mx-4 mt-10'>
            <div className='w-full md:w-1/2 lg:w-auto px-4 xl:px-10 mb-10 md:mb-0'>
              <div className='max-w-sm mx-auto h-full py-8 px-6 bg-white border border-gray-200 rounded-5xl'>
                <div className='max-w-2xs mx-auto text-center'>
                  <img className='block mx-auto mb-3' src='images/saturn-assets/images/contact/icon-orange-email.svg' alt='' />
                  <h5 className='text-2xl font-bold text-gray-900 mb-3'>Contact Us</h5>
                  <p className='text-gray-500 mb-3'>Contact us to seek help from us, we will help you as soon as possible</p>
                  <span className='block text-sm text-orange-900 mb-8'>info@rightly-guided.co.uk</span>
                  <a
                    className='relative group inline-block py-4 px-6 text-white font-semibold bg-orange-900 rounded-full overflow-hidden'
                    href='/contact'
                  >
                    <div className='absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500'></div>
                    <div className='relative flex items-center justify-center'>
                      <span className='mr-4'>Contact</span>
                      <span>
                        <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M6.83 5.28999L2.59 1.04999C2.49704 0.956266 2.38644 0.881872 2.26458 0.831103C2.14272 0.780334 2.01202 0.754196 1.88 0.754196C1.74799 0.754196 1.61729 0.780334 1.49543 0.831103C1.37357 0.881872 1.26297 0.956266 1.17 1.04999C0.983753 1.23736 0.879211 1.49081 0.879211 1.75499C0.879211 2.01918 0.983753 2.27263 1.17 2.45999L4.71 5.99999L1.17 9.53999C0.983753 9.72736 0.879211 9.98081 0.879211 10.245C0.879211 10.5092 0.983753 10.7626 1.17 10.95C1.26344 11.0427 1.37426 11.116 1.4961 11.1658C1.61794 11.2155 1.7484 11.2408 1.88 11.24C2.01161 11.2408 2.14207 11.2155 2.26391 11.1658C2.38575 11.116 2.49656 11.0427 2.59 10.95L6.83 6.70999C6.92373 6.61703 6.99813 6.50643 7.04889 6.38457C7.09966 6.26271 7.1258 6.13201 7.1258 5.99999C7.1258 5.86798 7.09966 5.73728 7.04889 5.61542C6.99813 5.49356 6.92373 5.38296 6.83 5.28999Z'
                            fill='#FAFBFC'
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutTextBlock
