import { Tab } from '@headlessui/react'
import { FC, Fragment, useState, useEffect } from 'react'
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import CommonLayout from './CommonLayout'
// Auth
import { API } from '../../constant'
import { getToken } from '../../helpers'
import { useAuthContext } from '../../context/AuthContext'

// TODO: Acount History Page

const historySampleData = [
  {
    id: 1,
    title: 'Quiz beginner',
    href: '#',
    description: 'Some text here to discribe the quiz subjects, areas and all the important stuff.',
    date: '07/03/2022',
    datetime: '07/03/2022',
    category: { title: 'Ramadan', href: '#' },
    points: 97,
    author: {
      name: 'Tiago Sucena',
      role: 'tiagosucena@gmail.com',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 2,
    title: 'Quiz beginner',
    href: '#',
    description: 'Some text here to discribe the quiz subjects, areas and all the important stuff.',
    date: '07/03/2022',
    datetime: '07/03/2022',
    category: { title: 'Ramadan', href: '#' },
    points: 77,
    author: {
      name: 'Tiago Sucena',
      role: 'tiagosucena@gmail.com',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 2,
    title: 'Quiz beginner',
    href: '#',
    description: 'Some text here to discribe the quiz subjects, areas and all the important stuff.',
    date: '07/03/2022',
    datetime: '07/03/2022',
    category: { title: 'Ramadan', href: '#' },
    points: 47,
    author: {
      name: 'Tiago Sucena',
      role: 'tiagosucena@gmail.com',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

interface Scores {
  id: number
  attributes: {
    quizName: string
    quizLevel: string
    QuizNumberQuestions: number
    quizPoints: number
    userEmail: string
    createdAt: string
  }
}

// Family quizzes
interface FamilyQuiz {
  id: number
  attributes: {
    familyQuizTitle: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    familyQuizDescription: string
    familyQuizUserEmail: string
    familyQuizUserName: string
    Questions: FamilyQuizQuestion[]
  }
}
// Quizzes interface
export interface FamilyQuizAnswer {
  id: number
  familyQuizQuestionAnswerText: string
  familyQuizQuestionAnswerIsCorrect: boolean
}

export interface FamilyQuizQuestion {
  id: number
  familyQuizQuestionTitle: string
  Answer: FamilyQuizAnswer[]
}

const AccountSavelists = () => {
  let [categories] = useState(['Quizes', 'Family Quizes', 'Charity', 'Donations'])

  const [scores, setScores] = useState<Scores[]>([])
  const [familyQuizzes, setFamilyQuizzes] = useState<FamilyQuiz[]>([])

  // Login
  const [isLogged, setIsLogged] = useState(true)
  const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})
  const [userEmail, setUserEmail] = useState('')

  useEffect(() => {
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)

    // // Get the correct header
    // if (isLoggedIn) {
    //   console.log('USER DATA - ' + userData)
    // } else {
    //   console.log('NOT LOGGED IN')
    // }
  }, [])

  // Get Quizzes
  async function fetchQuizzes(email: string) {
    const APIToken = process.env.REACT_APP_API_TOKEN
    const response = await fetch(`http://localhost:1337/api/scores/?populate=deep&filters[familyQuizUserEmail]=${email}`)
    const json = await response.json()
    setScores(json.data)
    // console.log(json.data)
  }

  // Get Family Quizzes
  async function fetchFamilyQuizzes(email: string) {
    const response = await fetch(`http://localhost:1337/api/family-quizzes/?populate=deep&filters[userEmail]=${email}`)
    const json = await response.json()
    setFamilyQuizzes(json.data)
    console.log(json.data)
  }

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    // save the user and jwt to the state userData if any of them is not null/undefined
    if (user && jwt) {
      setUser(user)
      console.log('USER DATA TO CHECK - ' + user)
      console.log('-----------------------')
      setUserData(JSON.parse(user))
      const { email } = JSON.parse(user)
      setUserEmail(email)

      setIsLogged(true)
    }

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  const quizData = () => {
    fetchQuizzes(userEmail)
    return (
      <div className='bg-white py-14 sm:py-16'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
            {scores &&
              scores.map(quiz => (
                <article key={quiz.id} className='flex max-w-xl flex-col items-start justify-between'>
                  <div className='flex items-center gap-x-4 text-xs'>
                    <time dateTime={quiz.attributes.createdAt} className='text-gray-500'>
                      {quiz.attributes.createdAt}
                    </time>
                    <span className='relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100'>
                      {quiz.attributes.quizName}
                    </span>
                  </div>
                  <div className='group relative'>
                    <h3 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                      <span className='relative z-10 rounded-full mr-2 bg-orange-400 py-1.5 px-2 font-medium text-white hover:bg-gray-100'>
                        {quiz.attributes.quizPoints}
                      </span>
                      <span>
                        <span className='absolute inset-0' />
                        {quiz.attributes.quizName}
                      </span>
                    </h3>
                    <p className='mt-5 text-sm leading-6 text-gray-600 line-clamp-3'>{quiz.attributes.quizName}</p>
                  </div>
                  <div className='relative mt-8 flex items-center gap-x-4'>
                    <div className='text-sm leading-6'>
                      <p className='font-semibold text-gray-900'>
                        <a href={quiz.attributes.userEmail}>
                          <span className='absolute inset-0' />
                          {quiz.attributes.userEmail}
                        </a>
                      </p>
                      <p className='text-gray-600'>{quiz.attributes.userEmail}</p>
                    </div>
                  </div>
                </article>
              ))}
          </div>
        </div>
      </div>
    )
  }

  const familyQuizData = () => {
    fetchFamilyQuizzes(userEmail)
    return (
      <div className='bg-white py-14 sm:py-16'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
            {familyQuizzes &&
              familyQuizzes.map(quiz => (
                <article key={quiz.id} className='flex max-w-xl flex-col items-start justify-between'>
                  <div className='flex items-center gap-x-4 text-xs'>
                    <time dateTime={quiz.attributes.createdAt} className='text-gray-500'>
                      {quiz.attributes.createdAt}
                    </time>
                    <span className='relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100'>
                      {quiz.attributes.familyQuizTitle}
                    </span>
                  </div>
                  <div className='group relative'>
                    <h3 className='mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                      <span className='relative z-10 rounded-full mr-2 bg-orange-400 py-1.5 px-2 font-medium text-white hover:bg-gray-100'>
                        {quiz.attributes.familyQuizDescription}
                      </span>
                      <span>
                        <span className='absolute inset-0' />
                        {quiz.attributes.familyQuizTitle}
                      </span>
                    </h3>
                    <p className='mt-5 text-sm leading-6 text-gray-600 line-clamp-3'>{quiz.attributes.familyQuizTitle}</p>
                  </div>
                  <div className='relative mt-8 flex items-center gap-x-4'>
                    <div className='text-sm leading-6'>
                      <p className='font-semibold text-gray-900'>
                        <a href={quiz.attributes.familyQuizTitle}>
                          <span className='absolute inset-0' />
                          {quiz.attributes.familyQuizTitle}
                        </a>
                      </p>
                      <p className='text-gray-600'>{quiz.attributes.familyQuizTitle}</p>
                    </div>
                  </div>
                </article>
              ))}
          </div>
        </div>
      </div>
    )
  }

  const AccountHistory = () => {
    return (
      <div className='space-y-6 sm:space-y-8'>
        <div>
          <h2 className='text-3xl font-semibold'>History</h2>
        </div>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

        <div>
          <Tab.Group>
            <Tab.List className='flex space-x-1 overflow-x-auto'>
              {categories.map(item => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? 'bg-primary-900 text-secondary-50 '
                          : 'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className='mt-8'>
                <div className='flex space-x-1 overflow-x-auto'>{quizData()}</div>
                <div className='flex mt-11 justify-center items-center'>
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className='mt-8'>
                <div className='flex space-x-1 overflow-x-auto'>{familyQuizData()}</div>
                <div className='flex mt-11 justify-center items-center'>
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className='mt-8'>
                <div className='flex space-x-1 overflow-x-auto'>{quizData()}</div>
                <div className='flex mt-11 justify-center items-center'>
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className='mt-8'>
                <div className='flex space-x-1 overflow-x-auto'>{quizData()}</div>
                <div className='flex mt-11 justify-center items-center'>
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    )
  }

  return (
    <div>
      <CommonLayout>{AccountHistory()}</CommonLayout>
    </div>
  )
}

export default AccountSavelists
