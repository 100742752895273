import appleSvg from 'images/Apple.svg'
import facebookSvg from 'images/Facebook.svg'
import googleSvg from 'images/Google.svg'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'

// Strapi Auth
import { API } from '../../constant'
import { useAuthContext } from '../../context/AuthContext'
import { setToken } from '../../helpers'

//TODO: Sign up page Done

export interface PageSignUpProps {
  className?: string
}

const loginSocials = [
  {
    name: 'Continue with Facebook',
    href: '#',
    icon: facebookSvg,
  },
  {
    name: 'Continue with Twitter',
    href: '#',
    icon: appleSvg,
  },
  {
    name: 'Continue with Google',
    href: '#',
    icon: googleSvg,
  },
]

// Login stuff
export interface User {
  username: string
  email: string
  // add the rest of the properties if applicable
}

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
  // Login stuff
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [error, setError] = useState('')
  const [confirmEmail, setConfirmEmail] = useState(false)

  const { user, isLoading, setUser } = useAuthContext()

  useEffect(() => {
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)
    if (isLoggedIn) {
      window.location.href = '/account'
    }
  }, [])

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  const onFinish = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setLoading(true)

    const dataForm = {
      username: (e.target as HTMLFormElement).email.value,
      email: (e.target as HTMLFormElement).email.value,
      password: (e.target as HTMLFormElement).password.value,
      name: 'Your Name',
    }

    try {
      const response = await fetch(`${API}/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataForm),
      })

      const data = await response.json()
      if (data?.error) {
        throw data?.error
      } else {
        // const { user, jwt } = await response.json()
        // // TODO: Remove logs in production
        // console.log('User profile', user)
        // console.log('User token', jwt)

        // // Save user login information in sessionStorage
        // window.sessionStorage.setItem('user', JSON.stringify(user))
        // window.sessionStorage.setItem('jwt', jwt)
        // set the token
        setToken(data.jwt)

        // set the user
        setUser(data.user)

        console.log(`Welcome to Social Cards ${data.user.username}!`)
        setConfirmEmail(true)

        // window.location.href = '/account'
        // navigate('/profile', { replace: true })
      }
    } catch (error) {
      console.error(error)
      setError('Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id='PageSignUp'>
      <Helmet>
        <title>Sign up | Rightly Guided </title>
      </Helmet>
      <div className='container mb-24 lg:mb-32'>
        <h2 className='mt-20 mb-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Signup
        </h2>
        <span className='block text-center mb-10 text-neutral-700 dark:text-neutral-300'>Don't have an account yet?</span>
        <div className='max-w-md mx-auto space-y-6 '>
          {error && <p className='text-red-500'>Error: {error}</p>}
          {confirmEmail && <p className='text-red-500'>Please verify your account with your email account!</p>}
          <Fragment>
            {/* FORM */}
            <form className='grid grid-cols-1 gap-6' action='#' method='post' onSubmit={onFinish}>
              <label className='block'>
                <span className='text-neutral-800 dark:text-neutral-200'>Email address</span>
                <Input required type='email' name='email' placeholder='example@example.com' className='mt-1' />
              </label>
              <label className='block'>
                <span className='flex justify-between items-center text-neutral-800 dark:text-neutral-200'>Password</span>
                <Input required name='password' type='password' className='mt-1' />
              </label>
              <ButtonPrimary type='submit'>Continue</ButtonPrimary>
            </form>
            {/* OR */}
          </Fragment>
          <div className='relative text-center'>
            <span className='relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900'>
              OR
            </span>
            <div className='absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800'></div>
          </div>
          <div className='grid gap-3'>
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className='nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]'
              >
                <img className='flex-shrink-0' src={item.icon} alt={item.name} />
                <h3 className='flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm'>{item.name}</h3>
              </a>
            ))}
          </div>
          {/* ==== */}
          <span className='block text-center text-neutral-700 dark:text-neutral-300'>
            Already have an account? {` `}
            <Link to='/login'>Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default PageSignUp
