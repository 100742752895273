import { MegamenuItem, NavItemType } from 'shared/Navigation/NavigationItem'
import ncNanoId from 'utils/ncNanoId'
import __megamenu from './jsons/__megamenu.json'

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image: 'https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Company',
    items: __megamenu.map(i => ({
      id: ncNanoId(),
      href: '/unique_id_',
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'App Name',
    items: __megamenu.map(i => ({
      id: ncNanoId(),
      href: '/unique_id_',
      name: i.AppName,
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://images.pexels.com/photos/5059013/pexels-photo-5059013.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'City',
    items: __megamenu.map(i => ({
      id: ncNanoId(),
      href: '/unique_id_',
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://images.pexels.com/photos/5159141/pexels-photo-5159141.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Contruction',
    items: __megamenu.map(i => ({
      id: ncNanoId(),
      href: '/unique_id_',
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://images.pexels.com/photos/7473041/pexels-photo-7473041.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    title: 'Country',
    items: __megamenu.map(i => ({
      id: ncNanoId(),
      href: '/unique_id_',
      name: i.Country,
    })),
  },
]

//TODO: menu
const otherPageChildMenus: NavItemType[] = [
  // {
  //   id: ncNanoId(),
  //   href: "/about",
  //   name: "About",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/contact",
  //   name: "Contact us",
  // },
  {
    id: ncNanoId(),
    href: '/login',
    name: 'Login',
  },
  {
    id: ncNanoId(),
    href: '/signup',
    name: 'Signup',
  },
  // {
  //   id: ncNanoId(),
  //   href: "/subscription",
  //   name: "Subscription",
  // },
]

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: '/add-listing-1',
    name: 'Add Listings',
    type: 'dropdown',
    children: [
      {
        id: ncNanoId(),
        href: '/add-listing-1',
        name: 'Add Listings 1',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-2',
        name: 'Add Listings 2',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-3',
        name: 'Add Listings 3',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-4',
        name: 'Add Listings 4',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-5',
        name: 'Add Listings 5',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-6',
        name: 'Add Listings 6',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-7',
        name: 'Add Listings 7',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-8',
        name: 'Add Listings 8',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-9',
        name: 'Add Listings 9',
      },
      {
        id: ncNanoId(),
        href: '/add-listing-10',
        name: 'Add Listings 10',
      },
    ],
  },
  //
  { id: ncNanoId(), href: '/checkout', name: 'Checkout' },
  { id: ncNanoId(), href: '/pay-done', name: 'Pay done' },
  //
  { id: ncNanoId(), href: '/author', name: 'Author Page' },
  { id: ncNanoId(), href: '/account', name: 'Account Page' },
]

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: '/',
    name: 'Home',
  },
  {
    id: ncNanoId(),
    href: '/about',
    name: 'About Us',
    type: 'dropdown',
    isNew: true,
    children: [
      {
        id: ncNanoId(),
        href: '/about',
        name: 'Our Project',
      },
      {
        id: ncNanoId(),
        href: '/how-it-works',
        name: 'How it Works',
      },

      {
        id: ncNanoId(),
        href: '/team',
        name: 'The Team',
      },

      //
      {
        id: ncNanoId(),
        href: '/areas',
        name: 'Areas',
        type: 'dropdown',
        children: [
          {
            id: ncNanoId(),
            href: '/education',
            name: 'Education',
          },
          {
            id: ncNanoId(),
            href: '/travel',
            name: 'Travel',
          },
          {
            id: ncNanoId(),
            href: '/health',
            name: 'Health',
          },
          {
            id: ncNanoId(),
            href: '/finance',
            name: 'Finance',
          },
          {
            id: ncNanoId(),
            href: '/advisory',
            name: 'Advisory & Business',
          },
          // {
          //   id: ncNanoId(),
          //   href: '/business',
          //   name: 'Business',
          // },
        ],
      },

      //

      //
      {
        id: ncNanoId(),
        href: '/faq',
        name: 'Support',
        isNew: true,
      },
    ],
  },
  {
    id: ncNanoId(),
    href: '/marketplace',
    name: 'Marketplace',
  },
  {
    id: ncNanoId(),
    href: '/projects',
    name: 'Projects',
  },
  {
    id: ncNanoId(),
    href: '/donate',
    name: 'Donate',
  },
  {
    id: ncNanoId(),
    href: '/invest',
    name: 'Invest',
  },
]
