import DonateTypes from 'components/Donate/DonateTypes'
import React from 'react'

export default function PageProjects() {
  return (
    <div>
      <section className='py-8 lg:pt-28 overflow-hidden bg-slate-50 dark:bg-slate-700'>
        <img className='absolute top-0 left-0 -ml-32 md:-ml-0' src='images/saturn-assets/images/pricing/circle-left-star.png' alt='' />

        <div className='container px-4 mx-auto'>
          <div className='mb-20 max-w-2xl mx-auto text-center'>
            <span
              className='inline-block mb-5 px-3 py-1.5 text-sm text-white uppercase tracking-tight font-semibold bg-gray-600 rounded-full'
              style={{
                background: 'url("images/basko-assets/images/gradient.png")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              Welcome to Rightly Guided
            </span>
            <h2 className='font-heading text-6xl tracking-tighter'>Empowering Your Journey</h2>
            <p className='mb-8 text-xl text-gray-500 mt-4'>
              At Rightly Guided, we are dedicated to providing a unique and holistic platform designed to empower individuals in their
              pursuit of spiritual growth, educational enrichment, and entrepreneurial success.
            </p>
          </div>
          <div className='max-w-7xl mx-auto'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Faith and Knowledge
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Rightly Guided offers a comprehensive platform for personal spiritual growth, learning, and business success. Our
                        project results from the combined efforts of scholars, entrepreneurs, tech experts, marketers, and thinkers, united
                        in fostering a faith-based, knowledgeable and innovative.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Discover a Transformative Experience
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Our platform provides a life-encompassing experience. If you aim to enrich Islamic understanding, explore business,
                        enhance tech skills, or engage in meaningful talks with peers, Rightly Guided is here to assist and guide you
                        throughout.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-8 lg:pt-18 relative overflow-hidden bg-slate-50 dark:bg-slate-700'>
        <div className='container px-4 mx-auto z-20'>
          <div className='mb-20 max-w-2xl mx-auto text-center'>
            <h2 className='font-heading text-6xl tracking-tighter'>Key Features and Opportunities</h2>
          </div>
          <div className='max-w-7xl mx-auto'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Faith
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Dive into Islamic teachings, spirituality, and engaging debates. Our platform offers extensive courses, renowned
                        scholar access, and a supportive community to boost your bond with Allah and His Messenger (pbuh).
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Business and Entrepreneurship
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Embrace the world of business and entrepreneurship with confidence. Rightly Guided provides valuable insights,
                        training courses, and networking opportunities to help you navigate the complexities of the business world while
                        adhering to Islamic principles.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Community Engagement
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Connect with a vibrant community of like-minded individuals who are passionate about faith, knowledge, and positive
                        change. Engage in discussions, share ideas, and build meaningful relationships through our chat rooms, social
                        groups, and events.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Education
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Unlock your potential and expand your knowledge through our diverse range of educational programs. From traditional
                        Islamic studies to business and technology courses, our platform equips you with the skills and expertise to excel
                        in various fields.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Technology and Innovation
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Stay at the forefront of technological advancements with our focus on AI, web development, graphic design,
                        videography, and more. Our platform combines the latest tools and expertise to help you harness the power of
                        technology for personal and professional growth.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Marketplace
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Showcase your products and services on our marketplace, where you can reach a wide audience of potential customers.
                        We offer dedicated sections for wholesale products, retail items, and a platform for service providers to offer
                        their expertise.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DonateTypes />
    </div>
  )
}
