import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetchCollectionChapters } from './FetchChapters'
import { useFetchCollectionBooks } from './FetchCollectionBooks'
import { useFetchCollections } from './FetchCollections'
import { useFetchHadiths } from './FetchHadiths'

interface Chapter {
  lang: string
  chapterNumber: string
  chapterTitle: string
  intro: string | null
  ending: string | null
}

interface Chapters {
  bookNumber: string
  chapter: Chapter[]
}

interface Collection {
  name: string
  collection: {
    lang: string
    title: string
    shortIntro: string
  }[]
}

interface Book {
  lang: string
  name: string
}

interface Books {
  bookNumber: string
  book: Book[]
  hadithStartNumber: number
  hadithEndNumber: number
  numberOfHadith: number
}

interface Hadith {
  lang: string
  body: string
}
interface HadithLang {
  lang: string
  chapterNumber: string
  chapterTitle: string
  urn: number
  body: string
  grades: {
    graded_by: string | null
    grade: string
  }[]
}

interface Hadiths {
  hadithNumber: string // change this to number
  hadith: HadithLang[]
}
const CollectionsList: React.FC = () => {
  const { collections, fetchCollections, isLoading: collectionsLoading } = useFetchCollections()
  const { books, fetchCollectionBooks, isLoading: booksLoading } = useFetchCollectionBooks()
  const { chapters, fetchChapters, isLoading: chaptersLoading } = useFetchCollectionChapters()
  const { fetchHadiths } = useFetchHadiths()

  const [chapterHadiths, setChapterHadiths] = useState<Record<string, Hadiths[]>>({})
  const [selectedCollection, setSelectedCollection] = useState<Collection | null>(null)
  const [selectedBook, setSelectedBook] = useState<Books | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    fetchCollections()
  }, [])

  const handleCollectionClick = (collection: Collection) => {
    setSelectedCollection(collection)
    fetchCollectionBooks(collection.name)
  }

  const handleBookClick = (book: Books) => {
    fetchChapters(selectedCollection!.name, book.bookNumber)
    navigate(`/sunnah/hadiths?collection=${selectedCollection!.name}&book=${book.bookNumber}`)
  }

  const fetchChapterHadiths = async (bookNumber: string) => {
    try {
      const hadiths = await fetchHadiths(selectedCollection!.name, bookNumber)
      setChapterHadiths(prev => ({
        ...prev,
        [bookNumber]: hadiths,
      }))
    } catch (error) {
      console.log('Error fetching hadiths:', error)
    }
  }

  const handleChapterClick = (chapter: Chapters) => {
    const bookNumber = chapter.bookNumber
    fetchChapterHadiths(bookNumber)
  }

  const handleBackClick = () => {
    if (selectedBook) {
      setSelectedBook(null)
    } else {
      setSelectedCollection(null)
    }
  }

  const isLoading = collectionsLoading || booksLoading || chaptersLoading

  if (!selectedCollection) {
    return (
      <div className='container py-12'>
        {/* Collection view */}
        {isLoading ? (
          <div className='flex justify-center items-center'>
            <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500'></div>
          </div>
        ) : (
          <div>
            {collections.map((collection: Collection) => (
              <div
                key={collection.name}
                className='bg-slate-50 dark:bg-slate-800 shadow-md rounded-lg my-6 py-4 px-4 flex items-center justify-between cursor-pointer hover:bg-yellow-50 dark:hover-bg-slate-700 dark:hover:bg-opacity-60'
                onClick={() => handleCollectionClick(collection)}
              >
                <div className='p-4'>
                  <div className='text-xl mb-2'>{collection.collection[0].title}</div>
                  <div>{collection.collection[0].shortIntro}</div>
                </div>
                <div className='p-4'>
                  <div className='text-xl mb-2'>{collection.collection[1].title}</div>
                  <div>{collection.collection[1].shortIntro}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div className='container'>
        {/* Book view */}
        <button className='bg-blue-600 text-white py-2 px-2 my-4' onClick={handleBackClick}>
          Back to Collections
        </button>{' '}
        {isLoading ? (
          <div className='flex justify-center items-center'>
            <div className='animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500'></div>
          </div>
        ) : (
          <div className='grid grid-cols-2 gap-x-8'>
            {books.map((book: Books) => (
              <div
                className='flex items-center justify-between bg-slate-50 hover:bg-yellow-50 dark:bg-slate-800 dark:hover-bg-slate-700 dark:hover:bg-opacity-60 shadow-md cursor-pointer rounded-lg py-4 px-4 my-4'
                key={book.bookNumber}
                onClick={() => handleBookClick(book)}
              >
                <div className='flex'>
                  <div className='bg-white dark:bg-slate-700 rounded-full mx-2 p-1 w-6 h-6 flex items-center justify-center text-xs'>
                    {book.bookNumber}{' '}
                  </div>
                  <div className='ml-2'>{book.book[0].name}</div>
                </div>
                <div className='text-right'>{book.book[1].name}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default CollectionsList
