import React, { FC } from "react";
import quizImg from "images/quiz-header.png";

export interface SListingHeaderProps {
  className?: string;
  children?: React.ReactNode;
}

const ListingHeader: FC<SListingHeaderProps> = ({ className = "", children }) => {
  return (
    <div className={`nc-SectionHero2ArchivePage relative ${className}`} data-nc-id="SectionHero2ArchivePage">
      <div className="absolute inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow">
        <img className="absolute inset-0 object-cover w-full h-full" src={quizImg} alt="hero" />
      </div>
      <div className="relative py-14 ">
        <div className="relative inline-flex">
          <div className="w-screen right-1  inset-y-0 absolute bg-primary-500"></div>
          <div className="relative max-w-3xl inline-flex flex-shrink-0 flex-col items-start py-16 sm:py-20 space-y-8 sm:space-y-10 text-white">
            <h2 className="font-medium text-4xl md:text-4xl xl:text-5xl pr-10 leading-[110%]">Available Appeals</h2>
            <div className="flex items-center text-base md:text-lg pr-6">
              <i className="text-2xl las la-question-circle"></i>
              <span className="ml-2.5">100+ Appeals </span>
              <span className="mx-5"></span>
              <i className="text-2xl las la-caret-square-up"></i>
              <span className="ml-2.5">Create yours</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingHeader;
