import React from 'react'
import NcModal from './NcModal'
import Button from 'shared/Button/Button'
import ButtonClose from 'shared/ButtonClose/ButtonClose'

interface NcModalConfirmProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  onCancel: () => void
}

const NcModalConfirm: React.FC<NcModalConfirmProps> = ({ isOpen, onClose, onConfirm, onCancel }) => {
  return (
    <NcModal
      isOpenProp={isOpen}
      onCloseModal={onClose}
      modalTitle='Confirm Close'
      contentExtraClass='max-w-xl'
      contentPaddingClass='py-6 px-8'
      renderContent={() => (
        <div className='flex flex-col items-center justify-center space-y-6'>
          {/* <ButtonClose onClick={onClose} className="absolute top-2 right-2" /> */}
          <h2 className='text-3xl text-center leading-10'>
            Are you sure you want to close <b>without saving?</b>
          </h2>
          <div className='flex justify-center space-x-8 py-12'>
            <Button
              className='mx-2nc-Button relative h-auto  inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-red-500 hover:bg-red-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
              onClick={onConfirm}
            >
              <i className='text-xl las la-times-circle'></i>
              <span className='ml-3'>Close without saving</span>
            </Button>
            <Button
              className='mx-2nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6 ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'
              onClick={() => {
                onCancel()
                onClose()
              }}
            >
              <i className='text-xl las la-redo'></i>
              <span className='ml-3'>Return to Quiz</span>
            </Button>
          </div>
        </div>
      )}
    />
  )
}

export default NcModalConfirm
