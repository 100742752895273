import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'

import blueLightImg from 'images/saturn-assets/images/content/blue-light-right.png'

import startLeft from 'images/saturn-assets/images/content/stars-left-top.svg'
import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'

import ProductsBlockView from '../PageAbout/ProductsBlock'
import PrivacyPolicyHeader from './PrivacyPolicyHeader'

export interface PagePrivacyPolicyProps {
  className?: string
}

// interface Block {
//   id: number
//   image: string
//   paragraphs: string[]
// }

// interface BlockComponentProps {
//   blocks: Block[]
// }

// const blocks = [
//   // Populate with your own data
//   {
//     id: 1,
//     image: logoRightlyGuided,
//     paragraphs: [
//       'Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1',
//       'Text 2',
//       'Text 3',
//     ],
//   },
//   { id: 2, image: logoRightlyGuided, paragraphs: ['Text 4', 'Text 5', 'Text 6'] },
// ]

const PagePrivacyPolicy: FC<PagePrivacyPolicyProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PagePrivacyPolicy overflow-hidden relative ${className}`} data-nc-id='PagePrivacyPolicy'>
      <Helmet>
        <title>Privacy Policy | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* ======== TESTS ======== */}
      {/* <BlockComponent blocks={blocks} /> */}
      {/* ======== TESTS ======== */}
      <section className='relative py-20 lg:py-24 overflow-hidden'>
        <img className='absolute top-0 left-0 mt-44' src={startLeft} alt='' />
        <img className='absolute top-0 right-0 mt-52' src={blueLightImg} alt='' />
        <div className='relative container px-4 mx-auto'>
          <PrivacyPolicyHeader />
          <div className=' px-0 sm:px-4'>
            <p className='text-base my-2'>
              This privacy notice for UK Catalogue (doing business as Rightly Guided) ('RG', 'we', 'us', or 'our'), describes how and why we
              might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Visit our website at <a href='http://www.rg.com/'>http://www.rightly-guided.com</a>, or any website of ours that links to
                  this privacy notice
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Engage with us in other related ways, including any sales, marketing, or events</p>
              </li>
            </ul>
            <p className='text-base my-2'>
              Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not
              agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please
              contact us at info@rightly-guided.com.
            </p>
            <h3 className='text-2xl font-semibold my-4'>1. WHAT INFORMATION DO WE COLLECT?</h3>
            <p className='text-base my-2'>
              <strong>Personal information you disclose to us</strong>
            </p>
            <p className='text-base my-2'>In Short: We collect personal information that you provide to us.</p>
            <p className='text-base my-2'>
              We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise
              when you contact us.
            </p>
            <p className='text-base my-2'>
              Personal Information Provided by You. The personal information that we collect depends on the context of your interactions
              with us and the Services, the choices you make, and the products and features you use. The personal information we collect may
              include the following:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>names</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>phone numbers</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>email addresses</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>usernames</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>passwords</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>contact preferences</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>debit/credit card numbers</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>billing addresses</p>
              </li>
            </ul>
            <p className='text-base my-2'>
              Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable law, we process the following
              categories of sensitive information:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>information revealing religious or philosophical beliefs</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>information revealing political opinions</p>
              </li>
            </ul>
            <p className='text-base my-2'>
              Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find
              their privacy notice link(s) here: <a href='https://stripe.com/en-gb/privacy'>https://stripe.com/en-gb/privacy</a>.
            </p>
            <p className='text-base my-2'>
              Social Media Login Data. We may provide you with the option to register with us using your existing social media account
              details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect
              the information described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?' below.
            </p>
            <p className='text-base my-2'>
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>
            <p className='text-base my-2'>
              <strong>Information automatically collected</strong>
            </p>
            <p className='text-base my-2'>
              In Short: Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics
              &mdash; is collected automatically when you visit our Services.
            </p>
            <p className='text-base my-2'>
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal
              your specific identity (like your name or contact information) but may include device and usage information, such as your IP
              address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country,
              location, information about how and when you use our Services, and other technical information. This information is primarily
              needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </p>
            <p className='text-base my-2'>The information we collect includes:</p>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers
                  automatically collect when you access or use our Services and which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address, device information, browser type, and settings and information about
                  your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches,
                  and other actions you take such as which features you use), device event information (such as system activity, error
                  reports (sometimes called 'crash dumps'), and hardware settings).
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device data may include information such as your IP address (or
                  proxy server), device and application identification numbers, location, browser type, hardware model, Internet service
                  provider and/or mobile carrier, operating system, and system configuration information.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Location Data. We collect location data such as information about your device's location, which can be either precise or
                  imprecise. How much information we collect depends on the type and settings of the device you use to access the Services.
                  For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based
                  on your IP address). You can opt out of allowing us to collect this information either by refusing access to the
                  information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able
                  to use certain aspects of the Services.
                </p>
              </li>
            </ul>
            <p className='text-base my-2'>
              <strong>Information collected from other sources</strong>
            </p>
            <p className='text-base my-2'>
              In Short: We may collect limited data from public databases, marketing partners, social media platforms, and other outside
              sources.
            </p>
            <p className='text-base my-2'>
              In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may
              obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data
              providers, social media platforms, and from other third parties. This information includes mailing addresses, job titles,
              email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media profiles,
              social media URLs, and custom profiles, for purposes of targeted advertising and event promotion. If you interact with us on a
              social media platform using your social media account (e.g. Facebook or Twitter), we receive personal information about you
              such as your name, email address, and gender. Any personal information that we collect from your social media account depends
              on your social media account's privacy settings.
            </p>
            <h3 className='text-2xl font-semibold my-4'>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
            <p className='text-base my-2'>
              In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
            </p>
            <p className='text-base my-2'>
              We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so
                  you can create and log in to your account, as well as keep your account in working order.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To deliver and facilitate delivery of services to the user. We may process your information to provide you with the
                  requested service.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To send administrative information to you. We may process your information to send you details about our products and
                  services, changes to our terms and policies, and other similar information.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To enable user-to-user communications. We may process your information if you choose to use any of our offerings that
                  allow for communication with another user.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To request feedback. We may process your information when necessary to request feedback and to contact you about your use
                  of our Services.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To deliver targeted advertising to you. We may process your information to develop and display personalised content and
                  advertising tailored to your interests, location, and more.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure,
                  including fraud monitoring and prevention.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To identify usage trends. We may process information about how you use our Services to better understand how they are
                  being used so we can improve them.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better
                  understand how to provide marketing and promotional campaigns that are most relevant to you.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  To save or protect an individual's vital interest. We may process your information when necessary to save or protect an
                  individual&rsquo;s vital interest, such as to prevent harm.
                </p>
              </li>
            </ul>
            <h3 className='text-2xl font-semibold my-4'>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h3>
            <p className='text-base my-2'>
              In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e.
              legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter
              into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
            </p>
            <p className='text-base my-2'>
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to
              process your personal information. As such, we may rely on the following legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information
                  for a specific purpose. You can withdraw your consent at any time.&nbsp;
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our
                  contractual obligations to you, including providing our Services or at your request prior to entering into a contract with
                  you.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate
                  business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we
                  may process your personal information for some of the purposes described in order to:
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Develop and display personalised and relevant advertising content for our users</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Analyse how our Services are used so we can improve them to engage and retain users</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Support our marketing activities</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Diagnose problems and/or prevent fraudulent activities</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Understand how our users use our products and services so we can improve user experience</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal
                  obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights,
                  or disclose your information as evidence in litigation in which we are involved.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the
                  vital interests of a third party, such as situations involving potential threats to the safety of any person.
                </p>
              </li>
            </ul>
            <h3 className='text-2xl font-semibold my-4'>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
            <p className='text-base my-2'>
              In Short: We may share information in specific situations described in this section and/or with the following categories of
              third parties.
            </p>
            <p className='text-base my-2'>
              Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service
              providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such
              information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your
              personal information. This means that they cannot do anything with your personal information unless we have instructed them to
              do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the
              data they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share
              personal information with are as follows:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>Ad Networks</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Cloud Computing Services</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Data Analytics Services</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Finance &amp; Accounting Tools</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Payment Processors</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Sales &amp; Marketing Tools</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>Testing Tools</p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>User Account Registration &amp; Authentication Services</p>
              </li>
            </ul>
            <p className='text-base my-2'>We also may need to share your personal information in the following situations:</p>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger,
                  sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honour
                  this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other
                  companies that we control or that are under common control with us.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Business Partners. We may share your information with our business partners to offer you certain products, services, or
                  promotions.
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p className='text-base my-2'>
                  Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the
                  Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users
                  and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services
                  and register for our Services through a social network (such as Facebook), your contacts on the social network will see
                  your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of
                  your activity, communicate with you within our Services, and view your profile.
                </p>
              </li>
            </ul>
            <h3 className='text-2xl font-semibold my-4'>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h3>
            <p className='text-base my-2'>
              In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or
              who advertise on our Services, but are not affiliated with, our Services.
            </p>
            <p className='text-base my-2'>
              The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from
              third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we
              do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use
              of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or
              application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third
              parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or
              privacy and security practices and policies of any third parties, including other websites, services, or applications that may
              be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond
              to your questions.
            </p>
            <h3 className='text-2xl font-semibold my-4'>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
            <p className='text-base my-2'>
              In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain
              information about you.
            </p>
            <p className='text-base my-2'>
              Our Services offer you the ability to register and log in using your third-party social media account details (like your
              Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your
              social media provider. The profile information we receive may vary depending on the social media provider concerned, but will
              often include your name, email address, friends list, and profile picture, as well as other information you choose to make
              public on such a social media platform.
            </p>
            <p className='text-base my-2'>
              We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise
              made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of
              your personal information by your third-party social media provider. We recommend that you review their privacy notice to
              understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their
              sites and apps.
            </p>
            <h3 className='text-2xl font-semibold my-4'>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
            <p className='text-base my-2'>
              In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless
              otherwise required by law.
            </p>
            <p className='text-base my-2'>
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice,
              unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No
              purpose in this notice will require us keeping your personal information for longer than three (3) months past the termination
              of the user's account.
            </p>
            <p className='text-base my-2'>
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
            <h3 className='text-2xl font-semibold my-4'>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
            <p className='text-base my-2'>
              In Short: We aim to protect your personal information through a system of organisational and technical security measures.
            </p>
            <p className='text-base my-2'>
              We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security
              of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal
              information, transmission of personal information to and from our Services is at your own risk. You should only access the
              Services within a secure environment.
            </p>
            <h3 className='text-2xl font-semibold my-4'>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
            <p className='text-base my-2'>
              In Short: In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you
              greater access to and control over your personal information. You may review, change, or terminate your account at any time.
            </p>
            <p className='text-base my-2'>
              In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the
              right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances,
              you may also have the right to object to the processing of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
            </p>
            <p className='text-base my-2'>We will consider and act upon any request in accordance with applicable data protection laws.</p>
            <p className='text-base my-2'>
              If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the
              right to complain to your{' '}
              <a href='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'>
                Member State data protection authority
              </a>{' '}
              or{' '}
              <a href='https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/'>
                UK data protection authority
              </a>
              .
            </p>
            <p className='text-base my-2'>
              If you are located in Switzerland, you may contact the{' '}
              <a href='https://www.edoeb.admin.ch/edoeb/en/home.html'>Federal Data Protection and Information Commissioner</a>.
            </p>
            <p className='text-base my-2'>
              Withdrawing your consent: If we are relying on your consent to process your personal information, you have the right to
              withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details
              provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below or updating your preferences.
            </p>
            <p className='text-base my-2'>
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, will it affect the
              processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <p className='text-base my-2'>
              Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications
              at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in
              the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we
              may still communicate with you &mdash; for example, to send you service-related messages that are necessary for the
              administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
            <p className='text-base my-2'>
              <strong>Account Information</strong>
            </p>
            <p className='text-base my-2'>
              If you would at any time like to review or change the information in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                <p className='text-base my-2'>Log in to your account settings and update your user account.</p>
              </li>
            </ul>
            <p className='text-base my-2'>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active
              databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any
              investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>
            <p className='text-base my-2'>
              If you have questions or comments about your privacy rights, you may email us at info@rightly-guided.com.
            </p>
            <h3 className='text-2xl font-semibold my-4'>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
            <p className='text-base my-2'>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting
              you can activate to signal your privacy preference not to have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As
              such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice
              not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you
              about that practice in a revised version of this privacy notice.
            </p>
            <p className='text-base my-2'>&nbsp;</p>
            <h3 className='text-2xl font-semibold my-4'>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
            <p className='text-base my-2'>
              In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal
              information.
            </p>
            <p className='text-base my-2'>
              California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents
              to request and obtain from us, once a year and free of charge, information about categories of personal information (if any)
              we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we
              shared personal information in the immediately preceding calendar year. If you are a California resident and would like to
              make such a request, please submit your request in writing to us using the contact information provided below.
            </p>
            <p className='text-base my-2'>
              If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to
              request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us
              using the contact information provided below and include the email address associated with your account and a statement that
              you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the
              data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
            </p>
            <h3 className='text-2xl font-semibold my-4'>12. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
            <p className='text-base my-2'>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p className='text-base my-2'>
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and
              the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may
              notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you
              to review this privacy notice frequently to be informed of how we are protecting your information.
            </p>
            <h3 className='text-2xl font-semibold my-4'>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
            <p className='text-base my-2'>
              If you have questions or comments about this notice, you may email us at info@rightly-guided.com&nbsp;
            </p>
            <h3 className='text-2xl font-semibold my-4'>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
            <p className='text-base my-2'>
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect
              from you, change that information, or delete it. To request to review, update, or delete your personal information, please
              visit <a href='http://rightly-guided.co.uk/'>http://rightly-guided.co.uk/</a>
            </p>
            <p className='text-base my-2'>&nbsp;</p>
          </div>
        </div>
      </section>
      {/* <div className=' relative '>
        <BgGlassmorphism />
        <div className='relative z-10'>
          <AreasSection />
        </div>
      </div> */}
      {/* <AboutHowItWorks /> */}
      {/* <AboutIntro /> */}
      {/* <CommunityBlock /> */}
      {/* <ProductsBlockView /> */}
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
        <GetInvolvedBlock />
      </div>
      <div className='relative'>
        <DonateTypes />
      </div>
    </div>
  )
}

export default PagePrivacyPolicy
