import React from 'react'

const selectIcon = (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.95898 16.2917L13.1257 19.4584L21.0423 11.5417'
      stroke='white'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    ></path>
  </svg>
)
const aiIconSection = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-16 h-16'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z' />
  </svg>
)
const validationIconSection = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-16 h-16'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5'
    />
  </svg>
)
const sectionsIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-16 h-16'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5'
    />
  </svg>
)
const toolsIconSection = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-16 h-16'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z'
    />
  </svg>
)

const infoIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
    />
  </svg>
)

interface StepsData {
  id: number
  title: string
  description: string
  icon: JSX.Element
}

const stepsData: StepsData[] = [
  {
    id: 1,
    title: 'Available AI',
    description: 'Choosing your AI source for best fit.',
    icon: aiIconSection,
  },
  {
    id: 2,
    title: 'Available Tools ',
    description: 'Choosing your tools needed.',
    icon: sectionsIcon,
  },
  {
    id: 3,
    title: 'Validations & Verifications',
    description: 'Choosing your validations and verifications you need.',
    icon: validationIconSection,
  },
  {
    id: 4,
    title: 'Tools & APIs',
    description: 'Sellect tools and api you want to connect with',
    icon: toolsIconSection,
  },
]

interface StepsProps {
  step: number
}

export default function Steps({ step }: StepsProps) {
  const currentStep = stepsData.find(s => s.id === step)!
  return (
    <section className='bg-coolGray-50 p-8'>
      <div className='flex flex-wrap items-center -m-2'>
        <div className='w-full md:w-1/2 p-2'>
          <div className='flex flex-wrap -m-2'>
            <div className='w-auto relative p-2'>
              <div>{currentStep.icon}</div>
            </div>
            <div className='flex-1 p-2'>
              <div className='mb-2 flex items-center font-semibold text-black text-3xl'>
                <div>{currentStep.title}</div>
                <div className='text-center bg-slate-600 rounded-xl text-xs text-white py-1 px-2 ml-4'>Step {currentStep.id}</div>
              </div>

              <p className='text-coolGray-500 font-medium'>{currentStep.description}</p>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2 p-2'>
          <div className='flex flex-wrap justify-end -m-2'>
            <div className='w-full md:w-auto p-2'>
              <button className='flex flex-wrap items-center justify-center py-3 px-4 w-full text-base text-coolGray-500 font-medium'>
                <span>How to build your tools</span>
              </button>
            </div>
            <div className='w-full md:w-auto p-2'>
              <button className='flex flex-wrap items-center justify-center py-3 px-4 w-full text-base text-white font-medium bg-green-500 hover:bg-green-600 rounded-md shadow-button'>
                {infoIcon}
                <span className='ml-2'>Tips</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
