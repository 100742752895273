import { init } from 'emailjs-com'
import starImg from 'images/saturn-assets/images/pricing/circle-left-star.png'
import { useState } from 'react'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

interface DonationsData {
  title: string
  description: string
  donationAmount: number
  type: string
  url: string
}

const donationsData: DonationsData[] = [
  {
    title: 'Support the Ummah',
    description: 'Help scholars and our community develop products to promote dean in mainstream',
    donationAmount: 10,
    type: 'individual',
    url: '/donate-5',
  },
  {
    title: 'Support us to create Halal Ai and other products',
    description: 'We are creating tools to help mothers young muslims etc to learn and earn via training and support.',
    donationAmount: 20,
    type: 'individual',
    url: '/donate-20',
  },
  {
    title: 'All the Help makes the difference',
    description: 'You can help us with litle as you can, we do believe litle is more!',
    donationAmount: 500,
    type: 'company',
    url: '/donate-50',
  },
]

const renderSidebar = () => {
  return (
    <div className='mx-auto px-0'>
      <div className='bg-white rounded-3xl dark:bg-slate-800'>
        <div className='flex flex-wrap md:items-center -m-8'>
          <div className='w-full md:w-1/2 p-8'>
            <div className='py-20 px-8 lg:px-10 bg-gray-100 dark:bg-slate-700 rounded-3xl'>
              {/* <img className='mb-2 md:mb-6 w-32' src={rgLogo} alt='' /> */}
              <h2 className='font-heading text-4xl md:text-5xl text-gray-900 font-black dark:text-white dark:text-opacity-75'>
                Your Donation
              </h2>
              <ul className='max-w-lg'>
                <h3 className='font-heading mb-6 text-xl text-gray-500 font-bold dark:text-white dark:text-opacity-75'>
                  Help Rightly Guided support the ummah to gain a deeper understanding of Islam and practice with ease.
                </h3>
                <li className='flex flex-wrap mb-6'>
                  <svg className='w-auto mr-2.5' width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.5 23C18.0228 23 22.5 18.5228 22.5 13C22.5 7.47715 18.0228 3 12.5 3C6.97715 3 2.5 7.47715 2.5 13C2.5 18.5228 6.97715 23 12.5 23ZM17.1339 11.3839C17.622 10.8957 17.622 10.1043 17.1339 9.61612C16.6457 9.12796 15.8543 9.12796 15.3661 9.61612L11.25 13.7322L9.63388 12.1161C9.14573 11.628 8.35427 11.628 7.86612 12.1161C7.37796 12.6043 7.37796 13.3957 7.86612 13.8839L10.3661 16.3839C10.8543 16.872 11.6457 16.872 12.1339 16.3839L17.1339 11.3839Z'
                      fill='#3B82F6'
                    ></path>
                  </svg>
                  <p className='flex-1 font-bold'>Get Exclusive Access</p>
                </li>
                <li className='flex flex-wrap mb-6'>
                  <svg className='w-auto mr-2.5' width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.5 23C18.0228 23 22.5 18.5228 22.5 13C22.5 7.47715 18.0228 3 12.5 3C6.97715 3 2.5 7.47715 2.5 13C2.5 18.5228 6.97715 23 12.5 23ZM17.1339 11.3839C17.622 10.8957 17.622 10.1043 17.1339 9.61612C16.6457 9.12796 15.8543 9.12796 15.3661 9.61612L11.25 13.7322L9.63388 12.1161C9.14573 11.628 8.35427 11.628 7.86612 12.1161C7.37796 12.6043 7.37796 13.3957 7.86612 13.8839L10.3661 16.3839C10.8543 16.872 11.6457 16.872 12.1339 16.3839L17.1339 11.3839Z'
                      fill='#3B82F6'
                    ></path>
                  </svg>
                  <p className='flex-1 font-bold'>Sadaqah Jariyah</p>
                </li>
                <li className='flex flex-wrap mb-6'>
                  <svg className='w-auto mr-2.5' width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.5 23C18.0228 23 22.5 18.5228 22.5 13C22.5 7.47715 18.0228 3 12.5 3C6.97715 3 2.5 7.47715 2.5 13C2.5 18.5228 6.97715 23 12.5 23ZM17.1339 11.3839C17.622 10.8957 17.622 10.1043 17.1339 9.61612C16.6457 9.12796 15.8543 9.12796 15.3661 9.61612L11.25 13.7322L9.63388 12.1161C9.14573 11.628 8.35427 11.628 7.86612 12.1161C7.37796 12.6043 7.37796 13.3957 7.86612 13.8839L10.3661 16.3839C10.8543 16.872 11.6457 16.872 12.1339 16.3839L17.1339 11.3839Z'
                      fill='#3B82F6'
                    ></path>
                  </svg>
                  <p className='flex-1 font-bold'>Support student learning</p>
                </li>
                <li className='flex flex-wrap mb-6'>
                  <svg className='w-auto mr-2.5' width='25' height='26' viewBox='0 0 25 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M12.5 23C18.0228 23 22.5 18.5228 22.5 13C22.5 7.47715 18.0228 3 12.5 3C6.97715 3 2.5 7.47715 2.5 13C2.5 18.5228 6.97715 23 12.5 23ZM17.1339 11.3839C17.622 10.8957 17.622 10.1043 17.1339 9.61612C16.6457 9.12796 15.8543 9.12796 15.3661 9.61612L11.25 13.7322L9.63388 12.1161C9.14573 11.628 8.35427 11.628 7.86612 12.1161C7.37796 12.6043 7.37796 13.3957 7.86612 13.8839L10.3661 16.3839C10.8543 16.872 11.6457 16.872 12.1339 16.3839L17.1339 11.3839Z'
                      fill='#3B82F6'
                    ></path>
                  </svg>
                  <p className='flex-1 font-bold'>Preserve the teachings of our Nabi ﷺ</p>
                </li>
              </ul>
            </div>
          </div>
          <div className='w-full md:w-1/2 p-8'>
            <form className='md:max-w-md mx-auto'>
              <div className='flex flex-wrap -m-3'>
                <div className='w-full p-3'>
                  <label
                    className='block mb-2 text-sm text-gray-500 font-bold dark:text-white dark:text-opacity-75'
                    htmlFor='signUpLightReverseInput1-1'
                  >
                    Full Name
                  </label>
                  <input
                    className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none border border-gray-200 focus:ring-4 focus:ring-blue-200 rounded-full '
                    id='signUpLightReverseInput1-1'
                    type='text'
                    placeholder='First and last name'
                  />
                </div>
                <div className='w-full p-3'>
                  <label
                    className='block mb-2 text-sm text-gray-500 font-bold dark:text-white dark:text-opacity-75'
                    htmlFor='signUpLightReverseInput1-2'
                  >
                    Email Address
                  </label>
                  <input
                    className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none border border-gray-200 focus:ring-4 focus:ring-blue-200 rounded-full'
                    id='signUpLightReverseInput1-2'
                    type='email'
                    placeholder='Enter email address'
                  />
                </div>
                <div className='w-full p-3'>
                  <label
                    className='block mb-2 text-sm text-gray-500 font-bold dark:text-white dark:text-opacity-75'
                    htmlFor='signUpLightReverseInput1-3'
                  >
                    City
                  </label>
                  <input
                    className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-gray-100 placeholder-gray-500 outline-none border border-gray-200 focus:ring-4 focus:ring-blue-200 rounded-full'
                    id='signUpLightReverseInput1-3'
                    type='text'
                    placeholder='Manchester'
                  />
                </div>
                <div className='w-full p-3'>
                  <div className='flex flex-wrap items-center justify-between -m-3'>
                    <div className='w-auto p-3'>
                      <div className='flex items-center'>
                        <input className='opacity-0 absolute h-6 w-6' id='signUpLightReverseCheckbox1-1' type='checkbox' />
                        <div className='flex flex-shrink-0 justify-center items-center w-6 h-6 mr-4 text-transparent bg-white border border-gray-200 rounded-md'>
                          <svg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M0.603516 3.77075L2.68685 5.85409L7.89518 0.645752'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            ></path>
                          </svg>
                        </div>
                        <label className='text-gray-500 font-bold' htmlFor='signUpLightReverseCheckbox1-1'>
                          <span>By signing up, I agree to the </span>
                          <a className='text-blue-500 hover:text-blue-600' href='/t&c'>
                            Terms &amp; Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full p-3'>
                  <div className='flex flex-wrap md:justify-end -m-2'>
                    <div className='w-full p-2'>
                      <a
                        className='block px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full'
                        href='##'
                      >
                        Donate Now
                      </a>
                      <p className='text-center mt-4'>You will be redirect to Stripe Payments</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function DonateTypes() {
  const [selectedType, setSelectedType] = useState('individual')

  return (
    <section className='relative py-20 overflow-hidden bg-gray-50 dark:bg-slate-900'>
      <img className='absolute top-0 left-0 -ml-32 md:-ml-0' src={starImg} alt='' />
      <div className='relative container px-4 mx-auto'>
        <div className='max-w-2xl lg:max-w-5xl mx-auto mb-20 text-center'>
          <span className='inline-block py-1 px-3 mb-4 text-xs font-semibold text-slate-800 rounded-full uppercase dark:text-white dark:text-opacity-75'>
            Donate
          </span>
          <h1 className='font-heading text-5xl sm:text-6xl font-bold text-gray-900 mb-6 dark:text-white'>Sadaqah Jariyah - Earn rewards</h1>
          {/* <div className='flex flex-wrap justify-center -m-5 my-10'>
            <div className='w-auto p-5'>
              <div className='flex items-center font-heading font-medium text-gray-600 text-base'>
                <input
                  className='w-5 h-5 mr-2'
                  id='radio1'
                  type='radio'
                  name='radio'
                  value='individual'
                  checked={selectedType === 'individual'}
                  onChange={() => setSelectedType('individual')}
                />

                <label htmlFor='radio1'>
                  <span className='dark:text-white dark:text-opacity-75'>Individual</span>
                  <span className='text-blue-600 dark:text-white dark:text-opacity-45'>(as person)</span>
                </label>
              </div>
            </div>
            <div className='w-auto p-5'>
              <div className='flex items-center font-heading font-medium text-gray-600 text-base'>
                <input
                  className='w-5 h-5 mr-2 '
                  id='radio2'
                  type='radio'
                  name='radio'
                  value='company'
                  checked={selectedType === 'company'}
                  onChange={() => setSelectedType('company')}
                />

                <label className='dark:text-white dark:text-opacity-75' htmlFor='radio2'>
                  Company / Organisation
                </label>
              </div>
            </div>
          </div> */}
        </div>
        <div className='max-w-4xl mx-auto'>
          {donationsData
            .filter(donation => donation.type === selectedType)
            .map((donation, index) => (
              <div key={index} className='max-w-md md:max-w-none mx-auto p-12 mb-6 bg-white rounded-3xl shadow-lg dark:bg-slate-600'>
                <div className='md:flex items-center justify-between'>
                  <div className='max-w-xs mx-auto md:mx-0 mb-10 md:mb-0'>
                    <h5 className='text-2xl font-semibold mb-4'>{donation.title}</h5>
                    <p className='text-gray-500 dark:text-white dark:text-opacity-75'>{donation.description}</p>
                  </div>
                  <div className='text-center'>
                    <span className='block text-5xl font-bold text-gray-900 mb-4 dark:text-white dark:text-opacity-75'>
                      £{donation.donationAmount}
                    </span>
                    {/* <NcModal
                      renderTrigger={openModal => (
                        <span
                          onClick={() => openModal()}
                          id={donation.url}
                          className='inline-block py-4 px-6 text-center text-slate-800 border border-gray-200 hover:border-slate-900 dark:text-white dark:text-opacity-75 font-semibold rounded-full transition duration-200 cursor-pointer'
                        >
                          View Options
                        </span>
                      )}
                      renderContent={renderSidebar}
                      modalTitle='Be a part of the preservation of Islam'
                      showCloseButton={true}
                      closeConfirmationOption={true}
                    /> */}
                    <a
                      target='_new'
                      href='https://donate.stripe.com/dR69BW8ZOeIRcScdQQ'
                      id={donation.url}
                      className='inline-block py-4 px-6 text-center text-slate-800 border border-gray-200 hover:border-slate-900 dark:text-white dark:text-opacity-75 font-semibold rounded-full transition duration-200 cursor-pointer'
                    >
                      Donate
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
