import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import HomeHeader from 'components/Home/HomeHeader'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import AreasSection from 'containers/PageAreas/AreasSection'
import barChartPageViews from 'images/chartViews.svg'
import collectinAiImg from 'images/collecting-AI.svg'
import ourAiImg from 'images/our-AI.svg'
import stepOne from 'images/step-1.png'
import stepTwo from 'images/step-2.png'
import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import AboutFeatures from '../PageAbout/AboutFeatures'

import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'
import AboutIntro from '../PageAbout/AboutIntro'
import AboutTextBlock from '../PageAbout/AboutTextBlock'
import CommunityBlock from '../PageAbout/CommunityBlock'
import DonateBlock from '../PageAbout/DonateBlock'
import ProductsBlockView from '../PageAbout/ProductsBlock'
import SectionFounder from '../PageAbout/SectionFounder'
import SectionStatistic from '../PageAbout/SectionStatistic'
// Import all exp logos
import logo1 from 'images/logos-experiences/logo1.png'
import logo10 from 'images/logos-experiences/logo10.png'
import logo11 from 'images/logos-experiences/logo11.png'
import logo12 from 'images/logos-experiences/logo12.png'
import logo13 from 'images/logos-experiences/logo13.png'
import logo14 from 'images/logos-experiences/logo14.png'
import logo15 from 'images/logos-experiences/logo15.png'
import logo16 from 'images/logos-experiences/logo16.png'
import logo17 from 'images/logos-experiences/logo17.png'
import logo18 from 'images/logos-experiences/logo18.png'
import logo19 from 'images/logos-experiences/logo19.png'
import logo2 from 'images/logos-experiences/logo2.png'
import logo20 from 'images/logos-experiences/logo20.png'
import logo21 from 'images/logos-experiences/logo21.png'
import logo22 from 'images/logos-experiences/logo22.png'
import logo23 from 'images/logos-experiences/logo23.jpg'
import logo24 from 'images/logos-experiences/logo24.jpg'
import logo3 from 'images/logos-experiences/logo3.png'
import logo4 from 'images/logos-experiences/logo4.png'
import logo5 from 'images/logos-experiences/logo5.png'
import logo6 from 'images/logos-experiences/logo6.jpg'
import logo7 from 'images/logos-experiences/logo7.png'
import logo8 from 'images/logos-experiences/logo8.png'
import logo9 from 'images/logos-experiences/logo9.png'

const logos = [
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
]
export interface PageHowItWorksProps {
  className?: string
}

// interface Block {
//   id: number
//   image: string
//   paragraphs: string[]
// }

// interface BlockComponentProps {
//   blocks: Block[]
// }

// const blocks = [
//   // Populate with your own data
//   {
//     id: 1,
//     image: logoRightlyGuided,
//     paragraphs: [
//       'Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1',
//       'Text 2',
//       'Text 3',
//     ],
//   },
//   { id: 2, image: logoRightlyGuided, paragraphs: ['Text 4', 'Text 5', 'Text 6'] },
// ]

const PageHowItWorks: FC<PageHowItWorksProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id='PageAbout'>
      <Helmet>
        <title>About | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <AboutHowItWorks />

      {/* <AboutIntro /> */}
      {/* <CommunityBlock /> */}
      {/* <section className='py-24 lg:py-40 overflow-hidden bg-slate-100'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img
                  className='transform hover:-translate-y-2 transition duration-500'
                  src='images/basko-assets/images/features/transaction.png'
                  alt=''
                />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      How It Works: Empowering Your Journey
                    </h3>
                    <p className='tracking-tight'>
                      Welcome to Rightly Guided, a platform designed to empower individuals in their pursuit of spiritual growth, personal
                      development, and entrepreneurial success.{' '}
                    </p>
                    <p className='tracking-tight mt-4'>
                      Discover the various areas of our platform and learn how each one contributes to your journey towards excellence and
                      fulfillment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className='py-24 lg:py-32 bg-white overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap xl:items-start -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <img
                className='mx-auto rounded-2xl transform hover:-translate-y-1 transition duration-500'
                src='images/basko-assets/images/how-it-works/workflow.jpg'
                alt=''
              />
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-xl'>
                <h2 className='font-heading mb-24 text-4xl md:text-6xl tracking-tighter'>Empowering Your Journey</h2>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          1
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Feed: Connect and discuss with like-minded peers, share insights and stay updated with community posts and
                          articles.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          2
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Groups: Join interest-based communities, engage in discussions, learn from collective wisdom and foster
                          relationships.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          3
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Courses: Enroll in diverse courses for religious knowledge, personal development, or business skills and learn
                          from experienced instructors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          4
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          1:1 and Small Group Sessions: Access personalized guidance from our experts on religious matters, business
                          development, personal growth, and entrepreneurship.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          5
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Training: Join specialized training programs and workshops to enhance skills, gain insights and stay updated with
                          latest trends.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          6
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Marketplace: Discover products, services, and resources tailored to your needs or offer your own in the
                          Marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          7
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Sandbox: Collaborate in our Sandbox for testing theories and developing innovative solutions with like-minded
                          individuals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='w-full p-6'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-auto p-2'>
                        <div
                          className='flex items-center justify-center text-3xl text-white font-semibold w-14 h-14 bg-indigo-500 rounded-full'
                          style={{ letterSpacing: '-0.5px' }}
                        >
                          7
                        </div>
                      </div>
                      <div className='flex-1 p-2'>
                        <p className='text-xl tracking-tight'>
                          Job and Voluntary Opportunities: Discover job and voluntary roles aligned with your interests, skills, and values
                          to make a positive impact while growing personally and professionally.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-28 pb-32 bg-white overflow-hidden'>
        <h3 className='text-2xl w-full mb-20 text-center font-semibold'>Our Experiences</h3>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap items-center justify-center -m-10'>
            {logos.map((logo, i) => (
              <div key={i} className='w-auto p-2 max-w-[120px] max-h-[120px] flex items-center justify-center'>
                <img src={logo} alt='' className='p-2 object-contain max-h-full max-w-full' />
              </div>
            ))}
            h
          </div>
        </div>
      </section>
      <ProductsBlockView />
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
        <GetInvolvedBlock />
      </div>
      <div className='relative'>
        <DonateTypes />
      </div>
      {/* <DonateBlock /> */}
      {/* <div className='lg:py-28 space-y-16 lg:space-y-28'> */}
      {/* TODO: Home Header */}
      {/* <HomeHeader className='py-20 md:py-15 lg:py-10 ' /> */}
      {/* <section className='section-bg-base section-bg-navy-ellipse'>
          <div className='main-container'>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Collect everything from all availables AI in our Marketplace</h2>
                  <p className='lg:text-lg'>
                    Our marketplace is a hub of AI technology, where we gather all the information from every available source. This ensures
                    that you have access to the latest and most comprehensive AI solutions to meet all the require needs for the subject.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:mx-0 mx-auto -mb-10'>
                    <div>
                      <img className='rounded-xl my-4' src={stepTwo} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-2 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Constructing with AI: Harnessing Our Custom-Built Models and Solutions</h2>
                  <p className='lg:text-lg'>
                    Our AI efficiently manages diverse tasks, such as analyzing text, images, videos, and social media, while handling
                    translations, personalization, anomaly detection, content moderation, ad customization, recommendations, and
                    fact-checking. It ensures the delivery of the most accurate and relevant data possible.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:-mb-32 md:mx-0 -mb-36 mx-auto'>
                    <div>
                      <img className='rounded-xl my-4' src={stepOne} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>Collect everything from all availables AI in our Marketplace</h2>
                  <p className='lg:text-lg'>
                    Our marketplace is a hub of AI technology, where we gather all the information from every available source. This ensures
                    that you have access to the latest and most comprehensive AI solutions to meet all the require needs for the subject.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='md:mx-0 mx-auto -mb-10'>
                    <div>
                      <img className='rounded-xl my-4' src={collectinAiImg} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-10'>
              <div className='gap-y-16 md:grid-cols-2 md:gap-x-10 lg:gap-x-24 grid items-center  '>
                <div className='transition duration-200 col-start-1 row-start-2 md:col-start-1 md:row-start-1 opacity-100 z-10'>
                  <h2 className='text-xl mb-4'>AI and Human Collaboration: Integrating Community, Partners, and Experts</h2>
                  <p className='lg:text-lg'>
                    Our AI collaborates with humans, including community members, partners, and Muftis worldwide, to create a seamless
                    integration of expertise. The AI oversees the process, harnessing valuable insights from human input to enhance overall
                    performance and produce superior results.
                  </p>
                </div>
                <div className='relative flex justify-center max-w-xl max-h-[36rem] h-full mx-auto w-full col-start-1 row-start-1 md:col-start-2 md:row-start-1 transition duration-300 opacity-100 z-10'>
                  <div className='-mb-14 md:min-w-[450px] mx-auto md:mx-0'>
                    <div>
                      <img className='rounded-xl my-4' src={stepTwo} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* <div className='w-full mx-auto'>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            From interactive quizzes to an app with all the answers to your questions from does this break my fast to can I vape; we want
            you to be able to openly ask questions and gain the right answers. We are working with trusted Ulemah and Scholars to provide
            you with knowledge with ease.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We have partnered with scholars across the UK to work towards a system built according to the Quran, sunnah and sharia.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We aim to ensure we are utilising the developments in technology whilst preserving Islam and making your life easier whether
            you’re trying to find an authentic hadith or you have a fiqh-related question and want to ask an actual Mufti or search through
            previous responses. This includes us training an AI which you can learn more about here.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            Together we can ensure that the timeless wisdom of Islam continues to inspire and rightly guide us for generations to come.
          </p>
          <p className='block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 mb-4'>
            We’d love to have your input as we develop our application for you
          </p>
        </div> */}
      {/* <SectionFounder /> */}
      {/* <div className='relative py-16'>
          <BackgroundSection />
          <SectionClientSay uniqueClassName='PageAbout_' />
        </div> */}
      {/* <SectionStatistic />
        <SectionSubscribe2 /> */}
      {/* </div> */}
    </div>
  )
}

export default PageHowItWorks
