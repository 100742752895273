import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import Heading2 from 'components/Heading/Heading2'
import SectionSliderNewCategories from 'components/Home/SectionHomeAppeals'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import { TaxonomyType } from 'data/types'
import imgSampleRamadan from 'images/more-ramadam.png'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ListingHeader from './ListingHeader'
import ListingQuizzesFilterCard from './ListingQuizzesFilterCard'

export interface ListingRealEstatePageProps {
  className?: string
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '#',
    name: 'Quiz Sample Name ',
    taxonomy: 'category',
    count: 17288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '2',
    href: '#',
    name: 'Quiz Sample Name ',
    taxonomy: 'category',
    count: 2118,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '3',
    href: '#',
    name: 'Quiz Sample Name ',
    taxonomy: 'category',
    count: 36612,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '5',
    href: '#',
    name: 'Quiz Sample Name ',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '4',
    href: '#',
    name: 'Quiz Sample Name ',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
]

const ListingRealEstatePage: FC<ListingRealEstatePageProps> = ({ className = '' }) => {
  useEffect(() => {
    const $body = document.querySelector('body')
    if ($body) {
      $body.className = 'theme-cyan-blueGrey'
    }
    return () => {
      if ($body) {
        $body.className = ''
      }
    }
  }, [])

  return (
    <div className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`} data-nc-id='ListingRealEstatePage'>
      <Helmet>
        <title>Rightly Guided | Authenticated Islamic AI </title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative'>
        {/* SECTION HERO */}
        {/* <ListingHeader className='' /> */}

        {/* SECTION */}
        <ListingQuizzesFilterCard className='pt-10 py-24 lg:py-28' />

        {/* SECTION 1 */}
        <div className='relative py-16'>
          <BackgroundSection />
          <SectionSliderNewCategories
            heading='Explore More'
            subHeading='Explore More'
            categoryCardType='card4'
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle='style2'
            uniqueClassName='nc-ListingRealEstatePage'
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className='py-24 lg:py-28' />
      </div>
    </div>
  )
}

export default ListingRealEstatePage
