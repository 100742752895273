import appBg from 'images/appPreview.svg'
import React, { useState } from 'react'
import Avatar from 'shared/Avatar/Avatar'
import DropDownToDo from './DropDownToDo'

type ItemData = {
  id: number
  question: string
  icon: React.ReactNode
  dataText: string[]
  dataImage: string[]
}

type SelectedItemData = string[] | null

const iconSuggestionSvg = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z' />
  </svg>
)

const editButton = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.4} stroke='currentColor' className='w-3 h-3'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
    />
  </svg>
)

const backButton = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-3 h-4'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75' />
  </svg>
)

const sendButton = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5'
    />
  </svg>
)

const thumbsUp = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-3 h-3'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z'
    />
  </svg>
)

const rightButton = (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5} stroke='currentColor' className='w-6 h-6'>
    <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
  </svg>
)

const itemsData: ItemData[] = [
  {
    id: 1,
    question: 'How should Zakāh and Sadaqat-ul-Fiṭr be given and to which charities? What should people check for in a charity?',
    icon: iconSuggestionSvg,
    dataText: [
      'How should Zakāh and Sadaqat-ul-Fiṭr be given and to which charities? What should people check for in a charity?',
      `
      <div class="space-y-2">
      <h4 class="text-xs leading-none">In the Name of Allah, the Most Gracious, the Most Merciful.</h4>



      <p class="text-xs10">Answered by: Muftī Amjad Moḥammed</p>

      </div>
      `,
      'text 3',
    ],
    dataImage: ['text 1', 'text 2', 'text 3'],
  },
  {
    id: 2,
    question: 'Explain quantum computing in simple terms',
    icon: iconSuggestionSvg,
    dataText: ['text 11', 'text 22', 'text 33', 'text 44', 'text 55'],
    dataImage: ['text 11', 'text 22', 'text 33', 'text 44', 'text 55'],
  },
]

interface TruncateTextProps {
  text: string
  limit: number
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, limit }) => {
  const [isTruncated, setIsTruncated] = useState<boolean>(true)

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated)
  }

  const truncatedText: string = text.length > limit ? text.slice(0, limit) + '...' : text
  const displayText: string = isTruncated ? truncatedText : text

  return (
    <div className='text-left w-full text-xs relative'>
      <div dangerouslySetInnerHTML={{ __html: displayText }}></div>
      {text.length > limit && (
        <div className='flex items-end justify-end z-10 relative'>
          <button className='absolute -bottom-1 right-0 btn btn-primary text-xs8' onClick={toggleTruncated}>
            {isTruncated ? 'Read More' : 'Read Less'}
          </button>
        </div>
      )}
      {text.length > limit && (
        <div className='absolute bottom-0 left-0 w-full h-32 pointer-events-none bg-gradient-to-t from-slate-100 to-transparent mix-blend-destination-out' />
      )}
    </div>
  )
}

const AppPreview = () => {
  const [showResultsWrapper, setShowResultsWrapper] = useState<boolean>(false)
  const [selectedItemData, setSelectedItemData] = useState<SelectedItemData>(null)

  const [showIntroWrapper, setShowIntroWrapper] = useState<boolean>(true)

  const handleCardClick = (itemData: string[]) => {
    setShowResultsWrapper(false)
    setSelectedItemData(itemData)
  }

  const handleBackClick = () => {
    setShowResultsWrapper(true)
    setSelectedItemData(null)
  }
  const handleIntroClick = () => {
    setShowResultsWrapper(true)
    setShowIntroWrapper(false)
  }

  // Header Component
  const AppPreviewHeader = () => {
    const handleBackIntroClick = () => {
      setShowResultsWrapper(false)
      setShowIntroWrapper(true)
    }
    return (
      <div className='w-full flex items-center justify-between px-4 pt-2'>
        {selectedItemData && (
          <button
            onClick={handleBackClick}
            className='bg-blue-500 text-white w-6 h-6 rounded-full focus:outline-none flex items-center justify-center'
          >
            {backButton}
          </button>
        )}
        {!selectedItemData && (
          <button onClick={handleBackIntroClick} className={`focus:outline-none flex items-center justify-center`}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-4 h-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
              />
            </svg>
          </button>
        )}

        <Avatar sizeClass='w-6 h-6' />
      </div>
    )
  }

  const StartSearchStart = () => {
    return (
      <div className='flex flex-col justify-between h-full px-4'>
        <div className='w-full'>
          <h3 className='text-sm pb-4'>What can I help you with today?</h3>
          <div className='relative w-full mb-4 max-w-2xl mx-auto text-center'>
            <input
              type='text'
              placeholder='Search...'
              value='Search Subject'
              onChange={() => true}
              onKeyDown={() => true}
              className='w-full px-4 py-2 border-2 border-gray-300 rounded-full'
            />
            <button
              onClick={() => true}
              className='bg-blue-500 border-2 border-gray-300 text-white p-2 rounded-full focus:outline-none absolute top-0 right-0 px-4 h-full'
            >
              {sendButton}
            </button>
          </div>
        </div>
        <div className='space-y-4 bg-sky-500 rounded-xl text-white mt-4'>
          <h4 className='text-xs pt-4 px-4'>
            Can't find what you are looking for, please click on "Ask a question" and provide your enquiry for submission.
          </h4>
          <div className='w-full px-4 pb-4'>
            <input
              type='text'
              placeholder='Search...'
              value='Search Subject'
              onChange={() => true}
              onKeyDown={() => true}
              className='w-full px-4 py-2 border-2 border-gray-300 rounded-full'
            />
          </div>
        </div>
        <div className='space-y-4 pt-4 flex-grow'>
          <button onClick={() => handleIntroClick()} className='bg-gray-50 w-full flex items-stretch justify-between rounded-l-xl'>
            <div className='flex flex-col items-start pl-6 py-2'>
              <h4 className='text-xs leading-normal'>Zakat</h4>
              <span className='text-xs8 leading-none'>878 Questions</span>
            </div>
            <div className='bg-green-500 w-10 flex items-center justify-center self-stretch'>{rightButton}</div>
          </button>
          <button onClick={() => handleIntroClick()} className='bg-gray-50 w-full flex items-stretch justify-between rounded-l-xl'>
            <div className='flex flex-col items-start pl-6 py-2'>
              <h4 className='text-xs leading-normal'>Zakat</h4>
              <span className='text-xs8 leading-none'>878 Questions</span>
            </div>
            <div className='bg-blue-500 w-10 flex items-center justify-center self-stretch'>{rightButton}</div>
          </button>
          <button onClick={() => handleIntroClick()} className='bg-gray-50 w-full flex items-stretch justify-between rounded-l-xl'>
            <div className='flex flex-col items-start pl-6 py-2'>
              <h4 className='text-xs leading-normal'>Zakat</h4>
              <span className='text-xs8 leading-none'>878 Questions</span>
            </div>
            <div className='bg-orange-500 w-10 flex items-center justify-center self-stretch'>{rightButton}</div>
          </button>
        </div>
      </div>
    )
  }

  const ResultsWrapper = () => {
    return (
      <div id='resultsWrapper' className={`my-4 w-full px-4`}>
        <h3 className='text-sm pb-4'>What can I help you with today?</h3>
        <div className='relative w-full mb-4 max-w-2xl mx-auto text-center'>
          <input
            type='text'
            placeholder='Search...'
            value='Search Subject'
            // onChange={() => true}
            // onKeyDown={() => true}
            className='w-full px-4 py-2 border-2 border-gray-300 rounded-full'
          />
          <button
            onClick={() => true}
            className='bg-blue-500 border-2 border-gray-300 text-white p-2 rounded-full focus:outline-none absolute top-0 right-0 px-4 h-full'
          >
            {sendButton}
          </button>
        </div>

        {/* List of questions */}
        <div className='space-y-4 pt-4'>
          {itemsData.map((item: ItemData) => (
            <button
              key={item.id}
              onClick={() => handleCardClick(item.dataText)}
              className='card bg-slate-50 text-left rounded-lg py-4 px-4 shadow-sm hover:bg-gray-50'
            >
              <div className=''>
                <div className='w-full flex items-center justify-between border-b mb-2 pb-2 border-gray-100'>
                  <div className='text-sm'>
                    <div className='text-xs12'>UserName</div>
                    <div className='text-xs8 leading-none'>8 hours ago</div>
                  </div>
                  <div className='text-gray-500 flex items-center justify-center space-x-1 bg-gray-200 rounded-xl w-10'>
                    <span className='text-xs8 pt-0.5'>24</span>
                    <span className=''>{thumbsUp}</span>
                  </div>
                </div>
                <div className='text-xs10 leading-normal'>{item.question}</div>
              </div>
            </button>
          ))}
        </div>
      </div>
    )
  }

  const SelectedItemData = ({
    selectedItemData,
    setSelectedItemData,
  }: {
    selectedItemData: string[] | null // Update type to include null.
    setSelectedItemData: React.Dispatch<React.SetStateAction<string[] | null>>
  }) => {
    return (
      <div id='resultItem' className={`px-4 w-full transition-all duration-300`}>
        <div className='space-y-4'>
          {selectedItemData?.map((text: string, index: number) => (
            <div
              key={index}
              className={`relative card flex flex-col items-center justify-between text-left rounded-lg py-4 px-4 shadow-sm hover:bg-gray-50 ${
                index % 2 === 0 ? 'bg-slate-100' : 'bg-slate-100'
              }`}
            >
              <div className='w-full flex items-center justify-between border-b mb-2 pb-2 border-gray-100'>
                <div className='flex items-center justify-center space-x-2'>
                  <div className='bg-blue-50 flex items-center justify-center rounded-full px-2 py-2 text-xs font-semibold'>TS</div>
                  <div className='w-full'>
                    <div className='text-xs12 leading-none'>UserName</div>
                    <div className='text-xs8 leading-none'>8 hours ago</div>
                  </div>
                </div>
                <div className='text-gray-500 flex items-center justify-center space-x-1 bg-gray-200 rounded-xl w-10'>
                  <span className='text-xs8 pt-0.5'>24</span>
                  <span className=''>{thumbsUp}</span>
                </div>
              </div>

              <div className='absolute left-0 top-0  py-1 px-1 rounded-lg'>{editButton}</div>
              <div className='relative'>
                <TruncateText text={text} limit={600} />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className='relative flex items-center justify-center w-auto'>
      <div
        className='w-full text-center bg-center bg-no-repeat bg-contain overflow-hidden inner-container flex justify-center relative'
        style={{
          backgroundImage: `url(${appBg})`,
          height: '40.9rem',
          paddingTop: '0.5625rem',
          paddingRight: '0.5rem',
          paddingBottom: '0.9rem',
          paddingLeft: '0.7rem',
        }}
      >
        <div className='absolute bottom-12 z-20  w-10 h-10 flex items-center justify-center bg-gray-900 rounded-xl'>
          <DropDownToDo />
        </div>
        <div
          className='mx-auto h-[94%] overflow-y-scroll absolute py-6 max-w-[288px] custom-scrollbar'
          style={{
            top: '20px',
            bottom: '0px',
          }}
        >
          <div className='mx-auto '>
            <AppPreviewHeader />
            {/* Getting the Subjects and content inside subjects */}

            <div
              className=' w-full transition-all duration-300 transform-gpu'
              style={{
                opacity: showIntroWrapper ? 1 : 0,
                transform: showIntroWrapper ? 'translateX(0)' : 'translateX(-2rem)',
              }}
            >
              {showIntroWrapper && (
                // Start Search Section
                <StartSearchStart />
              )}
            </div>

            <div
              className=' w-full transition-all duration-300 transform-gpu'
              style={{
                opacity: showResultsWrapper ? 1 : 0,
                transform: showResultsWrapper ? 'translateX(0)' : 'translateX(-2rem)',
              }}
            >
              {showResultsWrapper && <ResultsWrapper />}
            </div>
            <div
              className='my-4 w-full transition-all duration-300 transform-gpu'
              style={{
                opacity: selectedItemData ? 1 : 0,
                transform: selectedItemData ? 'translateX(0)' : 'translateX(-2rem)',
              }}
            >
              {selectedItemData && <SelectedItemData selectedItemData={selectedItemData} setSelectedItemData={setSelectedItemData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppPreview
