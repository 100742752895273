import { Tab } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import DonateTypes from 'components/Donate/DonateTypes'
import { DateRage } from 'components/HeroSearchForm/StaySearchForm'
import { GuestsObject } from 'components/HeroSearchForm2Mobile/GuestsInput'
import Label from 'components/Label/Label'
import ModalSelectDate from 'components/ModalSelectDate'
import ModalSelectGuests from 'components/ModalSelectGuests'
import StartRating from 'components/StartRating/StartRating'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import mastercardPng from 'images/mastercard.svg'
import logoRightlyGuided from 'images/rightly-guided-logo.svg'
import visaPng from 'images/vis.png'
import moment from 'moment'
import React, { FC, Fragment, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import NcImage from 'shared/NcImage/NcImage'
import NcModal from 'shared/NcModal/NcModal'
import Textarea from 'shared/Textarea/Textarea'
import converSelectedDateToString from 'utils/converSelectedDateToString'

export interface CheckOutPageProps {
  className?: string
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = '' }) => {
  const [rangeDates, setRangeDates] = useState<DateRage>({
    startDate: moment().add(1, 'day'),
    endDate: moment().add(5, 'days'),
  })
  const [guests, setGuests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  })

  const renderSidebar = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8'>
        <div className='flex flex-col sm:flex-row sm:items-center'>
          <div className='flex-shrink-0 w-full sm:w-12'>
            <div className='overflow-hidden'>
              <NcImage className='w-20' src={logoRightlyGuided} />
            </div>
          </div>
          <div className='py-5 sm:px-5 space-y-3'>
            <div>
              <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'>Donate or Invest</span>
              <span className='text-base font-medium mt-1 block'>Help us building the future!</span>
            </div>

            <div className='w-10 border-b border-neutral-200  dark:border-neutral-700'></div>
            <StartRating />
          </div>
        </div>
        <div className='flex flex-col space-y-4'>
          <h3 className='text-2xl font-semibold'>Total</h3>
          <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
            <span>Donations</span>
            <span>£750</span>
          </div>
          <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
            <span>Investment</span>
            <span>£1250</span>
          </div>

          <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='flex justify-between font-semibold'>
            <span>Total</span>
            <span>£2000</span>
          </div>
        </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <div>
          <div>
            <h3 className='text-2xl font-semibold mb-6'>Build yours</h3>
            <NcModal
              renderTrigger={openModal => (
                <span onClick={() => openModal()} className='block lg:hidden underline  mt-1 cursor-pointer'>
                  View Details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle='Details'
              showCloseButton={true}
              closeConfirmationOption={true}
            />
          </div>
          <div className='mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700'>
            <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button type='button' onClick={openModal} className='text-left flex-1 p-5 flex justify-between space-x-5'>
                  <div className='flex flex-col'>
                    <span className='text-sm text-neutral-400'>Options</span>
                    <span className='mt-1.5 text-lg font-semibold'>
                      <span className='line-clamp-1'>
                        {`${(guests.guestAdults || 0) + (guests.guestChildren || 0)} Donations, ${guests.guestInfants || 0} Investment`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className='w-6 h-6 text-neutral-6000 dark:text-neutral-400' />
                </button>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className='text-2xl font-semibold'>Pay with</h3>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 my-5'></div>

          <div className='mt-6'>
            <Tab.Group>
              <Tab.List className='flex my-5'>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? 'bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900'
                          : 'text-neutral-6000 dark:text-neutral-400'
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? 'bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900'
                          : ' text-neutral-6000 dark:text-neutral-400'
                      }`}
                    >
                      <span className='mr-2.5'>Credit card</span>
                      <img className='w-8' src={visaPng} alt='' />
                      <img className='w-8' src={mastercardPng} alt='' />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className='space-y-5'>
                  <div className='space-y-1'>
                    <Label>Card number </Label>
                    <Input defaultValue='111 112 222 999' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Card holder </Label>
                    <Input defaultValue='JOHN DOE' />
                  </div>
                  <div className='flex space-x-5  '>
                    <div className='flex-1 space-y-1'>
                      <Label>Expiration date </Label>
                      <Input type='date' defaultValue='MM/YY' />
                    </div>
                    <div className='flex-1 space-y-1'>
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className='space-y-1'>
                    <Label>Notes </Label>
                    <Textarea placeholder='...' />
                    <span className='text-sm text-neutral-500 block'>If you need anything please let us know.</span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className='space-y-5'>
                  <div className='space-y-1'>
                    <Label>Email </Label>
                    <Input type='email' defaultValue='example@gmail.com' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Password </Label>
                    <Input type='password' defaultValue='***' />
                  </div>
                  <div className='space-y-1'>
                    <Label>Notes </Label>
                    <Textarea placeholder='...' />
                    <span className='text-sm text-neutral-500 block'>If you need anything please let us know.</span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className='pt-8'>
              <ButtonPrimary href={'/pay-done'}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id='CheckOutPage'>
      <section className='py-8 lg:pt-28 overflow-hidden dark:bg-slate-700 bg-gray-50'>
        <div className='container px-4 mx-auto'>
          <div className='mb-20 max-w-2xl mx-auto text-center'>
            <span
              className='inline-block mb-5 px-3 py-1.5 text-sm text-white uppercase tracking-tight font-semibold bg-gray-600 rounded-full'
              style={{
                background: 'url("images/basko-assets/images/gradient.png")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              Support Our Vision
            </span>
            <h2 className='font-heading text-6xl tracking-tighter'>Empowering the Ummah and Enriching Lives</h2>
            <p className='mb-8 text-xl text-gray-500 mt-4'>
              Rightly Guided aims to offer a platform for learning, earning, and faith development under expert guidance. We trust in the
              power of education, opportunities, and community to uplift the global ummah and make a global positive impact.
            </p>
          </div>
          <div className='max-w-7xl mx-auto'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Join Our Legacy Initiative
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Running this large-scale project needs ample resources, including R&D, robust servers, advanced tech, and a
                        dedicated team. We see this as a legacy work, acknowledging past contributions and setting foundations for future
                        generations. Be part of this noble legacy.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Invest in the Ummah's Future
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Donating invests in the ummah's future, enabling self-sufficiency, independence, and empowerment. Your generosity
                        helps us keep developing innovative programs, creating learning avenues, and fostering growth platforms.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-yellow-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Join Us in Creating a Legacy"
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Please consider donating today to join our journey for positive change. Your support is highly valued by our
                        tireless team working to make this vision real. Together, let's build a legacy of empowerment, enlightenment, and
                        spiritual growth.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-blue-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Support Our Mission
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Your donations can boost our progress and extend our initiatives' reach. Every contribution, no matter how small, is
                        vital in our mission to educate millions in Quran and Hadith teachings, and proactive, results-oriented faith
                        principles.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-orange-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Make a Difference with Your Donation
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        We urge you to donate to our ongoing and future projects. Your contribution can impact more lives and propagate
                        Islam's beauty worldwide. Each donation advances our united vision of a thriving, knowledgeable, and resourceful
                        ummah.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-violet-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Amplify Our Impact, Spread the Word
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        We request you to share this with friends, family, and those who share our passion for advancing deeni projects.
                        Together, we can magnify our impact. Your help in spreading the word is vital to grow and expand our community's
                        reach.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='overflow-hidden'>
        <div className='relative pt-28 pb-28 bg-indigo-500 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='mb-16 max-w-3xl mx-auto text-center'>
              <h1 className='font-heading mb-4 text-6xl md:text-7xl text-white tracking-tighter'>Invest in Rightly Guided </h1>
              <h1 className='font-heading mb-4 text-4xl md:text-5xl text-white tracking-tighter'>Your Investment, Our Vision </h1>
              <p className='mb-8 text-xl text-white max-w-xl mx-auto'>
                When you invest in Rightly Guided, you're not just investing in a project, but in the development and empowerment of the
                Muslim community.
              </p>
            </div>
            <div className='relative z-10 overflow-hidden rounded-xl'>
              <div className='bg-black px-3 py-2.5'>
                <div className='flex flex-wrap items-center -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-row'>
                      <div className='w-auto p-1'>
                        <div className='w-2 h-2 bg-red-400 border border-red-500 rounded-full'></div>
                      </div>
                      <div className='w-auto p-1'>
                        <div className='w-2 h-2 bg-yellow-400 border border-yellow-500 rounded-full'></div>
                      </div>
                      <div className='w-auto p-1'>
                        <div className='w-2 h-2 bg-green-400 border border-green-500 rounded-full'></div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-1 p-2'>
                    <div className='max-w-xs mx-auto py-0.5 px-4 text-sm text-white text-opacity-50 text-center bg-white bg-opacity-10 tracking-tight rounded'>
                      <span>Rightly Guided</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-12 bg-gray-700'>
                <div className='flex flex-wrap lg:items-center -m-8'>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='max-w-md'>
                      <div className='flex flex-wrap -m-4'>
                        <div className='w-full p-4'>
                          <div className='flex flex-wrap -m-2'>
                            <div className='w-auto p-2'>
                              <span className='inline-block text-xl text-white font-semibold tracking-tight'>1.</span>
                            </div>
                            <div className='flex-1 p-2'>
                              <h3 className='mb-3 text-xl text-white font-semibold tracking-tight'>How to help the project</h3>
                              <p className='text-xl text-gray-500 tracking-tight'>
                                {' '}
                                Investment in Rightly Guided is managed by muftis, scholars, and experts, promising skillful handling.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='w-full p-4'>
                          <div className='flex flex-wrap -m-2'>
                            <div className='w-auto p-2'>
                              <span className='inline-block text-xl text-white font-semibold tracking-tight'>2.</span>
                            </div>
                            <div className='flex-1 p-2'>
                              <h3 className='mb-3 text-xl text-white font-semibold tracking-tight'>Joining the Community</h3>
                              <p className='text-xl text-gray-500 tracking-tight'>
                                Your Rightly Guided investment offers dual rewards - financial return and the satisfaction of contributing
                                to Muslim community growth.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='w-full p-4'>
                          <div className='flex flex-wrap -m-2'>
                            <div className='w-auto p-2'>
                              <span className='inline-block text-xl text-white font-semibold tracking-tight'>3.</span>
                            </div>
                            <div className='flex-1 p-2'>
                              <h3 className='mb-3 text-xl text-white font-semibold tracking-tight'>Donate &amp; Invest on RG</h3>
                              <p className='text-xl text-gray-500 tracking-tight'>
                                Volunteer with us to make an impact and gain valuable experiences enhancing personal growth.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 p-8'>
                    <div className='group relative max-w-max ml-auto'>
                      <a
                        className='absolute top-1/2 left-1/2 z-20 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center w-20 h-20 bg-white hover:bg-gray-200 rounded-full transition duration-200'
                        href='##'
                      >
                        <svg width='21' height='23' viewBox='0 0 21 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M4.29871 0.690679C2.36694 -0.459709 0.800781 0.482703 0.800781 2.79393V19.8498C0.800781 22.1633 2.36694 23.1045 4.29871 21.9552L18.6583 13.4058C20.5907 12.255 20.5907 10.3905 18.6583 9.23999L4.29871 0.690679Z'
                            fill='#5E27FF'
                          ></path>
                        </svg>
                      </a>
                      <div className='max-w-max overflow-hidden rounded-xl'>
                        <img
                          className='transform group-hover:scale-105 transition duration-500'
                          src='images/basko-assets/images/headers/video.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='absolute bottom-0 left-0 w-full'>
            <svg
              className='w-full'
              width='100%'
              height='186'
              preserveAspectRatio='none'
              viewBox='0 0 1600 186'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M1608 127.618C969.729 -103.398 167.657 31.1518 0 127.618V186H1608V127.618Z' fill='white'></path>
            </svg>
            <div className='h-80 bg-white'></div>
          </div>
        </div>
      </section>
      <section className='relative py-36 bg-blueGray-50 overflow-hidden'>
        <img className='absolute top-1/2 right-0 transform -translate-y-1/2' src='images/flaro-assets/images/pricing/gradient.svg' alt='' />
        <div className='relative z-10 container px-4 mx-auto'>
          <div className='flex flex-wrap lg:items-center -m-8'>
            <div className='w-full md:w-1/2 p-8 '>
              <div className='md:max-w-md'>
                <h2 className='mb-16 lg:mb-52 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none'>
                  Donate
                </h2>
                <h3 className='mb-6 text-lg text-gray-900 font-semibold leading-normal'>
                  Investment in Rightly Guided is managed by muftis, scholars, and experts, promising skillful handling.
                </h3>
                <p className='font-medium text-gray-600 leading-relaxed'>
                  Confidently invest with us, knowing your contribution profoundly impacts those seeking to learn, earn, and develop faith.
                  Together, we'll strengthen the Muslim ummah.
                </p>
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className='md:max-w-md mx-auto overflow-hidden rounded-3xl shadow-xl bg-slate-50'>
                <div className='p-9'>
                  <span className='mb-7 inline-block text-sm text-gray-500 font-semibold uppercase tracking-px'>Features included:</span>
                  <ul>
                    <li className='mb-4 flex items-center'>
                      <svg className='mr-2' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z'
                          stroke='#4F46E5'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></path>
                      </svg>
                      <p className='font-semibold leading-normal'>Early Access to our tools</p>
                    </li>
                    <li className='mb-4 flex items-center'>
                      <svg className='mr-2' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z'
                          stroke='#4F46E5'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></path>
                      </svg>
                      <p className='font-semibold leading-normal'>Get involved with our team</p>
                    </li>
                    <li className='mb-4 flex items-center'>
                      <svg className='mr-2' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z'
                          stroke='#4F46E5'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></path>
                      </svg>
                      <p className='font-semibold leading-normal'>Use our AI models</p>
                    </li>
                    <li className='mb-4 flex items-center'>
                      <svg className='mr-2' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z'
                          stroke='#4F46E5'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></path>
                      </svg>
                      <p className='font-semibold leading-normal'>Decide on new tools</p>
                    </li>
                    <li className='flex items-center'>
                      <svg className='mr-2' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z'
                          stroke='#4F46E5'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></path>
                      </svg>
                      <p className='font-semibold leading-normal'>Premium Features</p>
                    </li>
                  </ul>
                </div>
                <div className='p-9 bg-white'>
                  <div className='flex flex-wrap -m-8'>
                    <div className='w-full sm:w-1/2 p-8'>
                      <span className='mb-2 inline-block text-sm text-gray-500 font-semibold uppercase tracking-px'>DONATE NOW</span>
                      <p className='text-gray-900 font-semibold leading-normal'>Stay with us and help us growing</p>
                    </div>
                    <div className='w-full sm:w-1/2 p-8'>
                      <div className='sm:max-w-max ml-auto'>
                        <p className='font-bold'>
                          <span className='text-5xl leading-tight tracking-px-n'>£10</span>
                          <span className='text-lg text-gray-500 leading-snug tracking-px-n'>/pc</span>
                        </p>
                        <p className='font-medium text-gray-500 leading-relaxed'>donate from</p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-9'>
                    <button
                      className='py-4 px-5 w-full text-white font-semibold rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
                      type='button'
                    >
                      Start Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <DonateTypes />
      {/* <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800 dark:bg-slate-900 dark:from-slate-900 dark:via-slate-900'>
        <GetInvolvedBlock />
      </div> */}
      {/* <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'> */}
      {/* <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10 '>{renderMain()}</div> */}
      {/* <div className='hidden lg:block flex-grow'>{renderSidebar()}</div> */}
      {/* </main> */}
    </div>
  )
}

export default CheckOutPage
