import AppPreview from 'containers/PageHome/AppPreview'
import appStore from 'images/zeus-assets/buttons/app-store.svg'
import playStore from 'images/zeus-assets/buttons/google-play.svg'
import leftImg from 'images/zeus-assets/icons/dots/blue-dot-left-bars.svg'
import React from 'react'

export default function AppBuilderSection() {
  return (
    <section className='relative py-20 bg-gradient-to-t from-rose-100 to-teal-100'>
      <img className='hidden lg:block absolute top-0 left-0 mt-24' src={leftImg} alt='' />
      <div className='relative container px-4 mx-auto'>
        <div className='container flex flex-wrap items-center -mx-4'>
          <div className='w-full md:w-3/5 px-4 mb-16 md:mb-0'>
            <h2 className='mb-10 text-4xl font-semibold font-heading'>Build your App</h2>
            <div className='max-w-xl'>
              <p className='mb-10 text-xl text-gray-500'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque massa nibh, pulvinar vitae aliquet nec, accumsan
                aliquet orci.
              </p>
            </div>
            <div className='flex justify-start items-center'>
              <a className='mr-4' href='##'>
                <img src={appStore} alt='' />
              </a>
              <a href='##'>
                <img src={playStore} alt='' />
              </a>
            </div>
          </div>
          <div className='w-full md:w-2/5 px-4'>
            <AppPreview />
          </div>
        </div>
      </div>
    </section>
  )
}
