import { Popover, Transition } from '@headlessui/react'
import { FC, Fragment, useState, useEffect } from 'react'
import Avatar from 'shared/Avatar/Avatar'
// Auth
import { API } from '../../constant'
import { getToken } from '../../helpers'
import { useAuthContext } from '../../context/AuthContext'

const solutions = [
  {
    name: 'Beginner Quiz',
    description: 'You have finished the quiz',
    time: '12 hours ago',
    score: '79%',
    href: '#',
  },
  {
    name: 'Beginner Quiz',
    description: 'You have finished the quiz',
    time: '13 hours ago',
    score: '49%',
    href: '#',
  },
  {
    name: 'Beginner Quiz',
    description: 'You have finished the quiz',
    time: '14 hours ago',
    score: '59%',
    href: '#',
  },
]

interface Props {
  className?: string
}
interface Notifications {
  id: number
  attributes: {
    title: string
    description: string
    badge: string
    url: string
    isVisible: boolean
    updatedAt: string
    image: {
      data: {
        id: number
        attributes: {
          formats: {
            medium: {
              url: string
            }
          }
        }
      }
    }
  }
}

const NotifyDropdown: FC<Props> = ({ className = '' }) => {
  const [notifications, setNotifications] = useState<Notifications[]>([])
  // Login
  const [isLogged, setIsLogged] = useState(true)
  const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})

  useEffect(() => {
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)

    // Get the correct header
    if (isLoggedIn) {
      console.log('USER DATA - ' + userData)
    } else {
      console.log('NOT LOGGED IN')
    }
  }, [])

  // Get notifications
  async function fetchAppeals() {
    const response = await fetch('http://localhost:1337/api/notifications/?populate=deep')
    const json = await response.json()
    setNotifications(json.data)
    console.log('json.data ', json.data)
  }

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    // save the user and jwt to the state userData if any of them is not null/undefined
    if (user && jwt) {
      setUser(user)
      console.log('USER DATA TO CHECK - ' + user)
      console.log('-----------------------')
      setUserData(JSON.parse(user))
      setIsLogged(true)
    }

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  // calculate the date difference between now and the date of the notification
  function getDaysDifference(inputDate: string): string {
    // Parse the input date string
    const parsedDate = new Date(inputDate)

    // Get the current date
    const currentDate = new Date()

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - parsedDate.getTime()

    // Calculate the difference in days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

    if (daysDifference === 0) {
      return 'today'
    } else if (daysDifference === 1) {
      return '1 day'
    } else {
      return `${daysDifference} days`
    }
  }

  return (
    <div className={className}>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              onClick={() => {
                fetchAppeals()
              }}
              className={` ${
                open ? '' : 'text-opacity-90'
              } group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              <span className='w-2 h-2 bg-blue-500 absolute top-2 right-2 rounded-full'></span>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 mt-3 -right-28 sm:right-0 sm:px-0'>
                <div className='overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative grid gap-8 bg-white dark:bg-neutral-800 p-7'>
                    <h3 className='text-xl font-semibold'>Notifications</h3>
                    {notifications.map((item, index) => (
                      <a
                        key={index}
                        href={item.attributes.url}
                        className='flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative'
                      >
                        <Avatar sizeClass='w-8 h-8 sm:w-12 sm:h-12' />
                        <div className='ml-3 sm:ml-4 space-y-1'>
                          <p className='text-sm font-medium text-gray-900 dark:text-gray-200'>
                            {item.attributes.title}
                            <span className='bg-primary-500 text-white px-2 py-1 ml-2 tracking-widest text-xs rounded-full z-10 text-[10px]'>
                              {item.attributes.badge}
                            </span>
                          </p>
                          <p className='text-xs sm:text-sm text-gray-500 dark:text-gray-400'>{item.attributes.description}</p>
                          <p className='text-xs text-gray-400 dark:text-gray-400'>{getDaysDifference(item.attributes.updatedAt)}</p>
                        </div>
                        <span className='absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500'></span>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default NotifyDropdown
