import React from 'react'

export default function AlertTop() {
  return (
    <section className='py-4 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='p-6 bg-white border rounded-xl'>
          <div className='flex flex-wrap -m-2'>
            <div className='flex-1 p-2'>
              <div className='flex flex-wrap -m-1.5'>
                <div className='w-auto p-1.5'>
                  <svg
                    className='relative top-0.5'
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10 10H10.75C10.75 9.58579 10.4142 9.25 10 9.25V10ZM10 13.3333H9.25C9.25 13.7475 9.58579 14.0833 10 14.0833V13.3333ZM10.8333 14.0833C11.2475 14.0833 11.5833 13.7475 11.5833 13.3333C11.5833 12.9191 11.2475 12.5833 10.8333 12.5833V14.0833ZM9.16667 9.25C8.75245 9.25 8.41667 9.58579 8.41667 10C8.41667 10.4142 8.75245 10.75 9.16667 10.75V9.25ZM10 5.91667C9.58579 5.91667 9.25 6.25245 9.25 6.66667C9.25 7.08088 9.58579 7.41667 10 7.41667V5.91667ZM10.0083 7.41667C10.4225 7.41667 10.7583 7.08088 10.7583 6.66667C10.7583 6.25245 10.4225 5.91667 10.0083 5.91667V7.41667ZM9.25 10V13.3333H10.75V10H9.25ZM10 14.0833H10.8333V12.5833H10V14.0833ZM10 9.25H9.16667V10.75H10V9.25ZM16.75 10C16.75 13.7279 13.7279 16.75 10 16.75V18.25C14.5563 18.25 18.25 14.5563 18.25 10H16.75ZM10 16.75C6.27208 16.75 3.25 13.7279 3.25 10H1.75C1.75 14.5563 5.44365 18.25 10 18.25V16.75ZM3.25 10C3.25 6.27208 6.27208 3.25 10 3.25V1.75C5.44365 1.75 1.75 5.44365 1.75 10H3.25ZM10 3.25C13.7279 3.25 16.75 6.27208 16.75 10H18.25C18.25 5.44365 14.5563 1.75 10 1.75V3.25ZM10 7.41667H10.0083V5.91667H10V7.41667Z'
                      fill='#B8C1CC'
                    ></path>
                  </svg>
                </div>
                <div className='flex-1 p-1.5'>
                  <p className='font-medium'>
                    <span className='text-transparent bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600'>
                      Some Notice on top
                    </span>
                    <span> New Updates coming today</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='w-auto p-2'>
              <a className='relative top-1 text-neutral-300 hover:text-neutral-400' href='##'>
                <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L3.46967 11.4697ZM12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L12.5303 4.53033ZM4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L4.53033 3.46967ZM11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L11.4697 12.5303ZM4.53033 12.5303L12.5303 4.53033L11.4697 3.46967L3.46967 11.4697L4.53033 12.5303ZM3.46967 4.53033L11.4697 12.5303L12.5303 11.4697L4.53033 3.46967L3.46967 4.53033Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
