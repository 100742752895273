import React, { FC, useState, useEffect } from 'react'
import NcImage from 'shared/NcImage/NcImage'
import Badge from 'shared/Badge/Badge'
import StartRating from 'components/StartRating/StartRating'
import { Link } from 'react-router-dom'
import NcModal from 'shared/NcModal/NcModal'
import CircleProgressBar from './CircleProgressBar'
import AppealsData from './AppealsData.json'

// const stripe = require("stripe")("sk_test_QUXcoU3BnbZXp6IMVi7BkW8s");

export interface ListingAppealsFilterCardProps {
  className?: string
  data?: Appeal[]
}
// TODO: Interface review
interface Appeal {
  id: number
  attributes: {
    title: string
    description: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    shortDescription: string
    donationAmounts: {
      id: number
      amountStart: number
      amountEnd: number
    }
    donationsOptions: Array<{
      id: number
      donationButtons: Array<{
        id: number
        donationButtonsText: string
        donationButtonsLink: string
        donationButtonsAmount: number
      }>
    }>
    imageThumbnail: { data: { attributes: { url: string } } }
  }
}

const ListingAppealsFilterCard: FC<ListingAppealsFilterCardProps> = ({ className = '', data }) => {
  const [appeals, setAppeals] = useState<Appeal[] | (() => Appeal[])>(AppealsData.data)
  // const radialProgress = { '--value': 70 } as React.CSSProperties

  useEffect(() => {
    // async function fetchAppeals() {
    //   const response = await fetch('http://localhost:1337/api/appeals/?populate=deep')
    //   const json = await response.json()
    //   setAppeals(json.data)
    //   // console.log("json.data ", json.data);
    // }
    // fetchAppeals()
  }, [])

  // stripe stuff

  const headerRender = (imgUrl: string) => {
    return (
      <div className='relative w-full rounded-2xl overflow-hidden'>
        <div className='aspect-w-16 aspect-h-12 '>
          <NcImage containerClassName='flex items-center justify-center' className='w-full' src={`http://localhost:1337${imgUrl}`} />
        </div>
      </div>
    )
  }

  const calcDonationProgress = (amountStart: number, amountEnd: number) => {
    const progress = (amountStart * 100) / amountEnd
    return progress
  }

  const renderPopupAppeal = (data: Appeal) => {
    const {
      title,
      description,
      shortDescription,
      donationAmounts: { amountStart, amountEnd },
      donationsOptions,
      imageThumbnail: {
        data: {
          attributes: { url },
        },
      },
    } = data.attributes
    return (
      <div className='flex flex-col  md:flex-row'>
        <div className='w-auto'>
          <NcImage containerClassName='flex items-center justify-center' className='w-full' src={`http://localhost:1337${url}`} />
        </div>
        <div className='w-auto mx-8 my-2'>
          <div className='flex items-center justify-between'>
            <h3 className='text-2xl font-semibold my-4'>{title}</h3>
          </div>

          <p className='text-sm text-neutral-500 dark:text-neutral-400'>{description}</p>
          <div className='flex items-center justify-start space-x-2 mt-4 gap-4'>
            <div className=''>
              <CircleProgressBar percentage={amountStart} />
            </div>
            <div className='flex flex-col'>
              <div className='flex'>
                {amountStart} - {amountEnd}
                <span className='text-neutral-500 dark:text-neutral-400'>/</span>
                <StartRating reviewCount={amountStart} point={amountStart} />
              </div>

              <div className='w-full'>
                {donationsOptions.map((donationOption, idx) => {
                  return (
                    <div key={idx}>
                      {donationOption.donationButtons.map((donationButton, idx) => {
                        return (
                          <Link key={idx} className='mr-4' to={donationButton.donationButtonsLink}>
                            <button id='buttonPay' className='bg-primary-500 text-white px-4 py-2 rounded-lg mt-4'>
                              {donationButton.donationButtonsText}
                            </button>
                          </Link>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={`nc-SectionGridFilterCard ${className}`} data-nc-id='SectionGridFilterCard'>
      {/* <Heading2
            heading="Appeals"
            subHeading={
              <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
              100 Apeals
              <span className="mx-2">·</span>
              More
              </span>
            }
          /> */}

      <div className='mb-8 lg:mb-11'>
        {/* <TabFilters /> */}
        <div className='flex justify-end lg:space-x-4'>
          <div className='flex space-x-4'>
            <button className='nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  w-full sm:w-auto bg-green-500 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0'>
              Create Appeal
            </button>
            <div
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all'
        }`}
            >
              Total of Appeals: <span className='ml-1'>{appeals.length}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
        {Array.isArray(appeals) &&
          appeals.map((appeal: Appeal, idx: number) => (
            // <li key={appeal.id}>{appeal.attributes.title}</li>
            <div
              className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform`}
              data-nc-id='CarCard'
              key={idx}
            >
              <NcModal
                closeConfirmationOption={true}
                modalButton='Add your Appeal'
                renderTrigger={openModal => (
                  <div onClick={() => openModal()} key={idx} className='w-full cursor-pointer'>
                    {headerRender(appeal.attributes.imageThumbnail.data.attributes.url)}

                    <div className='default p-5  space-y-4'>
                      <div className='space-y-2'>
                        <div className='flex items-center space-x-2'>
                          {appeal.id && <Badge name='TOP' color='green' />}
                          <h2 className={`  capitalize default text-xl font-semibold`}>
                            <span className='line-clamp-1'>{appeal.attributes.title}</span>
                          </h2>
                        </div>
                        <div className='flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2'>
                          <span className=''>{appeal.attributes.shortDescription}</span>
                        </div>
                      </div>
                      <div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>

                      <div className='flex justify-between items-center'>
                        <span className='text-base font-semibold'>
                          {appeal.id + Number(((Math.random() * 6) | 0) + 1)}
                          <span className='text-neutral-500 dark:text-neutral-400'>/</span>
                        </span>
                        <StartRating reviewCount={appeal.id} point={appeal.id} />
                      </div>
                      <div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700'>
                        {/* {Number(appeal.attributes.donationAmounts.amountStart)}
            {Number(appeal.attributes.donationAmounts.amountEnd)} */}
                        <div
                          className='bg-green-400 h-2.5 rounded-full'
                          style={{
                            width: calcDonationProgress(
                              Number(appeal.attributes.donationAmounts.amountStart),
                              Number(appeal.attributes.donationAmounts.amountEnd)
                            ),
                          }}
                        ></div>
                      </div>
                      <div className='w-full flex items-center justify-between'>
                        <div className='flex flex-col items-left justify-start'>
                          <span className='text-xl font-semibold'>
                            £{appeal.attributes.donationAmounts.amountStart.toLocaleString('en-GB')}
                          </span>
                          <span className='text-sm'>Donated of £{appeal.attributes.donationAmounts.amountEnd.toLocaleString('en-GB')}</span>
                        </div>
                        <div className='flex items-center space-x-2'>
                          <button className='bg-primary-500 text-white px-4 py-2 rounded-lg'>View Appeal</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                renderContent={() => renderPopupAppeal(appeal)}
                modalTitle={appeal.attributes.title}
              />
            </div>
          ))}
      </div>
      <div className='flex mt-16 justify-center items-center'>{/* <Pagination /> */}</div>
    </div>
  )
}

export default ListingAppealsFilterCard
