import emailjs, { init } from 'emailjs-com'
import logoImg from 'images/logoRgDark.png'
import React, { FC, FormEvent, useState } from 'react'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const countryList: string[] = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. "Swaziland")',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

export default function JoinCommunityForm() {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[1].value
    const message = `Community Join | ${(e.target as any)[2].value} | ${(e.target as any)[3].value}`

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'joinCommunityRg', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }
  return (
    <section className='py-16 bg-white overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='text-center max-w-lg mx-auto'>
          <a className='mb-10 hidden sm:flex items-center justify-center' href='##'>
            <img className='w-32' src={logoImg} alt='' />
          </a>
          <h2 className='mb-4 text-4xl md:text-5xl text-center font-bold font-heading tracking-px-n leading-tight'>Join the Community</h2>
          <p className='mb-12 hidden sm:block font-medium text-lg text-gray-600 leading-normal'>
            Lorem ipsum dolor sit amet, to the con adipiscing. Volutpat tempor to the condim entum.
          </p>
          <form action='#' onSubmit={handleSubmit} method='post'>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-1'
                type='text'
                placeholder='First &amp; Last Name'
              />
            </label>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-2'
                type='email'
                placeholder='Email Address'
              />
            </label>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-3'
                type='text'
                placeholder='Your Profession or Interests'
              />
            </label>
            <label className='block mb-5'>
              <div className='relative'>
                <select
                  className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                  id='grid-state'
                  required
                >
                  {countryList.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <button
              className='mb-8 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
              type='submit'
              disabled={formStatus === 'sending'}
            >
              Join our community
            </button>

            <p className='font-medium'>
              <span>Already joined our community?</span>
              <a className='text-indigo-600 hover:text-indigo-700 ml-2' href='##'>
                Get Notify
              </a>
            </p>
            <div className='flex items-center justify-center mt-4'>
              {formStatus === 'success' && (
                <p className='absolute mt-2 text-center'>
                  <span className=' text-green-600 rounded-xl text-base py-2 px-2 w-full'>Email sent successfully!</span>
                </p>
              )}
              {formStatus === 'error' && (
                <p className='absolute mt-2 text-center'>
                  <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                    There was an error sending the email. Please try again.
                  </span>
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
