import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import DonateTypes from 'components/Donate/DonateTypes'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import React, { FC, FormEvent, useState } from 'react'
import { Helmet } from 'react-helmet'
import SocialsList from 'shared/SocialsList/SocialsList'
import FAQSearch from './FAQSearch'

export interface PageContactProps {
  className?: string
}

const info = [
  {
    title: 'ADDRESS',
    desc: 'Street Road, M1 1PE, Manchester, UK',
  },
  {
    title: 'EMAIL',
    desc: 'hello@rightly-guided.com',
  },
  {
    title: 'PHONE',
    desc: '+44 (0) 123 456 7890',
  },
]

const PageContact: FC<PageContactProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id='PageContact'>
      <Helmet>
        <title>FAQ | Rightly Guided </title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative mb-24 lg:mb-32'>
        <h2 className='my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Get Support
        </h2>
        <div>
          <FAQSearch />
        </div>
      </div>
      <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800 dark:bg-slate-900 dark:from-slate-900 dark:via-slate-900'>
        <GetInvolvedBlock />
      </div>
      {/* <div className='relative mb-24 lg:mb-32'>
          <Workflow />
        </div> */}
      <div className='relative'>
        <DonateTypes />
      </div>
      {/* OTHER SECTIONS */}
      {/* <div className='container'>
        <div className='relative py-16'>
          <BackgroundSection />
          <SectionClientSay uniqueClassName='Pagecontact_' />
        </div>
        <SectionSubscribe2 className='py-24 lg:py-32' />
      </div> */}
    </div>
  )
}

export default PageContact
