import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import SectionSliderNewCategories from 'components/Home/SectionHomeAppeals'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import { TaxonomyType } from 'data/types'
import imgSampleRamadan from 'images/more-ramadam.png'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import QuizzesListFilterCard from './FamilyQuizzesListFilterCard'

export interface FamilyQuizzesPageProps {
  className?: string
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: '1',
    href: '#',
    name: 'Family Quizz Sample Name',
    taxonomy: 'category',
    count: 17288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '2',
    href: '#',
    name: 'Family Quizz Sample Name',
    taxonomy: 'category',
    count: 2118,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '3',
    href: '#',
    name: 'Family Quizz Sample Name',
    taxonomy: 'category',
    count: 36612,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '5',
    href: '#',
    name: 'Family Quizz Sample Name',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
  {
    id: '4',
    href: '#',
    name: 'Family Quizz Sample Name',
    taxonomy: 'category',
    count: 188288,
    thumbnail: imgSampleRamadan,
    listingType: 'experiences',
  },
]

const FamilyQuizzesPage: FC<FamilyQuizzesPageProps> = ({ className = '' }) => {
  return (
    <div className={`nc-ListingFlightsPage relative overflow-hidden ${className}`} data-nc-id='ListingFlightsPage'>
      <Helmet>
        <title>Rightly Guided | Authenticated Islamic AI </title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative'>
        {/* SECTION HERO */}
        {/* <ListingHeader className="" /> */}

        {/* SECTION */}
        <QuizzesListFilterCard className='pt-10 pb-24 lg:pb-28' />

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          heading='Explore more Quizzes'
          subHeading='Explore more quizzes below'
          categoryCardType='card4'
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName='ListingFlightsPage-section1'
        />

        {/* SECTION */}
        <SectionSubscribe2 className='py-24 lg:py-28' />
      </div>
    </div>
  )
}

export default FamilyQuizzesPage
