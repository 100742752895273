import React, { FC } from 'react'
import { SocialType } from 'shared/SocialsShare/SocialsShare'

export interface SocialsList1Props {
  className?: string
}

const tiktokIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-tiktok' viewBox='0 0 16 16'>
    {' '}
    <path d='M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z' />{' '}
  </svg>
)

const SocialIcons: SocialType[] = [
  { name: 'tiktok', icon: 'lab la-tiktok', href: 'https://www.tiktok.com/@rightlyguidedapp' },
  { name: 'Facebook', icon: 'lab la-facebook-square', href: '#' },
  { name: 'Twitter', icon: 'lab la-twitter', href: 'https://twitter.com/RightlyGuidedUk' },
  { name: 'Youtube', icon: 'lab la-youtube', href: 'https://www.youtube.com/channel/UCnrFiZzVhamuFrdXQ15n4Ng' },
  { name: 'Instagram', icon: 'lab la-instagram', href: 'https://www.instagram.com/rightlyguidedapp/' },
]

const SocialsListFooter: FC<SocialsList1Props> = ({ className = 'space-y-2.5' }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className='flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group'
        key={index}
      >
        {item.name === 'tiktok' ? <span className='pt-3.5'>{tiktokIcon}</span> : <i className={item.icon}></i>}
        {/* <span className='hidden lg:block text-sm'>{item.name}</span> */}
      </a>
    )
  }

  return (
    <div className={`nc-SocialsList1 flex items-center gap-0.5 justify-center ${className}`} data-nc-id='SocialsList1'>
      {SocialIcons.map(renderItem)}
    </div>
  )
}

export default SocialsListFooter
