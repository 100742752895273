import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import Label from 'components/Label/Label'
import emailjs, { init } from 'emailjs-com'
import React, { FormEvent, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import Textarea from 'shared/Textarea/Textarea'
import dataQuestions from './data.json'

export interface FAQ {
  id: number
  question: string
  answer: string
  category: string // New field
  imageUrl?: string
  video?: string
}

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

// export const faqs: FAQ[] = [
//   {
//     id: 1,
//     question: 'Who is Rightly Guided and what do you do?',
//     answer: `
//       <p>
//       We are a team of like minded muslims with a passion to learn. We have first-hand experience with the struggle in accessing information and knowing what information is authentic so we decided to take on the challenge.

//       </p>
//       <p>
//       Here at Rightly Guided we want to provide you with access to Islamic knowledge with ease and make learning simple.
//       </p>
//     `,
//   },
//   {
//     id: 2,
//     question: 'What is meant by a fully authenticated AI?',
//     answer: `
//       <p>
//       We have partnered with scholars across the UK to work towards a system built according to the Quran, sunnah and sharia.

//       </p>
//       <p>
//       We aim to ensure we are utilising the developments in technology whilst preserving Islam and making your life easier whether you’re trying to find an authentic hadith or you have a fiqh-related question and want to ask an actual Mufti or search through previous responses. This includes us training an AI which you can learn more about here.      </p>
//       <p>Together we can ensure that the timeless wisdom of Islam continues to inspire and rightly guide us for generations to come.</p>
//     `,
//     // imageUrl: 'https://via.placeholder.com/100',
//     // video: 'https://www.youtube.com/embed/fwq4ayGgl48',
//   },
//   {
//     id: 3,
//     question: 'Is your AI built based on a specific school of thought?',
//     answer: `
//       <p>
//       Working with various different scholars we aim to ensure there is clarity as to who information relates to - allowing you to select your school of thought before using to avoid confusion.

//       </p>
//       <p>
//       At present many systems on the internet can cause confusion as there are various answers on the internet and they do not let you know what madhab they relate to which is key when learning fiqh.
//       </p>
//     `,
//     // imageUrl: 'https://via.placeholder.com/100',
//     // video: '',
//   },
//   {
//     id: 4,
//     question: 'How will my donation be used?',
//     answer: `
//       <p>
//       Your contribution will help build the largest Islamic library in the world and change the way we access information as muslims.

//       </p>
//       <ul>
//       <li>A source of authentic books for the world to benefit from</li>
//       <li>Help students/ everyone study learn and create vibrant future communities</li>
//       </ul>
//       <p>Your contribution will allow us to:
//             </p>
//       <ul>
//       <li>Support with development of features such as robust authentication, curated content and user-friendly design</li>
//       <li>Build a secure system with state of the art servers to protect against corruption and improve speed of access</li>
//       <li>Secure access for everyone globally</li>
//       <li>Future proofing and protection</li>
//       <li>With a project this big it is key we have the right people involved and we want to ensure to hire the brightest minds of our times to develop this even further</li>
//       <li>AI support to create or give correct information to the public</li>
//       <li>Build the go to place for charities community developers who wish to create amazing products & services to benefit humanity go towards the development of features such as, and staff costs.
//       </li>
//       </ul>
//     `,
//     // imageUrl: 'https://via.placeholder.com/100',
//     // video: '',
//   },
//   {
//     id: 5,
//     question: 'There are so many Ai’s out there; can’t I just use them?',
//     answer: `
//       <p>
//       As the world evolves and AI is more prominent across the globe to make life easier we are now seeing an introduction of faith based AI’s alongside generalised AI’s including the option to type in anything and get a hadith to match.

//       </p>
//       <p>
//       However this comes with many challenges including misinformation which puts your Imaan at risk. As a Muslim it is key that we are mindful of where we are getting our information from. We have seen the answers provided by various AI’s however they lack authentication and can often have problematic answers which risks confusion.
//       </p>
//       <p>
//       By building a fully authenticated AI with Islam at the foundation of all we do this will provide ease to future generations and people that are currently navigating through day to day life.</p>
//     `,
//     // imageUrl: 'https://via.placeholder.com/100',
//     // video: '',
//   },
//   {
//     id: 6,
//     question: 'I want to be a part of the project. Can I get involved?',
//     answer: `
//       <p>
//       We’re always keen to have people involved and welcome the community getting involved! If you’re interested in getting involved complete this form or email us further information via info@rightly-guided.com
//       </p>

//     `,
//     // imageUrl: 'https://via.placeholder.com/100',
//     // video: '',
//   },
// ]

export const faqs: FAQ[] = dataQuestions.map((item, index) => ({
  id: index + 1,
  question: item.Question,
  answer: item.Answer,
  category: item.category, // New field
  // If your JSON contains imageUrl and video, uncomment these lines
  // imageUrl: item.imageUrl,
  // video: item.video,
}))

const FAQSearch: React.FC = () => {
  const [search, setSearch] = useState<string>('')
  const [filteredFAQs, setFilteredFAQs] = useState<FAQ[]>(faqs)
  const [openFaqId, setOpenFaqId] = useState<number | null>(null)

  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const [showForm, setShowForm] = useState<boolean>(false)

  // inside FAQSearch component
  const [currentCategory, setCurrentCategory] = useState<string>('All')

  // Set initial state for displaying FAQ items
  const [faqDisplayCount, setFaqDisplayCount] = useState<number>(9)

  // Function to handle 'Load More' button click
  const handleLoadMore = () => {
    setFaqDisplayCount(faqDisplayCount + 10)
  }

  // Create a set of all unique categories
  const categories = ['All', ...Array.from(new Set(faqs.map(faq => faq.category)))]

  const handleCategoryChange = (category: string) => {
    setCurrentCategory(category)
    if (category === 'All') {
      setFilteredFAQs(faqs)
    } else {
      const filtered = faqs.filter(faq => faq.category === category)
      setFilteredFAQs(filtered)
    }
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[1].value
    const message = (e.target as any)[2].value

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const onSearchButtonClick = () => {
    const filtered = faqs.filter(faq => faq.question.toLowerCase().includes(search.toLowerCase()))
    setFilteredFAQs(filtered)

    if (filtered.length === 0) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchButtonClick()
    }
  }

  const clearSearch = () => {
    setSearch('')
    setFilteredFAQs(faqs)
    setShowForm(false)
  }

  const onAskNowButtonClick = () => {
    setShowForm(true)
  }

  return (
    <div className='container mx-auto py-8'>
      <div className='relative w-full mb-4 max-w-2xl mx-auto text-center'>
        <input
          type='text'
          placeholder='Search...'
          value={search}
          onChange={handleSearchInput}
          onKeyDown={handleKeyDown}
          className='w-full px-4 py-2 border-2 border-gray-300 rounded-full'
        />
        <button
          onClick={onSearchButtonClick}
          className='bg-blue-500 border-2 border-gray-300 text-white p-2 rounded-full focus:outline-none absolute top-0 right-0 px-4 h-full'
        >
          Search
        </button>
        {search && (
          <button
            onClick={clearSearch}
            className='absolute top-0 right-20 mr-2 pr-4 focus:outline-none text-gray-500 h-full flex items-center justify-center'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z'
              />
            </svg>
          </button>
        )}
      </div>
      <div className='relative w-full max-w-md mx-auto'>
        <Label>Category</Label>
        <select onChange={e => handleCategoryChange(e.target.value)} className='w-full px-4 py-2 border-2 border-gray-300 rounded'>
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div className='pt-8 z-10 faqData'>
        {filteredFAQs.slice(0, faqDisplayCount).map(faq => (
          <FAQItem key={faq.id} faq={faq} openFaqId={openFaqId} setOpenFaqId={setOpenFaqId} />
        ))}
      </div>
      <div>
        {faqDisplayCount < filteredFAQs.length && (
          <div className='flex justify-center mt-4'>
            <button className='btn btn-primary bg-slate-500 py-4 px-4 text-white' onClick={handleLoadMore}>
              Load More
            </button>
          </div>
        )}
      </div>

      <div id='formAskFaq' className='relative mx-auto mt-16'>
        {showForm ? (
          <div className='md:w-2/3 w-full mx-auto flex-shrink-0 grid grid-cols-1 sm:grid-cols-1 gap-12 bg-gray-100 py-8 px-8 rounded-xl'>
            <div>
              <h3 className='text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4'>Can't find it, ask us now!</h3>
              <form className='grid grid-cols-1 gap-6' action='#' method='post' onSubmit={handleSubmit}>
                <label className='block'>
                  <Label>Full name</Label>

                  <Input placeholder='Example Doe' type='text' className='mt-1' />
                </label>
                <label className='block'>
                  <Label>Email address</Label>

                  <Input type='email' placeholder='example@example.com' className='mt-1' />
                </label>
                <label className='block'>
                  <Label>Message</Label>

                  <Textarea className='mt-1' rows={6} />
                </label>
                <div>
                  {formStatus === 'success' && <p>Email sent successfully!</p>}
                  {formStatus === 'error' && <p>There was an error sending the email. Please try again.</p>}
                  <ButtonPrimary type='submit' disabled={formStatus === 'sending'}>
                    Send Message
                  </ButtonPrimary>
                  <button
                    onClick={() => {
                      setShowForm(false)
                      // make me a way to scroll 200px top
                      window.scrollBy(0, -480)
                    }}
                    className='mx-4'
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className='text-center'>
            <h3 className='text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4'>Can't find your answer?</h3>
            <button onClick={onAskNowButtonClick} className='bg-blue-500 text-white px-4 py-2 rounded-full focus:outline-none'>
              Ask now
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

interface FAQItemProps {
  faq: FAQ
  openFaqId: number | null
  setOpenFaqId: (id: number | null) => void
}

const FAQItem: React.FC<FAQItemProps> = ({ faq, openFaqId, setOpenFaqId }) => {
  const isOpen = openFaqId === faq.id

  const handleClick = () => {
    if (isOpen) {
      setOpenFaqId(null)
    } else {
      setOpenFaqId(faq.id)
    }
  }

  return (
    <div className='mb-4 border-b border-gray-200 z-10'>
      <button onClick={handleClick} className='w-full text-left py-3 focus:outline-none flex items-center justify-between  z-10'>
        <span className='text-xs sm:text-lg'>{faq.question}</span>{' '}
        {isOpen ? <ChevronUpIcon className='w-5 h-5' /> : <ChevronDownIcon className='w-5 h-5' />}
      </button>
      {isOpen && (
        <div className='flex flex-col md:flex-row py-3'>
          {faq.video && faq.imageUrl && (
            <div className='order-last md:order-first md:mr-4'>
              {faq.video ? (
                <iframe
                  width='560'
                  height='315'
                  src={faq.video}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  allowFullScreen
                  className='w-full md:w-auto'
                ></iframe>
              ) : (
                faq.imageUrl && <img src={faq.imageUrl} alt='faq' className='w-20 h-20 mb-4 md:mb-0' />
              )}
            </div>
          )}

          <div className='order-first md:order-last' dangerouslySetInnerHTML={{ __html: faq.answer }} />
        </div>
      )}
    </div>
  )
}

export default FAQSearch
