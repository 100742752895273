import FooterNav from 'components/FooterNav'
import AccountBilling from 'containers/AccountPage/AccountBilling'
import AccountSavelists from 'containers/AccountPage/AccountHistory'
import AccountPage from 'containers/AccountPage/AccountPage'
import AccountPass from 'containers/AccountPage/AccountPass'
import AuthorPage from 'containers/AuthorPage/AuthorPage'
import BlogPage from 'containers/BlogPage/BlogPage'
import BlogSingle from 'containers/BlogPage/BlogSingle'
import DonatePage from 'containers/DonatePage/DonatePage'
import FamilyQuizzesPage from 'containers/FamilyQuizzesPage/FamilyQuizzesPage'
import ListingAppealsPage from 'containers/ListingAppealsPage/ListingAppealsPage'
import ListingQuizzesPage from 'containers/ListingQuizzesPage/ListingQuizzesPage'
import Page404 from 'containers/Page404/Page404'
import PageAbout from 'containers/PageAbout/PageAbout'
import PageAreas from 'containers/PageAreas/PageAreas'
import PageAreasAdvisory from 'containers/PageAreas/PageAreasAdvisory'
import PageAreasBusiness from 'containers/PageAreas/PageAreasBusiness'
import PageAreasEducation from 'containers/PageAreas/PageAreasEducation'
import PageAreasFinance from 'containers/PageAreas/PageAreasFinance'
import PageAreasHealth from 'containers/PageAreas/PageAreasHealth'
import PageAreasTravel from 'containers/PageAreas/PageAreasTravel'
import PageContact from 'containers/PageContact/PageContact'
import PageDownloads from 'containers/PageDownloads/PageDownloads'
import FAQSearchPage from 'containers/PageFaq/PageFaq'
import PageFeatures from 'containers/PageFeatures/PageFeatures'
import PagePrivacyPolicy from 'containers/PageGenericPages/PagePrivacyPolicy'
import PageTermsConditions from 'containers/PageGenericPages/PageTermsConditions'

import PageAppPreview from 'containers/AppPreview/PageAppPreview'
import EventPage from 'containers/EventPage/EventPage'
import PageAddons from 'containers/PageAddons/PageAddons'
import PageBio from 'containers/PageBio/PageBio'
import PageHome from 'containers/PageHome/PageHome'
import PageHowItWorks from 'containers/PageHowItWorks/PageHowItWorks'
import PageInvest from 'containers/PageInvest/PageInvest'
import { PageJoinCommunity } from 'containers/PageJoinCommunity/PageJoinCommunity'
import PageLogin from 'containers/PageLogin/PageLogin'
import PageMarketplace from 'containers/PageMarketplace/PageMarketplace'
import PageProjects from 'containers/PageProjects/PageProjects'
import PageSignUp from 'containers/PageSignUp/PageSignUp'
import PageSubcription from 'containers/PageSubcription/PageSubcription'
import PageChapters from 'containers/PageSunnah/PageChapters'
import PageSunnah from 'containers/PageSunnah/PageSunnah'
import { PageTeam } from 'containers/PageTeam/PageTeam'
import PayPage from 'containers/PayPage/PayPage'
import SiteHeader from 'containers/SiteHeader'
import WelcomePage from 'containers/WelcomePage/WelcomePage'
import useWindowSize from 'hooks/useWindowResize'
import React from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import Footer from 'shared/Footer/Footer'
import ScrollToTop from './ScrollToTop'
import { Page } from './types'

export const pages: Page[] = [
  // New Pages
  { path: '/join-community', exact: true, component: PageJoinCommunity },
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  { path: '/app-preview', exact: true, component: PageAppPreview },
  { path: '/home', exact: true, component: PageHome },
  { path: '/bio', exact: true, component: PageBio },
  { path: '/projects', exact: true, component: PageProjects },
  { path: '/team', exact: true, component: PageTeam },
  // Generic Pages
  { path: '/privacy-policy', exact: true, component: PagePrivacyPolicy },
  { path: '/t&c', exact: true, component: PageTermsConditions },
  // Sunnah
  { path: '/sunnah', exact: true, component: PageSunnah },
  { path: '/sunnah/hadiths', exact: true, component: PageChapters },
  //
  { path: '/quizzes', component: ListingQuizzesPage },
  { path: '/family-quizzes', component: FamilyQuizzesPage },
  { path: '/appeals', component: ListingAppealsPage },

  { path: '/faq', component: FAQSearchPage },
  { path: '/downloads', component: PageDownloads },
  // { path: '/welcome', component: WelcomePage },
  { path: '/features', component: PageFeatures },

  //
  { path: '/donate', component: DonatePage },
  { path: '/events', component: EventPage },

  { path: '/pay-done', component: PayPage },
  //
  { path: '/author', component: AuthorPage },
  { path: '/account', component: AccountPage },
  { path: '/account-password', component: AccountPass },
  { path: '/account-history', component: AccountSavelists },
  { path: '/account-billing', component: AccountBilling },
  //
  { path: '/blog', component: BlogPage },
  { path: '/blog-single', component: BlogSingle },
  //
  { path: '/contact', component: PageContact },
  { path: '/about', component: PageAbout },
  { path: '/areas', component: PageAreas },
  { path: '/how-it-works', component: PageHowItWorks },
  { path: '/invest', component: PageInvest },
  // Areas
  { path: '/education', component: PageAreasEducation },
  { path: '/travel', component: PageAreasTravel },
  { path: '/health', component: PageAreasHealth },
  { path: '/finance', component: PageAreasFinance },
  { path: '/advisory', component: PageAreasAdvisory },
  { path: '/business', component: PageAreasBusiness },
  // Addons

  { path: '/add-ons', component: PageAddons },

  // Others
  { path: '/marketplace', component: PageMarketplace },
  { path: '/signup', component: PageSignUp },
  { path: '/login', component: PageLogin },
  { path: '/subscription', component: PageSubcription },
  { path: '/page404', component: Page404 },
  //
]

const Content = () => {
  const location = useLocation()
  const WIN_WIDTH = useWindowSize().width || window.innerWidth
  const isAppPreview = location.pathname.startsWith('/app-preview')

  return (
    <>
      {!isAppPreview && <SiteHeader />}

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component
          return <Route key={path} element={<Component />} path={path} />
        })}
        <Route element={<Page404 />} />
      </Routes>

      {!isAppPreview && WIN_WIDTH < 768 && <FooterNav />}
      {!isAppPreview && <Footer />}
    </>
  )
}

const MyRoutes = () => {
  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop />
      <Content />
    </BrowserRouter>
  )
}

export default MyRoutes
