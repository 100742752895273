import { JoinCommunityPopup } from 'containers/PageJoinCommunity/JoinCommunityPopup'
import teamImg from 'images/communityBanner.png'
import gradiantBg from 'images/flaro-assets/images/numbers/gradient.svg'
import checkImg from 'images/gradia-assets/elements/features/check2.svg'
import React from 'react'
import NcModal from 'shared/NcModal/NcModal'
import JoinCommunityForm from './JoinCommunityForm'

const JoinCommunityModal = () => {
  return <JoinCommunityForm />
}

export default function JoinCommunityTypes() {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      const yCoordinate = element.getBoundingClientRect().top + window.scrollY - 100 // minus 100px offset
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }
  return (
    <section className='relative py-32 overflow-hidden'>
      <div className='container mx-auto px-4'>
        <div className='pt-16 px-16 bg-gradient-to-r from-blue-300 via-green-200 to-yellow-300 rounded-2xl'>
          <div className='flex flex-wrap items-center -m-6 pb-16'>
            <div className='w-auto lg:w-1/2 p-6'>
              <div className='lg:max-w-xl'>
                <h2 className='mb-6 font-heading font-semibold text-3xl sm:text-6xl text-gray-900'>Authentic Guidance</h2>
                <p className='mb-10 text-gray-600 text-lg'>
                  We partnered with UK scholars to develop a technology-based system that upholds Islam, making your life easier. Find
                  authentic hadiths and consult Muftis for fiqh queries.
                </p>
                <div className='flex flex-wrap -m-1.5'>
                  <div className='w-full lg:w-auto p-1.5'>
                    <JoinCommunityPopup />
                    {/* <NcModal
                      renderTrigger={openModal => (
                        <button
                          onClick={() => openModal()}
                          className='group relative font-heading px-6 py-4 w-full lg:w-auto uppercase text-white text-xs font-semibold bg-gray-900 overflow-hidden rounded-md tracking-px'
                        >
                          <div className='absolute top-0 left-0 transform -translate-x-full group-hover:-translate-x-0 h-full w-full transition ease-in-out duration-500 bg-gray-800'></div>
                          <p className='relative z-10'>Join Community</p>
                        </button>
                      )}
                      renderContent={JoinCommunityModal}
                      modalTitle='Join our Community'
                      showCloseButton={true}
                      closeConfirmationOption={true}
                    /> */}
                  </div>
                  {/* <div className='w-full lg:w-auto p-1.5'>
                    <button
                      onClick={() => scrollToElement('joinWaitlist')}
                      className='font-heading px-6 py-4 w-full lg:w-auto uppercase text-gray-900 text-xs font-semibold bg-white hover:bg-gray-50 rounded-md tracking-px'
                    >
                      Join waitlist
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='w-auto lg:w-1/2 p-6'>
              <img className='mx-auto transform hover:-translate-x-6 transition ease-in-out duration-500' src={teamImg} alt='' />
            </div>
          </div>
          <div className='relative py-8  overflow-hidden border-t border-white border-opacity-30'>
            <img className='absolute bottom-0 left-0' src={gradiantBg} alt='' />
            <div className='container px-4 mx-auto'>
              <div className='flex flex-wrap -m-8 items-center'>
                <div className='w-full md:w-1/2 lg:w-1/4 p-8'>
                  <h2 className='text-2xl font-bold font-heading tracking-px-n leading-tight dark:text-slate-800'>
                    Learn with people around the globe
                  </h2>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-8'>
                  <div className='md:w-56'>
                    <div className='mb-3 text-2xl md:text-3xl font-bold font-heading tracking-px-n leading-tight flex items-center'>
                      <svg width='36' height='28' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M16.5 5.83333C16.3906 5.83339 16.2822 5.81188 16.181 5.77002C16.0799 5.72817 15.988 5.66678 15.9106 5.58939C15.8332 5.512 15.7719 5.42011 15.73 5.31897C15.6881 5.21784 15.6666 5.10945 15.6667 5V2.84505L10.4225 8.08916C10.3452 8.16656 10.2533 8.22796 10.1522 8.26985C10.0511 8.31175 9.94277 8.33331 9.83333 8.33331C9.7239 8.33331 9.61554 8.31175 9.51445 8.26985C9.41335 8.22796 9.3215 8.16656 9.24414 8.08916L6.50002 5.34505L2.08919 9.75583C1.93245 9.90975 1.72127 9.99555 1.50159 9.99456C1.28191 9.99356 1.07151 9.90586 0.91617 9.75052C0.760831 9.59518 0.673123 9.38478 0.672128 9.1651C0.671133 8.94542 0.756932 8.73424 0.910858 8.5775L5.91086 3.5775C5.98822 3.5001 6.08007 3.4387 6.18116 3.39681C6.28226 3.35492 6.39062 3.33335 6.50005 3.33335C6.60948 3.33335 6.71784 3.35492 6.81893 3.39681C6.92003 3.4387 7.01188 3.5001 7.08924 3.5775L9.83336 6.32161L14.4883 1.66666H12.3334C12.1123 1.66666 11.9004 1.57887 11.7441 1.42259C11.5878 1.2663 11.5 1.05434 11.5 0.833329C11.5 0.612315 11.5878 0.400352 11.7441 0.244073C11.9004 0.0877924 12.1123 -4.76837e-06 12.3334 -4.76837e-06H16.5C16.6095 -6.67572e-05 16.7179 0.0214453 16.819 0.063302C16.9201 0.105159 17.012 0.166539 17.0894 0.243935C17.1668 0.321329 17.2282 0.413218 17.2701 0.514352C17.3119 0.615484 17.3334 0.723876 17.3334 0.833329V5C17.3334 5.10945 17.3119 5.21784 17.2701 5.31897C17.2282 5.42011 17.1668 5.512 17.0894 5.58939C17.012 5.66678 16.9201 5.72817 16.819 5.77002C16.7179 5.81188 16.6095 5.83339 16.5 5.83333Z'
                          fill='#17BB84'
                        ></path>
                      </svg>
                      <span className='ml-2 dark:text-slate-900'>1500+</span>
                    </div>
                    <p className='text-xs text-gray-700 font-medium leading-normal'>Interact with others on their learning journey</p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-8'>
                  <div className='md:w-56'>
                    <div className='mb-3 text-2xl md:text-3xl font-bold font-heading tracking-px-n leading-tight flex items-center'>
                      <svg width='36' height='28' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M16.5 5.83333C16.3906 5.83339 16.2822 5.81188 16.181 5.77002C16.0799 5.72817 15.988 5.66678 15.9106 5.58939C15.8332 5.512 15.7719 5.42011 15.73 5.31897C15.6881 5.21784 15.6666 5.10945 15.6667 5V2.84505L10.4225 8.08916C10.3452 8.16656 10.2533 8.22796 10.1522 8.26985C10.0511 8.31175 9.94277 8.33331 9.83333 8.33331C9.7239 8.33331 9.61554 8.31175 9.51445 8.26985C9.41335 8.22796 9.3215 8.16656 9.24414 8.08916L6.50002 5.34505L2.08919 9.75583C1.93245 9.90975 1.72127 9.99555 1.50159 9.99456C1.28191 9.99356 1.07151 9.90586 0.91617 9.75052C0.760831 9.59518 0.673123 9.38478 0.672128 9.1651C0.671133 8.94542 0.756932 8.73424 0.910858 8.5775L5.91086 3.5775C5.98822 3.5001 6.08007 3.4387 6.18116 3.39681C6.28226 3.35492 6.39062 3.33335 6.50005 3.33335C6.60948 3.33335 6.71784 3.35492 6.81893 3.39681C6.92003 3.4387 7.01188 3.5001 7.08924 3.5775L9.83336 6.32161L14.4883 1.66666H12.3334C12.1123 1.66666 11.9004 1.57887 11.7441 1.42259C11.5878 1.2663 11.5 1.05434 11.5 0.833329C11.5 0.612315 11.5878 0.400352 11.7441 0.244073C11.9004 0.0877924 12.1123 -4.76837e-06 12.3334 -4.76837e-06H16.5C16.6095 -6.67572e-05 16.7179 0.0214453 16.819 0.063302C16.9201 0.105159 17.012 0.166539 17.0894 0.243935C17.1668 0.321329 17.2282 0.413218 17.2701 0.514352C17.3119 0.615484 17.3334 0.723876 17.3334 0.833329V5C17.3334 5.10945 17.3119 5.21784 17.2701 5.31897C17.2282 5.42011 17.1668 5.512 17.0894 5.58939C17.012 5.66678 16.9201 5.72817 16.819 5.77002C16.7179 5.81188 16.6095 5.83339 16.5 5.83333Z'
                          fill='#17BB84'
                        ></path>
                      </svg>
                      <span className='ml-2 dark:text-slate-900'>3000+</span>
                    </div>
                    <p className='text-xs text-gray-700 font-medium leading-normal'>Get personalised support and advice</p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 lg:w-1/4 p-8'>
                  <div className='md:w-56'>
                    <div className='mb-3 text-2xl md:text-3xl font-bold font-heading tracking-px-n leading-tight flex items-center'>
                      <svg width='36' height='28' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M16.5 5.83333C16.3906 5.83339 16.2822 5.81188 16.181 5.77002C16.0799 5.72817 15.988 5.66678 15.9106 5.58939C15.8332 5.512 15.7719 5.42011 15.73 5.31897C15.6881 5.21784 15.6666 5.10945 15.6667 5V2.84505L10.4225 8.08916C10.3452 8.16656 10.2533 8.22796 10.1522 8.26985C10.0511 8.31175 9.94277 8.33331 9.83333 8.33331C9.7239 8.33331 9.61554 8.31175 9.51445 8.26985C9.41335 8.22796 9.3215 8.16656 9.24414 8.08916L6.50002 5.34505L2.08919 9.75583C1.93245 9.90975 1.72127 9.99555 1.50159 9.99456C1.28191 9.99356 1.07151 9.90586 0.91617 9.75052C0.760831 9.59518 0.673123 9.38478 0.672128 9.1651C0.671133 8.94542 0.756932 8.73424 0.910858 8.5775L5.91086 3.5775C5.98822 3.5001 6.08007 3.4387 6.18116 3.39681C6.28226 3.35492 6.39062 3.33335 6.50005 3.33335C6.60948 3.33335 6.71784 3.35492 6.81893 3.39681C6.92003 3.4387 7.01188 3.5001 7.08924 3.5775L9.83336 6.32161L14.4883 1.66666H12.3334C12.1123 1.66666 11.9004 1.57887 11.7441 1.42259C11.5878 1.2663 11.5 1.05434 11.5 0.833329C11.5 0.612315 11.5878 0.400352 11.7441 0.244073C11.9004 0.0877924 12.1123 -4.76837e-06 12.3334 -4.76837e-06H16.5C16.6095 -6.67572e-05 16.7179 0.0214453 16.819 0.063302C16.9201 0.105159 17.012 0.166539 17.0894 0.243935C17.1668 0.321329 17.2282 0.413218 17.2701 0.514352C17.3119 0.615484 17.3334 0.723876 17.3334 0.833329V5C17.3334 5.10945 17.3119 5.21784 17.2701 5.31897C17.2282 5.42011 17.1668 5.512 17.0894 5.58939C17.012 5.66678 16.9201 5.72817 16.819 5.77002C16.7179 5.81188 16.6095 5.83339 16.5 5.83333Z'
                          fill='#17BB84'
                        ></path>
                      </svg>
                      <span className='ml-2 dark:text-slate-900'>4500+</span>
                    </div>
                    <p className='text-xs text-gray-700 font-medium leading-normal'>
                      Engage with authentic content relating to Fiqh, Quran and Sunnah
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
