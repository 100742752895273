import react from 'react'

import comments from 'images/customerGuideBlock.png'
import headphones from 'images/donateBlockImg.png'
import joinTeamBlockImg from 'images/joinTeamBlock.png'

const GetInvolvedBlock = () => {
  return (
    <section className='relative pt-24 pb-48 overflow-hidden '>
      {/* <img className='absolute top-0 right-0' src={radial1} alt='' />
      <img className='absolute bottom-0 left-0' src={radial2} alt='' /> */}
      <div className='relative z-10 container mx-auto px-4'>
        <div className='md:max-w-3xl md:mx-auto'>
          <div className='mb-20 max-w-xl'>
            <h2 className=' font-heading font-bold text-6xl sm:text-7xl text-white'>Get Involved</h2>
            <p className='text-2xl text-white'>Be a part of groundbreaking developments in technology and preserving the Deen.</p>
          </div>

          <div className='flex flex-wrap -m-5'>
            <div className='w-full md:w-1/2 md:relative md:top-12 p-5'>
              <div className='h-full p-9 transform hover:-translate-y-3 bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 rounded-2xl transition ease-out duration-1000'>
                <p className='mb-4 font-heading text-base text-opacity-70 dark:text-slate-800'>Schools and Communities</p>
                <h3 className='mb-20 font-heading font-bold text-3xl dark:text-slate-800 '>We work closely with Educational Institutes.</h3>
                <div>
                  <div className='pr-7'>
                    <div className='relative z-10 p-5 text-center bg-white rounded-10 shadow-3xl'>
                      <p className='mb-3 text-3xl font-bold text-black'>162.9k</p>
                      <p className='mb-3 text-xs font-bold text-black'>Last 31 Days Social Media</p>
                      <p className='text-xs text-gray-500'>10% Increase from Previous month</p>
                    </div>
                  </div>
                  <div className='-mt-14 pl-7'>
                    <div className='p-5 text-center bg-white opacity-30 rounded-10 shadow-3xl'>
                      <p className='mb-3 text-3xl font-bold text-black'>162.9k</p>
                      <p className='mb-3 text-xs font-bold text-black'>Last 7 Days Website Visits</p>
                      <p className='text-xs text-gray-500'>10% Increase from Last Week</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/2 p-5'>
              <div className='flex flex-col justify-between h-full transform hover:-translate-y-3 overflow-hidden bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 rounded-2xl transition ease-out duration-1000'>
                <div className='p-9 pb-0'>
                  <p className='mb-4 font-heading text-base text-opacity-70 dark:text-slate-800'>I want to get involved</p>
                  <h3 className='mb-5 font-heading font-bold  text-3xl dark:text-slate-800'>Help support the project </h3>
                </div>
                <img className='mx-auto w-full' src={headphones} alt='' />
              </div>
            </div>

            <div className='w-full md:w-1/2 md:relative md:top-12 p-5'>
              <div className='flex flex-col justify-between h-full transform hover:-translate-y-3 overflow-hidden bg-gradient-to-r from-rose-100 to-teal-100 rounded-2xl transition ease-out duration-1000'>
                <div className='p-9 pb-0'>
                  <p className='mb-4 font-heading text-base text-opacity-70 dark:text-slate-800'>I want to join your team</p>
                  <h3 className='mb-5 font-heading font-bold text-3xl dark:text-slate-800'>Join team</h3>
                </div>
                <img className='mx-auto w-full' src={joinTeamBlockImg} alt='' />
              </div>
            </div>

            <div className='w-full md:w-1/2 p-5'>
              <div className='h-full p-9 pb-0 bg-gradient-to-r from-teal-200 to-lime-200 transform hover:-translate-y-3 rounded-2xl transition ease-out duration-1000'>
                <p className='mb-4 font-heading text-base text-opacity-70 dark:text-slate-800'>Customer Guided</p>
                <h3 className='mb-20 font-heading font-bold text-3xl dark:text-slate-800'>Protecting Faith, Empowering Communities</h3>
                <img className='mx-auto' src={comments} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetInvolvedBlock
