import React from 'react'
import { Link } from 'react-router-dom'
import logoRgDark from 'images/logoRgDark.svg'
import logoRgLight from 'images/logoRgLight.svg'

export interface LogoProps {
  img?: string
  imgLight?: string
  className?: string
}

const Logo: React.FC<LogoProps> = ({ img = logoRgDark, imgLight = logoRgLight, className = 'w-34' }) => {
  return (
    <Link to='/' className={`flex ttnc-logo text-primary-6000 focus:outline-none focus:ring-0 ${className}`}>
      {/* <span className="text-black text-xl pl-2 w-full dark:hidden">Rightly Guided</span>
      <span className="text-white text-xl pl-2 w-full hidden dark:block">Rightly Guided</span> */}
      {img ? <img className={`block max-h-11 ${imgLight ? 'dark:hidden' : ''}`} src={img} alt='Rightly Guided' /> : 'Rightly Guided'}
      {imgLight && <img className='hidden max-h-11 dark:block' src={imgLight} alt='Rightly Guided' />}
    </Link>
  )
}

export default Logo
