import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, ReactNode, useEffect, useState } from 'react'
import Button from 'shared/Button/Button'
import ButtonClose from 'shared/ButtonClose/ButtonClose'
import NcModalConfirm from './NcModalConfirmation'

// TODO: Interface review
export interface NcModalProps {
  renderContent: () => ReactNode
  renderTrigger?: (openModal: Function) => ReactNode
  contentExtraClass?: string
  contentPaddingClass?: string
  triggerText?: ReactNode
  modalTitle?: ReactNode
  modalButton?: ReactNode
  isOpenProp?: boolean
  onCloseModal?: () => void
  closeConfirmationOption?: boolean
  showCloseButton?: boolean
  fullHeight?: boolean
  fullWidth?: boolean
}

const NcModal: React.FC<NcModalProps> = ({
  renderTrigger,
  renderContent,
  contentExtraClass = 'max-w-screen-xl',
  contentPaddingClass = 'py-4 px-6 md:py-5',
  triggerText = 'Open Modal',
  modalTitle = 'Modal title',
  modalButton = null,
  isOpenProp,
  onCloseModal,
  closeConfirmationOption,
  showCloseButton = true,
  fullHeight = false,
  fullWidth = false,
}) => {
  let [isOpen, setIsOpen] = useState(!!isOpenProp)
  let [showConfirm, setShowConfirm] = useState(false)

  let contentClass = `inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 ${contentExtraClass}`
  if (fullHeight) {
    contentClass += ' min-h-screen'
  }
  if (fullWidth) {
    contentClass = contentClass.replace('max-w-screen-xl', 'w-full')
  }

  function closeModal() {
    if (closeConfirmationOption) {
      setIsOpen(false)
      return
    }
    if (typeof isOpenProp !== 'boolean') {
      if (showConfirm) {
        setShowConfirm(false)
      } else {
        setShowConfirm(true)
      }
    } else {
      onCloseModal && onCloseModal()
    }
  }

  function openModal() {
    if (typeof isOpenProp !== 'boolean') {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    setIsOpen(!!isOpenProp)
  }, [isOpenProp])

  return (
    <>
      {renderTrigger ? renderTrigger(openModal) : <Button onClick={openModal}>{triggerText}</Button>}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={closeModal}>
          <div className='min-h-screen px-1 text-center md:px-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-75'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-75'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Overlay className='fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80 pointer-events-none' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='inline-block h-screen align-middle ' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-75'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-75'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              {showConfirm ? (
                <NcModalConfirm
                  isOpen={true}
                  onClose={() => setShowConfirm(false)}
                  onConfirm={() => {
                    setShowConfirm(false)
                    setIsOpen(false)
                    onCloseModal && onCloseModal()
                  }}
                  onCancel={() => setShowConfirm(false)}
                />
              ) : (
                <div className={contentClass}>
                  <div className=' py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5'>
                    {showCloseButton && (
                      <ButtonClose
                        onClick={closeModal}
                        className='absolute w-14 h-14 right-2 top-1/2 transform -translate-y-1/2 sm:right-4'
                      />
                    )}

                    {modalTitle && (
                      <div className='flex flex-row items-center justify-between'>
                        <Dialog.Title as='h3' className='text-base font-semibold text-neutral-900 lg:text-xl dark:text-neutral-200 mx-2'>
                          {modalTitle}
                        </Dialog.Title>
                        {modalButton && (
                          <div className='mt-4 mr-14 sm:mt-0 sm:ml-4 sm:flex-shrink-0 sm:flex sm:items-center'>
                            <Button onClick={() => modalButton + ' clicked'} className='w-full sm:w-auto bg-green-500 text-white'>
                              {modalButton}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className={contentPaddingClass}>{renderContent()}</div>
                </div>
              )}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
export default NcModal
