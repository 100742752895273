import rightImg from 'images/about-hero-right.png'
import logoRightlyGuided from 'images/rightly-guided-logo.svg'
import React, { FC } from 'react'
import SectionFounder from './SectionDownloads'
import SectionStatistic from './SectionStatistic'
import { Helmet } from 'react-helmet'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import SectionHero from './SectionHero'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import Heading2 from 'components/Heading/Heading2'

export interface PageAboutProps {
  className?: string
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id='PageAbout'>
      <Helmet>
        <title>Downloads | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionFounder />
        <div className='relative py-16'>
          <BackgroundSection />
          <SectionClientSay uniqueClassName='PageAbout_' />
        </div>

        <SectionStatistic />

        <SectionSubscribe2 />
      </div>
    </div>
  )
}

export default PageAbout
