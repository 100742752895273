import HomeHeader from 'components/Home/HomeHeader'
import SectionOurFeatures from 'components/SectionOurFeatures/SectionOurFeatures'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import SectionSubscribe3 from 'components/SectionSubscribe3/SectionSubscribe3'
import React, { useRef, useState } from 'react'
import HeaderHomeNew from './HeaderHomeNew'
// import SectionHowItWork from 'components/Home/SectionHowItWork'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import Heading from 'components/Heading/Heading'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import GetInvolvedBlock from './GetInvolvedBlock'
import PodcastsBlock from './PodcastsBlock'
import Workflow from './Workflow'

import SectionHomeAppShow from 'components/Home/SectionHomeAppShowFood'
import SectionFeatures from 'components/SectionFeatures/SectionFeatures'
import SectionFeaturesBlocks from 'components/SectionFeatures/SectionFeaturesBlocks'
import FAQSearch from 'containers/PageFaq/FAQSearch'
import { BarChart, CircleChart } from './CircleChart'
import LineChartComponent from './HomeCharts'
// import chatGptLogo from 'images/ChatGPT_logo.svg'
import YouTubeEmbed from 'components/YoutubeEmbed/YouTubeEmbed'
import TabsAppShow from './TabsAppShow'

import bgQuiz from 'images/bgQuiz.jpg'
import barChartPageViews from 'images/chartViews.svg'
import marketplaceImage from 'images/marketplaceImgRight.svg'

import AppPreview from './AppPreview'
import ResponsiveIframe from './ResponsiveIframe'
import VideoSection from './VideoSection'

import MarketplaceSection from './MarketplaceSection'

// Steps
import StartStep from './HowItWorks/StartStep'
// Import images
import JoinCommunityTypes from 'components/Community/JoinCommunityTypes'
import DonateTypes from 'components/Donate/DonateTypes'
import ProductsBlockView from 'containers/PageAbout/ProductsBlock'
import AreasSection from 'containers/PageAreas/AreasSection'
import HeaderHome from 'containers/PageAreas/HeaderHome'
import stepStampleImg from 'images/our-features.png'
import CtaJoinList from './CtaJoinList'
import JoinCommunity from './JoinCommunity'
import MarketplaceList from './MarketplaceList'
import NotifyBlock from './NotifyBlock'
import StatsBlock from './StatsBlock'

// function getDayOfWeek(daysBefore: number): string {
//   const date = new Date()
//   date.setDate(date.getDate() - daysBefore)
//   return date.toLocaleString('en-US', { weekday: 'long' })
// }

// const dataChart = [
//   { day: getDayOfWeek(5), views: 20 },
//   { day: getDayOfWeek(4), views: 120000 },
//   { day: getDayOfWeek(3), views: 250000 },
//   { day: getDayOfWeek(2), views: 750000 },
//   { day: getDayOfWeek(1), views: 800000 },
// ]

// const steps = [
//   {
//     title: 'Step 1',
//     description: 'This is the first step.',
//     // typewriterText: 'In the follow step we want to showcase a input that writes some text on it',
//   },
//   {
//     title: 'Step 2',
//     description: 'This is the second step.',
//     image: stepStampleImg,
//     typewriterText: 'On this step, we want to load multiple AI collecting all the information about the question',
//   },
//   {
//     title: 'Step 3',
//     description: 'This is the third step.',
//     image: stepStampleImg,
//     typewriterText: 'Our AI will be combining and verifying all information within our own AI and all the books available ',
//   },
//   {
//     title: 'Step 4',
//     description: 'This is the third step.',
//     image: stepStampleImg,
//     typewriterText: 'For some specific topics we will review with out community and Muftis etc',
//   },
//   {
//     title: 'Step 5',
//     description: 'Building up the content, providing images, text, videos and sources of all the knowleage collected',
//     image: stepStampleImg,
//     typewriterText: "I'm typing step 1!",
//   },
//   {
//     title: 'Step 6',
//     description: 'Next time this subject is requested, we can guarantee that is mufti approved',
//     image: stepStampleImg,
//     typewriterText: "I'm typing step 1!",
//   },
// ]

function PageHome() {
  return (
    <div className='nc-PageHome relative overflow-hidden'>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <HeaderHome />

      <JoinCommunity />

      <div className=' relative '>
        <BgGlassmorphism />
        <div className='relative'>
          <AreasSection />
        </div>

        <div className='relative py-20 md:py-15 lg:py-10 '>
          <BgGlassmorphism />
          <div className='relative'>
            <CtaJoinList />
          </div>

          <div className='relative'>
            <BgGlassmorphism />
            <div className='relative'>
              <ProductsBlockView />
            </div>
          </div>
          <BgGlassmorphism />
          <div className='relative pt-16'>
            <TabsAppShow />
          </div>
          <div className='relative'>
            {/* <BgGlassmorphism /> */}
            <JoinCommunityTypes />
          </div>
        </div>
        <div className='relative -mt-32'>
          <NotifyBlock />
        </div>
        {/* SECTION 2 */}
        {/* <div className='relative py-16'>
          <BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 ' />
          <SectionHomeAppeals
            categories={Appeals}
            categoryCardType='card4'
            itemPerRow={3}
            heading='Suggestions Appeals'
            subHeading='Populares appeals to help donate and raise more money'
            sliderStyle='style2'
            uniqueClassName='PageHome_appeals'
          />
        </div> */}

        {/* Quiz Play */}
        {/* <div className='relative py-1'> */}
        {/* <BackgroundSection className=' dark:bg-black dark:bg-opacity-20 brightness-25 rounded-xl' bgImage={bgQuiz} isRepeat='repeat' /> */}
        {/* <SectionOurFeatures /> */}
        {/* </div> */}

        {/* <div className='relative mb-24 lg:mb-32'> */}
        {/* <Heading className='text-2xl text-center mb-4' desc='What your need is here'>
            Frequent Asked Questions
          </Heading>
          <FAQSearch />
          <AppPreview /> */}
        {/* </div> */}

        <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800 dark:bg-slate-900 dark:from-slate-900 dark:via-slate-900'>
          <GetInvolvedBlock />
        </div>
        {/* <div className='relative mb-24 lg:mb-32'>
          <Workflow />
        </div> */}
        <div className='relative'>
          <DonateTypes />
        </div>
        {/* <div className='relative mb-24 lg:mb-32'>
          <Heading className='text-2xl text-center mb-4' desc='Understand how we work behind the scenes'>
            Behind the scenes
          </Heading>
          <div id='stepsWrapper' className='bg-yellow-200 px-2 py-8 rounded-xl'>
            <StartStep steps={steps} />
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default PageHome
