import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import LangDropdown from 'components/Header/LangDropdown'
import { JoinCommunityPopup } from 'containers/PageJoinCommunity/JoinCommunityPopup'
import { NAVIGATION_DEMO } from 'data/navigation'
import React from 'react'
import { NavLink } from 'react-router-dom'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import ButtonClose from 'shared/ButtonClose/ButtonClose'
import Logo from 'shared/Logo/Logo'
import SocialsList from 'shared/SocialsList/SocialsList'
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode'
import { NavItemType } from './NavigationItem'

export interface NavMobileProps {
  data?: NavItemType[]
  onClickClose?: () => void
}

const NavMobile: React.FC<NavMobileProps> = ({ data = NAVIGATION_DEMO, onClickClose }) => {
  const _renderMenuChild = (item: NavItemType) => {
    return (
      <ul className='nav-mobile-sub-menu pl-6 pb-1 text-base'>
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as='li'>
            <NavLink
              end
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex px-4 text-neutral-900 dark:text-neutral-200 text-sm font-medium rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 ${
                  isActive ? 'text-secondary' : ''
                }`
              }
            >
              <span className={`py-2.5 pr-3 ${!i.children ? 'block w-full' : ''}`}>{i.name}</span>
              {i.children && (
                <span className='flex-1 flex' onClick={e => e.preventDefault()}>
                  <Disclosure.Button as='span' className='py-2.5 flex justify-end flex-1'>
                    <ChevronDownIcon className='ml-2 h-4 w-4 text-neutral-500' aria-hidden='true' />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && <Disclosure.Panel>{_renderMenuChild(i)}</Disclosure.Panel>}
          </Disclosure>
        ))}
      </ul>
    )
  }

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={item.id} as='li' className='text-neutral-900 dark:text-white'>
        <NavLink
          end
          className={({ isActive }) =>
            `flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg ${
              isActive ? 'text-secondary' : ''
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span className={`py-2.5 pr-3 ${!item.children ? 'block w-full' : ''}`}>{item.name}</span>
          {item.children && (
            <span className='flex-1 flex' onClick={e => e.preventDefault()}>
              <Disclosure.Button as='span' className='py-2.5 flex items-center justify-end flex-1 '>
                <ChevronDownIcon className='ml-2 h-4 w-4 text-neutral-500' aria-hidden='true' />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
      </Disclosure>
    )
  }

  return (
    <div className='overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800'>
      <div className='py-6 px-5'>
        <Logo />
        <div className='flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm'>
          <span>
            Have the power of Ai doing your tasks from presenting your requirements ready for actions with simple voice or text commands.
            Saving you money & time.
          </span>

          <div className='flex justify-between items-center mt-4'>
            <SocialsList itemClass='w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300' />
            <span className='block'>
              <SwitchDarkMode className='bg-neutral-100 dark:bg-neutral-800' />
            </span>
          </div>
        </div>
        <span className='absolute right-2 top-2 p-1'>
          <ButtonClose onClick={onClickClose} />
        </span>
      </div>
      <ul className='flex flex-col py-6 px-2 space-y-1'>{data.map(_renderItem)}</ul>
      <div className='flex items-center justify-between py-6 px-5'>
        {/* <a href='/waitlist' rel='noopener noreferrer'>
          <ButtonPrimary href='/waitlist'>
            <span className='text-xs mr-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.1}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z'
                />
              </svg>
            </span>
            Join early access waitlist
          </ButtonPrimary>
        </a> */}
        {/* <a className='text-xs' href='/waitlist'>
          {' '}
          Learn more
        </a> */}
        {/* <JoinCommunityPopup /> */}
        {/* <LangDropdown panelClassName='z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0' /> */}
      </div>
    </div>
  )
}

export default NavMobile
