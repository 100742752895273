import HomeHeader from 'components/Home/HomeHeader'
import SectionOurFeatures from 'components/SectionOurFeatures/SectionOurFeatures'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import React, { useRef, useState } from 'react'
// import SectionHowItWork from 'components/Home/SectionHowItWork'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import Heading from 'components/Heading/Heading'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'

import SectionHomeAppShow from 'components/Home/SectionHomeAppShowFood'
import SectionFeatures from 'components/SectionFeatures/SectionFeatures'
import FAQSearch from 'containers/PageFaq/FAQSearch'
import { BarChart, CircleChart } from '../PageHome/CircleChart'
import LineChartComponent from '../PageHome/HomeCharts'
// import chatGptLogo from 'images/ChatGPT_logo.svg'
import YouTubeEmbed from 'components/YoutubeEmbed/YouTubeEmbed'
import TabsAppShow from '../PageHome/TabsAppShow'

import barChartPageViews from 'images/barChartPageViews.svg'
import bgQuiz from 'images/bgQuiz.jpg'
// Charts
import Header from 'components/Header/Header'
import MarketplaceList from 'containers/AppPreview/Tools/MarketplaceList'
import smallChartOne from 'images/smallChartOne.svg'
import smallChartThree from 'images/smallChartThree.svg'
import smallChartTwo from 'images/smallChartTwo.svg'
import AppPreview from '../PageHome/AppPreview'
import VideoSection from '../PageHome/VideoSection'
import AlertTop from './Dashboard/AlertTop'
import AppBuilderSection from './Dashboard/AppBuilderSection'
import DiscoverTools from './Dashboard/DiscoverTools'
import IntegrationsSection from './Dashboard/IntegrationsSection'
import NewTools from './Dashboard/NewTools'
import StatsTop from './Dashboard/StatsTop'
import HeaderPreview from './HeaderPreview'
import SideBarPreview from './SideBarPreview'

// function getDayOfWeek(daysBefore: number): string {
//   const date = new Date()
//   date.setDate(date.getDate() - daysBefore)
//   return date.toLocaleString('en-US', { weekday: 'long' })
// }

// const dataChart = [
//   { day: getDayOfWeek(5), views: 20 },
//   { day: getDayOfWeek(4), views: 120000 },
//   { day: getDayOfWeek(3), views: 250000 },
//   { day: getDayOfWeek(2), views: 750000 },
//   { day: getDayOfWeek(1), views: 800000 },
// ]

const dataFirstFeatures = [
  {
    id: 1,
    title: 'Halal restaurant recommendations',
    feature: 'AI Generated',
    content:
      'HalalAI can help you find nearby halal restaurants, and even provide recommendations based on your dietary preferences and previous reviews.',
  },
  {
    id: 2,
    title: 'Prayer time reminders',
    feature: 'AI Generated',
    content: 'HalalAI can remind you of the daily prayer times in your location, ensuring that you never miss a prayer.',
  },
  {
    id: 3,
    title: 'Halal holiday planner',
    feature: 'AI Generated',
    content:
      'HalalAI can help you plan your next vacation or trip, providing recommendations for halal-friendly destinations, hotels, and activities.',
  },
  {
    id: 4,
    title: 'Halal product scanner',
    feature: 'AI Generated',
    content:
      'HalalAI can scan the barcodes of food products and determine whether they are halal or not, making it easy to shop for halal groceries.',
  },
  {
    id: 5,
    title: 'Quranic recitation assistant',
    feature: 'AI Generated',
    content: 'HalalAI can assist with Quranic recitation, providing guidance and feedback on pronunciation and intonation.',
  },
]
const dataSecondFeatures = [
  {
    id: 1,
    title: 'Islamic finance advisor',
    feature: 'AI Generated',
    content: 'HalalAI can provide advice and guidance on Islamic finance, helping you to make ethical and halal financial decisions.',
  },
  {
    id: 2,
    title: 'Halal travel guide',
    feature: 'AI Generated',
    content:
      'HalalAI can provide tips and recommendations for halal travel, including halal-friendly airlines, airports, and travel agencies.',
  },
  {
    id: 3,
    title: 'Halal fashion recommendations',
    feature: 'AI Generated',
    content:
      'HalalAI can provide fashion recommendations that align with halal guidelines, including suggestions for modest clothing and accessories.',
  },
  {
    id: 4,
    title: 'Halal beauty tips',
    feature: 'AI Generated',
    content: 'HalalAI can provide advice and tips for halal beauty, including suggestions for halal makeup and skincare products.',
  },
  {
    id: 5,
    title: 'Halal marriage advisor',
    feature: 'AI Generated',
    content: 'HalalAI can provide advice and guidance on Islamic marriage, including suggestions for halal dating and marriage practices.',
  },
]

function PageAppPreview() {
  let headerClassName = 'shadow-sm dark:border-b dark:border-neutral-700'
  return (
    <div className='nc-PageHome relative overflow-hidden bg-slate-50 '>
      <Header className={headerClassName} navType='MainNavUser' />
      {/* <HeaderPreview /> */}
      {/* <SideBarPreview /> */}
      <div className='relateve container'>
        <AlertTop />
        <StatsTop />

        <section className='py-4 overflow-hidden'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 border rounded-xl bg-white'>
              <div className='flex flex-wrap justify-between mb-6 -m-2'>
                <div className='w-auto p-2'>
                  <h3 className='font-heading text-lg font-semibold'>Most Used Tools</h3>
                </div>
                <div className='w-auto p-2'>
                  <a
                    className='text-xs text-slate-900 text-transparent font-semibold uppercase bg-clip-text bg-gradient-purple-left'
                    href='##'
                  >
                    View all
                  </a>
                </div>
              </div>
              <MarketplaceList />
            </div>
          </div>
        </section>
        <NewTools />
        <div className='columns-2'>
          <DiscoverTools />
          <IntegrationsSection />
        </div>
      </div>
      <AppBuilderSection />
      <div className='w-full mx-auto py-20 max-w-xl text-center'></div>
    </div>
  )
}

export default PageAppPreview
