import emailjs, { init } from 'emailjs-com'
import gradiantBg from 'images/flaro-assets/images/newsletter/gradient.svg'
import React, { FC, FormEvent, useState } from 'react'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

export default function NotifyBlock() {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[0].value
    const message = 'Early Access Waitlist'

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <section className='relative pt-32 pb-24 bg-white overflow-hidden dark:bg-slate-900'>
      <img className='absolute bottom-0 left-1/2 transform -translate-x-1/2' src={gradiantBg} alt='' />
      <div className='relative z-10 container px-4 mx-auto'>
        <div className='text-center max-w-2xl mx-auto'>
          <p className='mb-5 text-sm text-indigo-600 font-semibold uppercase tracking-px'>GET NOTIFIED</p>
          <h2 className='mb-11 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight'>Stay in the Loop</h2>
          <form className='mb-11 md:max-w-md mx-auto' action='#' method='post' onSubmit={handleSubmit}>
            <div className='mb-5'>
              <input
                className='px-4 py-4 w-full text-gray-500 font-medium text-center placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                required
                type='email'
                placeholder='Your email address'
              />
            </div>
            <button
              className='py-4 px-6 w-full text-white font-semibold rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
              disabled={formStatus === 'sending'}
              type='submit'
            >
              Let me know when is ready!
            </button>
            <div className='flex items-center justify-center mt-6'>
              {formStatus === 'success' && (
                <p className='absolute mt-1 text-center'>
                  <span className=' text-green-600 rounded-xl text-base py-2 px-2 w-full'>Email sent successfully!</span>
                </p>
              )}
              {formStatus === 'error' && (
                <p className='absolute mt-1 text-center'>
                  <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                    There was an error sending the email. Please try again.
                  </span>
                </p>
              )}
            </div>
          </form>
          <ul className='-m-4'>
            <li className='inline-flex flex-wrap items-center p-4'>
              <svg className='mr-2.5' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M3.75 9.75L6.75 12.75L14.25 5.25'
                  stroke='#4F46E5'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>
              </svg>
              <span className='font-medium leading-relaxed'>Get weekly reports</span>
            </li>
            <li className='inline-flex flex-wrap items-center p-4'>
              <svg className='mr-2.5' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M3.75 9.75L6.75 12.75L14.25 5.25'
                  stroke='#4F46E5'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>
              </svg>
              <span className='font-medium leading-relaxed'>Unlimited Access</span>
            </li>
            <li className='inline-flex flex-wrap items-center p-4'>
              <svg className='mr-2.5' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M3.75 9.75L6.75 12.75L14.25 5.25'
                  stroke='#4F46E5'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                ></path>
              </svg>
              <span className='font-medium leading-relaxed'>Access to beta tests</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
