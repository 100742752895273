import dotsSideBg from 'images/saturn-assets/images/contact/dots-side-1.svg'
import dotsSideBg2 from 'images/saturn-assets/images/contact/dots-side-2.svg'
import iconEmailImg from 'images/saturn-assets/images/contact/icon-orange-email.svg'
import React, { FC, FormEvent, useState } from 'react'
import NcModal from 'shared/NcModal/NcModal'

const renderInvestMoney = () => {
  return (
    <section className='py-16 xl:pb-56 bg-white overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='text-center max-w-xl mx-auto'>
          <h2 className='mb-4 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight'>Invest with us</h2>
          <p className='mb-12 font-medium text-lg text-gray-600 leading-normal'>
            Lorem ipsum dolor sit amet, to the con adipiscing. Volutpat tempor to the condim entum.
          </p>
          <form>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-1'
                type='text'
                placeholder='First &amp; Last Name'
              />
            </label>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-2'
                type='text'
                placeholder='Email Address'
              />
            </label>
            <label className='block mb-5'>
              <input
                className='px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300'
                id='signUpInput2-3'
                type='text'
                placeholder='£500'
              />
            </label>

            <button
              className='mb-8 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200'
              type='button'
            >
              Invest Now
            </button>
            <p className='font-medium'>
              <span>Want to speak with us?</span>
              <a className='text-indigo-600 hover:text-indigo-700 ml-2' href='##'>
                Book a meeting.
              </a>
            </p>
          </form>
        </div>
      </div>
    </section>
  )
}

export default function InvestBlock() {
  return (
    <div>
      <section className='py-8 lg:pt-28 overflow-hidden dark:bg-slate-700 bg-gray-50'>
        <div className='container px-4 mx-auto'>
          <div className='mb-20 max-w-2xl mx-auto text-center'>
            <span
              className='inline-block mb-5 px-3 py-1.5 text-sm text-white uppercase tracking-tight font-semibold bg-gray-600 rounded-full'
              style={{
                background: 'url("images/basko-assets/images/gradient.png")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              Support Our Vision
            </span>
            <h2 className='font-heading text-6xl tracking-tighter'>Invest in Rightly Guided: Your Investment, Our Vision</h2>
            <p className='mb-8 text-xl text-gray-500 mt-4'>
              When you invest in Rightly Guided, you're not just investing in a project, but in the development and empowerment of the
              Muslim community. Your investment will be managed by a team of muftis, scholars, and industry experts, ensuring that your
              money is in capable hands.
            </p>
          </div>
          <div className='max-w-7xl mx-auto'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-green-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Invest or Volunteer Today
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Invest or volunteer with Rightly Guided today and be a driving force in shaping a brighter future. Your involvement
                        is not just an investment in the present, but a commitment to leaving a lasting impact for generations to come.
                        Together, let's create a legacy of empowerment and growth within the Muslim community.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-indigo-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Join Our Team & Make a Difference
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Join our team of dedicated volunteers and make a difference in the lives of millions. Your skills, time, and passion
                        can help shape the future of our community, while also gaining valuable experiences and opportunities for personal
                        growth.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-4'>
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4'>
                    <div className='relative p-8 bg-yellow-500 bg-opacity-10 rounded-lg'>
                      <h3 className='mb-4 text-2xl font-semibold' style={{ letterSpacing: '-0.5px' }}>
                        Unlock the Potential of Your Investment
                      </h3>
                      <p className='mb-9 tracking-tight'>
                        Unlock the potential of your investment and be part of a project that not only promises financial growth but also
                        brings about positive change in the Muslim community. Your support is a catalyst for progress, as we strive to
                        create a thriving ecosystem that benefits individuals, families, and society at large.
                      </p>

                      <a
                        className='relative z-10 flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200'
                        href='#'
                      >
                        <span className='mr-2.5'>Coming Soon</span>
                        <div className='inline-block'>
                          <svg width={23} height={24} viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                              stroke='currentColor'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
