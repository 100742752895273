import comingSoonImg from 'images/newImages/Business-and-Entrepreneurship.jpg'
import CharityInitiatives from 'images/newImages/Charity-Initiatives.jpg'
import CoFoundersandSpecialists from 'images/newImages/CoFounders-and-Specialists.jpg'
import ExternalAdvisors from 'images/newImages/External-Advisors.png'
import ExternalAdvisors2 from 'images/newImages/External-Advisors2.png'
import MuftiAmjadSb from 'images/newImages/Mufti-Amjad-sb.png'
import TeamofVolunteers from 'images/newImages/Team-of-Volunteers.jpg'
import test from 'images/newImages/test.png'
import React from 'react'
// import logos
import logo1 from 'images/founders-logos/logo1.png'
import logo2 from 'images/founders-logos/logo2.png'
import logo3 from 'images/founders-logos/logo3.png'
import logo4 from 'images/founders-logos/logo4.png'
import logo5 from 'images/founders-logos/logo5.png'
import logo6 from 'images/founders-logos/logo6.png'

// Import all exp logos
import logoTwo1 from 'images/logos-experiences/logo1.png'
import logoTwo10 from 'images/logos-experiences/logo10.png'
import logoTwo11 from 'images/logos-experiences/logo11.png'
import logoTwo12 from 'images/logos-experiences/logo12.png'
import logoTwo13 from 'images/logos-experiences/logo13.png'
import logoTwo14 from 'images/logos-experiences/logo14.png'
import logoTwo15 from 'images/logos-experiences/logo15.png'
import logoTwo16 from 'images/logos-experiences/logo16.png'
import logoTwo17 from 'images/logos-experiences/logo17.png'
import logoTwo18 from 'images/logos-experiences/logo18.png'
import logoTwo19 from 'images/logos-experiences/logo19.png'
import logoTwo2 from 'images/logos-experiences/logo2.png'
import logoTwo20 from 'images/logos-experiences/logo20.png'
import logoTwo21 from 'images/logos-experiences/logo21.png'
import logoTwo22 from 'images/logos-experiences/logo22.png'
import logoTwo23 from 'images/logos-experiences/logo23.jpg'
import logoTwo24 from 'images/logos-experiences/logo24.jpg'
import logoTwo3 from 'images/logos-experiences/logo3.png'
import logoTwo4 from 'images/logos-experiences/logo4.png'
import logoTwo5 from 'images/logos-experiences/logo5.png'
import logoTwo6 from 'images/logos-experiences/logo6.jpg'
import logoTwo7 from 'images/logos-experiences/logo7.png'
import logoTwo8 from 'images/logos-experiences/logo8.png'
import logoTwo9 from 'images/logos-experiences/logo9.png'

const logos = [
  logoTwo1,
  logoTwo2,
  logoTwo3,
  logoTwo4,
  logoTwo5,
  logoTwo6,
  logoTwo7,
  logoTwo8,
  logoTwo9,
  logoTwo10,
  logoTwo11,
  logoTwo12,
  logoTwo13,
  logoTwo14,
  logoTwo15,
  logoTwo16,
  logoTwo17,
  logoTwo18,
  logoTwo19,
  logoTwo20,
  logoTwo21,
  logoTwo22,
  logoTwo23,
  logoTwo24,
]

export const PageTeam = () => {
  return (
    <div>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={MuftiAmjadSb} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      Muftī Qāḍī Sayyid Amjad M Mohammed, MPhil
                    </h3>
                    <p className='text-xl mt-2 mb-2'>BSc (Hons) BMAIS PGCE PGDipRes PGCHEP FHEA NPQH</p>
                    <p className='tracking-tight'>
                      Mufti Amjad M. Mohammed is the visionary behind Rightly Guided. With his profound knowledge of Islamic teachings and
                      extensive experience in Islamic education, he leads the way in shaping our platform to align with the principles of
                      Islam. His expertise and guidance provide the foundation for a holistic and spiritually enriching experience within
                      the Rightly Guided community.
                    </p>
                    <div className='max-w-lg mt-8'>
                      <img className='' src={ExternalAdvisors2} alt='' />
                    </div>
                    <a
                      className='mt-4 nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 '
                      rel='noopener noreferrer'
                      href='/bio'
                    >
                      View Bio
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={CoFoundersandSpecialists} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      Co-Founders and Specialists
                    </h3>
                    <p className='tracking-tight'>
                      Rightly Guided was co-founded by a diverse team of experts and specialists who bring their unique skills and
                      experiences to the table. Our team consists of tech developers, AI specialists, cybersecurity experts, fintech and
                      finance specialists, business consultants, social media strategists, entrepreneurs, investors, and even celebrities.
                      Together, we combine our talents to create an innovative platform that caters to the diverse needs of our community.
                      In Phase 3, we will be revealing more about our team members, showcasing their contributions to the development of
                      Rightly Guided.
                    </p>
                    <div className='flex w-full flex-wrap justify-around items-center mt-6'>
                      <img className='w-24' src={logo1} alt='' />
                      <img className='w-24' src={logo2} alt='' />
                      <img className='w-24' src={logo3} alt='' />
                      <img className='w-24' src={logo4} alt='' />
                      <img className='w-24 p-4' src={logo5} alt='' />
                      <img className='w-24' src={logo6} alt='' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={TeamofVolunteers} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      Team of Volunteers
                    </h3>
                    <p className='tracking-tight'>
                      We value the active participation of our community members, and that's why we have a dedicated team of volunteers.
                      Joining our volunteer program offers numerous benefits, including subsidized access to our courses, exclusive
                      opportunities, and the chance to contribute to the growth and development of Rightly Guided. As a volunteer, you will
                      have the opportunity to engage with like-minded individuals, gain valuable experience, and make a positive impact
                      within our community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={ExternalAdvisors} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      External Advisors
                    </h3>
                    <p className='tracking-tight'>
                      To ensure that Rightly Guided remains at the forefront of innovation and offers the best services to our community, we
                      work closely with external advisors. These advisors come from various fields, mirroring the diverse range of expertise
                      within our co-founding team. Their valuable insights, feedback, and independent consultancy help us continuously
                      evolve and improve our platform. We engage with internationally renowned business owners, collaborate with chambers of
                      commerce, and have partnerships with trade departments in various countries. This global perspective allows us to
                      cater to the needs of our community on a broader scale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={comingSoonImg} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      Coming Soon: Community Board and Thought Leaders
                    </h3>
                    <p className='tracking-tight'>
                      We are in the process of finalising our Community Board, which will consist of thought leaders and industry experts.
                      Their role will be to manage and engage with the community groups within Rightly Guided, ensuring that we remain
                      different and results-oriented. The Community Board will actively listen to feedback and suggestions from the
                      community, bringing them to the attention of the board and external teams. This collaborative approach will help us
                      develop new features, products, and services that truly meet the evolving needs of our community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-24 lg:py-40 overflow-hidden'>
        <div className='container px-4 mx-auto'>
          <div className='flex flex-wrap items-center -m-8'>
            <div className='w-full md:w-1/2 p-8'>
              <div className='max-w-max mx-auto'>
                <img className='transform hover:-translate-y-2 transition duration-500' src={CharityInitiatives} alt='' />
              </div>
            </div>
            <div className='w-full md:w-1/2 p-8'>
              <div className=''>
                <div className='flex flex-wrap -m-6'>
                  <div className='w-auto p-6'>
                    <h3 className='mb-4 text-3xl font-semibold tracking-tighter' style={{ letterSpacing: '-0.5px' }}>
                      Charity Initiatives
                    </h3>
                    <p className='tracking-tight'>
                      At Rightly Guided, we prioritize giving back and impacting positively. We're partnering with charities and launching
                      our initiatives to aid significant causes and impact lives. Stay updated with our charity projects promoting
                      compassion and social responsibility.
                    </p>
                    <p className='tracking-tight mt-4'>
                      Our team, volunteers, advisors, and upcoming community board are key to Rightly Guided's growth. United, we strive to
                      offer a platform that fosters spiritual, educational, and entrepreneurial empowerment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-28 pb-32 bg-white overflow-hidden'>
        <h3 className='text-2xl w-full mb-20 text-center font-semibold'>Our Experiences</h3>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap items-center justify-center -m-10'>
            {logos.map((logo, i) => (
              <div key={i} className='w-auto p-2 max-w-[120px] max-h-[120px] flex items-center justify-center'>
                <img src={logo} alt='' className='p-2 object-contain max-h-full max-w-full' />
              </div>
            ))}
            h
          </div>
        </div>
      </section>
    </div>
  )
}
