import iphone2 from 'images/joinCommunityApp.png'
import bgImg from 'images/joinCommunityBg.png'
import react from 'react'
import Button from 'shared/Button/Button'
import ButtonPrimary from 'shared/Button/ButtonPrimary'

const JoinCommunity = () => {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      const yCoordinate = element.getBoundingClientRect().top + window.scrollY - 100 // minus 100px offset
      window.scrollTo({ top: yCoordinate, behavior: 'smooth' })
    }
  }
  return (
    <section className='pt-20 pb-28 bg-white dark:bg-slate-900 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <h2 className='text-5xl md:text-7xl text-center font-bold dark:text-white dark:text-opacity-90 font-heading font-heading tracking-px-n leading-tight md:max-w-lg mx-auto'>
          Join our community
        </h2>
      </div>
      <div className='relative'>
        <img className='w-full' src={bgImg} alt='' />

        <img
          className='absolute top-1/2 w-3/12 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-9 mx-auto hover:scale-105 transition ease-in-out duration-1000'
          src={iphone2}
          alt=''
        />
      </div>
      <div className='container px-4 mx-auto'>
        <p className='mb-9 text-lg text-gray-600 text-center font-medium leading-normal md:max-w-xl mx-auto'>
          Become part of the Rightly Guided community to gain early access and help us shape the perfect product for you.
        </p>
        <div className='flex items-center justify-center space-x-4'>
          <ButtonPrimary href='/#products' onClick={() => scrollToElement('products')}>
            <span className='text-xs mr-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6'
                />
              </svg>
            </span>
            View Products
          </ButtonPrimary>
          <Button href='/how-it-works' onClick={() => scrollToElement('howitworks')}>
            <span className='text-xs mr-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.1}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z'
                />
              </svg>
            </span>
            How it Works
          </Button>
        </div>
      </div>
    </section>
  )
}

export default JoinCommunity
