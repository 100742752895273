import { useState } from 'react'

const API_URL = 'https://sunnah-server.herokuapp.com'
const API_KEY = 'SqD712P3E82xnwOAEOkGd5JZH8s9wRR24TqNFzjk'

export const useFetchCollections = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [collections, setCollections] = useState([])

  const fetchCollections = () => {
    setIsLoading(true)
    fetch(`${API_URL}/collections`, {
      headers: {
        'X-API-Key': API_KEY,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('HTTP error ' + response.status)
        }
      })
      .then(result => {
        setCollections(result.data)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        // Handle the error
      })
  }

  return { fetchCollections, collections, isLoading }
}
