import { error } from 'console'
import React, { useState } from 'react'

interface AboutYou {
  firstName: string
  lastName: string
  email: string
}

interface AboutYouProps {
  onAboutYouDetailsChange: (aboutYouDetails: AboutYou) => void
  goToNextStep: () => void
}

const AboutYou: React.FC<AboutYouProps> = ({ onAboutYouDetailsChange, goToNextStep }) => {
  const [formState, setFormState] = useState<AboutYou>({ firstName: '', lastName: '', email: '' })

  const handleInputChange = (field: keyof AboutYou) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState(prev => ({ ...prev, [field]: e.target.value }))
  }

  const handleNext = () => {
    const { firstName, lastName } = formState
    if (firstName.length >= 2 && lastName.length >= 2 && firstName.length <= 30 && lastName.length <= 30) {
      onAboutYouDetailsChange(formState)
      // handle the next logic here
      goToNextStep()
    } else {
      // ! handle the validation failure here
      console.log('error no fields ')
    }
  }

  const handleClear = () => {
    setFormState({ firstName: '', lastName: '', email: '' })
  }

  return (
    <div className='flex flex-wrap max-w-2xl pb-11 -m-1'>
      <div className='w-full sm:w-1/2 p-1'>
        <label htmlFor='firstNameInput' className='block text-xs font-bold mb-1'>
          First Name
        </label>
        <input
          className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
          id='firstNameInput'
          type='text'
          placeholder='First Name'
          maxLength={30}
          value={formState.firstName}
          onChange={handleInputChange('firstName')}
        />
      </div>
      <div className='w-full sm:w-1/2 p-1'>
        <label htmlFor='lastNameInput' className='block text-xs font-bold mb-1'>
          Last Name
        </label>
        <input
          className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
          id='lastNameInput'
          type='text'
          placeholder='Last Name'
          maxLength={30}
          value={formState.lastName}
          onChange={handleInputChange('lastName')}
        />
      </div>
      <div className='w-full sm:w-1/2 p-1'>
        <label htmlFor='emailInput' className='block text-xs font-bold mb-1'>
          Email
        </label>
        <input
          className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
          id='emailInput'
          type='text'
          placeholder='Email'
          value={formState.email}
          onChange={handleInputChange('email')}
        />
      </div>
      <div className='w-full flex items-center justify-start sm:justify-start p-2 py-4 space-x-4'>
        <a className='' href='##' onClick={handleClear}>
          Clear
        </a>
        <a
          className='inline-block w-full lg:w-auto py-4 px-8 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
          href='##'
          onClick={handleNext}
        >
          Save
        </a>
      </div>
    </div>
  )
}

export default AboutYou
