import { Popover, Transition } from '@headlessui/react'
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
} from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Avatar from 'shared/Avatar/Avatar'

const solutions = [
  {
    name: 'Account',
    href: '/account',
    icon: UserCircleIcon,
  },
  {
    name: 'Notifications',
    href: '#',
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: 'History',
    href: '#',
    icon: HeartIcon,
  },
  {
    name: 'Appeals',
    href: '##',
    icon: HomeIcon,
  },
]

const solutionsFoot = [
  {
    name: 'Logout',
    href: '##',
    icon: ArrowRightOnRectangleIcon,
  },
]

export default function AvatarDropdown() {
  function logout() {
    // remove user and jwt data from local storage and local session
    window.sessionStorage.removeItem('user')
    window.sessionStorage.removeItem('jwt')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('jwt')
    window.location.reload()
  }

  const getUserDetails = () => {
    // get the username from the session storage saved ans user and the array key is name
    const userData = window.sessionStorage.getItem('user')
    if (userData) {
      // turn the user into json abject
      const nameJson = JSON.parse(userData)
      // get the name from the json object
      const nameString = nameJson.name
      console.log('name', nameString)

      return getUserNameInitials(nameString)
    }
  }

  const getUserNameInitials = (name: string) => {
    console.log('name', name)
    const nameArray = name.split(' ')
    let initials = ''

    if (nameArray.length === 1) {
      initials = nameArray[0].charAt(0)
    } else {
      initials = nameArray[0].charAt(0) + nameArray[1].charAt(0)
    }

    return initials.toUpperCase()
  }

  return (
    <div className='AvatarDropdown'>
      <Popover className='relative'>
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <div
                className={`w-12 h-12 relative flex justify-center items-center rounded-full font-semibold bg-blue-500 text-xl text-white uppercase`}
              >
                <span className=''>{getUserDetails()}</span>
              </div>
              {/* <Avatar sizeClass='w-8 h-8 sm:w-9 sm:h-9' /> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0'>
                <div className='overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative grid gap-6 bg-white dark:bg-neutral-800 p-7'>
                    {solutions.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className='flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50'
                      >
                        <div className='flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300'>
                          <item.icon aria-hidden='true' className='w-6 h-6' />
                        </div>
                        <div className='ml-4'>
                          <p className='text-sm font-medium '>{item.name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <hr className='h-[1px] border-t border-neutral-300 dark:border-neutral-700' />
                  <div className='relative grid gap-6 bg-white dark:bg-neutral-800 p-7'>
                    <a
                      onClick={logout}
                      className='flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer'
                    >
                      <div className='flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300'>
                        <ArrowRightOnRectangleIcon aria-hidden='true' className='w-6 h-6' />
                      </div>
                      <div className='ml-4'>
                        <p className='text-sm font-medium '>Logout</p>
                      </div>
                    </a>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}
