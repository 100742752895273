import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import HomeHeader from 'components/Home/HomeHeader'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import barChartPageViews from 'images/chartViews.svg'
import collectinAiImg from 'images/collecting-AI.svg'
import ourAiImg from 'images/our-AI.svg'
import arroww, { default as arrowImg, default as otherArrow } from 'images/saturn-assets/images/features/arrow.svg'
import blueImg from 'images/saturn-assets/images/features/blue-photo.png'
import starImg from 'images/saturn-assets/images/features/star-element-right.png'
import stepOne from 'images/step-1.png'
import stepTwo from 'images/step-2.png'
import React, { FC, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import AboutHowItWorks from '../PageAbout/AboutHowItWorks'
import AboutFeatures from './AboutFeatures'
import AboutFeaturesExtra from './AboutFeaturesExtra'

import DonateTypes from 'components/Donate/DonateTypes'
import GetInvolvedBlock from 'containers/PageHome/GetInvolvedBlock'
import AboutIntro from './AboutIntro'
import AboutTextBlock from './AboutTextBlock'
import AreasSection from './AreasSection'
import CommunityBlock from './CommunityBlock'
import DonateBlock from './DonateBlock'
import ProductsBlockView from './ProductsBlock'
import SectionFounder from './SectionFounder'
import SectionStatistic from './SectionStatistic'

export interface PageAboutProps {
  className?: string
}

// interface Block {
//   id: number
//   image: string
//   paragraphs: string[]
// }

// interface BlockComponentProps {
//   blocks: Block[]
// }

// const blocks = [
//   // Populate with your own data
//   {
//     id: 1,
//     image: logoRightlyGuided,
//     paragraphs: [
//       'Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1Text 1',
//       'Text 2',
//       'Text 3',
//     ],
//   },
//   { id: 2, image: logoRightlyGuided, paragraphs: ['Text 4', 'Text 5', 'Text 6'] },
// ]

const PageAreas: FC<PageAboutProps> = ({ className = '' }) => {
  return (
    <div className={`nc-PageAreas overflow-hidden relative ${className}`} data-nc-id='PageAreas'>
      <Helmet>
        <title>Areas | Rightly Guided </title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* ======== TESTS ======== */}
      {/* <BlockComponent blocks={blocks} /> */}
      {/* ======== TESTS ======== */}
      {/* <AboutTextBlock /> */}
      {/* <AboutFeatures />
      <AboutFeaturesExtra />
      <AboutHowItWorks />
      <AboutIntro />
      <CommunityBlock />
      <ProductsBlockView />
      <DonateBlock /> */}
      <div className='lg:py-28'>
        {/* TODO: Home Header */}
        {/* <HomeHeader className='py-20 md:py-15 lg:py-10 ' /> */}

        <AreasSection />

        <div className='relative py-16'>
          <AboutHowItWorks />
        </div>
        <div className='relative bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-slate-500 via-slate-600 to-slate-800'>
          <GetInvolvedBlock />
        </div>
        <div className='relative'>
          <DonateTypes />
        </div>
        {/* <div className='relative py-16'>
          <BackgroundSection />
          <SectionClientSay uniqueClassName='PageAbout_' />
        </div> */}
      </div>
    </div>
  )
}

export default PageAreas
