import emailjs, { init } from 'emailjs-com'
import gradiantBg from 'images/saturn-assets/images/sign-up/background-gradient-color.png'
import React, { FC, FormEvent, useState } from 'react'

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const countryList: string[] = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo (Congo-Brazzaville)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia (Czech Republic)',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini (fmr. "Swaziland")',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (formerly Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

const CtaJoinList = () => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[1].value
    const message = `Early Access Waitlist | ${(e.target as any)[2].value}`

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <section id='joinWaitlist' className='relative pt-6 px-4 lg:px-6 pb-20 md:pb-32 overflow-hidden'>
      <div className="relative max-w-7xl pt-12 sm:pt-28 mx-auto bg-[url('images/saturn-assets/images/sign-up/background-gradient-color.png')] bg-no-repeat bg-auto bg-top rounded-3xl">
        {/* <img className='absolute top-0 left-0 object-contain' src={gradiantBg} alt='' /> */}
        <h3 className='font-heading text-4xl text-white font-semibold mb-4 text-center'>Get Early Access</h3>
        <p className='text-lg text-white mb-10 text-center'>Join our community and be amogst the first to use our Rightly Guided Systems</p>
        <div className='relative container px-4 mx-auto'>
          <div className='max-w-lg md:max-w-xl py-14 px-6 xs:px-12 lg:px-16 mx-auto bg-white rounded-xl shadow-lg'>
            <form action='#' onSubmit={handleSubmit} method='post'>
              <div className='mb-6'>
                <label className='block mb-1.5 text-sm text-gray-900 font-semibold' htmlFor=''>
                  Name
                </label>
                <input
                  className='w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg'
                  type='text'
                  required
                  placeholder='Your Name'
                />
              </div>
              <div className='mb-6'>
                <label className='block mb-1.5 text-sm text-gray-900 font-semibold' htmlFor=''>
                  Email
                </label>
                <input
                  className='w-full py-3 px-4 text-sm text-gray-900 placeholder-gray-400 border border-gray-200 focus:border-purple-500 focus:outline-purple rounded-lg'
                  type='email'
                  required
                  placeholder='email@google.com'
                />
              </div>
              <div className='mb-6'>
                <label className='block mb-1.5 text-sm text-gray-900 font-semibold' htmlFor=''>
                  Country
                </label>
                <div className='relative'>
                  <select
                    className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
                    id='grid-state'
                    required
                  >
                    {countryList.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                className='relative group block w-full mb-6 py-3 px-5 text-center text-sm font-semibold text-orange-50 bg-slate-700 rounded-full overflow-hidden'
                type='submit'
                disabled={formStatus === 'sending'}
              >
                <div className='absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500'></div>
                <span className='relative'>Join waitlist</span>
              </button>

              <div className='text-center'>
                <span className='text-xs font-semibold text-gray-900'>
                  <span>Already joined our waitlist?</span>
                  <a className='inline-block ml-1 text-orange-900 hover:text-orange-700' href='##'>
                    Click here
                  </a>
                </span>
              </div>
              <div className='flex items-center justify-center mt-6'>
                {formStatus === 'success' && (
                  <p className='absolute mt-1 text-center'>
                    <span className=' text-green-600 rounded-xl text-base py-2 px-2 w-full'>Email sent successfully!</span>
                  </p>
                )}
                {formStatus === 'error' && (
                  <p className='absolute mt-1 text-center'>
                    <span className=' text-red-500 rounded-xl text-xs12 py-2 px-2 w-full'>
                      There was an error sending the email. Please try again.
                    </span>
                  </p>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CtaJoinList
