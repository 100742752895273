import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import JoinCommunityForm from 'components/Community/JoinCommunityForm'
import VideoSection from 'containers/PageHome/VideoSection'
import { JoinCommunityPopup } from 'containers/PageJoinCommunity/JoinCommunityPopup'
import lineImg from 'images/gradia-assets/elements/hero/line.svg'
import videoImg from 'images/gradia-assets/images/hero/dashboard.png'
import videoPlayBehind from 'images/video-play-behind.png'
import videoThumbDesktop from 'images/videoThumbDesktop.png'
import videoThumbMobile from 'images/videoThumbMobile.png'
import react from 'react'
import NcModal from 'shared/NcModal/NcModal'

const JoinCommunityModal = () => {
  return <JoinCommunityForm />
}

const AboutHowItWorks = () => {
  return (
    <section className='relative'>
      <div className='relative z-10 overflow-hidden py-20 sm:py-44'>
        <div className='container px-4 mx-auto'>
          <div className='text-center'>
            <p className='mb-5 text-base text-slate-700 font-semibold uppercase tracking-px'>👋 Welcome to Rightly Guided</p>
            <h1 className='mb-9 text-2xl md:text-5xl xl:text-10xl font-bold font-heading tracking-px-n leading-none'>
              Building the world’s first fully authenticated Islamic AI
            </h1>
            <div className='mb-7 md:inline-block'>
              <JoinCommunityPopup />
              {/* <NcModal
                renderTrigger={openModal => (
                  <button
                    className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
                    type='button'
                    onClick={() => openModal()}
                  >
                    Join our Community
                  </button>
                )}
                renderContent={JoinCommunityModal}
                modalTitle='Join our Community'
                showCloseButton={true}
                closeConfirmationOption={true}
              /> */}
            </div>
            <div className='mb-16 block'>
              <a className='inline-block text-black hover:text-gray-800' href='/#products'>
                <div className='flex flex-wrap items-center -m-1.5'>
                  <div className='w-auto p-1.5'>
                    <svg width='11' height='13' viewBox='0 0 11 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M4.18003 11.4261C2.8586 12.3918 1 11.448 1 9.81135V3.43865C1 1.80198 2.8586 0.858209 4.18003 1.82387L8.5403 5.01022C9.63359 5.80916 9.63359 7.44084 8.5403 8.23978L4.18003 11.4261Z'
                        stroke='currentColor'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>
                    </svg>
                  </div>
                  <div className='w-auto p-1.5'>
                    <p className='font-medium'>See how the magic happens</p>
                  </div>
                </div>
              </a>
            </div>
            <div className='relative max-w-6xl sm:hidden mx-auto'>
              <VideoSection
                browserFrame={false}
                src='https://storage.googleapis.com/videos-rg/RG-mobile-light.m4v'
                thumbnail={videoThumbMobile}
              />
            </div>
            <div className='relative max-w-6xl hidden sm:block mx-auto'>
              <VideoSection
                browserFrame={false}
                src='https://storage.googleapis.com/videos-rg/RG-desktop-light.m4v'
                thumbnail={videoThumbDesktop}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutHowItWorks
