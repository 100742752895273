import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'
import Heading from 'components/Heading/Heading'
import Label from 'components/Label/Label'
import SectionClientSay from 'components/SectionClientSay/SectionClientSay'
import SectionFeatures from 'components/SectionFeatures/SectionFeatures'
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2'
import TabsAppShow from 'containers/PageHome/TabsAppShow'
import VideoSection from 'containers/PageHome/VideoSection'
import emailjs, { init } from 'emailjs-com'
import logosFeatures from 'images/logos-features.svg'
import React, { FC, FormEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSwipeable } from 'react-swipeable'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import Input from 'shared/Input/Input'
import NcModal from 'shared/NcModal/NcModal'
import Textarea from 'shared/Textarea/Textarea'

export interface PageContactProps {
  className?: string
}

interface Feature {
  title: string
  description: string
  image: string
}

interface FeatureSliderProps {
  features: Feature[]
}

const dataFirstFeatures = [
  {
    id: 1,
    title: 'Halal restaurant recommendations1',
    feature: 'AI Generated',
    image: 'https://i.imgur.com/3QXQfQb.png',
    content:
      'HalalAI can help you find nearby halal restaurants, and even provide recommendations based on your dietary preferences and previous reviews.',
  },
  {
    id: 2,
    title: 'Prayer time reminders',
    feature: 'AI Generated',
    image: 'https://i.imgur.com/3QXQfQb.png',
    content: 'HalalAI can remind you of the daily prayer times in your location, ensuring that you never miss a prayer.',
  },
  {
    id: 3,
    title: 'Halal holiday planner',
    feature: 'AI Generated',
    image: 'https://i.imgur.com/3QXQfQb.png',
    content:
      'HalalAI can help you plan your next vacation or trip, providing recommendations for halal-friendly destinations, hotels, and activities.',
  },
  {
    id: 4,
    title: 'Halal product scanner',
    feature: 'AI Generated',
    image: 'https://i.imgur.com/3QXQfQb.png',
    content:
      'HalalAI can scan the barcodes of food products and determine whether they are halal or not, making it easy to shop for halal groceries.',
  },
  {
    id: 5,
    title: 'Quranic recitation assistant',
    feature: 'AI Generated',
    image: 'https://i.imgur.com/3QXQfQb.png',
    content: 'HalalAI can assist with Quranic recitation, providing guidance and feedback on pronunciation and intonation.',
  },
]

const FeatureSlider: React.FC<FeatureSliderProps> = ({ features }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const goToPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const goToNext = () => {
    if (currentIndex < features.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: goToNext,
    onSwipedRight: goToPrevious,
  })

  const currentFeature = features[currentIndex]

  return (
    <div {...swipeHandlers} className='container'>
      <div className='mx-8 flex items-center w-full'>
        <img className='w-1/6 h-auto' src={currentFeature.image} alt={currentFeature.title} />
        <div className='ml-6'>
          <h2 className='text-2xl font-bold mb-2'>{currentFeature.title}</h2>
          <p>{currentFeature.description}</p>
        </div>
      </div>
      <div className='mx-8 flex items-center justify-between w-full'>
        <div>
          {currentIndex > 0 && (
            <button className='px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md' onClick={goToPrevious}>
              Previous
            </button>
          )}
        </div>
        <div>
          {currentIndex < features.length - 1 && (
            <button className='px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-md' onClick={goToNext}>
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

// Initialize EmailJS with your User ID
init('2qh1QWAT9R1p4g-Wx')

const PageFeatures: FC<PageContactProps> = ({ className = '' }) => {
  const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle')
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormStatus('sending')

    const name = (e.target as any)[0].value
    const email = (e.target as any)[1].value
    const message = (e.target as any)[2].value

    const templateParams = {
      name,
      email,
      message,
    }

    try {
      await emailjs.send('rightlyguidedSmtpId', 'earlyAccessRG', templateParams)
      setFormStatus('success')
    } catch (error) {
      setFormStatus('error')
    }
  }

  return (
    <div className={`nc-PageContact relative overflow-hidden ${className}`} data-nc-id='PageContact'>
      <Helmet>
        <title>Features | Rightly Guided </title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative space-y-20 mb-14 lg:space-y-24 lg:mb-24'>
        <Heading className='text-2xl pt-20 md:pt-15 lg:pt-10 text-center' desc='To provide you the most advanced Ai assistant in the world'>
          Some of our features
        </Heading>
        {/* SECTION 1 */}
        <SectionFeatures hasTitle={false} className='' uniqueClassName='home_' />

        <div className='w-full container text-xl text-center'>
          <BgGlassmorphism />
          <TabsAppShow />
        </div>
        <div className='w-full text-xl text-center'>
          <Heading className='text-2xl' desc='RG is unifying all the major AI models including our own private Shariah-moderated AI. '>
            Title For this one
          </Heading>
          <VideoSection
            src='https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
            thumbnail='https://storage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg'
          />
          {/* <YouTubeEmbed
            url='https://www.youtube.com/watch?v=K4TOrB7at0Y'
            height='auto'
            width='100%'
            minHeight={220}
            className='md:min-h-auto sm:min-h-440 pt-8'
          /> */}
        </div>

        <div className='relative mx-auto my-16'>
          <div className='md:w-2/3 w-full mx-auto flex-shrink-0 grid grid-cols-1 sm:grid-cols-1 gap-12 bg-orange-100 py-8 px-8 rounded-xl'>
            <div>
              <h3 className='text-2xl font-semibold text-neutral-900 dark:text-neutral-100 text-center mb-4'>Join our Waitlist</h3>
              <form className='grid grid-cols-1 gap-6' action='#' method='post' onSubmit={handleSubmit}>
                <label className='block'>
                  <Label>Full name</Label>

                  <Input placeholder='Example Doe' type='text' className='mt-1' />
                </label>
                <label className='block'>
                  <Label>Email address</Label>

                  <Input type='email' placeholder='example@example.com' className='mt-1' />
                </label>
                <label className='block'>
                  <Label>Message</Label>

                  <Textarea className='mt-1' rows={6} />
                </label>
                <div>
                  {formStatus === 'success' && <p>Email sent successfully!</p>}
                  {formStatus === 'error' && <p>There was an error sending the email. Please try again.</p>}
                  <ButtonPrimary type='submit' disabled={formStatus === 'sending'}>
                    Send Message
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='relative py-20 md:py-15 lg:py-10 '>
          <BackgroundSection className='bg-orange-50 dark:bg-black dark:bg-opacity-20 brightness-25' />
          <SectionSubscribe2 />
        </div>

        <div className='relative'>
          <div className='relative py-16'>
            {/* <BackgroundSection /> */}
            <SectionClientSay uniqueClassName='Pagecontact_' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageFeatures
