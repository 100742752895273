import React, { useEffect, useState } from 'react'

type Service = {
  id: number
  title: string
  image: string
  url: string
  category: string
  price: number
  description: string
  tooltip: string
}

const services: Service[] = [
  {
    id: 1,
    title: 'Web Development',
    image: 'images/basko-assets/images/blog/workflow.jpg',
    url: '#',
    category: 'Development',
    price: 1000,
    description: 'Professional Web Development',
    tooltip: 'Click for more info',
  },
  {
    id: 2,
    title: 'App Development',
    image: 'images/basko-assets/images/blog/news.jpg',
    url: '#',
    category: 'Development',
    price: 2000,
    description: 'Professional App Development',
    tooltip: 'Click for more info',
  },
  {
    id: 3,
    title: 'Web Development',
    image: 'images/basko-assets/images/blog/workflow.jpg',
    url: '#',
    category: 'Development',
    price: 1000,
    description: 'Professional Web Development',
    tooltip: 'Click for more info',
  },
  {
    id: 4,
    title: 'App Development',
    image: 'images/basko-assets/images/blog/news.jpg',
    url: '#',
    category: 'Development',
    price: 2000,
    description: 'Professional App Development',
    tooltip: 'Click for more info',
  },
  {
    id: 5,
    title: 'Web Development',
    image: 'images/basko-assets/images/blog/workflow.jpg',
    url: '#',
    category: 'Development',
    price: 1000,
    description: 'Professional Web Development',
    tooltip: 'Click for more info',
  },
  {
    id: 6,
    title: 'App Development',
    image: 'images/basko-assets/images/blog/news.jpg',
    url: '#',
    category: 'Development',
    price: 2000,
    description: 'Professional App Development',
    tooltip: 'Click for more info',
  },
]

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default function ServicesList() {
  const [currentService, setCurrentService] = useState(0)

  // handle the mobile
  const { width } = useWindowSize()

  // Return null or a loading spinner until we have a valid width
  if (width === undefined) {
    return null
  }

  const isMobile = width <= 768
  const itemsToShow = isMobile ? 1 : 2

  const handleNext = () => {
    setCurrentService(currentService >= services.length - itemsToShow ? 0 : currentService + 1)
  }

  const handlePrev = () => {
    setCurrentService(currentService === 0 ? services.length - itemsToShow : currentService - 1)
  }

  return (
    <section className='py-24 lg:py-32 bg-gray-100 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='flex flex-wrap -m-4'>
          <div className='w-full md:w-1/2 lg:w-1/3 p-4'>
            <div className='flex flex-col justify-between h-full md:max-w-xs'>
              <div className='mb-12'>
                <h2 className='font-heading mb-4 text-6xl tracking-tighter'>Services</h2>
                <p className='text-xl tracking-tight'>Some of our most wanted services available for you!</p>
              </div>
              <a className='flex items-center font-semibold hover:text-indigo-500 tracking-tight transition duration-200' href='#'>
                <span className='mr-2.5'>View all services</span>
                <div className='inline-block'>
                  <svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M8.95693 7.75693L15.5566 7.75693M15.5566 7.75693L15.5566 14.3566M15.5566 7.75693L7.07132 16.2422'
                      stroke='currentColor'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
          <div className='mx-auto'>
            <div className='flex flex-wrap -m-4'>
              {services.slice(currentService, currentService + itemsToShow).map(service => (
                <div className='w-full md:w-1/2  p-4' key={service.id}>
                  <a
                    className='group block p-6 bg-white rounded-lg transform hover:-translate-y-2 transition duration-500'
                    href={service.url}
                    title={service.tooltip}
                  >
                    <img className='mb-6 rounded-lg w-full' src={service.image} alt={service.title} />
                    <h3 className='mb-6 text-3xl font-semibold tracking-tight group-hover:underline'>{service.title}</h3>
                    <p className='font-medium'>
                      <span>{service.category}</span>
                      <span className='text-gray-400'>/</span>
                      <span>{service.price}</span>
                    </p>
                    <p>{service.description}</p>
                  </a>
                </div>
              ))}
            </div>
            <div className='flex justify-between mt-4'>
              <button className='mx-2 px-4 py-2 rounded bg-gray-200' onClick={handlePrev}>
                Prev
              </button>
              <button className='mx-2 px-4 py-2 rounded bg-gray-200' onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
