import JoinCommunityForm from 'components/Community/JoinCommunityForm'
import SectionHomeAppShowAdvisory from 'components/Home/SectionHomeAppShowAdvisory'
import { JoinCommunityPopup } from 'containers/PageJoinCommunity/JoinCommunityPopup'
import React from 'react'
import NcModal from 'shared/NcModal/NcModal'

const JoinCommunityModal = () => {
  return <JoinCommunityForm />
}

export default function ShowAppSamplesAdvisory() {
  return (
    <section className='py-36 overflow-hidden bg-gradient-to-t from-slate-100 to-slate-100 dark:from-slate-800 dark:to-slate-800'>
      <div className='container px-4 mx-auto'>
        <div className='flex flex-wrap xl:items-center -m-8'>
          <div className='w-full md:w-1/2 xs:pl-32 p-8'>
            <h2 className='mb-5 text-4xl md:text-7xl font-bold font-heading tracking-px-n leading-tight'>What we are building</h2>
            <p className='mb-8 text-lg text-gray-600 font-medium md:max-w-lg'>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat.
            </p>
            <div className='mb-20 md:inline-block'>
              {/* <NcModal
                renderTrigger={openModal => (
                  <button
                    className='py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200 dark:text-white dark:text-opacity-75'
                    type='button'
                    onClick={() => openModal()}
                  >
                    Join our Community
                  </button>
                )}
                renderContent={JoinCommunityModal}
                modalTitle='Join our Community'
                showCloseButton={true}
                closeConfirmationOption={true}
              /> */}
              <JoinCommunityPopup />
            </div>
            <div className='flex flex-wrap -m-8'>
              <div className='w-full md:w-1/2 p-8'>
                <div className='md:max-w-xs'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-8 h-8 mb-4'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' d='M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z' />
                  </svg>

                  <h3 className='mb-3 text-xl font-semibold leading-normal'>Early Access</h3>
                  <p className='text-gray-600 font-medium leading-relaxed'>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  </p>
                </div>
              </div>
              <div className='w-full md:w-1/2 p-8'>
                <div className='md:max-w-xs'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-8 h-8 mb-4'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                    />
                  </svg>

                  <h3 className='mb-3 text-xl font-semibold leading-normal'>Get Beta Tools</h3>
                  <p className='text-gray-600 font-medium leading-relaxed'>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 p-8'>
            <div className='max-w-lg mx-auto text-center'>
              <SectionHomeAppShowAdvisory
                key={1} // This will force the component to re-render
                heading='App Show 1'
                subHeading='What we have for you in our app'
                categoryCardType='card5'
                itemPerRow={1}
                uniqueClassName='PageHome-AppShow-Food'
                showTitle={false}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
