import React, { useState } from 'react'
import AddVerification from './AddVerification'
import { CheckUsername } from './CheckUsername'

interface Username {
  username: string
  isAvailable: boolean
  price: number
}

interface AboutYou {
  firstName: string
  lastName: string
  email: string
}

interface OrderDetails {
  aboutYou: AboutYou
  usernameList: Username[]
  verification: Verification
}

interface Verification {
  isVerified: boolean
  price: number
}

const BuildPackage: React.FC = () => {
  const initialOrderDetails: OrderDetails = {
    aboutYou: { firstName: '', lastName: '', email: '' },
    usernameList: [],
    verification: { isVerified: false, price: 0 },
  }
  const [orderDetails, setOrderDetails] = useState<OrderDetails>(initialOrderDetails)

  const handleAboutYouDetails = (aboutYouDetails: AboutYou) => {
    setOrderDetails(prevDetails => ({ ...prevDetails, aboutYou: aboutYouDetails }))
  }

  const handleUsernameList = (usernameList: Username[]) => {
    setOrderDetails(prevDetails => ({ ...prevDetails, usernameList }))
  }

  const handleVerificationChange = (verification: Verification) => {
    setOrderDetails(prevDetails => ({ ...prevDetails, verification }))
  }

  const AboutYouSection: React.FC<{
    onAboutYouDetailsChange: (aboutYouDetails: AboutYou) => void
    goToNextStep: () => void
  }> = ({ onAboutYouDetailsChange, goToNextStep }) => {
    const [formState, setFormState] = useState<AboutYou>({ firstName: '', lastName: '', email: '' })

    const handleInputChange = (field: keyof AboutYou) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormState(prev => ({ ...prev, [field]: e.target.value }))
    }

    const handleNext = () => {
      const { firstName, lastName } = formState
      if (firstName.length >= 2 && lastName.length >= 2 && firstName.length <= 30 && lastName.length <= 30) {
        onAboutYouDetailsChange(formState)
        goToNextStep()
      } else {
        console.log('error no fields ')
      }
    }

    const handleClear = () => {
      setFormState({ firstName: '', lastName: '', email: '' })
    }

    return (
      <div className='flex flex-wrap max-w-2xl pb-11 -m-1'>
        <div className='w-full sm:w-1/2 p-1'>
          <label htmlFor='firstNameInput' className='block text-xs font-bold mb-1'>
            First Name
          </label>
          <input
            className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
            id='firstNameInput'
            type='text'
            placeholder='First Name'
            maxLength={30}
            value={formState.firstName}
            onChange={handleInputChange('firstName')}
          />
        </div>
        <div className='w-full sm:w-1/2 p-1'>
          <label htmlFor='lastNameInput' className='block text-xs font-bold mb-1'>
            Last Name
          </label>
          <input
            className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
            id='lastNameInput'
            type='text'
            placeholder='Last Name'
            maxLength={30}
            value={formState.lastName}
            onChange={handleInputChange('lastName')}
          />
        </div>
        <div className='w-full sm:w-1/2 p-1'>
          <label htmlFor='emailInput' className='block text-xs font-bold mb-1'>
            Email
          </label>
          <input
            className='px-4 py-3.5 w-full text-sm text-neutral-400 hover:bg-gray-50 outline-none placeholder-neutral-400 border focus:border-neutral-600 border-neutral-200 rounded-lg'
            id='emailInput'
            type='text'
            placeholder='Email'
            value={formState.email}
            onChange={handleInputChange('email')}
          />
        </div>
        <div className='w-full flex items-center justify-start sm:justify-start p-2 py-4 space-x-4'>
          <a className='' href='##' onClick={handleClear}>
            Clear
          </a>
          <a
            className='inline-block w-full lg:w-auto py-4 px-8 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
            href='##'
            onClick={handleNext}
          >
            Save
          </a>
        </div>
      </div>
    )
  }

  // steps components
  const StepAboutYou = ({
    handleAboutYouDetails,
    goToNextStep,
  }: {
    handleAboutYouDetails: (data: AboutYou) => void
    goToNextStep: () => void
  }) => <AboutYouSection onAboutYouDetailsChange={handleAboutYouDetails} goToNextStep={goToNextStep} />

  const StepUsername = ({ handleUsernameList }: { handleUsernameList: (data: Username[]) => void }) => (
    <CheckUsername onUsernameListChange={handleUsernameList} goToNextStep={goToNextStep} />
  )

  // Manage the steps
  const [currentStep, setCurrentStep] = useState(0)

  const goToNextStep = () => {
    if (currentStep < StepsData.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      // Add your logic for what should happen after the last step.
    }
  }

  const handleStepTitleClick = (stepIndex: number) => {
    setCurrentStep(stepIndex)
  }

  // Array Steps Data structure
  const StepsData = [
    {
      id: '1',
      title: 'To start, tell us about you',
      description: 'A bit of information about you',
      component: <StepAboutYou handleAboutYouDetails={handleAboutYouDetails} goToNextStep={goToNextStep} />,
    },
    {
      id: '2',
      title: 'Now, reserve your username',
      description: 'Reserve and Secure your username.',
      component: <StepUsername handleUsernameList={handleUsernameList} />,
    },
    {
      id: '3',
      title: 'Get Genuine Verification',
      description: 'ou can get your verification Certify.',
      component: <AddVerification onVerificationChange={handleVerificationChange} goToNextStep={goToNextStep} />,
    },
    {
      id: '4',
      title: 'Get partner badges',
      description: 'Get your badges available by country.',
      component: <StepUsername handleUsernameList={handleUsernameList} />,
    },
    {
      id: '5',
      title: 'Invest & Earn',
      description: 'Invest and earn by donating.',
      component: <StepUsername handleUsernameList={handleUsernameList} />,
    },
    {
      id: '6',
      title: 'You&rsquo;re all set!',
      description: 'Happy times.',
      component: <StepUsername handleUsernameList={handleUsernameList} />,
    },
  ]

  return (
    <section className='py-4 overflow-hidden bg-slate-100'>
      <div className='max-w-2xl lg:max-w-5xl mx-auto my-24 text-center'>
        <h1 className='font-heading text-4xl sm:text-6xl lg:text-7xl font-bold text-gray-900 mb-8'>
          <span>Build</span>
          <span className='font-serif italic'> your </span>
          <span>Package</span>
        </h1>
        <div className='flex items-center justify-center'>
          <div className='inline-flex p-1 items-center justify-end bg-gray-300 rounded-full'>
            <button className='w-5 h-5 bg-transparent rounded-full'></button>
            <button className='w-5 h-5 bg-blue-600 rounded-full'></button>
          </div>
          <span className='ml-3 text-xl text-gray-900'>Annual pricing (save 20%)</span>
        </div>
      </div>
      <div className='container px-4 mx-auto'>
        <div className='flex flex-wrap'>
          <div className='px-6 py-12 bg-white border rounded-xl flex-grow'>
            {StepsData.map((step, index) => (
              <div key={step.id} id={step.id} className='flex flex-wrap -m-2'>
                <div className='w-auto p-2'>
                  <div className='flex flex-col items-center justify-between h-full'>
                    <div className='block pb-4'>
                      <div className='flex items-center justify-center w-10 h-10 border border-neutral-200 rounded-full'>
                        <span className='text-lg font-semibold'>{step.id}</span>
                      </div>
                    </div>
                    <div className='w-px h-full border border-dashed'></div>
                  </div>
                </div>
                <div className='flex-1 px-2 py-1'>
                  <div className='flex items-center justify-between mb-6'>
                    <div>
                      <h3 onClick={() => handleStepTitleClick(index)} className='font-heading text-lg font-semibold'>
                        {step.title}
                      </h3>
                      <p className='text-neutral-500'>{step.description}</p>
                    </div>
                    <button onClick={() => handleStepTitleClick(index)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth={1.5}
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                        />
                      </svg>
                    </button>
                  </div>

                  {index === currentStep && (
                    <>
                      <div className='py-4 pb-2 px-4'>{step.component}</div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className='px-10'>
            <div className='mb-14'>
              <div id='aboutYourOrderWrapper'>
                <h2 className='mb-7 md:mt-6 text-3xl font-heading font-medium'>About Your Order</h2>
                <div className='flex relative items-center justify-between py-4 px-10 mb-3 leading-8 bg-white bg-opacity-50 font-heading font-medium rounded-3xl'>
                  <div>
                    <p>First Name: {orderDetails.aboutYou.firstName}</p>
                    <p>Last Name: {orderDetails.aboutYou.lastName}</p>
                    <p>Email: {orderDetails.aboutYou.email}</p>
                  </div>
                </div>
              </div>

              <div id='usernameOrderWrapper'>
                <h2 className='mb-7 md:mt-6 text-3xl font-heading font-medium'>Username(s)</h2>

                {orderDetails.usernameList.map((username, index) => (
                  <div
                    key={index}
                    className='flex relative items-center justify-between py-4 px-10 mb-3 leading-8 bg-white bg-opacity-50 font-heading font-medium rounded-3xl'
                  >
                    <div>{username.username}</div>
                    <span className='flex items-center text-xl'>
                      <span className='mr-2 text-base'>£</span>
                      <span>{username.price.toFixed(2)}</span>
                    </span>
                    <button className='absolute top-0 right-0 lg:mt-0 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                        <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                        <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                      </svg>
                    </button>
                  </div>
                ))}
              </div>

              <div id='verificationOrderWrapper'>
                <h2 className='mb-7 md:mt-6 text-3xl font-heading font-medium'>Verification</h2>

                <div className='flex relative items-center justify-between py-4 px-10 mb-3 leading-8 bg-white bg-opacity-50 font-heading font-medium rounded-3xl'>
                  <div>{orderDetails.verification.isVerified ? 'Verified' : 'Not Verified'}</div>
                  <span className='flex items-center text-xl'>
                    <span className='mr-2 text-base'>£</span>
                    <span>{orderDetails.verification.price.toFixed(2)}</span>
                  </span>
                  <button className='absolute top-0 right-0 lg:mt-0 lg:-mr-4 text-gray-200 hover:text-gray-300'>
                    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect x='0.5' y='0.5' width='27' height='27' rx='13.5' stroke='currentColor'></rect>
                      <line x1='20.495' y1='8.49497' x2='8.49498' y2='20.495' stroke='currentColor' stroke-width='1.4'></line>
                      <line x1='19.505' y1='20.495' x2='7.50503' y2='8.49498' stroke='currentColor' stroke-width='1.4'></line>
                    </svg>
                  </button>
                </div>
              </div>

              <a
                className='inline-block w-full lg:w-auto py-5 px-10 text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl'
                href='#'
              >
                Checkout
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BuildPackage
