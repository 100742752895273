import zapierLogo from 'images/dashy-assets/images/zapier.svg'
import chatGptLogo from 'images/google-logo.svg'
import avatarImg from 'images/saturn-assets/images/cta/avatar3.png'
import React from 'react'
import NcModal from 'shared/NcModal/NcModal'
import SchoolSubjectAi from './SchoolSubjectAi'

interface ToolsUsed {
  id: number
  tool: string
}
interface Marketplace {
  id: number
  icon: string
  title: string
  description: string
  toolsUsed: ToolsUsed[]
  isComingSoon: boolean
}
const zapierTool: ToolsUsed = {
  id: 1,
  tool: zapierLogo,
}

const chatGptTool: ToolsUsed = {
  id: 2,
  tool: chatGptLogo,
}

const marketplaceData: Marketplace[] = [
  {
    id: 1,
    icon: zapierLogo,
    title: 'School Subject AI',
    description: 'Ai tool for generating school content',
    toolsUsed: [zapierTool, chatGptTool],
    isComingSoon: true,
  },
  {
    id: 2,
    icon: zapierLogo,
    title: 'Mufti AI Model',
    description: 'Ai tool for approving Mufti content',
    toolsUsed: [zapierTool, chatGptTool],
    isComingSoon: true,
  },
  {
    id: 3,
    icon: zapierLogo,
    title: 'Mufti AI Model',
    description: 'Ai tool for approving Mufti content',
    toolsUsed: [zapierTool, chatGptTool],
    isComingSoon: true,
  },
]

const openEditorPopup = () => {
  return <SchoolSubjectAi />
}

export default function MarketplaceList() {
  return (
    <section className='pt-4 pb-4 rounded-xl'>
      <div className='w-full'>
        <div className='flex flex-wrap -mx-4 -mb-6'>
          {marketplaceData.map(item => (
            <div className='w-full md:w-1/2 lg:w-1/3 px-4 mb-6' key={item.id}>
              <div className='relative p-6 bg-white dark:bg-slate-800 hover:bg-light border rounded-lg cursor-pointer shadow-md '>
                <div className='flex items-center justify-start'>
                  <div>
                    {item.isComingSoon && (
                      <span className='absolute right-2 top-2 ml-4 py-0 px-2 text-center text-slate-800  font-bold text-xs8 rounded-xl transition duration-200'>
                        Coming Soon
                      </span>
                    )}
                    <div className='flex items-center'>
                      <div className='flex items-center justify-center w-12 h-12 bg-blue-200 rounded-lg'>
                        <img src={item.icon} alt='' />
                      </div>
                      <div className=' ml-4'>
                        <div className='flex items-center justify-between'>
                          <h4 className='text-xl dark:text-white dark:text-opacity-50 font-bold'>{item.title}</h4>
                        </div>

                        <p className='text-xs12 dark:text-white dark:text-opacity-50 '>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex items-center my-6'>
                  <div className='flex mr-2 items-center justify-center gap-x-2'>
                    {item.toolsUsed.map(tool => (
                      <img className='w-8 h-8 ' src={tool.tool} alt='' key={tool.id} />
                    ))}
                  </div>
                  <span className='text-xs text-base dark:text-white dark:text-opacity-50'>Tools Used</span>
                </div>
                <div className='flex items-center justify-start space-x-4'>
                  <a
                    className='flex items-center py-2 px-4 text-center text-white font-bold text-sm leading-6 bg-slate-400 hover:bg-slate-500 rounded-xl transition duration-200'
                    href='##'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 mr-2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15'
                      />
                    </svg>
                    Run
                  </a>
                  <NcModal
                    renderTrigger={openModal => (
                      <button className='flex items-center' type='button' onClick={() => openModal()}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-6 h-6 mr-2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z'
                          />
                          <path strokeLinecap='round' strokeLinejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' />
                        </svg>
                        <span className='text-xs'>Open in Editor</span>
                      </button>
                    )}
                    renderContent={openEditorPopup}
                    modalTitle='Join our Community'
                    showCloseButton={true}
                    closeConfirmationOption={true}
                    fullHeight={true}
                    fullWidth={true}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className='block max-w-[200px] mx-auto py-8'>
          <a
            className='flex items-center justify-center py-4 px-4 text-center text-white font-bold text-sm leading-6 bg-slate-600 hover:bg-slate-500 rounded-xl transition duration-200'
            href='##'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6 mr-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
              />
            </svg>
            Load More
          </a>
        </div> */}
      </div>
    </section>
  )
}
