import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import moreRamadamImg from "images/more-ramadam.png";

export interface SectionHomeAppealsInterface {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 3 | 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 17288,
    thumbnail: moreRamadamImg,
  },
  {
    id: "2",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 2118,
    thumbnail: moreRamadamImg,
  },
  {
    id: "3",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 36612,
    thumbnail: moreRamadamImg,
  },
  {
    id: "4",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 188288,
    thumbnail: moreRamadamImg,
  },
  {
    id: "5",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 188288,
    thumbnail: moreRamadamImg,
  },
  {
    id: "6",
    href: "#",
    name: "The Guide for Praying",
    taxonomy: "category",
    count: 188288,
    thumbnail: moreRamadamImg,
  },
];

const SectionHomeAppeals: FC<SectionHomeAppealsInterface> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS,
  itemPerRow = 3,
  categoryCardType = "card3",
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 0,
        },
        1024: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        680: {
          gap: 20,
          perView: itemPerRow - 1,
        },
        460: {
          gap: 20,
          perView: 1,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (item: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={item} />;
      case "card4":
        return <CardCategory4 taxonomy={item} />;
      case "card5":
        return <CardCategory5 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={subHeading} hasNextPrev={sliderStyle === "style1"} isCenter={sliderStyle === "style2"}>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {categories.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && <NextPrev className="justify-center mt-16" />}
      </div>
    </div>
  );
};

export default SectionHomeAppeals;
