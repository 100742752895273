import googleDriveLogo from 'images/dashy-assets/images/google-drive.svg'
import jetpackLogo from 'images/dashy-assets/images/jetpack.svg'
import linkedinLogo from 'images/dashy-assets/images/linkedin.svg'
import notionLogo from 'images/dashy-assets/images/notion.svg'
import slackLogo from 'images/dashy-assets/images/slack.svg'
import tiktokLogo from 'images/dashy-assets/images/tiktok.svg'
import woocommerceLogo from 'images/dashy-assets/images/woocommerce.svg'
import wordpressLogo from 'images/dashy-assets/images/wordpress.svg'
import zapierLogo from 'images/dashy-assets/images/zapier.svg'
import react from 'react'

const IntegrationSecition = () => {
  return (
    <section className='py-4 overflow-hidden'>
      <div className='container px-4 mx-auto'>
        <div className='p-6 bg-white border rounded-xl dark:bg-slate-800  '>
          <div className='flex flex-wrap justify-between mb-5 -m-2'>
            <div className='w-auto p-2'>
              <h3 className='font-heading text-lg font-semibold'>Integrations</h3>
            </div>
            <div className='w-auto p-2'>
              <a className='text-xs text-base font-semibold uppercase bg-clip-text bg-gradient-purple-left' href='##'>
                View all
              </a>
            </div>
          </div>
          <div className='flex flex-wrap -m-2'>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={wordpressLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>WordPress</h3>
                        <p className='text-xs text-neutral-500'>CMS</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={zapierLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Zapier</h3>
                        <p className='text-xs text-neutral-500'>Automation</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={woocommerceLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>WooCommerce</h3>
                        <p className='text-xs text-neutral-500'>eCommerce Plugin</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={slackLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Slack</h3>
                        <p className='text-xs text-neutral-500'>Communication</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={notionLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Notion</h3>
                        <p className='text-xs text-neutral-500'>Writing Tool</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={googleDriveLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Google Drive</h3>
                        <p className='text-xs text-neutral-500'>Cloud</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={jetpackLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Jetpack</h3>
                        <p className='text-xs text-neutral-500'>Plugins</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={linkedinLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>Linkedin</h3>
                        <p className='text-xs text-neutral-500'>Social Media</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-1/2 md:w-1/3 p-2'>
              <div className='p-4 h-full bg-white border hover:border-neutral-200 rounded-lg cursor-pointer'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-auto p-2'>
                    <div className='flex flex-wrap items-center -m-1.5'>
                      <div className='w-auto p-1.5'>
                        <img src={tiktokLogo} alt='' />
                      </div>
                      <div className='flex-1 p-1.5'>
                        <h3 className='font-heading mb-0.5 font-semibold'>TikTok</h3>
                        <p className='text-xs text-neutral-500'>Social Media</p>
                      </div>
                    </div>
                  </div>
                  <div className='w-auto p-2'>
                    <a
                      className='inline-flex flex-wrap items-center justify-center px-1.5 py-1.5 w-full font-medium text-sm text-center text-neutral-50 hover:text-neutral-100 bg-neutral-600 hover:bg-opacity-95 rounded-lg focus:ring-4 focus:ring-neutral-400'
                      href='##'
                    >
                      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M9.35937 4C9.35937 3.58579 9.02359 3.25 8.60937 3.25C8.19516 3.25 7.85937 3.58579 7.85937 4H9.35937ZM7.85937 12C7.85937 12.4142 8.19516 12.75 8.60937 12.75C9.02359 12.75 9.35937 12.4142 9.35937 12H7.85937ZM12.6094 8.75C13.0236 8.75 13.3594 8.41421 13.3594 8C13.3594 7.58579 13.0236 7.25 12.6094 7.25V8.75ZM4.60938 7.25C4.19516 7.25 3.85938 7.58579 3.85938 8C3.85937 8.41421 4.19516 8.75 4.60937 8.75L4.60938 7.25ZM7.85937 4V8H9.35937V4H7.85937ZM7.85937 8V12H9.35937V8H7.85937ZM12.6094 7.25H8.60937V8.75H12.6094V7.25ZM8.60937 7.25L4.60938 7.25L4.60937 8.75H8.60937L8.60937 7.25Z'
                          fill='#F9FAFB'
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntegrationSecition
