import React, { useState, useEffect } from 'react'
import AddQuizzFields from './AddQuizzFields'
import QuizzPreview from './QuizzPreview'
import BackgroundSection from 'components/BackgroundSection/BackgroundSection'
import { useAuthContext } from '../../../context/AuthContext'
import { API } from '../../../constant'
import { getToken } from '../../../helpers'

export interface QuizQuestion {
  id: number
  question: string
  answers: QuizAnswer[]
}

export interface QuizAnswer {
  id: number
  text: string
  isCorrect: boolean
}

interface FamilyQuizQuestion {
  familyQuizQuestionTitle: string
  Answer: FamilyQuizQuestionAnswer[]
}

interface FamilyQuizQuestionAnswer {
  familyQuizQuestionAnswerText: string
  familyQuizQuestionAnswerIsCorrect: boolean
}

interface FamilyQuizData {
  data: {
    familyQuizTitle: string
    familyQuizDescription: string
    familyQuizUserEmail: string
    familyQuizUserName: string
    Questions: FamilyQuizQuestion[]
  }
}

interface OriginalQuizQuestion {
  id: number
  question: string
  answers: {
    id: number
    text: string
    isCorrect: boolean
  }[]
}

interface TransformedQuizQuestionAnswer {
  familyQuizQuestionAnswerText: string
  familyQuizQuestionAnswerIsCorrect: boolean
}

interface TransformedQuizQuestion {
  familyQuizQuestionTitle: string
  Answer: TransformedQuizQuestionAnswer[]
}

interface FamilyQuizData {
  data: {
    familyQuizTitle: string
    familyQuizDescription: string
    familyQuizUserEmail: string
    familyQuizUserName: string
    Questions: TransformedQuizQuestion[]
  }
}

function QuizFormParent() {
  const [questions, setQuestions] = useState<QuizQuestion[]>([])

  // const [transformedData, setTransformedData] = useState<FamilyQuizData>(transformData(questions))

  const [quizTitle, setQuizTitle] = useState<string>('')
  const [quizDescription, setQuizDescription] = useState<string>('')
  const [familyQuizSaved, setFamilyQuizSaved] = useState<boolean>(false)

  // User login propose
  const { user, isLoading, setUser } = useAuthContext()
  const [userData, setUserData] = useState({})
  const [isLogged, setIsLogged] = useState(false)

  const handleQuestionOrderChange = (questionIndex: number, direction: 'up' | 'down') => {
    const newQuestions = [...questions]
    const questionToMove = newQuestions[questionIndex]
    newQuestions.splice(questionIndex, 1)
    if (direction === 'up') {
      newQuestions.splice(questionIndex - 1, 0, questionToMove)
    } else if (direction === 'down') {
      newQuestions.splice(questionIndex + 1, 0, questionToMove)
    }
    setQuestions(newQuestions)
  }

  const addQuestion = () => {
    const defaultQuestions = [
      { question: 'What is my favourite colour?', answers: ['Red', 'Blue', 'Green', 'Yellow'] },
      { question: 'What is the capital of Australia?', answers: ['Sydney', 'Melbourne', 'Canberra', 'Brisbane'] },
      { question: 'What is the highest mountain in the world?', answers: ['Mount Everest', 'K2', 'Makalu', 'Cho Oyu'] },
      { question: 'What is the largest mammal on Earth?', answers: ['Blue whale', 'Elephant', 'Giraffe', 'Hippopotamus'] },
      { question: 'What is the largest country in the world?', answers: ['Russia', 'Canada', 'China', 'United States'] },
      { question: 'What is the largest ocean in the world?', answers: ['Pacific Ocean', 'Atlantic Ocean', 'Indian Ocean', 'Arctic Ocean'] },
      { question: 'What is the largest planet in the solar system?', answers: ['Jupiter', 'Saturn', 'Neptune', 'Uranus'] },
      {
        question: 'What is the largest desert in the world?',
        answers: ['Sahara Desert', 'Gobi Desert', 'Kalahari Desert', 'Arabian Desert'],
      },
      {
        question: 'What is the largest freshwater lake in the world?',
        answers: ['Lake Superior', 'Lake Victoria', 'Lake Baikal', 'Lake Huron'],
      },
      { question: 'What is the largest island in the world?', answers: ['Greenland', 'New Guinea', 'Borneo', 'Madagascar'] },
      { question: 'What is the largest volcano in the world?', answers: ['Mauna Loa', 'Mount Etna', 'Mount Kilimanjaro', 'Mount Fuji'] },
      {
        question: 'What is the largest river in the world?',
        answers: ['Amazon River', 'Nile River', 'Yangtze River', 'Mississippi River'],
      },
      { question: 'What is the largest country in Africa?', answers: ['Algeria', 'Egypt', 'Nigeria', 'Sudan'] },
      { question: 'What is the largest country in Asia?', answers: ['China', 'India', 'Indonesia', 'Russia'] },
      { question: 'What is the largest country in Europe?', answers: ['France', 'Germany', 'Italy', 'Russia'] },
      { question: 'What is the largest country in North America?', answers: ['Canada', 'Mexico', 'United States', 'Greenland'] },
      { question: 'What is the largest country in South America?', answers: ['Argentina', 'Brazil', 'Colombia', 'Peru'] },
      { question: 'What is the largest country in Oceania?', answers: ['Australia', 'Fiji', 'New Zealand', 'Papua New Guinea'] },
      { question: 'What is the largest city in the world?', answers: ['Tokyo', 'Delhi', 'Shanghai', 'Sao Paulo'] },
      { question: 'What is the largest city in Africa?', answers: ['Cairo', 'Lagos', 'Kinshasa', 'Khartoum'] },
      { question: 'What is the largest city in Asia?', answers: ['Delhi', 'Shanghai', 'Tokyo', 'Mumbai'] },
      { question: 'What is the largest city in Europe?', answers: ['Moscow', 'Istanbul', 'London', 'Berlin'] },
      { question: 'What is the largest city in North America?', answers: ['Mexico City', 'New York City', 'Los Angeles', 'Chicago'] },
    ]

    const questionExists = questions.some(question => question.question === defaultQuestions[0].question)

    if (!questionExists && questions.length < 20) {
      const newQuestion = {
        id: questions.length + 1,
        question: defaultQuestions[questions.length + 1].question,
        answers: [
          {
            id: 1,
            text: defaultQuestions[questions.length + 1].answers[0],
            isCorrect: false,
          },
          {
            id: 2,
            text: defaultQuestions[questions.length + 1].answers[1],
            isCorrect: true,
          },
          {
            id: 3,
            text: defaultQuestions[questions.length + 1].answers[2],
            isCorrect: false,
          },
          {
            id: 4,
            text: defaultQuestions[questions.length + 1].answers[3],
            isCorrect: false,
          },
        ],
      }
      setQuestions([...questions, newQuestion])
    } else if (questionExists) {
      console.log('Question already exists!')
    } else {
      console.log('Maximum number of questions reached!')
    }
  }

  // handle the save
  const SaveQuizButton = () => {
    const { userEmail, userName } = transformUserData()
    // console.log('env', process.env.REACT_APP_API_TOKEN)
    async function handleSaveQuiz(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault()

      const transformedData = transformData(questions, quizTitle, quizDescription, userEmail, userName)

      const transformedUserData = transformUserData()

      const APIToken = process.env.REACT_APP_API_TOKEN
      const response = await fetch(`${API}/family-quizzes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify(
          transformDataAndUserData(questions, transformedUserData.userEmail, transformedUserData.userName, quizTitle, quizDescription)
        ),
      })

      const responseData = await response.json()
      setFamilyQuizSaved(true)
      console.log('Quiz score saved successfully', responseData)
    }

    return (
      <button
        onClick={handleSaveQuiz}
        className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer ${
          familyQuizSaved ? 'bg-gray-400 border-gray-400 pointer-events-none' : ''
        }`}
        disabled={familyQuizSaved}
      >
        {familyQuizSaved ? 'Saved' : 'Save your Quiz'}
      </button>
    )
  }

  // Check if user is already logged in when the app starts
  function checkUserLoggedIn(): boolean {
    const user = window.sessionStorage.getItem('user')
    const jwt = window.sessionStorage.getItem('jwt')

    // save the user and jwt to the state userData if any of them is not null/undefined
    if (user && jwt) {
      setUser(user)
      console.log('USER DATA TO CHECK - ' + user)
      console.log('-----------------------')
      setUserData(JSON.parse(user))
      setIsLogged(true)
    }

    return !!user && !!jwt // Return true if both user and jwt are not null/undefined
  }

  // load at least one question!
  useEffect(() => {
    addQuestion()
    // Check if user is already logged in when the app starts
    const isLoggedIn = checkUserLoggedIn()
    console.log('logged in:', isLoggedIn)
  }, [])

  function transformDataAndUserData(
    originalData: OriginalQuizQuestion[],
    userEmail: string,
    userName: string,
    quizTitle: string,
    quizDescription: string
  ): FamilyQuizData {
    const transformedQuestions: TransformedQuizQuestion[] = originalData.map(question => {
      const transformedAnswers: TransformedQuizQuestionAnswer[] = question.answers.map(answer => {
        return {
          familyQuizQuestionAnswerText: answer.text,
          familyQuizQuestionAnswerIsCorrect: answer.isCorrect,
        }
      })

      return {
        familyQuizQuestionTitle: question.question,
        Answer: transformedAnswers,
      }
    })

    const familyQuizData: FamilyQuizData = {
      data: {
        familyQuizTitle: quizTitle,
        familyQuizDescription: quizDescription,
        familyQuizUserEmail: userEmail,
        familyQuizUserName: userName,
        Questions: transformedQuestions,
      },
    }

    return familyQuizData
  }
  const transformUserData = () => {
    const { email, name } = JSON.parse(user)
    return {
      userEmail: email,
      userName: name,
    }
  }
  function transformData(
    originalData: OriginalQuizQuestion[],
    quizTitle: string,
    quizDescription: string,
    userEmail: string,
    userName: string
  ): FamilyQuizData {
    const transformedQuestions: TransformedQuizQuestion[] = originalData.map(question => {
      const transformedAnswers: TransformedQuizQuestionAnswer[] = question.answers.map(answer => {
        return {
          familyQuizQuestionAnswerText: answer.text,
          familyQuizQuestionAnswerIsCorrect: answer.isCorrect,
        }
      })

      return {
        familyQuizQuestionTitle: question.question,
        Answer: transformedAnswers,
      }
    })

    const familyQuizData: FamilyQuizData = {
      data: {
        familyQuizTitle: quizTitle,
        familyQuizDescription: quizDescription,
        familyQuizUserEmail: userEmail,
        familyQuizUserName: userName,
        Questions: transformedQuestions,
      },
    }

    return familyQuizData
  }

  const addAnswer = (questionIndex: number) => {
    const question = questions[questionIndex]
    if (question.answers.length < 8) {
      const newQuestions = [...questions]
      const newAnswer = {
        id: question.answers.length + 1,
        text: '',
        isCorrect: false,
      }
      newQuestions[questionIndex].answers.push(newAnswer)
      setQuestions(newQuestions)
    } else {
      console.log('Maximum number of answers reached for this question!')
    }
  }

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number) => {
    const newQuestions = [...questions]
    newQuestions[questionIndex].question = event.target.value
    setQuestions(newQuestions)
  }

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number, answerIndex: number) => {
    const newQuestions = [...questions]
    newQuestions[questionIndex].answers[answerIndex].text = event.target.value
    setQuestions(newQuestions)
  }

  const handleCorrectAnswerChange = (event: React.ChangeEvent<HTMLInputElement>, questionIndex: number, answerIndex: number) => {
    const newQuestions = [...questions]
    newQuestions[questionIndex].answers.forEach(answer => {
      answer.isCorrect = false
    })
    newQuestions[questionIndex].answers[answerIndex].isCorrect = true
    setQuestions(newQuestions)
  }

  const handleRemoveQuestion = (questionIndex: number) => {
    const newQuestions = [...questions]
    newQuestions.splice(questionIndex, 1)
    setQuestions(newQuestions)
  }

  const handleRemoveAnswer = (questionIndex: number, answerIndex: number) => {
    // don't allow to remove answers if there are only 2 left
    if (questions[questionIndex].answers.length <= 2) return
    const newQuestions = [...questions]
    newQuestions[questionIndex].answers.splice(answerIndex, 1)
    setQuestions(newQuestions)
  }

  const saveQuiz = () => {
    // TODO: save the quiz questions to a database or API
    console.log('Quiz saved:', questions)
  }

  const handleQuizTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuizTitle(event.target.value)
  }

  const handleQuizDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuizDescription(event.target.value)
  }

  return (
    <div className='w-full'>
      <form className='w-full max-w'>
        <div className='w-full'>
          <div className='flex items-center border-b py-2'>
            <input
              className='appearance-none bg-transparent border-none text-2xl font-semibold w-full mr-3 py-4 px-6 leading-tight focus:outline-none'
              type='text'
              id='familyQuizTitle'
              name='familyQuizTitle'
              placeholder='Awesome Family Quiz'
              aria-label='Awesome Family Quiz'
              value={quizTitle}
              onChange={handleQuizTitleChange}
            />

            <button
              className='flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-800 text-sm py-1 px-2 rounded'
              type='button'
            >
              Generate Name
            </button>
            <button>Saving</button>
          </div>
        </div>

        <div className='w-full'>
          <div className='flex gap-x-8 md:gap-x-6 items-center border-b py-2'>
            <textarea
              id='familyQuizDesc'
              name='familyQuizDesc'
              value={quizDescription}
              onChange={handleQuizDescriptionChange}
              rows={2}
              className='appearance-none bg-transparent border-none font-semibold w-full mr-3 py-4 px-6 leading-tight focus:outline-none'
              placeholder='The description of your quiz will be only for you'
              defaultValue={'The description of your quiz will be only for you'}
            />

            <div className=''>
              <svg className='animate-spin -ml-1 mr-3 h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'>
                <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='3'></circle>
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </form>
      <AddQuizzFields
        questions={questions}
        addQuestion={addQuestion}
        addAnswer={addAnswer}
        handleQuestionChange={handleQuestionChange}
        handleAnswerChange={handleAnswerChange}
        handleCorrectAnswerChange={handleCorrectAnswerChange}
        handleRemoveQuestion={handleRemoveQuestion}
        handleRemoveAnswer={handleRemoveAnswer}
        saveQuiz={saveQuiz}
        handleQuestionOrderChange={handleQuestionOrderChange}
      />
      <div className='relative py-4 bg-orange-50 rounded-b-xl dark:bg-black dark:bg-opacity-20 mx-4'>
        <div className='w-full'>
          <QuizzPreview questions={questions} />
        </div>
        <div className='container flex flex-col justify-center'>
          <SaveQuizButton />
        </div>
      </div>
    </div>
  )
}

export default QuizFormParent
