import { useEffect, useState } from 'react'

const API_URL = 'https://sunnah-server.herokuapp.com'
const API_KEY = 'SqD712P3E82xnwOAEOkGd5JZH8s9wRR24TqNFzjk'

interface Book {
  lang: string
  name: string
}

interface Books {
  bookNumber: string
  book: Book[]
  hadithStartNumber: number
  hadithEndNumber: number
  numberOfHadith: number
}

export const useFetchCollectionBooks = () => {
  const [books, setBooks] = useState<Books[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchCollectionBooks = (collectionName: string) => {
    setIsLoading(true)
    fetch(`${API_URL}/collections/${collectionName}/books`, {
      headers: {
        'X-API-Key': API_KEY,
      },
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('HTTP error ' + response.status)
        }
      })
      .then(result => {
        setBooks(result.data)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.log('error', error)
      })
  }

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      setBooks([])
    }
  }, [])

  return { books, fetchCollectionBooks, isLoading }
}
