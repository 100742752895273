import React, { FC, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary'
import { Link } from 'react-router-dom'
import QuizzPreview from './AddQuizz/QuizzPreview'
import NcModal from 'shared/NcModal/NcModal'
import { API } from '../../constant'

export interface ListingsFamilyQuizzesCardProps {
  className?: string
  data: FamilyQuiz
  familyQuizId: number
}

// Quizzes interface
export interface FamilyQuizAnswer {
  id: number
  familyQuizQuestionAnswerText: string
  familyQuizQuestionAnswerIsCorrect: boolean
}

export interface FamilyQuizQuestion {
  id: number
  familyQuizQuestionTitle: string
  Answer: FamilyQuizAnswer[]
}

export interface FamilyQuiz {
  id: number
  attributes: {
    familyQuizTitle: string
    createdAt: string
    updatedAt: string
    publishedAt: string
    familyQuizDescription: string
    familyQuizUserEmail: string
    familyQuizUserName: string
    Questions: FamilyQuizQuestion[]
  }
}

interface FamilyQuizListProps {
  quizId: number
  className?: string
  data: FamilyQuiz['attributes']
  onUpdate: (quizId: number, updatedData: FamilyQuiz['attributes']) => void
  onUpdateQuestions: (quizId: number, updatedQuestions: FamilyQuizQuestion[]) => void // Add this line
}

interface FamilyQuizData {
  id: number
  familyQuizTitle: string
  familyQuizDescription: string
  Questions: FamilyQuizQuestion[]
}

const ListingsFamilyQuizzesCard: FC<FamilyQuizListProps> = ({ className = '', data, quizId, onUpdate, onUpdateQuestions }) => {
  const { familyQuizTitle, familyQuizDescription, Questions } = data
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [showSaveMessage, setShowSaveMessage] = useState(false)

  // handle the questions indexes
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)

  const [familyQuizzes, setFamilyQuizzes] = useState<FamilyQuizData[]>([])

  const [editedFamilyQuizTitle, setEditedFamilyQuizTitle] = useState('')
  const [editedFamilyQuizDescription, setEditedFamilyQuizDescription] = useState('')
  const [editedQuestions, setEditedQuestions] = useState<FamilyQuizQuestion[]>([])

  const renderModalContent = () => {
    // Handle the isCorrect to allow only one answer to be correct per
    const handleIsCorrectChange = (questionId: number, answerId: number, isChecked: boolean) => {
      setEditedQuestions(questions =>
        questions.map(question =>
          question.id === questionId
            ? {
                ...question,
                Answer: question.Answer.map(answer =>
                  answer.id === answerId
                    ? { ...answer, familyQuizQuestionAnswerIsCorrect: isChecked }
                    : { ...answer, familyQuizQuestionAnswerIsCorrect: false }
                ),
              }
            : question
        )
      )
    }

    return (
      <div>
        {/* Render the form for editing the quiz data */}
        <form>
          {/* Render the quiz title input */}
          <div>
            <label htmlFor='familyQuizTitle'>Quiz Title:</label>
            <input
              type='text'
              id='familyQuizTitle'
              value={editedFamilyQuizTitle}
              onChange={event => setEditedFamilyQuizTitle(event.target.value)}
            />
          </div>

          {/* Render the quiz description input */}
          <div>
            <label htmlFor='familyQuizDescription'>Quiz Description:</label>
            <textarea
              id='familyQuizDescription'
              value={editedFamilyQuizDescription}
              onChange={event => setEditedFamilyQuizDescription(event.target.value)}
            />
          </div>

          {/* Render the quiz questions input */}
          <div>
            <label htmlFor='familyQuizQuestions'>Quiz Questions:</label>
            {editedQuestions
              .filter((_, index) => index === currentQuestionIndex)
              .map(question => (
                <div key={question.id} className='bg-gray-100 my-4 rounded-xl py-8 px-4'>
                  <div className='py-4'>
                    <label htmlFor='familyQuizQuestionTitle'>Question:</label>
                    <input
                      type='text'
                      value={question.familyQuizQuestionTitle}
                      onChange={event =>
                        setEditedQuestions(questions =>
                          questions.map(q => (q.id === question.id ? { ...q, familyQuizQuestionTitle: event.target.value } : q))
                        )
                      }
                    />
                  </div>

                  <div className='w-full'>
                    {question.Answer.map(answer => (
                      <div className='flex items-center justify-center mx-2 my-2' key={answer.id}>
                        <div className='w-full'>
                          <label htmlFor='familyQuizQuestionAnswerText'>
                            Answer:
                            <input
                              type='text'
                              value={answer.familyQuizQuestionAnswerText}
                              onChange={event =>
                                setEditedQuestions(questions =>
                                  questions.map(q =>
                                    q.id === question.id
                                      ? {
                                          ...q,
                                          Answer: q.Answer.map(a =>
                                            a.id === answer.id
                                              ? {
                                                  ...a,
                                                  familyQuizQuestionAnswerText: event.target.value,
                                                }
                                              : a
                                          ),
                                        }
                                      : q
                                  )
                                )
                              }
                            />
                          </label>
                        </div>
                        <div className='mt-4 mx-4'>
                          <label>
                            <input
                              type='checkbox'
                              checked={answer.familyQuizQuestionAnswerIsCorrect}
                              onChange={event => handleIsCorrectChange(question.id, answer.id, event.target.checked)}
                            />
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            <div className='flex justify-between items-center mt-4'>
              <div className='flex items-center'>
                <button
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                    currentQuestionIndex === 0 ? 'hidden' : ''
                  }`}
                  type='button'
                  disabled={currentQuestionIndex === 0}
                  onClick={() => setCurrentQuestionIndex(prevIndex => prevIndex - 1)}
                >
                  Previous
                </button>
              </div>
              <div className='flex items-center'>
                <button
                  type='button'
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                    currentQuestionIndex === editedQuestions.length - 1 ? 'hidden' : ''
                  }`}
                  disabled={currentQuestionIndex === editedQuestions.length - 1}
                  onClick={() => setCurrentQuestionIndex(prevIndex => prevIndex + 1)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className='w-full mt-4'>
            {showSaveMessage && <p className='text-green-500 text-center mb-4'>Quiz Family Saved</p>}
            <button
              className='w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
              id='btnEditSave'
              type='button'
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    )
  }

  const handleSaveClick = async () => {
    try {
      console.log(editedFamilyQuizTitle)
      const APIToken = process.env.REACT_APP_API_TOKEN_FAMILY
      const response = await fetch(`${API}/family-quizzes/${quizId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${APIToken}`,
        },
        body: JSON.stringify({
          data: {
            familyQuizTitle: editedFamilyQuizTitle,
            familyQuizDescription: editedFamilyQuizDescription,
            Questions: editedQuestions.map(question => ({
              id: question.id,
              familyQuizQuestionTitle: question.familyQuizQuestionTitle,
              Answer: question.Answer.map(answer => ({
                id: answer.id,
                familyQuizQuestionAnswerText: answer.familyQuizQuestionAnswerText,
                familyQuizQuestionAnswerIsCorrect: answer.familyQuizQuestionAnswerIsCorrect,
              })),
            })),
          },
        }),
      })

      if (!response.ok) {
        // Handle error
        response.json().then(json => console.log(json))
      } else {
        const json = await response.json()
        console.log('saving' + json.data)

        // Call the onUpdate prop to update the quiz data in the parent component
        onUpdate(quizId, json.data.attributes)

        setFamilyQuizzes(familyQuizzes =>
          familyQuizzes.map(item => (item.id === quizId ? { ...item, attributes: json.data.attributes } : item))
        )

        // Update the parent component questions
        onUpdateQuestions(quizId, editedQuestions)

        // Show the save message
        setShowSaveMessage(true)

        // Close the modal after 1 second
        setTimeout(() => {
          setIsModalOpen(false)
          setShowSaveMessage(false) // Reset the save message state
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      console.error('Error updating quiz:', error)
    }
  }

  const renderDetailTop = () => {
    return (
      <div>
        <div className='w-full '>
          <div className='flex'>
            {Questions?.map(question => (
              <div className='p-4 m-2 bg-gray-100 w-1/2 rounded-xl' key={question.id}>
                <h3 className=' font-semibold'>{question.familyQuizQuestionTitle}</h3>
                <ul>
                  {question.Answer.map(answer => (
                    <li key={answer.id}>
                      {answer.familyQuizQuestionAnswerText}{' '}
                      <span className='text-green-600 py-1 px-2'>{answer.familyQuizQuestionAnswerIsCorrect ? '✓' : ''}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderDetail = () => {
    if (!isOpen) return null
    return <div className='p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl '>{renderDetailTop()}</div>
  }

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id='FlightCard'
    >
      <div className={` sm:pr-20 relative  ${className}`} data-nc-id='FamilyQuizzesCard'>
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href='##' className='' />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? 'transform -rotate-180' : ''
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className='text-xl las la-angle-down'></i>
        </span>

        <div className='flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0'>
          {/* LOGO IMG */}
          <div className='w-16 lg:w-22 flex-shrink-0'>
            <img src='https://img.logoipsum.com/226.svg' className='w-10' alt='' />
          </div>

          {/* TIME - NAME */}
          <div className='hidden lg:block  min-w-[150px] flex-[6] '>
            <div className='flex'>
              <div className='flex items-center space-x-2 bg-gray-50 px-1 py-1'>{quizId}</div>
              <span className='text-xl font-semibold text-secondary-6000'>{familyQuizTitle}</span>
            </div>
            <div className='text-sm text-neutral-500 font-normal mt-0.5'>{familyQuizTitle}</div>
          </div>

          {/* Points */}
          <div className='flex-[2] whitespace-nowrap sm:text-center'>
            <div className=''>
              <Link
                to='/#'
                className='
                text-opacity-90
                group px-4 bg-orange-400 text-white py-2 mx-2 border border-orange-400 hover:border-orange-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-white-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              >
                Play
              </Link>
              <Link
                to='/#'
                className='
                text-opacity-90
                group px-4 py-2 border border-neutral-300 mx-2 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
              >
                Share
              </Link>
              <NcModal
                renderTrigger={openModal => (
                  <button
                    id='editFamilyQuiz'
                    className='text-lg'
                    onClick={() => {
                      setEditedFamilyQuizTitle(familyQuizTitle)
                      setEditedFamilyQuizDescription(familyQuizDescription)
                      setEditedQuestions(Questions)
                      openModal()
                    }}
                  >
                    edit
                  </button>
                )}
                renderContent={renderModalContent}
                modalTitle='Edit Family Quiz'
                closeConfirmationOption={true}
              />
            </div>
          </div>

          {/* PRICE */}
          <div className='flex-[2] whitespace-nowrap sm:text-right'>
            <div className='text-xs sm:text-sm text-neutral-500 font-normal mt-0.5'>Total Points</div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  )
}

export default ListingsFamilyQuizzesCard
